import { BagIcon, CalendarIcon, PeopleCopyIcon } from 'assets/harmonic-icons';
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import ChartIcon from 'assets/harmonic-icons/chart';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import RangeInput from 'harmonic-components/RangeInput/RangeInput';
import { useFieldSpec } from 'hooks/useFieldSpec';
import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import {
  FilterGroupGeneratorId,
  PeopleSearchCurrentExperienceGenerator,
  PeopleSearchPastExperienceGenerator,
  companyFundingTotalDefaultValue,
  companyTeamHeadcountRangeDefaultValue,
  fundingSliderStepRanges,
  headcountStepRanges
} from 'interfaces/SearchV2';
import { capitalize, isNil, omitBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  PeopleSearchExperienceGeneratorKey,
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import CollapsibleFieldWrapper from '../Common/CollapsibleFieldWrapper';
import CompanyMultiselectById from '../Common/CompanyMultiselectById';
import { TwoSidedRangeValue } from '../Common/CustomRange/DateModal';
import DateAddedToHarmonicSelect from '../Common/DateAddedToHarmonicSelect';
import DateWithSelect, { DateWithSelectMode } from '../Common/DateWithSelect';
import FoundingDateSelect from '../Common/FoundingDateSelect';
import IncludeExcludeMultiselect from '../Common/IncludeExcludeMultiselect';
import InvestorMultiselectByName from '../Common/InvestorMultiselectByName';
import KeywordsMultiselect from '../Common/KeywordsMultiselect';
import LastFundingDateSelect from '../Common/LastFundingDateSelect';
import ListMultiselect from '../Common/ListMultiselect';
import MultiselectWithMode from '../Common/MultiselectWithMode';
import TenureSelect from '../Common/TenureSelect';
import {
  PeopleSmallSectionHeader,
  PeopleSubSectionHeader,
  SectionDivider
} from '../Common/Typography';
import { getAllowedValuesFromFieldSpec } from '../Common/utils';
import CurrentCompanySectionExpandableButtons from './CurrentExperienceExpandableButtons';
import TenureFundingStageMultiselect from './TenureFundingStageMultiselect';

interface CurrentOrPastExperienceProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  expandedFields: PeopleSearchExperienceGeneratorKey[];
  onUpdateExpandedFields: (
    newExpandedFields: PeopleSearchExperienceGeneratorKey[]
  ) => void;
  mode: 'current' | 'past';
  disabled?: boolean;
}

const CurrentOrPastExperience: React.FC<CurrentOrPastExperienceProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  mode,
  expandedFields,
  onUpdateExpandedFields
}) => {
  // State to store the values before they were removed/deleted
  // When we decide to expand the filter again, it gets from the cache
  const [cachedExperienceState, setCachedExperienceState] =
    useState<null | PeopleSearchPastExperienceGenerator>(null);
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  let currentExperienceState = controlledSearchState?.[
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE
  ] as PeopleSearchCurrentExperienceGenerator;

  if (mode === 'past') {
    currentExperienceState = controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE
    ] as PeopleSearchPastExperienceGenerator;
  }

  useEffect(() => {
    if (isNil(cachedExperienceState) && !isNil(currentExperienceState)) {
      setCachedExperienceState(currentExperienceState);
    }
  }, [currentExperienceState, cachedExperienceState]);

  const updateFieldValuesFromCachedState = (
    fieldNames: PeopleSearchExperienceGeneratorKey[]
  ) => {
    // Updates the value from the cached state.
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cachedValues: Record<PeopleSearchExperienceGeneratorKey, any> = {};

    for (const fieldName of fieldNames) {
      const cachedValue = cachedExperienceState?.[fieldName];
      if (cachedValue) {
        cachedValues[fieldName] = cachedValue;
      }
    }
    updateValues(cachedValues);
  };

  const addNewExpandedField = (
    fieldNames: PeopleSearchExperienceGeneratorKey[]
  ) => {
    // Field names array includes multiple fields names for certain fields to account for include/exclude, any/none/all mode
    // We only need to add first field name in the array to the expanded fields
    // Rest of the array are for reading values from cached state
    const firstFieldName = fieldNames[0];
    updateFieldValuesFromCachedState(fieldNames);
    onUpdateExpandedFields([...expandedFields, firstFieldName]);
  };

  const updateValues = (
    newValues:
      | PeopleSearchCurrentExperienceGenerator
      | PeopleSearchPastExperienceGenerator,
    updateCachedQuery = true
  ) => {
    const newStateWithoutUndefinedFields = omitBy(
      {
        ...(currentExperienceState ?? {}),
        ...newValues,
        current_or_past: mode
      },
      (value) =>
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0)
    );

    const noArgumentsExist =
      Object.keys(newStateWithoutUndefinedFields).length === 1 &&
      Object.keys(newStateWithoutUndefinedFields)[0] === 'current_or_past';

    const updatedQuery = getUpdatedQuery(
      searchQuery,
      mode === 'current'
        ? FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE
        : FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE,
      noArgumentsExist ? undefined : newStateWithoutUndefinedFields
    );
    onUpdateSearchQuery(updatedQuery);
    if (updateCachedQuery) {
      setCachedExperienceState({
        ...cachedExperienceState,
        ...newValues
      });
    }
  };
  const removeFields = (fieldNames: PeopleSearchExperienceGeneratorKey[]) => {
    const newExpandedFields = expandedFields.filter(
      (field) => !fieldNames.includes(field)
    );
    onUpdateExpandedFields(newExpandedFields);

    const fieldsToUpdate: Partial<PeopleSearchCurrentExperienceGenerator> = {};
    fieldNames.forEach((fieldName) => {
      fieldsToUpdate[fieldName] = undefined;
    });

    updateValues(fieldsToUpdate, false);
  };
  const { fieldSpec } = useFieldSpec();

  const fieldSpecs = useMemo(() => {
    return fieldSpec?.filter(
      (fieldSpec) =>
        fieldSpec.allowed_comparators &&
        fieldSpec.allowed_comparators.length > 0
    );
  }, [fieldSpec]);

  const roleDepartmentOptions = useMemo(
    () =>
      getAllowedValuesFromFieldSpec(fieldSpecs, 'person_position_department'),
    [fieldSpecs]
  );

  const roleTypeOptions = useMemo(
    () =>
      getAllowedValuesFromFieldSpec(
        fieldSpecs,
        'person_position_role_type'
      ).map((option) => ({
        label: capitalize(option.label),
        value: option.value
      })),
    [fieldSpecs]
  );

  const technologyTagOptions = useMemo(
    () =>
      getAllowedValuesFromFieldSpec(
        fieldSpecs,
        'person_company_technology_type_tags'
      ),
    [fieldSpecs]
  );

  const industryTagOptions = useMemo(
    () =>
      getAllowedValuesFromFieldSpec(
        fieldSpecs,
        'person_company_market_vertical_type_tags'
      ),
    [fieldSpecs]
  );

  const customerTypeTagOptions = useMemo(
    () =>
      getAllowedValuesFromFieldSpec(
        fieldSpecs,
        'person_company_customer_type_tags'
      ),
    [fieldSpecs]
  );

  const roleTitleContainsAnyOfField = (
    <MultiselectWithMode
      label="Contains all of"
      selected={currentExperienceState?.role_title_contains_all_of || []}
      onChange={(newValues) =>
        updateValues({ role_title_contains_all_of: newValues ?? [] })
      }
      mode="select"
    />
  );

  const roleTitleContainsNoneOfField = (
    <MultiselectWithMode
      label="Contains none of"
      selected={currentExperienceState?.role_title_contains_none_of || []}
      onChange={(newValues) =>
        updateValues({ role_title_contains_none_of: newValues ?? [] })
      }
      mode="exclude"
    />
  );

  const roleDepartmentField = (
    <div>
      <CollapsibleFieldWrapper
        title="Role Department"
        onRemove={() =>
          removeFields(['role_department_any_of', 'role_department_none_of'])
        }
        closeBtnTestId="RoleDepartmentCloseBtn"
      >
        <IncludeExcludeMultiselect
          options={roleDepartmentOptions}
          included={currentExperienceState?.role_department_any_of || []}
          excluded={currentExperienceState?.role_department_none_of || []}
          onChangeInclude={(newValues) =>
            updateValues({ role_department_any_of: newValues ?? [] })
          }
          onChangeExclude={(newValues) =>
            updateValues({ role_department_none_of: newValues ?? [] })
          }
          dataTestId="RoleDepartmentSelect"
          placeholder="Select a role department (e.g. Product, Design, Engineering)"
        />
      </CollapsibleFieldWrapper>
    </div>
  );

  const roleTypeField = (
    <div>
      <CollapsibleFieldWrapper
        title="Role Type"
        onRemove={() => removeFields(['role_type_any_of', 'role_type_none_of'])}
      >
        <IncludeExcludeMultiselect
          options={roleTypeOptions}
          included={currentExperienceState?.role_type_any_of || []}
          excluded={currentExperienceState?.role_type_none_of || []}
          onChangeInclude={(newValues) =>
            updateValues({ role_type_any_of: newValues ?? [] })
          }
          onChangeExclude={(newValues) =>
            updateValues({ role_type_none_of: newValues ?? [] })
          }
          placeholder="Select a role type (e.g. Founder, Executive, Operator)"
        />
      </CollapsibleFieldWrapper>
    </div>
  );

  // Current Or Past Company fields
  const addedToHarmonicField = mode === 'current' && (
    <CollapsibleFieldWrapper
      title="Added to Harmonic"
      onRemove={() => {
        removeFields(['company_initialized_date_range_value']);
      }}
    >
      <DateAddedToHarmonicSelect
        selected={currentExperienceState?.company_initialized_date_range_value}
        customRangeValue={
          currentExperienceState?.company_initialized_date_custom_range as TwoSidedRangeValue
        }
        onChange={(newValue, newCustomValue) => {
          const updatedValues = {
            company_initialized_date_range_value: newValue
          };
          if (newCustomValue) {
            //eslint-disable-next-line
            //@ts-ignore
            updatedValues.company_initialized_date_custom_range =
              newCustomValue;
          } else {
            //eslint-disable-next-line
            //@ts-ignore
            updatedValues.company_initialized_date_custom_range = null;
          }
          updateValues(updatedValues);
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const totalFundingField = (
    <CollapsibleFieldWrapper
      title="Total funding"
      onRemove={() => {
        removeFields(['company_funding_range']);
      }}
    >
      <div className="w-[96%]">
        <RangeInput
          value={currentExperienceState?.company_funding_range}
          defaultValue={companyFundingTotalDefaultValue.range}
          sliderStepRanges={fundingSliderStepRanges}
          onValueChange={(newValues) =>
            updateValues({
              company_funding_range: newValues ?? undefined
            })
          }
          prefix="$"
        />
      </div>
    </CollapsibleFieldWrapper>
  );

  const headcountRangeField = (
    <CollapsibleFieldWrapper
      title="Headcount"
      onRemove={() => {
        removeFields(['company_headcount_range']);
      }}
    >
      <div className="w-[96%]">
        <RangeInput
          value={currentExperienceState?.company_headcount_range}
          defaultValue={companyTeamHeadcountRangeDefaultValue.range}
          sliderStepRanges={headcountStepRanges}
          onValueChange={(newValues) =>
            updateValues({
              company_headcount_range: newValues ?? undefined
            })
          }
        />
      </div>
    </CollapsibleFieldWrapper>
  );

  const investorsField = (
    <CollapsibleFieldWrapper
      title="Investors"
      onRemove={() => {
        removeFields(['company_investor_names_any_of']);
      }}
    >
      <InvestorMultiselectByName
        selected={currentExperienceState?.company_investor_names_any_of ?? []}
        onChange={(newValues) =>
          updateValues({ company_investor_names_any_of: newValues ?? [] })
        }
        placeholder="Search investor"
        mode="include"
      />
    </CollapsibleFieldWrapper>
  );

  const companyIsInListField = (
    <CollapsibleFieldWrapper
      title="Company lists"
      onRemove={() => {
        removeFields([
          'company_in_watchlist_ids_any_of',
          'company_in_watchlist_ids_none_of'
        ]);
      }}
    >
      <div className="flex gap-g60">
        <div className="w-1/2">
          <ListMultiselect
            mode="company_watchlist"
            selected={
              currentExperienceState?.company_in_watchlist_ids_any_of ?? []
            }
            onChange={(newValues) =>
              updateValues({ company_in_watchlist_ids_any_of: newValues ?? [] })
            }
            placeholder="Select company lists to include"
            separateAffinityLists
          />
        </div>
        <div className="w-1/2">
          <ListMultiselect
            mode="company_watchlist"
            selected={
              currentExperienceState?.company_in_watchlist_ids_none_of ?? []
            }
            onChange={(newValues) =>
              updateValues({
                company_in_watchlist_ids_none_of: newValues ?? []
              })
            }
            placeholder="Select company lists to exclude"
            excludeMode
            separateAffinityLists
          />
        </div>
      </div>
    </CollapsibleFieldWrapper>
  );

  const companyKeywordsField = (
    <CollapsibleFieldWrapper
      title="Company keywords"
      onRemove={() => {
        removeFields([
          'company_keywords_contains_any_of',
          'company_keywords_contains_all_of',
          'company_keywords_contains_none_of'
        ]);
      }}
    >
      <div className="flex flex-col gap-g40">
        <KeywordsMultiselect
          selected={
            currentExperienceState?.company_keywords_contains_any_of || []
          }
          onChange={(newValues) =>
            updateValues({
              company_keywords_contains_any_of: newValues ?? []
            })
          }
          mode="include"
          dataTestId="CurrentPastCompanyKeywords-anyof"
        />
        <KeywordsMultiselect
          selected={
            currentExperienceState?.company_keywords_contains_all_of || []
          }
          onChange={(newValues) =>
            updateValues({
              company_keywords_contains_all_of: newValues ?? []
            })
          }
          mode="select"
          dataTestId="CurrentPastCompanyKeywords-allof"
        />
        <KeywordsMultiselect
          selected={
            currentExperienceState?.company_keywords_contains_none_of || []
          }
          onChange={(newValues) =>
            updateValues({
              company_keywords_contains_none_of: newValues ?? []
            })
          }
          mode="exclude"
          dataTestId="CurrentPastCompanyKeywords-noneof"
        />
      </div>
    </CollapsibleFieldWrapper>
  );

  const lastFundingDateField = (
    <CollapsibleFieldWrapper
      title="Last funding date"
      onRemove={() => {
        removeFields(['company_last_funding_date_range_value']);
      }}
    >
      <LastFundingDateSelect
        selected={currentExperienceState?.company_last_funding_date_range_value}
        customRangeValue={
          currentExperienceState?.company_last_funding_date_custom_range as TwoSidedRangeValue
        }
        onChange={(newValue, newCustomValue) => {
          const updatedValues = {
            company_last_funding_date_range_value: newValue
          };
          if (newCustomValue) {
            //eslint-disable-next-line
            //@ts-ignore
            updatedValues.company_last_funding_date_custom_range =
              newCustomValue;
          } else {
            //eslint-disable-next-line
            //@ts-ignore
            updatedValues.company_last_funding_date_custom_range = null;
          }
          updateValues(updatedValues);
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const foundingDateField = (
    <CollapsibleFieldWrapper
      title="Founding date"
      onRemove={() => {
        removeFields(['company_founding_date_range_value']);
      }}
    >
      <FoundingDateSelect
        selected={currentExperienceState?.company_founding_date_range_value}
        customRangeValue={
          currentExperienceState?.company_founding_date_custom_range as TwoSidedRangeValue
        }
        onChange={(newValue, newCustomValue) => {
          const updatedValues = {
            company_founding_date_range_value: newValue
          };
          if (newCustomValue) {
            //eslint-disable-next-line
            //@ts-ignore
            updatedValues.company_founding_date_custom_range = newCustomValue;
          } else {
            //eslint-disable-next-line
            //@ts-ignore
            updatedValues.company_founding_date_custom_range = null;
          }
          updateValues(updatedValues);
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const industryField = (
    <CollapsibleFieldWrapper
      title="Industry"
      onRemove={() => {
        removeFields([
          'company_industry_tags_include_any_of',
          'company_industry_tags_exclude_all_of'
        ]);
      }}
    >
      <IncludeExcludeMultiselect
        options={industryTagOptions}
        included={
          currentExperienceState?.company_industry_tags_include_any_of || []
        }
        excluded={
          currentExperienceState?.company_industry_tags_exclude_all_of || []
        }
        onChangeInclude={(newValues) =>
          updateValues({
            company_industry_tags_include_any_of: newValues ?? []
          })
        }
        onChangeExclude={(newValues) =>
          updateValues({
            company_industry_tags_exclude_all_of: newValues ?? []
          })
        }
        placeholder="Select an industry (e.g. Industrial and manufacturing)"
      />
    </CollapsibleFieldWrapper>
  );

  const technologyField = (
    <CollapsibleFieldWrapper
      title="Technology"
      onRemove={() => {
        removeFields([
          'company_technology_tags_include_any_of',
          'company_technology_tags_exclude_all_of'
        ]);
      }}
    >
      <IncludeExcludeMultiselect
        options={technologyTagOptions}
        included={
          currentExperienceState?.company_technology_tags_include_any_of || []
        }
        excluded={
          currentExperienceState?.company_technology_tags_exclude_all_of || []
        }
        onChangeInclude={(newValues) =>
          updateValues({
            company_technology_tags_include_any_of: newValues ?? []
          })
        }
        onChangeExclude={(newValues) =>
          updateValues({
            company_technology_tags_exclude_all_of: newValues ?? []
          })
        }
        placeholder="Select technology (e.g. Artificial intelligence, Robotics, Hardware)"
      />
    </CollapsibleFieldWrapper>
  );

  const customerTypeField = (
    <CollapsibleFieldWrapper
      title="Customer Type"
      onRemove={() => {
        removeFields([
          'company_customer_type_tags_include_any_of',
          'company_customer_type_tags_exclude_all_of'
        ]);
      }}
    >
      <IncludeExcludeMultiselect
        options={customerTypeTagOptions}
        included={
          currentExperienceState?.company_customer_type_tags_include_any_of ||
          []
        }
        excluded={
          currentExperienceState?.company_customer_type_tags_exclude_all_of ||
          []
        }
        onChangeInclude={(newValues) =>
          updateValues({
            company_customer_type_tags_include_any_of: newValues ?? []
          })
        }
        onChangeExclude={(newValues) =>
          updateValues({
            company_customer_type_tags_exclude_all_of: newValues ?? []
          })
        }
        placeholder="Select a customer type (e.g. B2C, B2B, B2G)"
      />
    </CollapsibleFieldWrapper>
  );

  // Timeline fields
  const employmentDurationField = (
    <CollapsibleFieldWrapper
      title="Employment timeline"
      onRemove={() => {
        removeFields([
          'role_start_date_mode',
          'role_start_date_value',
          'role_end_date_mode',
          'role_end_date_value'
        ]);
      }}
    >
      <div
        className={classNames(mode === 'past' && 'grid grid-cols-2 gap-g40')}
      >
        <div className="flex flex-col gap-g20">
          <p className="text-content-weak typography-label">Start date</p>
          <DateWithSelect
            mode={
              currentExperienceState?.role_start_date_mode as DateWithSelectMode
            }
            value={
              currentExperienceState?.role_start_date_value as
                | string
                | [string, string]
                | null
                | undefined
            }
            onChange={(mode, value) => {
              if (isNil(value)) {
                return updateValues({
                  role_start_date_mode: undefined,
                  role_start_date_value: undefined
                });
              }
              updateValues({
                role_start_date_mode: mode,
                role_start_date_value: value as string | [string, string]
              });
            }}
            label="Add start date"
          />
        </div>
        {mode === 'past' && (
          <div className="flex flex-col gap-g20">
            <p className="text-content-weak typography-label">End date</p>
            <DateWithSelect
              mode={
                currentExperienceState?.role_end_date_mode as DateWithSelectMode
              }
              value={currentExperienceState?.role_end_date_value as string}
              onChange={(mode, value) => {
                if (isNil(value)) {
                  return updateValues({
                    role_end_date_mode: undefined,
                    role_end_date_value: undefined
                  });
                }
                updateValues({
                  role_end_date_mode: mode,
                  role_end_date_value: value as string
                });
              }}
              label="Add end date"
            />
          </div>
        )}
      </div>
    </CollapsibleFieldWrapper>
  );

  const tenureFundingStageField = (
    <CollapsibleFieldWrapper
      title="Funding stage experience"
      onRemove={() => {
        removeFields(['role_tenure_funding_stage_any_of']);
      }}
    >
      <TenureFundingStageMultiselect
        selected={
          currentExperienceState?.role_tenure_funding_stage_any_of ?? []
        }
        onChange={(newValues) =>
          updateValues({ role_tenure_funding_stage_any_of: newValues ?? [] })
        }
      />
    </CollapsibleFieldWrapper>
  );

  const tenureField = (
    <CollapsibleFieldWrapper
      title="Tenure"
      onRemove={() => {
        removeFields(['role_tenure_value', 'role_tenure_comparator']);
      }}
    >
      <TenureSelect
        selected={
          currentExperienceState?.role_tenure_value as [string, string] | string
        }
        comparator={currentExperienceState?.role_tenure_comparator}
        onChange={(comparator, newValue) =>
          updateValues({
            role_tenure_comparator: comparator,
            role_tenure_value: newValue
          })
        }
      />
    </CollapsibleFieldWrapper>
  );

  // All the expanded fields are kept track in array in particular order
  const roleTitleExpandedFields = expandedFields
    .map((field) => {
      if (field === 'role_title_contains_all_of') {
        return roleTitleContainsAnyOfField;
      }
      if (field === 'role_title_contains_none_of') {
        return roleTitleContainsNoneOfField;
      }
    })
    .filter((val) => val);

  const roleDepartmentAndTypeExpandedFields = expandedFields
    .map((field) => {
      if (field === 'role_department_any_of') {
        return roleDepartmentField;
      }
      if (field === 'role_type_any_of') {
        return roleTypeField;
      }
    })
    .filter((val) => val);

  const currentOrPastCompanyExpandedFields = expandedFields
    .map((field) => {
      if (field === 'company_initialized_date_range_value') {
        return addedToHarmonicField;
      }
      if (field === 'company_funding_range') {
        return totalFundingField;
      }
      if (field === 'company_headcount_range') {
        return headcountRangeField;
      }
      if (field === 'company_investor_names_any_of') {
        return investorsField;
      }
      if (field === 'company_in_watchlist_ids_any_of') {
        return companyIsInListField;
      }
      if (field === 'company_keywords_contains_any_of') {
        return companyKeywordsField;
      }
      if (field === 'company_last_funding_date_range_value') {
        return lastFundingDateField;
      }
      if (field === 'company_founding_date_range_value') {
        return foundingDateField;
      }
      if (field === 'company_industry_tags_include_any_of') {
        return industryField;
      }
      if (field === 'company_technology_tags_include_any_of') {
        return technologyField;
      }
      if (field === 'company_customer_type_tags_include_any_of') {
        return customerTypeField;
      }
    })
    .filter((val) => val);

  const timelineExpandedFields = expandedFields
    .map((field) => {
      if (field === 'role_start_date_value') {
        return employmentDurationField;
      }
      if (field === 'role_tenure_value') {
        return tenureField;
      }
      if (field === 'role_tenure_funding_stage_any_of') {
        return tenureFundingStageField;
      }
    })
    .filter((val) => val);

  return (
    <div className="w-full">
      <PeopleSmallSectionHeader>
        {mode === 'current' ? 'Current role' : 'Past role'}{' '}
      </PeopleSmallSectionHeader>
      <PeopleSubSectionHeader>Role title</PeopleSubSectionHeader>
      <div className="flex flex-col gap-g40">
        <MultiselectWithMode
          label="Contains any of"
          selected={currentExperienceState?.role_title_contains_any_of || []}
          onChange={(newValues) =>
            updateValues({ role_title_contains_any_of: newValues ?? [] })
          }
          mode="include"
        />
        {roleTitleExpandedFields}
        <div className="flex gap-g40">
          {!expandedFields.includes('role_title_contains_all_of') && (
            <Button
              type="secondary"
              label="Contains all of"
              emphasis="high"
              size="compact"
              onClick={() =>
                addNewExpandedField(['role_title_contains_all_of'])
              }
            />
          )}
          {!expandedFields.includes('role_title_contains_none_of') && (
            <Button
              type="secondary"
              label="Contains none of"
              emphasis="high"
              size="compact"
              onClick={() =>
                addNewExpandedField(['role_title_contains_none_of'])
              }
            />
          )}
        </div>
      </div>
      {roleDepartmentAndTypeExpandedFields.length > 0 && (
        <div className="mt-g80 flex flex-col gap-g80">
          {roleDepartmentAndTypeExpandedFields}
        </div>
      )}

      <div className="mt-g80 flex gap-g40">
        {!expandedFields.includes('role_department_any_of') && (
          <Button
            type="secondary"
            leadingIcon={BagIcon}
            label="Role department"
            emphasis="high"
            onClick={() =>
              addNewExpandedField([
                'role_department_any_of',
                'role_department_none_of'
              ])
            }
          />
        )}
        {!expandedFields.includes('role_type_any_of') && (
          <Button
            type="secondary"
            leadingIcon={PeopleCopyIcon}
            label="Role type"
            emphasis="high"
            onClick={() =>
              addNewExpandedField(['role_type_any_of', 'role_type_none_of'])
            }
          />
        )}
      </div>
      <div className="py-p80 my-p40">
        <SectionDivider />
      </div>

      <PeopleSmallSectionHeader>
        {mode === 'current' ? 'Current company' : 'Past company'}{' '}
      </PeopleSmallSectionHeader>
      <div className="flex flex-col gap-g40">
        <CompanyMultiselectById
          selected={
            currentExperienceState?.company_id_any_of?.map((id) => ({
              name: '',
              id
            })) ?? []
          }
          placeholder={
            currentExperienceState?.select_stealth_companies
              ? 'Unselect all stealth companies to search'
              : undefined
          }
          onChange={(newValues) =>
            updateValues({
              company_id_any_of:
                newValues?.map((value) => value.id as number) ?? []
            })
          }
          disabled={currentExperienceState?.select_stealth_companies}
          mode="include"
        />
        <Checkbox
          id="select-stealth-companies-checkbox"
          dataTestId="select-stealth-companies-checkbox"
          checked={currentExperienceState?.select_stealth_companies}
          label="Select all stealth companies"
          onChange={() =>
            updateValues({
              select_stealth_companies:
                !currentExperienceState?.select_stealth_companies
            })
          }
        />
      </div>

      {currentOrPastCompanyExpandedFields.length > 0 && (
        <div className="mt-g80 flex flex-col gap-g80">
          {currentOrPastCompanyExpandedFields}
        </div>
      )}
      <div className="mt-g80" data-visual-test="transparent">
        <CurrentCompanySectionExpandableButtons
          mode={mode}
          expandedFields={expandedFields}
          addNewExpandedField={addNewExpandedField}
        />
      </div>

      <div className="py-p80 my-p40">
        <SectionDivider />
      </div>

      <PeopleSmallSectionHeader>Timeline</PeopleSmallSectionHeader>

      {timelineExpandedFields.length > 0 && (
        <div className="flex flex-col gap-g80">{timelineExpandedFields}</div>
      )}

      <div className="mt-g80 flex gap-g30">
        {!expandedFields.includes('role_tenure_value') && (
          <Button
            type="secondary"
            label="Tenure"
            leadingIcon={CalendarIcon}
            emphasis="high"
            dataTestId="CurrentPastCompany-ExpandableButton-role_tenure_value"
            onClick={() =>
              addNewExpandedField([
                'role_tenure_value',
                'role_tenure_comparator'
              ])
            }
          />
        )}
        {!expandedFields.includes('role_start_date_value') && (
          <Button
            type="secondary"
            label="Employment timeline"
            leadingIcon={CalendarDaysIcon}
            emphasis="high"
            dataTestId="CurrentPastCompany-ExpandableButton-role_start_date_value"
            onClick={() =>
              addNewExpandedField([
                'role_start_date_value',
                'role_start_date_mode',
                'role_end_date_value',
                'role_end_date_mode'
              ])
            }
          />
        )}
        {!expandedFields.includes('role_tenure_funding_stage_any_of') && (
          <Button
            type="secondary"
            label="Funding stage"
            leadingIcon={ChartIcon}
            emphasis="high"
            onClick={() =>
              addNewExpandedField(['role_tenure_funding_stage_any_of'])
            }
            dataTestId="CurrentPastCompany-ExpandableButton-role_tenure_funding_stage_any_of"
          />
        )}
      </div>
    </div>
  );
};

export default CurrentOrPastExperience;
