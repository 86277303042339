import MyNetworkList from 'assets/harmonic-icons/my-network-list';
import NotInNetwork from 'assets/harmonic-icons/not-in-network';
import TeamNetworkList from 'assets/harmonic-icons/team-network-list';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import useFlags from 'hooks/useFlags';
import {
  ConnectionScope,
  FilterGroupGeneratorId,
  SearchData
} from 'interfaces/SearchV2';
import React from 'react';
import { SPLITS } from 'utils/constants';
import CorrespondencesSubSection from '../Common/CorrespondencesSubSection';

type CorrespondencesGeneratorParams =
  SearchData[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES];

interface ConnectionsSubSectionProps {
  generatorArgs: CorrespondencesGeneratorParams;
  onUpdateGeneratorArgs: (newArgs?: CorrespondencesGeneratorParams) => void;
}

const ConnectionsSubSection: React.FC<ConnectionsSubSectionProps> = ({
  generatorArgs,
  onUpdateGeneratorArgs
}) => {
  const { enabled: correspondencesEnabled } = useFlags(
    SPLITS.showCorrespondencesInSearch
  );

  return (
    <div className="flex flex-col gap-g50">
      <div className="flex items-start gap-g40 self-stretch">
        <RadioPill
          dataTestId="Any-Connections-Radio-Pill"
          onClick={() => {
            onUpdateGeneratorArgs({
              ...generatorArgs,
              connection_status: ConnectionScope.ANY
            });
          }}
          label="Any"
          selected={generatorArgs?.connection_status === ConnectionScope.ANY}
        />
        <RadioPill
          dataTestId="My-Connections-Radio-Pill"
          onClick={() => {
            onUpdateGeneratorArgs({
              ...generatorArgs,
              connection_status: ConnectionScope.MY_CONNECTIONS
            });
          }}
          leadingIcon={MyNetworkList}
          label="In my network"
          selected={
            generatorArgs?.connection_status === ConnectionScope.MY_CONNECTIONS
          }
        />
        <RadioPill
          dataTestId="Team-Connections-Radio-Pill"
          onClick={() => {
            onUpdateGeneratorArgs({
              ...generatorArgs,
              connection_status: ConnectionScope.TEAM_CONNECTIONS
            });
          }}
          leadingIcon={TeamNetworkList}
          label="In team network"
          selected={
            generatorArgs?.connection_status ===
            ConnectionScope.TEAM_CONNECTIONS
          }
        />
        <RadioPill
          dataTestId="No-Connections-Radio-Pill"
          onClick={() => {
            onUpdateGeneratorArgs({
              ...generatorArgs,
              connection_status: ConnectionScope.NO_CONNECTIONS
            });
          }}
          leadingIcon={NotInNetwork}
          label="Not in network"
          selected={
            generatorArgs?.connection_status === ConnectionScope.NO_CONNECTIONS
          }
        />
      </div>

      {correspondencesEnabled && (
        <CorrespondencesSubSection
          addTopMarginOnFieldOpen
          generatorArgs={generatorArgs}
          onUpdateGeneratorArgs={
            onUpdateGeneratorArgs as (
              args:
                | SearchData[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES]
                | SearchData[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES]
            ) => void
          }
        />
      )}
    </div>
  );
};

export default ConnectionsSubSection;
