import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import React from 'react';
import GeneralConnectionsSubSection from './GeneralConnectionsSubSection';
import GeneralHighlightsSubSection from './GeneralHighlightsSubSection';
import GeneralLinkedinHeadlineSubSection from './GeneralLinkedinHeadlineSubSection';
import GeneralListsSubSection from './GeneralListsSubSection';
import GeneralLocationSubSection from './GeneralLocationSubSection';

interface GeneralSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const GeneralSection: React.FC<GeneralSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled
}) => {
  const setLinkedInBannerDismissedState = (dismissed: boolean) => {
    localStorage.setItem(
      'LinkedInBannerDismissedState',
      JSON.stringify(dismissed)
    );
  };

  const getLinkedInBannerDismissedState = (): boolean => {
    const linkedinBannerDismissed = localStorage.getItem(
      'LinkedInBannerDismissedState'
    );
    return linkedinBannerDismissed
      ? JSON.parse(linkedinBannerDismissed)
      : false;
  };

  const [linkedInBannerDismissed, setLinkedInBannerDismissed] = React.useState(
    getLinkedInBannerDismissedState
  );

  const onDismissLinkedinBanner = () => {
    setLinkedInBannerDismissed(true);
    setLinkedInBannerDismissedState(true);
  };

  return (
    <div className="w-full flex flex-col items-start gap-g90 self-stretch">
      <GeneralHighlightsSubSection
        searchQuery={searchQuery}
        onUpdateSearchQuery={onUpdateSearchQuery}
        disabled={disabled}
      />
      <GeneralLocationSubSection
        searchQuery={searchQuery}
        onUpdateSearchQuery={onUpdateSearchQuery}
        disabled={disabled}
      />
      <GeneralConnectionsSubSection
        searchQuery={searchQuery}
        onUpdateSearchQuery={onUpdateSearchQuery}
        disabled={disabled}
      />
      <GeneralLinkedinHeadlineSubSection
        searchQuery={searchQuery}
        onUpdateSearchQuery={onUpdateSearchQuery}
        disabled={disabled}
      />
      <GeneralListsSubSection
        searchQuery={searchQuery}
        onUpdateSearchQuery={onUpdateSearchQuery}
        disabled={disabled}
      />
    </div>
  );
};

export default GeneralSection;
