import React from 'react';

import { SettingsGearIcon } from 'assets/harmonic-icons';
import {
  SectionDivider,
  SmallSectionHeader,
  SubSectionHeader
} from 'components/QueryBuilder/Common/Typography';
import Button from 'harmonic-components/Button/Button';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import {
  FilterGroupGeneratorId,
  SectorTagsRowTypes
} from 'interfaces/SearchV2';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import { SectorPreset, chipDisplayOrder } from './SectorPresets';
import SectorSectionTagsRowV2 from './SectorSectionTagsRowV2';

interface ISectorPresetsPillProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const SectorPresetsPill: React.FC<ISectorPresetsPillProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const [isExpanded, setIsExpanded] = React.useState(
    Boolean(
      controlledSearchState?.[
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET
      ]?.preset
    )
  );

  const [selectedPreset, setSelectedPreset] = React.useState<string>('');

  React.useEffect(() => {
    setSelectedPreset(
      controlledSearchState?.[
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET
      ]?.preset || ''
    );
  }, [controlledSearchState]);

  const onSectorPresetClick = (selected: boolean, preset: string) => {
    analytics.trackCustomEvent({
      event: CustomTrackEvent.SECTOR_PRESET_CLICK,
      properties: {
        selected,
        preset
      }
    });
    onUpdateSearchQuery(
      getUpdatedQuery(
        searchQuery,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET,
        !selected ? { preset: preset as SectorPreset } : undefined
      )
    );
  };

  if (!isExpanded) {
    return (
      <div className="flex">
        <Button
          type="secondary"
          leadingIcon={SettingsGearIcon}
          emphasis="high"
          label="Sector filters"
          onClick={() => setIsExpanded(true)}
          fullWidth={false}
        />
      </div>
    );
  }
  return (
    <>
      <SectionDivider />
      <SmallSectionHeader>Sector tags</SmallSectionHeader>
      <div className="flex flex-col gap-g70">
        <SubSectionHeader>Industry</SubSectionHeader>
        <SectorSectionTagsRowV2
          onUpdateSearchQuery={onUpdateSearchQuery}
          searchQuery={searchQuery}
          rowType={SectorTagsRowTypes.MARKET_VERTICAL}
          disabled={disabled}
        />
        <div>
          <SubSectionHeader>Technology</SubSectionHeader>
          <SectorSectionTagsRowV2
            onUpdateSearchQuery={onUpdateSearchQuery}
            searchQuery={searchQuery}
            rowType={SectorTagsRowTypes.TECHNOLOGY_TYPE}
            disabled={disabled}
          />
        </div>
        <div>
          <SubSectionHeader>Customer type</SubSectionHeader>
          <SectorSectionTagsRowV2
            onUpdateSearchQuery={onUpdateSearchQuery}
            searchQuery={searchQuery}
            rowType={SectorTagsRowTypes.CUSTOMER}
            disabled={disabled}
          />
        </div>
        <SubSectionHeader>Sector presets</SubSectionHeader>
        <div className="flex flex-col gap-g50">
          <div className="flex gap-g20 flex-wrap">
            {chipDisplayOrder.map((chipMeta) => (
              <RadioPill
                key={chipMeta.title}
                label={chipMeta.title}
                selected={selectedPreset === chipMeta.sectorPreset}
                leadingIcon={chipMeta.icon}
                onClick={() =>
                  onSectorPresetClick(
                    selectedPreset === chipMeta.sectorPreset,
                    chipMeta.sectorPreset
                  )
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectorPresetsPill;
