import { useCallback } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import { SortHookResponse } from 'utils/sort';

export const useCompanySearchSort = (): SortHookResponse => {
  const { editFilterStore, companyExplorePageSort } = useShallowFilterStore([
    'editFilterStore',
    'companyExplorePageSort'
  ]);

  const changeSort = useCallback(
    ({ field, descending }: { field: string; descending: boolean }) => {
      editFilterStore('companyExplorePageSort', {
        sortField: field,
        descending: descending
      });
    },
    [editFilterStore]
  );

  return {
    changeSort,
    sort: companyExplorePageSort
  };
};
