import { FlagIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Checkboxes, {
  CheckboxItem
} from 'components/QueryBuilder/Common/Checkboxes';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import Modal from 'harmonic-components/Modal/Modal';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextArea from 'harmonic-components/TextArea/TextArea';
import { UserReportedDataIssueInput } from 'interfaces/UserReportedDataIssue';
import { useState } from 'react';
import { submitDataIssue } from 'utils/hasuraApi';
import { displayToast } from 'utils/toasts';
import { Form } from './Form';

const DEFAULT_PLACEHOLDER_TEXT =
  'e.g. This company shows 2 funding rounds, but there are actually 3';

export const ReportDataIssueLegalDisclaimer = (props: {
  className?: string;
}) => {
  const { className } = props;
  return (
    <p
      className={classNames(
        className,
        'typography-label-small-default text-content-weak'
      )}
    >
      This feedback may be used to correct or verify our data.
    </p>
  );
};

type ReportDataIssueProps = {
  reportParams: Omit<UserReportedDataIssueInput, 'reportText'>;
  placeholderText?: string;
  showCircularBorder?: boolean;
  commonlyReportedIssues?: CheckboxItem[];
};

const ReportDataIssue = ({
  reportParams,
  placeholderText,
  commonlyReportedIssues
}: ReportDataIssueProps) => {
  //define state
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [textInput, setTextInput] = useState<string>('');
  const [selectedCommonlyReportedIssues, setSelectedCommonlyReportedIssues] =
    useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //define event handlers
  const onChangeSelectedCommonlyReportedIssues = (value: string) => {
    const index = selectedCommonlyReportedIssues.indexOf(value);
    if (index === -1) {
      //add value to array
      setSelectedCommonlyReportedIssues((prev) => [...prev, value]);
    } else {
      //remove value from array
      setSelectedCommonlyReportedIssues((prev) => {
        const newArray = [...prev];
        newArray.splice(index, 1);
        return newArray;
      });
    }
  };

  const onClose = () => {
    setDialogOpen(false);
    setSelectedCommonlyReportedIssues([]);
    setTextInput('');
    setLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    const reportText =
      selectedCommonlyReportedIssues.length > 0
        ? `[${selectedCommonlyReportedIssues.join(', ')}] ${textInput}`
        : textInput;
    try {
      submitDataIssue({ ...reportParams, reportText });
      displayToast({
        primaryText: 'Report submitted successfully',
        mode: 'success'
      });
    } catch (e) {
      displayToast({
        primaryText: 'There was an error submitting report',
        mode: 'error'
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const hasCommonlyReportedIssues = !!commonlyReportedIssues?.length;

  return (
    <div data-testid="ReportDataIssue">
      <IconButton
        icon={FlagIcon}
        onClick={() => setDialogOpen(true)}
        type="secondary"
        emphasis="high"
        size="default"
        dataTestId="ReportDataIssue-Button"
      />

      <Form onSubmit={onSubmit}>
        <Modal isOpen={dialogOpen} onClose={onClose}>
          <ModalTitle title="Suggest a correction" />
          <div data-testid="ReportDataIssue-Popover" className="p-p50">
            {hasCommonlyReportedIssues && (
              <>
                <div className="pb-p20">
                  <p className="typography-label-default text-content-weak">
                    Choose one or more of the commonly reported issues:
                  </p>
                </div>
                <div className="flex flex-col gap-g20 pb-p30">
                  <Checkboxes
                    dataTestId="ReportDataIssue-CommonlyReportedIssues-Checkboxes"
                    options={commonlyReportedIssues}
                    selectedValues={selectedCommonlyReportedIssues}
                    onValueChange={(newValues) =>
                      setSelectedCommonlyReportedIssues(newValues ?? [])
                    }
                    singleColumn
                  />
                </div>
                <div className="pb-p20">
                  <p className="typography-label-default text-content-weak">
                    Please provide any additional details.
                  </p>
                </div>
              </>
            )}
            <TextArea
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              placeholder={placeholderText ?? DEFAULT_PLACEHOLDER_TEXT}
              {...(hasCommonlyReportedIssues && { rows: 2 })}
            />
            <ReportDataIssueLegalDisclaimer className="pl-p10 pt-p30" />
          </div>

          <ModalFooter>
            <Button
              dataTestId="ReportDataIssue-Submit-Button"
              type="primary"
              label="Submit"
              isDisabled={loading}
              loading={loading}
              onClick={onSubmit}
            />
          </ModalFooter>
        </Modal>
      </Form>
    </div>
  );
};

export default ReportDataIssue;
