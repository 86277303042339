import { SelectionContentIcon } from 'assets/harmonic-icons';
import UnselectionContentIcon from 'assets/harmonic-icons/unselected-content';
import classNames from 'classnames';
import React from 'react';

interface SelectionPillProps {
  label: string;
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
}

const SelectionPill: React.FC<SelectionPillProps> = ({
  label,
  onClick,
  disabled,
  selected
}) => {
  return (
    <button
      data-testid={`selection-pill-button-${label.toLowerCase()}`}
      disabled={disabled}
      onClick={onClick}
      aria-selected={selected}
      className={classNames(
        'flex flex-row pl-p40 pr-p50 py-p20 gap-g30 items-center rounded-br90 bg-int-fill-secondary-enabled',
        'typography-label-default-default text-int-fill-secondary-onEnabled',
        'focus:shadow-int-focus-outside',
        'hover:bg-int-fill-secondary-hover active:bg-int-fill-secondary-pressed',
        'aria-selected:bg-int-fill-secondary-selected-enabled aria-selected:text-int-fill-secondary-onSelected aria-selected:hover:bg-int-fill-secondary-selected-hover aria-selected:active:bg-int-fill-secondary-selected-pressed',
        'disabled:bg-int-fill-secondary-disabled disabled:text-int-fill-secondary-onDisabled',
        'disabled:aria-selected:bg-int-fill-primary-disabled disabled:aria-selected:text-int-fill-secondary-onSelected'
      )}
    >
      {selected ? (
        <div
          data-testid={`selection-pill-selected-icon-${label.toLowerCase()}`}
        >
          <SelectionContentIcon />
        </div>
      ) : (
        <div
          data-testid={`selection-pill-unselected-icon-${label.toLowerCase()}`}
        >
          <UnselectionContentIcon />
        </div>
      )}
      {label}
    </button>
  );
};

export default SelectionPill;
