import classNames from 'classnames';
import { IconProps } from './type';

const ExcludeInvertedIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25002 7.87029C2.25002 7.73221 2.36194 7.62029 2.50002 7.62029H9.20798C9.34605 7.62029 9.45798 7.73221 9.45798 7.87029V10.1828C9.45798 10.3209 9.34605 10.4328 9.20798 10.4328H2.50002C2.36194 10.4328 2.25002 10.3209 2.25002 10.1828V7.87029ZM6.70896 2.25662C6.57089 2.25662 6.45896 2.36855 6.45896 2.50662V15.5464C6.45896 15.6845 6.57089 15.7964 6.70896 15.7964H9.02146C9.15953 15.7964 9.27146 15.6845 9.27146 15.5464V12.2684C9.27146 12.0457 9.54074 11.9342 9.69823 12.0916L12.6173 15.0108C12.715 15.1084 12.8733 15.1084 12.9709 15.0108L14.6061 13.3756C14.7037 13.2779 14.7037 13.1196 14.6061 13.022L12.4436 10.8596C12.2861 10.7021 12.3977 10.4328 12.6204 10.4328H15.5002C15.6382 10.4328 15.7502 10.3209 15.7502 10.1828V7.87029C15.7502 7.73221 15.6382 7.62029 15.5002 7.62029H12.6204C12.3977 7.62029 12.2862 7.351 12.4437 7.19351L14.6061 5.03109C14.7037 4.93345 14.7037 4.77516 14.6061 4.67753L12.9709 3.04235C12.8733 2.94472 12.715 2.94472 12.6173 3.04235L9.69823 5.96145C9.54074 6.11894 9.27146 6.0074 9.27146 5.78468V2.50662C9.27146 2.36855 9.15953 2.25662 9.02146 2.25662H6.70896Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default ExcludeInvertedIcon;
