import { useApolloClient, useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';
import {
  GetCompanyNotesQuery,
  GetCompanyNotesQueryVariables
} from '__generated__/graphql';
import { NoteTextIcon } from 'assets/harmonic-icons';
import axios, { CancelTokenSource } from 'axios';
import classnames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { CompanyCustomTextScope } from 'interfaces/DataModel/Company';
import { debounce } from 'lodash';
import { getCompanyNotes } from 'queries/getCompanyNotes';
import React, { useCallback, useEffect, useState } from 'react';
import { NOTE_SAVE_ERROR } from 'utils/constants';
import { logger } from 'utils/logger';
import { updateCompanyCustomText } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import CompanyIconButtonActionLoader from './CompanyIconButtonActionLoader';

interface CompanyNotesProps {
  companyId: number;
}

const CompanyNotesButton: React.FC<CompanyNotesProps> = ({ companyId }) => {
  const client = useApolloClient();
  const [notesPopoverAnchorEl, setNotesPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const NotesPopoverOpen = Boolean(notesPopoverAnchorEl);
  const handleNotesPopoverClose = () => {
    setNotesPopoverAnchorEl(null);
  };
  const handleNotesPopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setNotesPopoverAnchorEl(event.currentTarget);
  };
  const { data, loading } = useQuery<
    GetCompanyNotesQuery,
    GetCompanyNotesQueryVariables
  >(getCompanyNotes, {
    variables: { id: companyId },
    fetchPolicy: 'no-cache'
  });
  const [userNotesValue, setUserNotesValue] = useState('');
  const [teamNotesValue, setTeamNotesValue] = useState('');

  const [userNoteCancelToken, setUserNoteCancelToken] =
    useState<null | CancelTokenSource>(null);
  const [teamNoteCancelToken, setTeamNoteCancelToken] =
    useState<null | CancelTokenSource>(null);

  const [tabValue, setTabValue] = useState<string>('my_notes');
  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const cancelToken = axios.CancelToken;

  const debouncedSaveUserNoteFunction = useCallback(
    debounce(async (newNote) => {
      const source = cancelToken.source();
      //Cancel previous request to avoid race condition
      if (userNoteCancelToken) userNoteCancelToken.cancel();
      setUserNoteCancelToken(source);

      try {
        await updateCompanyCustomText(
          client,
          companyId,
          newNote,
          CompanyCustomTextScope.USER,
          source.token
        );
      } catch (err) {
        if (!axios.isCancel(err)) {
          displayToast({
            primaryText: 'Error saving note',
            mode: 'error'
          });
          logger.error('Company user note save error', {
            error: err
          });
        }
      }
    }, 500),
    []
  );
  const debouncedSaveTeamNoteFunction = useCallback(
    debounce(async (newNote) => {
      const source = cancelToken.source();
      //Cancel previous request to avoid race condition
      if (teamNoteCancelToken) teamNoteCancelToken.cancel();
      setTeamNoteCancelToken(source);

      try {
        await updateCompanyCustomText(
          client,
          companyId,
          newNote,
          CompanyCustomTextScope.TEAM,
          source.token
        );
      } catch (err) {
        if (!axios.isCancel(err)) {
          displayToast({
            primaryText: NOTE_SAVE_ERROR,
            mode: 'error'
          });
          logger.error('Company team note save error', {
            error: err
          });
        }
      }
    }, 500),
    []
  );

  const handleUserNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteValue = e.target.value;
    setUserNotesValue(newNoteValue);
    debouncedSaveUserNoteFunction(newNoteValue);
  };
  const handleTeamNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteValue = e.target.value;
    setTeamNotesValue(newNoteValue);
    debouncedSaveTeamNoteFunction(newNoteValue);
  };

  useEffect(() => {
    if (!loading) {
      setUserNotesValue(data?.getCompanyById?.userNotes || '');
      setTeamNotesValue(data?.getCompanyById?.teamNotes || '');
    }
  }, [data, loading]);

  if (loading) return <CompanyIconButtonActionLoader />;

  return (
    <div data-testid="CompanyNotesButton">
      <IconButton
        icon={NoteTextIcon}
        onClick={handleNotesPopoverOpen}
        type="secondary"
        emphasis="high"
        size="default"
      />
      <Popover
        open={NotesPopoverOpen}
        anchorEl={notesPopoverAnchorEl}
        onClose={handleNotesPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className="mt-1 max-h-96"
        classes={{
          paper: 'shadow-static-elevation-floating'
        }}
        onClick={(e) => e.stopPropagation()}
        disableAutoFocus
        disableEnforceFocus
      >
        <div data-testid="CompanyNotes" className="bg-white w-96 rounded-md">
          <Tabs
            tabs={[
              {
                label: 'My Notes',
                value: 'my_notes',
                variation: 'label'
              },
              {
                label: 'Team Notes',
                value: 'team_notes',
                variation: 'label'
              }
            ]}
            selectedTabValue={tabValue}
            onTabChange={handleTabChange}
          />
          <div className="p-p20">
            {tabValue === 'my_notes' && (
              <textarea
                rows={7}
                value={userNotesValue}
                onChange={handleUserNoteChange}
                placeholder="Empty"
                className={classnames(
                  'mt-2 p-2 rounded-md text-content-default typography-label leading-4 bg-transparent w-full',
                  'resize-none border border-solid border-transparent'
                )}
              />
            )}

            {tabValue === 'team_notes' && (
              <textarea
                rows={7}
                value={teamNotesValue}
                onChange={handleTeamNoteChange}
                placeholder="Empty"
                className={classnames(
                  'mt-2 p-2 rounded-md text-content-default typography-label leading-4 bg-transparent w-full',
                  'resize-none border border-solid border-transparent'
                )}
              />
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default CompanyNotesButton;
