import { Reference, useApolloClient, useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';
import {
  GetCompanyProfileHeaderQuery,
  GetCompanyProfileHeaderQueryVariables
} from '__generated__/graphql';
import { ReactComponent as AffinityConfirmationImage } from 'assets/affinity-confirmation-image.svg';
import { AffinityIcon, CircleCheckIcon, ListIcon } from 'assets/harmonic-icons';
import { Tooltip } from 'common/components/Tooltip';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import Select from 'harmonic-components/Select/Select';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import { AffinityOwner, StatusOption } from 'interfaces/Affinity';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import {
  getAffinityWatchlistStatusOwnerOptions,
  getCustomerAffinityApiKeyIsSet,
  pushToAffinity,
  removeFromAffinity
} from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import { HarmonicLoader } from '../ResultsWrapper/LoadingOverlay';
import AddCompanytoWatchlistButtonContentLoader from './AddCompanyToWatchlistButtonContentLoader';

interface AddCompanyToAffinityButtonProps {
  companyId: number;
  isDisabled: boolean;
  tooltipText?: string;
}
const AddCompanyToAffinityButton: React.FC<AddCompanyToAffinityButtonProps> = ({
  companyId,
  isDisabled,
  tooltipText
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const { affinityLists } = useFetchWatchlists();
  const { getWatchlistContainingCompanies } = useWatchlistActions();
  const { show } = useModal();
  const [isAffinityIntegrated, setIsAffinityIntegrated] = useState(false);
  const [
    loadingAffinityIntegrationStatus,
    setLoadingAffinityIntegrationStatus
  ] = useState(false);

  const { enabled: affinityIntegrationMocked } = useFlags(
    SPLITS.mockAffinityFrontend
  );

  useEffect(() => {
    setLoadingAffinityIntegrationStatus(true);
    if (affinityIntegrationMocked) {
      setIsAffinityIntegrated(true);
      setLoadingAffinityIntegrationStatus(false);
      return;
    }
    getCustomerAffinityApiKeyIsSet()
      .then((isSet) => {
        if (isSet) {
          setIsAffinityIntegrated(isSet);
        }
      })
      .finally(() => setLoadingAffinityIntegrationStatus(false));
  }, [affinityIntegrationMocked]);

  const handleClose = () => {
    setFilterTerm('');
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleAddToCRMClick = () => {
    show(<CRMNotSetUpYet />);
  };

  const open = Boolean(anchorEl);
  const affinityListsContainingCompany = getWatchlistContainingCompanies(
    affinityLists,
    [companyId]
  );

  const sortedAffinitiyLists = affinityLists.sort((a, b) => {
    const isSelectedA = affinityListsContainingCompany.some(
      (affinityWatchlistIdContainingCompany) =>
        affinityWatchlistIdContainingCompany === a.id
    );

    const isSelectedB = affinityListsContainingCompany.some(
      (affinityWatchlistIdContainingCompany) =>
        affinityWatchlistIdContainingCompany === b.id
    );

    // If one of them is selected and the other is not, the selected one should come first
    if (isSelectedA && !isSelectedB) {
      return -1;
    } else if (!isSelectedA && isSelectedB) {
      return 1;
    } else {
      // If both are selected or both are not selected, sort alphabetically
      return a.name.localeCompare(b.name);
    }
  });
  const filteredAffinityLists = sortedAffinitiyLists.filter((affinityList) =>
    affinityList.name.toLowerCase().includes(filterTerm.toLocaleLowerCase())
  );

  if (loadingAffinityIntegrationStatus) {
    return <AddCompanytoWatchlistButtonContentLoader />;
  }

  if (!isAffinityIntegrated) {
    return <Button onClick={handleAddToCRMClick} label="Add to CRM" />;
  }

  return (
    <>
      <Tooltip title={tooltipText}>
        <Button
          dataTestId="AddCompanyToAffinityButton"
          leadingIcon={
            affinityListsContainingCompany.length > 0
              ? CircleCheckIcon
              : AffinityIcon
          }
          onClick={handleOpen}
          label={
            affinityListsContainingCompany.length > 0
              ? 'In Affinity'
              : 'Add to Affinity'
          }
          isDisabled={isDisabled}
          isSelected={open}
        />
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className="mt-p40"
      >
        <div className="w-[264px] truncate line-clamp-1">
          <Dropdown
            filterPlaceholder="Search Affinity lists"
            filterTerm={filterTerm}
            onFilterTermChange={(value) => setFilterTerm(value)}
          >
            {filteredAffinityLists.map((affinityList) => {
              const isSelected = affinityListsContainingCompany.some(
                (affinityWatchlistIdContainingCompany) =>
                  affinityWatchlistIdContainingCompany === affinityList.id
              );
              return (
                <ListItem
                  key={affinityList.id}
                  variant={ListVariant.checkboxes}
                  label={affinityList.name}
                  value={affinityList.id}
                  onChange={() => {
                    handleClose();
                    if (isSelected) {
                      show(
                        <RemoveCompanyFromAffinity
                          companyId={companyId}
                          affinityListId={affinityList.id}
                        />
                      );
                    } else {
                      show(
                        <AddCompanyToAffinityDetails
                          companyId={companyId}
                          affinityListId={affinityList.id}
                        />
                      );
                    }
                  }}
                  selected={isSelected}
                  primaryIcon={AffinityIcon}
                />
              );
            })}
          </Dropdown>
        </div>
      </Popover>
    </>
  );
};

const CRMNotSetUpYet: React.FC = () => {
  const { close } = useModal();
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate('/settings?t=integrations');
    close();
  };

  return (
    <div>
      <ModalTitle title="No CRM integrated yet" />
      <div className="flex flex-col items-start self-stretch p-p60 max-w-[360px]">
        <p className="text-content-weak typography-label-default-default">
          Please integrate with your CRM first. Please visit the setup page to
          go through the setup.
        </p>
      </div>
      <ModalFooter>
        <Button label="Cancel" onClick={() => close()} />
        <Button
          type="primary"
          label="Set up CRM Integration"
          onClick={onSubmit}
        />
      </ModalFooter>
    </div>
  );
};

interface RemoveCompanyFromAffinityProps {
  companyId: number;
  affinityListId: string;
}
const RemoveCompanyFromAffinity: React.FC<RemoveCompanyFromAffinityProps> = ({
  companyId,
  affinityListId
}) => {
  const client = useApolloClient();
  const { data: companyProfileData } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only'
  });
  const { affinityLists } = useFetchWatchlists();
  const { close } = useModal();
  const selectedAffinityList = affinityLists.find(
    (affinityList) => affinityList.id === affinityListId
  );
  const [loading, setLoading] = useState(false);
  const companyName = companyProfileData?.getCompanyById?.name ?? '';
  const affinityListName = selectedAffinityList?.name ?? '';

  const { enabled: affinityIntegrationMocked } = useFlags(
    SPLITS.mockAffinityFrontend
  );

  const removeCompanyFromAffinityListCache = (
    affinityListId: string,
    companyId: number
  ) => {
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Company',
        id: companyId
      }), // The id of the company in the cache.
      fields: {
        watchlists: (existingWatchlists) => {
          return existingWatchlists.filter(
            (watchlistRef: Reference) =>
              client.cache.identify({
                __typename: 'CompanyWatchlist',
                id: affinityListId
              }) !== watchlistRef.__ref
          );
        }
      }
    });
  };

  const onRemove = async () => {
    setLoading(true);
    if (affinityIntegrationMocked) {
      removeCompanyFromAffinityListCache(affinityListId, companyId);
      displayToast({
        primaryText: 'Successfully removed',
        mode: 'success'
      });
      setLoading(false);
      close();
      return;
    }
    await removeFromAffinity(affinityListId, companyId);
    removeCompanyFromAffinityListCache(affinityListId, companyId);
    displayToast({
      primaryText: 'Successfully removed',
      mode: 'success'
    });
    setLoading(false);
    close();
  };

  return (
    <div className="">
      <ModalTitle title={`Remove from Affinity list`} />
      <div className="pb-p80 pt-p20 px-p20 max-w-[360px]">
        <p className="text-content-default typography-paragraph-default-default p-p40">
          Are you sure you want to remove {companyName} from {affinityListName}?
        </p>
      </div>
      <ModalFooter>
        <Button label="Cancel" onClick={() => close()} />
        <Button
          type="negative"
          label="Remove from Affinity list"
          loading={loading}
          onClick={onRemove}
        />
      </ModalFooter>
    </div>
  );
};

interface AddCompanyToAffinityDetailsProps {
  companyId: number;
  affinityListId: string;
}
const AddCompanyToAffinityDetails: React.FC<
  AddCompanyToAffinityDetailsProps
> = ({ companyId, affinityListId }) => {
  const client = useApolloClient();
  const { data: companyProfileData } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only'
  });
  const { affinityLists } = useFetchWatchlists();
  const { close, show } = useModal();
  const [currentlySelectedAffinityList, setCurrentlySelectedAffinityList] =
    useState<string>(affinityListId);
  const [currentlySelectedStatus, setCurrentlySelectedStatus] =
    useState<string>('');
  const [currentlySelectedOwners, setCurrentlySelectedOwners] = useState<
    string[]
  >([]);
  const [owners, setOwnerOptions] = useState<AffinityOwner[]>([]);
  const [status_options, setStatusOptions] = useState<StatusOption[]>([]);
  const [pushToAffinityLoading, setPushToAffinityLoading] = useState(false);
  const [fetchConfigLoading, setFetchConfigLoading] = useState(false);
  const companyName = companyProfileData?.getCompanyById?.name ?? '';

  const { enabled: affinityIntegrationMocked } = useFlags(
    SPLITS.mockAffinityFrontend
  );

  const addCompanyToAffinityListCache = (
    affinityListId: string,
    companyId: number
  ) => {
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Company',
        id: companyId
      }), // The id of the company in the cache.
      fields: {
        watchlists: (existingWatchlists) => {
          return [
            ...existingWatchlists,
            // Add the reference to the watchlist in the cache
            {
              __ref: client.cache.identify({
                __typename: 'CompanyWatchlist',
                id: affinityListId
              })
            }
          ];
        }
      }
    });
  };

  const onSubmit = async () => {
    setPushToAffinityLoading(true);
    if (affinityIntegrationMocked) {
      addCompanyToAffinityListCache(currentlySelectedAffinityList, companyId);
      show(
        <AddCompanyToAffinityConfirmation
          companyId={companyId}
          affinityListId={currentlySelectedAffinityList}
          listUrl={`https://www.harmonic.ai/affinity/lists/${currentlySelectedAffinityList}`}
          entityViewUrl={`https://www.harmonic.ai/affinity/entities/${companyId}`}
        />
      );
      setPushToAffinityLoading(false);
      return;
    }
    try {
      const resp = await pushToAffinity(
        currentlySelectedAffinityList,
        [companyId],
        currentlySelectedOwners,
        currentlySelectedStatus
      );
      const affinityCompanyUrl =
        resp.results[`urn:harmonic:company:${companyId}`]?.entity_view_url;
      addCompanyToAffinityListCache(currentlySelectedAffinityList, companyId);
      show(
        <AddCompanyToAffinityConfirmation
          companyId={companyId}
          affinityListId={currentlySelectedAffinityList}
          listUrl={resp.list_url}
          entityViewUrl={affinityCompanyUrl}
        />
      );
    } catch (err) {
      logger.error('Error adding company to Affinity', {
        error: err
      });
      displayToast({
        primaryText: 'Error adding company to Affinity. Please try again later',
        mode: 'error'
      });
    } finally {
      setPushToAffinityLoading(false);
    }
  };

  useEffect(() => {
    setFetchConfigLoading(true);
    if (affinityIntegrationMocked) {
      setOwnerOptions([
        {
          urn: 'urn:harmonic:user:1',
          first_name: 'Carter',
          last_name: 'Headley',
          primary_email: 'carter@harmonic.ai'
        },
        {
          urn: 'urn:harmonic:user:2',
          primary_email: 'max@harmonic.ai',
          first_name: 'Max',
          last_name: 'Ruderman'
        }
      ]);
      setStatusOptions([
        {
          id: '1',
          text: 'New'
        },
        {
          id: '2',
          text: 'Qualified'
        }
      ]);
      setCurrentlySelectedStatus('1');
      setCurrentlySelectedOwners(['urn:harmonic:user:1']);
      setFetchConfigLoading(false);
      return;
    }
    getAffinityWatchlistStatusOwnerOptions(currentlySelectedAffinityList).then(
      (options) => {
        setOwnerOptions(options.owners);
        setStatusOptions(options.status_options);
        if (options.suggested_status) {
          setCurrentlySelectedStatus(options.suggested_status);
        }
        if (options.suggested_owners && options.suggested_owners.length > 0) {
          setCurrentlySelectedOwners(options.suggested_owners);
        }
        setFetchConfigLoading(false);
      }
    );
  }, [
    affinityListId,
    currentlySelectedAffinityList,
    affinityIntegrationMocked
  ]);

  const [ownersFilterTerm, setOwnersFilterTerm] = useState<string>('');
  const filteredOwners = owners.filter((owner) => {
    const fullName = owner.first_name + ' ' + owner.last_name;
    return fullName.toLowerCase().includes(ownersFilterTerm.toLowerCase());
  });
  const sortedOwners = filteredOwners.sort((a, b) =>
    (a.first_name + ' ' + a.last_name).localeCompare(
      b.first_name + ' ' + b.last_name
    )
  );

  if (fetchConfigLoading || pushToAffinityLoading) {
    return (
      <div>
        <ModalTitle title={`Add ${companyName} to Affinity list`} />
        <div className="w-[440px] h-[256px] flex items-center justify-center">
          <HarmonicLoader showText={!pushToAffinityLoading} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ModalTitle title={`Add ${companyName} to Affinity list`} />
      <div className="p-p60">
        <div className="flex flex-col items-start gap-g50 self-stretch">
          {/* Affinity List Selector */}
          <div className="flex flex-col items-start gap-g20 self-stretch">
            <p className="text-content-weak typography-label-default-default">
              Affinity list
            </p>
            <Select
              fullWidth={true}
              getLabelFromValue={(value) =>
                affinityLists.find((al) => al.id === value)?.name ?? ''
              }
              multiple={false}
              selected={currentlySelectedAffinityList}
            >
              {affinityLists.map((affinityList) => (
                <ListItem
                  primaryIcon={ListIcon}
                  key={affinityList.id}
                  variant={ListVariant.default}
                  label={affinityList.name}
                  value={affinityList.id}
                  onClick={() => {
                    setCurrentlySelectedAffinityList(affinityList.id);
                  }}
                  selected={affinityList.id === currentlySelectedAffinityList}
                />
              ))}
            </Select>
            <p className="text-content-weak typography-label-default-default">
              Harmonic is only able to display lists you have{' '}
              <a className="text-content-strong typography-paragraph-default-link cursor-pointer">
                imported
              </a>
              .
            </p>
          </div>

          {/* Status Selector */}
          <div className="flex flex-col items-start gap-g20 self-stretch">
            <p className="text-content-weak typography-label-default-default">
              Status
            </p>
            <Select
              fullWidth={true}
              multiple={false}
              selected={currentlySelectedStatus}
              getLabelFromValue={(id) =>
                status_options.find((s) => s.id === id)?.text ?? ''
              }
            >
              {status_options.map((status) => (
                <ListItem
                  key={status.id}
                  variant={ListVariant.default}
                  label={status.text}
                  value={status.id}
                  onClick={() => setCurrentlySelectedStatus(status.id)}
                  selected={status.id === currentlySelectedStatus}
                />
              ))}
            </Select>
          </div>

          {/* Owners Selector */}
          <div className="flex flex-col items-start gap-g20 self-stretch">
            <p className="text-content-weak typography-label-default-default">
              Owners
            </p>
            <Select
              getLabelFromValue={(value) => {
                const owner = owners.find((o) => o.urn.toString() === value);
                return owner?.first_name + ' ' + owner?.last_name;
              }}
              fullWidth={true}
              multiple={true}
              selected={currentlySelectedOwners.map((owner) =>
                owner.toString()
              )}
              onRemove={(key) =>
                setCurrentlySelectedOwners((prev) =>
                  prev.filter((p) => p.toString() !== key)
                )
              }
              filterTerm={ownersFilterTerm}
              onFilterTermChange={(value) => setOwnersFilterTerm(value)}
              filterable={true}
            >
              {sortedOwners.map((owner) => (
                <ListItem
                  key={owner.urn}
                  variant={ListVariant.checkboxes}
                  label={owner.first_name + ' ' + owner.last_name}
                  value={owner.urn}
                  onChange={() => {
                    setCurrentlySelectedOwners((prev) =>
                      prev.some((p) => p === owner.urn)
                        ? prev.filter((p) => p !== owner.urn)
                        : [...prev, owner.urn]
                    );
                    setOwnersFilterTerm('');
                  }}
                  selected={currentlySelectedOwners.some(
                    (p) => p === owner.urn
                  )}
                />
              ))}
            </Select>
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button label="Cancel" onClick={() => close()} />
        <Button
          type="primary"
          label="Add to Affinity list"
          onClick={onSubmit}
        />
      </ModalFooter>
    </div>
  );
};

interface AddCompanyToAffinityConfirmationProps {
  companyId: number;
  affinityListId: string;
  listUrl?: string;
  entityViewUrl?: string;
}
const AddCompanyToAffinityConfirmation: React.FC<
  AddCompanyToAffinityConfirmationProps
> = ({ companyId, affinityListId, listUrl, entityViewUrl }) => {
  const { data: companyProfileData } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only'
  });
  const { affinityLists } = useFetchWatchlists();
  const { close } = useModal();
  const selectedAffinityList = affinityLists.find(
    (affinityList) => affinityList.id === affinityListId
  );
  const companyName = companyProfileData?.getCompanyById?.name ?? '';
  const affinityListName = selectedAffinityList?.name ?? '';

  return (
    <div>
      <ModalTitle title={`Add ${companyName} to Affinity list`} />
      <div className="h-[256px] px-[56px] flex items-center justify-center">
        <div className="flex flex-col items-center gap-g80">
          <div className="flex flex-col justify-center items-center gap-g40">
            <AffinityConfirmationImage />
            <p className="text-content-title typography-title-extraSmall">
              {companyName} has been successfully added to {affinityListName}
            </p>
          </div>
          <div className="flex gap-g20">
            {entityViewUrl && (
              <a href={entityViewUrl} onClick={(e) => e.preventDefault()}>
                <Button
                  label={`View ${companyName} in Affinity`}
                  onClick={() => window.open(entityViewUrl)}
                  emphasis="medium"
                />
              </a>
            )}
            {listUrl && (
              <a href={listUrl} onClick={(e) => e.preventDefault()}>
                <Button
                  label={`Go to list in Affinity`}
                  onClick={() => window.open(listUrl)}
                  emphasis="medium"
                />
              </a>
            )}
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button label="Close" type="primary" onClick={() => close()} />
      </ModalFooter>
    </div>
  );
};

export default AddCompanyToAffinityButton;
