import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

interface AccordionProps {
  open?: boolean;
}
const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  open,
  children
}) => {
  const [childrenHeight, setChildrenHeight] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef.current) {
      setChildrenHeight(parentRef.current.clientHeight);
    }
  }, [parentRef.current, children]);

  return (
    <div
      aria-expanded={open}
      className={classNames('overflow-hidden duration-300')}
      style={{
        maxHeight: open ? childrenHeight : 0
      }}
    >
      <div ref={parentRef}>{children}</div>
    </div>
  );
};

export default Accordion;
