import { Location as GraphQLLocation } from '__generated__/graphql';
import { useState } from 'react';
import { LocationStateType } from '../AddLocation';
import { chipDisplayOrder } from '../RegionPresets';
import { parseSelectedLocations } from '../utils/utils';

interface UseLocationStateProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange: (newLocationState?: any) => void;
  locationState: LocationStateType;
}

interface handleLocationChangeProps {
  selected: boolean;
  location: GraphQLLocation;
}

export const useLocationState = ({
  onValueChange,
  locationState
}: UseLocationStateProps) => {
  const [locationFilterTerm, setLocationFilterTerm] = useState('');

  const handleRegionClick = (region: string) => {
    const selectedPresets = locationState?.region || [];
    const newRegions = !selectedPresets.includes(region)
      ? [...selectedPresets, region]
      : selectedPresets.filter((preset) => preset !== region);

    const newLocation = {
      ...locationState,
      region: newRegions
    };
    onValueChange(newLocation);
  };

  const handleOnRemoveClick = (itemToRemove: string) => {
    // get type
    const type = parseSelectedLocations(locationState).locationObject.find(
      (selected) => {
        return selected.text === itemToRemove;
      }
    )?.type;

    if (!type) return;

    if (locationState) {
      let newLocation: LocationStateType = {};

      switch (type) {
        case 'locations': {
          const newLocations = locationState.locations?.filter(
            (selected) => selected.text !== itemToRemove
          );
          newLocation = {
            ...locationState,
            locations: newLocations
          };
          break;
        }
        case 'excluded_locations': {
          const newExcludedLocations = locationState.excluded_locations?.filter(
            (selected) => selected.text !== itemToRemove
          );
          newLocation = {
            ...locationState,
            excluded_locations: newExcludedLocations
          };
          break;
        }
        case 'region': {
          const newRegions = locationState.region?.filter((selected) => {
            const region =
              chipDisplayOrder.find((chip) => chip.region === selected)
                ?.title ?? selected;
            return region !== itemToRemove;
          });
          newLocation = {
            ...locationState,
            region: newRegions
          };
          break;
        }
        default: {
          // legacy location filter (city/state/country)
          const currentValues = locationState[
            type as keyof typeof locationState
          ] as string[];
          const newValues = currentValues.filter(
            (selected) => selected !== itemToRemove
          );
          newLocation = {
            ...locationState,
            [type]: newValues
          };
          break;
        }
      }

      // check if all values are empty arrays
      const isNoLocationsSelected = Object.values(newLocation).every(
        (value) => Array.isArray(value) && value.length === 0
      );

      if (isNoLocationsSelected) {
        newLocation = undefined;
      }

      onValueChange(newLocation);
    }
  };

  const handleLocationChange = ({
    selected,
    location
  }: handleLocationChangeProps) => {
    if (!selected) {
      onValueChange({
        ...locationState,
        locations: [
          ...(locationState?.locations ?? []),
          {
            entity_urn: location.entityUrn,
            text: location.text,
            location_type: location.locationType,
            city: location.city,
            state: location.state,
            country: location.country
          }
        ]
      });
      setLocationFilterTerm('');
    } else {
      handleOnRemoveClick(location.text);
    }
  };

  const handleExcludeLocationChange = ({
    selected,
    location
  }: handleLocationChangeProps) => {
    if (!selected) {
      onValueChange({
        ...locationState,
        excluded_locations: [
          ...(locationState?.excluded_locations ?? []),
          {
            entity_urn: location.entityUrn,
            text: location.text,
            location_type: location.locationType,
            city: location.city,
            state: location.state,
            country: location.country
          }
        ]
      });
      setLocationFilterTerm('');
    } else {
      handleOnRemoveClick(location.text);
    }
  };

  return {
    handleRegionClick,
    handleOnRemoveClick,
    handleLocationChange,
    handleExcludeLocationChange,
    locationFilterTerm,
    setLocationFilterTerm
  };
};
