import { useQuery } from '@apollo/client';
import ReportDataIssue from 'components/common/ReportDataIssue';
import { ILineChartDataPoint } from 'interfaces/Charts';
import {
  HeadcountMetricsType,
  SocialMetricsType
} from 'interfaces/DataModel/Company';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import React, { useMemo } from 'react';
import { formatMetricsForChart } from 'utils/chart';
import CompanySocialCard from './CompanySocialCard';
import CompanySocialsContentLoader from './CompanySocialsContentLoader';
import SocialMetricsGraph from './SocialMetricsGraph';

import {
  GetCompanyMetricsQuery,
  GetCompanyMetricsQueryVariables,
  GetCompanyMetrics_GetCompanyByIdFragment
} from '__generated__/graphql';
import { isNil } from 'lodash';

export type SocialMetricsChartMapType = Partial<
  Record<SocialMetricsType, ILineChartDataPoint[]>
>;
export type HeadcountMetricsChartMapType = Partial<
  Record<HeadcountMetricsType, ILineChartDataPoint[]>
>;

export const getSocialMetricsChartDataPointMap = (
  tractionMetrics?:
    | GetCompanyMetrics_GetCompanyByIdFragment['tractionMetrics']
    | null,
  showWebTraffic?: boolean
): SocialMetricsChartMapType => {
  const socialMetricsMap: SocialMetricsChartMapType = {};

  const facebookLikes = formatMetricsForChart(
    tractionMetrics?.facebookLikeCount?.metrics ?? []
  );
  const twitterFollowers = formatMetricsForChart(
    tractionMetrics?.twitterFollowerCount?.metrics ?? []
  );
  const instagramFollowers = formatMetricsForChart(
    tractionMetrics?.instagramFollowerCount?.metrics ?? []
  );
  const linkedinFollowers = formatMetricsForChart(
    tractionMetrics?.linkedinFollowerCount?.metrics ?? []
  );

  const webTraffic = formatMetricsForChart(
    tractionMetrics?.webTraffic?.metrics ?? []
  );
  if (webTraffic.length > 1 && showWebTraffic) {
    socialMetricsMap[SocialMetricsType.WEB_TRAFFIC] = webTraffic;
  }
  if (facebookLikes.length > 1) {
    socialMetricsMap[SocialMetricsType.FACEBOOK_LIKES] = facebookLikes;
  }
  if (twitterFollowers.length > 1) {
    socialMetricsMap[SocialMetricsType.TWITTER_FOLLOWERS] = twitterFollowers;
  }
  if (instagramFollowers.length > 1) {
    socialMetricsMap[SocialMetricsType.INSTAGRAM_FOLLOWERS] =
      instagramFollowers;
  }
  if (linkedinFollowers.length > 1) {
    socialMetricsMap[SocialMetricsType.LINKEDIN_FOLLOWERS] = linkedinFollowers;
  }

  return socialMetricsMap;
};

export const getHeadcountMetricsChartDataPointMap = (
  tractionMetrics?:
    | GetCompanyMetrics_GetCompanyByIdFragment['tractionMetrics']
    | null
) => {
  const headcountMetricsMap: HeadcountMetricsChartMapType = {};
  const correctedHeadcount = formatMetricsForChart(
    tractionMetrics?.correctedHeadcount?.metrics ?? []
  );
  const linkedinHeadcount = formatMetricsForChart(
    tractionMetrics?.externalHeadcount?.metrics ?? []
  );
  if (correctedHeadcount.length > 1) {
    headcountMetricsMap[HeadcountMetricsType.CORRECTED_HEADCOUNT] =
      correctedHeadcount;
  }
  if (linkedinHeadcount.length > 1) {
    headcountMetricsMap[HeadcountMetricsType.LINKEDIN_HEADCOUNT] =
      linkedinHeadcount;
  }
  return headcountMetricsMap;
};
interface CompanySocialsProps {
  companyId: number;
}

const CompanySocials: React.FC<CompanySocialsProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetCompanyMetricsQuery,
    GetCompanyMetricsQueryVariables
  >(getCompanyMetrics, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });

  const companySocials = data?.getCompanyById?.socials;
  const tractionMetrics = data?.getCompanyById?.tractionMetrics;
  const facebookLikeCount = companySocials?.facebook?.likeCount;
  const fbTractionMetrics = tractionMetrics?.facebookLikeCount?.ago90d;
  const webTrafficCount =
    tractionMetrics?.webTraffic?.metrics?.[0]?.metricValue;
  const webTrafficMetrics = tractionMetrics?.webTraffic?.ago90d;
  const twitterFollowerCount = companySocials?.twitter?.followerCount;
  const twitterTractionMetrics = tractionMetrics?.twitterFollowerCount?.ago90d;
  const instagramFollowerCount = companySocials?.instagram?.followerCount;
  const instagramTractionMetrics =
    tractionMetrics?.instagramFollowerCount?.ago90d;
  const linkedinFollowerCount = companySocials?.linkedin?.followerCount;
  const linkedinTractionMetrics =
    tractionMetrics?.linkedinFollowerCount?.ago90d;

  const socialMetricsDataPointMap = useMemo(
    () => getSocialMetricsChartDataPointMap(tractionMetrics, true),
    [tractionMetrics]
  );

  const isLoading = isNil(data);

  return (
    <div style={{ paddingBottom: '160px' }}>
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center w-full pb-p70 border-b border-solid border-border">
          <p className="text-content-title typography-title-medium">
            Company Socials
          </p>
        </div>
        <ReportDataIssue
          reportParams={{
            companyUrn: 'urn:company:harmonic:' + companyId,
            reportSourceView: ReportSourceView.COMPANY,
            reportSourceSection: ReportSourceSection.FINANCING
          }}
          placeholderText="The company is showing incorrect twitter followers"
        />
      </div>

      {isLoading ? (
        <CompanySocialsContentLoader />
      ) : (
        <div className="py-6 w-full">
          <div className="grid grid-cols-3 gap-3">
            <CompanySocialCard
              type={SocialMetricsType.WEB_TRAFFIC}
              value={webTrafficCount}
              tractionMetrics={webTrafficMetrics}
            />

            <CompanySocialCard
              type={SocialMetricsType.FACEBOOK_LIKES}
              value={facebookLikeCount}
              tractionMetrics={fbTractionMetrics}
            />
            <CompanySocialCard
              type={SocialMetricsType.INSTAGRAM_FOLLOWERS}
              value={instagramFollowerCount}
              tractionMetrics={instagramTractionMetrics}
            />
            <CompanySocialCard
              type={SocialMetricsType.TWITTER_FOLLOWERS}
              value={twitterFollowerCount}
              tractionMetrics={twitterTractionMetrics}
            />
            <CompanySocialCard
              type={SocialMetricsType.LINKEDIN_FOLLOWERS}
              value={linkedinFollowerCount}
              tractionMetrics={linkedinTractionMetrics}
            />
          </div>
          <div data-testid="CompanySocialMetricsGraph" className="mt-7">
            <SocialMetricsGraph
              socialMetricsDataPointMap={socialMetricsDataPointMap}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanySocials;
