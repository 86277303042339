import {
  PeopleListCustomFieldValueFragment,
  PeopleSearchFragment
} from '__generated__/graphql';
import { IHighlight } from './Highlight';
import { ICompanyRelationship } from './Relationship';

interface IPersonEducationExperience {
  school_name: string;
  school_linkedin_url: string;
  school_logo_url: string;
  degree: string;
  field: string;
  grade: string;
  start_date: string;
  end_date: string;
}

export enum PersonExperienceDataTypes {
  NAME = 'name',
  TITLE = 'title',
  DEPARTMENT = 'department',
  TAGS = 'tags'
}

export enum PersonExperienceDataTimeTypes {
  CURRENT = 'current',
  PREVIOUS = 'previous',
  ALL_PREVIOUS = 'all_previous'
}
export interface IPerson {
  id: number;

  full_name: string;
  first_name: string;
  last_name: string;

  profile_picture_url: string;
  linkedin_url: string;
  twitter_url: string;
  location: string;
  address_formatted: string;
  street: string;
  city: string;
  state: string;
  zip: number;
  country: string;

  education: IPersonEducationExperience[];

  contact_emails: string[]; // deprecated -- will remove soon
  personal_emails: string[];
  contact_numbers: string[];

  highlights: IHighlight[];

  company_relationships: ICompanyRelationship[];
}

export interface IPersonWrapped {
  person: PeopleSearchFragment;
  entryUrn?: string;
  netNewId?: number;
  customFieldValues?: PeopleListCustomFieldValueFragment[];
}

export interface PersonRole {
  title: string;
  description?: string;
  startDate?: string | null;
  endDate?: string | null;
  isCurrentPosition?: boolean;
}
