import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import ListMultiselect from '../Common/ListMultiselect';
import { SubSectionHeader } from '../Common/Typography';

interface GeneralListsSubSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const GeneralListsSubSection: React.FC<GeneralListsSubSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const inListsValue =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS
    ]?.in_lists;
  const notInListsValue =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS
    ]?.not_in_lists;
  return (
    <div data-testid="people-lists-section" className="w-full">
      <div className="flex flex-col justify-center items-start pb-p50">
        <SubSectionHeader>People lists</SubSectionHeader>
      </div>
      <div className="flex gap-g60">
        <div className="w-1/2 flex flex-col gap-g20">
          <label className="text-content-weak typography-label">
            Include lists
          </label>
          <ListMultiselect
            mode="people_watchlist"
            dataTestid="people-lists-include-multiselect"
            selected={inListsValue ?? []}
            onChange={(newValues) =>
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS,
                  {
                    in_lists: newValues,
                    not_in_lists: notInListsValue
                  }
                )
              )
            }
            placeholder="Select people lists to include"
          />
        </div>
        <div className="w-1/2 flex flex-col gap-g20">
          <label className="text-content-weak typography-label">
            Exclude lists
          </label>
          <ListMultiselect
            mode="people_watchlist"
            dataTestid="people-lists-exclude-multiselect"
            selected={notInListsValue ?? []}
            onChange={(newValues) =>
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS,
                  {
                    in_lists: inListsValue,
                    not_in_lists: newValues
                  }
                )
              )
            }
            placeholder="Select people lists to exclude"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralListsSubSection;
