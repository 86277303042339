import { KeyboardEvent, PropsWithChildren } from 'react';

export const Form: React.FC<
  PropsWithChildren & {
    children: React.ReactNode | React.ReactNode[];
    onSubmit: () => void;
    className?: string;
    enableSubmitOnCmdEnter?: boolean;
    enableSubmitOnEnter?: boolean;
  }
> = ({
  children,
  onSubmit,
  className,
  enableSubmitOnCmdEnter = true,
  enableSubmitOnEnter = false
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
    if (
      (e.metaKey || e.ctrlKey) &&
      e.key === 'Enter' &&
      enableSubmitOnCmdEnter
    ) {
      e.preventDefault();
      onSubmit();
    }
    if (enableSubmitOnEnter) {
      // allow shift for textareas new lines
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();
      }
    }
  };

  return (
    <form
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      className={className}
      role="form"
    >
      {children}
    </form>
  );
};
