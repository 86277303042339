import { gql } from '@apollo/client';

export const getAcceleratorFilters = gql`
  query GetAcceleratorFilters {
    getAcceleratorFilters {
      accelerators {
        __typename
        entityUrn
        text
        cohorts {
          __typename
          entityUrn
          text
        }
      }
      acceleratorGroups {
        __typename
        entityUrn
        text
        accelerators {
          __typename
          entityUrn
          text
          cohorts {
            __typename
            entityUrn
            text
          }
        }
      }
    }
  }
`;
