import React, { PropsWithChildren } from 'react';

interface LeftImageBannerProps {
  leftGraphic?: React.ReactNode;
}

/**
 * For banners with a left graphic. Currently used for ConnectionsBanner and AffinityBanner
 */
const LeftImageBanner: React.FC<PropsWithChildren<LeftImageBannerProps>> = (
  props
) => {
  const { leftGraphic, children } = props;
  return (
    <div className="rounded-br40 flex border border-border border-solid overflow-clip">
      {leftGraphic && (
        <div className="w-[220px] overflow-clip border-r border-solid border-border flex-shrink-0">
          {leftGraphic}
        </div>
      )}
      <div className="flex-grow py-p50 px-p60 bg-surface-nested">
        {children}
      </div>
    </div>
  );
};

export default LeftImageBanner;
