import IconButton from 'harmonic-components/IconButton/IconButton';
import CloseIcon from 'icons/CloseIcon';
import React from 'react';

interface BannerProps {
  text: string;
  type?: 'neutral' | 'informative' | 'warning' | 'error';
  showIcon?: boolean;
  onClose?: () => void;
  dataTestId?: string;
}

const STYLE_CONFIG = {
  neutral: {
    bg: 'bg-surface-sentiment-neutral',
    text: 'text-content-onSentiment-neutral',
    icon: 'info',
    iconColor: 'text-content-weak'
  },
  informative: {
    bg: 'bg-surface-sentiment-informative',
    text: 'text-content-onSentiment-informative',
    icon: 'info',
    iconColor: 'text-content-sentiment-informative'
  },
  warning: {
    bg: 'bg-surface-sentiment-caution',
    text: 'text-content-onSentiment-caution',
    icon: 'warning',
    iconColor: 'text-content-sentiment-caution'
  },
  error: {
    bg: 'bg-surface-sentiment-negative',
    text: 'text-content-onSentiment-negative',
    icon: 'flag',
    iconColor: 'text-content-sentiment-negative'
  }
};

/**
 * General purpose banner: https://www.figma.com/design/VE8KNtDMLjhIoE5TUSaj4h/Component-Library?node-id=6182-6692&m=dev
 */
const Banner: React.FC<BannerProps> = ({
  text,
  showIcon = false,
  type = 'neutral',
  onClose,
  dataTestId
}) => {
  return (
    <div
      role="banner"
      data-testid={dataTestId}
      className={`rounded-br40 p-p40 pl-p80 flex border overflow-clip items-center ${STYLE_CONFIG[type].bg} ${STYLE_CONFIG[type].text}`}
    >
      {showIcon && (
        <span
          data-testid="banner-icon"
          className={`icon text-[16px] text-center justify-center items-center flex icon-filled ${STYLE_CONFIG[type].iconColor}`}
        >
          {STYLE_CONFIG[type].icon}
        </span>
      )}
      <div data-testid="banner-text" className="flex-grow p-p30 items-center">
        {text}
      </div>
      {onClose && (
        <IconButton
          icon={CloseIcon}
          onClick={onClose}
          className="text-content-weak"
          emphasis="low"
          size="compact"
          type="inline"
        />
      )}
    </div>
  );
};

export default Banner;
