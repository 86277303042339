import { HarmonicIcon } from '../type';

const RoundIconDebt: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M2.66683 13.3337C2.30016 13.3337 1.98627 13.2031 1.72516 12.942C1.46405 12.6809 1.3335 12.367 1.3335 12.0003V4.00033C1.3335 3.63366 1.46405 3.31977 1.72516 3.05866C1.98627 2.79755 2.30016 2.66699 2.66683 2.66699H13.3335C13.7002 2.66699 14.0141 2.79755 14.2752 3.05866C14.5363 3.31977 14.6668 3.63366 14.6668 4.00033V12.0003C14.6668 12.367 14.5363 12.6809 14.2752 12.942C14.0141 13.2031 13.7002 13.3337 13.3335 13.3337H2.66683ZM2.66683 8.00033H13.3335V5.33366H2.66683V8.00033Z"
      fill="#A6823A"
    />
  </svg>
);

export default RoundIconDebt;
