import classNames from 'classnames';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import { EntitySearchListType } from 'interfaces/SearchModel/Search';
import { useSchoolTypeahead } from './hooks/useSchoolTypeahead';

interface SchoolMultiselectProps {
  selected: string[];
  disabled?: boolean;
  dataTestId?: string;
  onChange: (newOptions?: string[]) => void;
  initialFocus?: boolean;
  entityType: EntitySearchListType;
}
const SchoolMultiselect: React.FC<SchoolMultiselectProps> = ({
  selected,
  dataTestId,
  onChange,
  initialFocus,
  entityType
}) => {
  const {
    searchTerm,
    options: schoolTypeaheadOptions,
    handleSearch
  } = useSchoolTypeahead({ entityType });

  const onClickListItem = (value: string) => {
    const selectedOption = selected.find((s) => s === value);
    if (selectedOption) {
      onChange(selected.filter((s) => s !== value));
    } else {
      onChange([...selected, value]);
    }
    handleSearch('');
  };

  return (
    <Select
      multiple
      selected={selected}
      onRemove={(key) => {
        const newSelected = selected.filter((s) => s !== key);
        onChange(newSelected.length > 0 ? newSelected : undefined);
      }}
      filterable
      filterTerm={searchTerm}
      onFilterTermChange={handleSearch}
      freeSolo
      placeholder="Type school name"
      initialFocus={initialFocus}
      onAdd={(value) => value && onClickListItem(value)}
      getLabelFromValue={(value) =>
        schoolTypeaheadOptions.find((option) => option?.value === value)
          ?.label ?? ''
      }
      dataTestId={dataTestId}
      fullWidth={true}
    >
      <div className="overflow-y-scroll w-full">
        {schoolTypeaheadOptions.map((school) => {
          if (!school) return null;
          return (
            <ListItem
              key={school.value}
              value={school.value}
              label={school.label ?? ''}
              onChange={() => onClickListItem(school.value)}
              selected={selected.includes(school.value)}
              variant={ListVariant.checkboxes}
            />
          );
        })}
      </div>
      {searchTerm && (
        <div
          className={classNames(
            'w-full',
            schoolTypeaheadOptions.length > 0 &&
              'border-t border-solid border-border'
          )}
        >
          <ListItem
            label={`+ Add ${searchTerm}`}
            key={`add-${searchTerm}`}
            value={searchTerm}
            onClick={() => onClickListItem(searchTerm)}
            variant={ListVariant.default}
          />
        </div>
      )}
    </Select>
  );
};

export default SchoolMultiselect;
