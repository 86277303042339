import {
  GetCompanyWatchlistQuery,
  ListCustomFieldType
} from '__generated__/graphql';
import gql from 'graphql-tag';

const COMPANY_LIST_NAMED_VIEW_FRAGMENT = gql`
  fragment CompanyListNamedView on CompanyListNamedView {
    id
    entityUrn
    name
    visibleColumns
    searchQuery
    displayType
    groupByField {
      urn
    }
    hideEmptyColumns
  }
`;

export const COMPANY_LIST_NAMED_VIEW_SIDEBAR_FRAGMENT = gql`
  fragment CompanyListNamedViewSidebar on CompanyListNamedView {
    entityUrn
  }
`;
export const SELECT_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql`
  fragment SelectListCustomFieldMetadata on SelectListCustomFieldMetadata {
    options {
      color
      name
      urn
    }
  }
`;

export const NUMBER_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql`
  fragment NumberListCustomFieldMetadata on NumberListCustomFieldMetadata {
    numberFormat: format
  }
`;

export const DATE_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql`
  fragment DateListCustomFieldMetadata on DateListCustomFieldMetadata {
    dateFormat: format
  }
`;

export const PERSON_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql`
  fragment PersonListCustomFieldMetadata on PersonListCustomFieldMetadata {
    mode
  }
`;

export const CustomFieldsFragment = gql`
  fragment CustomFields on CompanyListCustomField {
    __typename
    urn
    name
    type
    createdAt
    defaultValue {
      ... on NumberListCustomFieldValue {
        numberDefaultValue: value
      }
      ... on TextCustomFieldValue {
        textDefaultValue: value
      }
      ... on SingleSelectCustomFieldValue {
        singleSelectDefaultValue: value
      }
      ... on MultiSelectCustomFieldValue {
        multiSelectDefaultValue: value
      }
      ... on PersonSelectCustomFieldDefaultValue {
        personDefaultValue: value
        personDefaultValueMode: mode
      }
      ... on DateListCustomFieldDefaultValue {
        dateDefaultValue: value
        dateDefaultValueMode: mode
      }
      ... on WebsiteListCustomFieldValue {
        websiteDefaultValue: value
      }
      ... on CheckboxListCustomFieldValue {
        checkboxDefaultValue: value
      }
      ... on StatusListCustomFieldValue {
        statusDefaultValue: value
      }
    }
    metadata {
      __typename
      ... on SelectListCustomFieldMetadata {
        ...SelectListCustomFieldMetadata
      }
      ... on NumberListCustomFieldMetadata {
        ...NumberListCustomFieldMetadata
      }
      ... on DateListCustomFieldMetadata {
        ...DateListCustomFieldMetadata
      }
      ... on PersonListCustomFieldMetadata {
        ...PersonListCustomFieldMetadata
      }
    }
  }
  ${SELECT_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${NUMBER_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${DATE_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${PERSON_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
`;

export const GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS = gql`
  fragment WatchlistWithCompanyId on CompanyWatchlist {
    owner {
      ... on User {
        entityUrn
        email
      }
      ... on Customer {
        identifier
        name
      }
    }
    id
    entityUrn
    name
    userWatchlistType
    sharedWithTeam
    companyIds
    customFields {
      ...CustomFields
    }
    namedViews {
      ...CompanyListNamedView
    }
  }
  ${CustomFieldsFragment}
  ${COMPANY_LIST_NAMED_VIEW_FRAGMENT}
`;

export const GET_WATCHLIST = gql`
  query GetCompanyWatchlist($idOrUrn: String!) {
    getCompanyWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      ...WatchlistWithCompanyId
    }
  }
  ${GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS}
`;

export const GET_COMPANIES_WATCHLIST_MOCKED_RESPONSE: GetCompanyWatchlistQuery =
  {
    getCompanyWatchlistByIdOrUrn: {
      owner: {
        entityUrn: 'urn:harmonic:user:4621',
        email: 'miguel@harmonic.ai',
        __typename: 'User'
      },
      id: '566d7621-ea4f-4565-a075-0dde9f4bd6d1',
      entityUrn:
        'urn:harmonic:company_watchlist:566d7621-ea4f-4565-a075-0dde9f4bd6d1',
      name: 'MCP - Test 1',
      userWatchlistType: 'USER_MANAGED_WATCHLIST',
      sharedWithTeam: true,
      companyIds: ['1', '2138562', '142540', '3205143', '12692674', '2160828'],
      customFields: [
        {
          __typename: 'CompanyListCustomField',
          type: ListCustomFieldType.TEXT,
          urn: 'urn:harmonic:company_list_custom_field:5',
          name: 'Text custom field',
          defaultValue: null,
          metadata: null,
          createdAt: '2024-01-01T00:00:00Z'
        },
        {
          __typename: 'CompanyListCustomField',
          type: ListCustomFieldType.SINGLE_SELECT,
          urn: 'urn:harmonic:company_list_custom_field:6',
          name: 'Select custom field',
          defaultValue: null,
          metadata: {
            __typename: 'SelectListCustomFieldMetadata',
            options: [
              {
                color: '#fff',
                name: 'Option 1',
                urn: 'urn:harmonic:select_list_custom_field_value_option:b164fdb7-8dce-48b1-9966-2ba2c9d1745a'
              },
              {
                color: '#fff',
                name: 'Option 2',
                urn: 'urn:harmonic:select_list_custom_field_value_option:ed2e9e7c-fdff-4eaf-8f1f-77aad120be55'
              }
            ]
          },
          createdAt: '2024-01-01T00:00:00Z'
        }
      ],
      namedViews: [],
      __typename: 'CompanyWatchlist'
    }
  };
