import { FC } from 'react';

const ThreeDotIcon: FC = () => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M5.27 8.933a.974.974 0 01-.667-.28A.929.929 0 014.337 8c0-.24.106-.48.266-.667a.937.937 0 01.667-.266c.24 0 .48.106.653.266a.95.95 0 01.28.667c0 .24-.106.48-.28.653a.867.867 0 01-.653.28zm4-.28A.942.942 0 009.55 8a1 1 0 00-.28-.667.929.929 0 00-.653-.266c-.24 0-.494.106-.667.266A.937.937 0 007.683 8c0 .24.107.48.267.653a.95.95 0 00.667.28.91.91 0 00.653-.28zm3.333 0a.942.942 0 00.28-.653 1 1 0 00-.28-.667.929.929 0 00-.653-.266c-.24 0-.493.106-.667.266a.937.937 0 00-.266.667c0 .24.106.48.266.653a.95.95 0 00.667.28.91.91 0 00.653-.28z"
      ></path>
    </svg>
  );
};

export default ThreeDotIcon;
