import CompanySearchFilterGroup from 'components/Dashboard/Grids/SearchQueryBuilder/CompanySearchFilterGroup';
import PeopleSearchFilterGroup from 'components/Dashboard/Grids/SearchQueryBuilder/PeopleSearchFilterGroup';
import {
  EntityListType,
  ISearchFilterGroup,
  SearchFilterGroupQuery
} from 'interfaces/SearchModel/Search';
import React, { useCallback } from 'react';
import { SubSectionHeader } from './Typography';

interface CustomSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
  entityType: EntityListType;
}
const CustomSection: React.FC<CustomSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled,
  entityType
}) => {
  const onUpdateCustomFilterGroup = useCallback(
    (newFilterGroup: ISearchFilterGroup) => {
      onUpdateSearchQuery({
        filter_group: newFilterGroup,
        controlled_filter_group: searchQuery.controlled_filter_group
      });
    },
    []
  );

  return (
    <div>
      <SubSectionHeader>Advanced filters</SubSectionHeader>
      <p className="typography-label-default-default text-content-weak">
        Show only{' '}
        {entityType === EntityListType.COMPANY_SAVED_SEARCH ||
        entityType === EntityListType.COMPANY_WATCHLIST
          ? 'companies'
          : 'people'}{' '}
        matching the following criteria
      </p>
      {entityType === EntityListType.COMPANY_SAVED_SEARCH ? (
        <CompanySearchFilterGroup
          recursiveLevel={0}
          isSearchOwner={false}
          filterGroup={searchQuery.filter_group}
          updateParentFilterGroup={onUpdateCustomFilterGroup}
          disabled={disabled}
        />
      ) : (
        <PeopleSearchFilterGroup
          recursiveLevel={0}
          isSearchOwner={false}
          filterGroup={searchQuery.filter_group}
          updateParentFilterGroup={onUpdateCustomFilterGroup}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CustomSection;
