import { DocumentNode, WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { useMemo } from 'react';
import {
  GetCompanyWatchlistQuery,
  GetPeopleWatchlistQuery
} from '../__generated__/graphql';
import { useLocalSearchState } from './useAppState';
import { LocalNamedView } from './useAppStore';
import useDashboardLocation, {
  DashboardLocation
} from './useDashboardLocation';

type UseBaseFetchSingleWatchlistParams<Q, T> = {
  fetchPolicy?: WatchQueryFetchPolicy;
  skipLocalSearch?: boolean;
  query: DocumentNode;
  location: DashboardLocation;
  transformData: (data: Q) => T;
};

export const useBaseFetchSingleWatchlist = <
  Q,
  T extends
    | GetCompanyWatchlistQuery['getCompanyWatchlistByIdOrUrn']
    | GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn']
>({
  fetchPolicy = 'cache-first',
  query,
  location,
  transformData,
  skipLocalSearch = false
}: UseBaseFetchSingleWatchlistParams<Q, T>) => {
  const { searchByKey } = useLocalSearchState();
  const routeDetails = useDashboardLocation();
  const urn = routeDetails.urn;
  const isCorrectLocation = routeDetails.location === location;

  const id = getIdFromUrn(urn ?? null) || '';

  const { data, loading, error } = useQuery(query, {
    variables: {
      idOrUrn: id
    },
    skip: !isCorrectLocation,
    fetchPolicy,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true
  });

  const transformedData = useMemo(() => {
    if (data && isCorrectLocation) {
      const watchlist = transformData(data);
      if (!skipLocalSearch) {
        const namedViews = watchlist?.namedViews?.map((namedView) => {
          const localSearch = searchByKey[
            namedView.entityUrn
          ] as LocalNamedView;
          if (!localSearch) return namedView;

          return {
            ...namedView,
            name: localSearch.name ?? namedView.name,
            searchQuery: localSearch.searchQuery ?? namedView.searchQuery,
            visibleColumns:
              localSearch.visibleColumns ?? namedView.visibleColumns,
            displayType: localSearch.displayType ?? namedView.displayType,
            hideEmptyColumns:
              localSearch.hideEmptyColumns ?? namedView.hideEmptyColumns,
            groupByField: localSearch.groupByField ?? namedView.groupByField
          };
        });

        return {
          ...watchlist,
          namedViews
        };
      }
      return watchlist;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchByKey, isCorrectLocation]);

  return {
    data: transformedData,
    loading: !data && loading,
    error
  };
};
