import { useQuery } from '@apollo/client';
import { GetPeopleWatchlistsQuery } from '__generated__/graphql';
import MyNetworkList from 'assets/harmonic-icons/my-network-list';
import NotInNetwork from 'assets/harmonic-icons/not-in-network';
import TeamNetworkList from 'assets/harmonic-icons/team-network-list';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import useFlags from 'hooks/useFlags';
import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import React from 'react';
import { SPLITS } from 'utils/constants';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import { ConnectionsBanner } from '../Common/ConnectionsBanner';
import CorrespondencesSubSection from '../Common/CorrespondencesSubSection';
import { SubSectionHeader } from '../Common/Typography';

interface GeneralConnectionsSubSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const GeneralConnectionsSubSection: React.FC<
  GeneralConnectionsSubSectionProps
> = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const { enabled: correspondencesEnabled } = useFlags(
    SPLITS.showCorrespondencesInSearch
  );

  const connections =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS
    ];

  const { data } = useQuery<GetPeopleWatchlistsQuery>(GET_PEOPLE_WATCHLISTS, {
    fetchPolicy: 'cache-first'
  });

  const userConnectionsList = data?.getPeopleWatchlistsForTeam?.find(
    (wl) => wl?.userWatchlistType === 'USER_CONNECTIONS'
  );
  const teamConnectionsList = data?.getPeopleWatchlistsForTeam?.find(
    (wl) => wl?.userWatchlistType === 'TEAM_CONNECTIONS'
  );

  const myConnectionsSelected = connections?.in_lists?.includes(
    userConnectionsList?.id as string
  );

  const teamConnectionsSelected = connections?.in_lists?.includes(
    teamConnectionsList?.id as string
  );

  const notConnectedSelected =
    connections?.not_in_lists?.includes(userConnectionsList?.id as string) &&
    connections?.not_in_lists?.includes(teamConnectionsList?.id as string);

  const anySelected =
    !connections?.in_lists?.includes(userConnectionsList?.id as string) &&
    !connections?.in_lists?.includes(teamConnectionsList?.id as string) &&
    !notConnectedSelected;

  return (
    <div data-testid="Connections-section" className="w-full">
      <div className="flex flex-col gap-g50">
        <div className="mb-g80">
          <ConnectionsBanner />
        </div>
        <SubSectionHeader>Network</SubSectionHeader>
        <div className="flex items-start gap-g40 self-stretch">
          <RadioPill
            dataTestId="Any-Connections-Radio-Pill"
            onClick={() => {
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS,
                  undefined
                )
              );
            }}
            label="Any"
            selected={anySelected}
          />
          <RadioPill
            dataTestId="My-Connections-Radio-Pill"
            onClick={() => {
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS,
                  {
                    in_lists: [userConnectionsList?.id as string],
                    not_in_lists: []
                  }
                )
              );
            }}
            label="In my network"
            leadingIcon={MyNetworkList}
            selected={myConnectionsSelected ?? false}
            disabled={!userConnectionsList?.id}
          />
          <RadioPill
            dataTestId="Team-Connections-Radio-Pill"
            onClick={() => {
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS,
                  {
                    in_lists: [teamConnectionsList?.id as string],
                    not_in_lists: []
                  }
                )
              );
            }}
            label="In team network"
            leadingIcon={TeamNetworkList}
            selected={teamConnectionsSelected ?? false}
            disabled={!teamConnectionsList?.id}
          />
          <RadioPill
            dataTestId="Not-Connected-Radio-Pill"
            onClick={() => {
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS,
                  {
                    in_lists: [],
                    not_in_lists: [
                      userConnectionsList?.id as string,
                      teamConnectionsList?.id as string
                    ]
                  }
                )
              );
            }}
            label="Not in network"
            leadingIcon={NotInNetwork}
            selected={notConnectedSelected ?? false}
            disabled={!userConnectionsList?.id || !teamConnectionsList?.id}
          />
        </div>
        {correspondencesEnabled && (
          <CorrespondencesSubSection
            addTopMarginOnFieldOpen
            generatorArgs={
              controlledSearchState?.[
                FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES
              ]
            }
            onUpdateGeneratorArgs={(newArgs) => {
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES,
                  newArgs
                )
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GeneralConnectionsSubSection;
