import { IDashboardURNParams } from 'interfaces/Dashboard';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export enum DashboardLocation {
  DISCOVER_DASHBOARD = 'discover_dashboard',
  COMPANY_SEARCH = 'company_search',
  PEOPLE_SEARCH = 'people_search',
  COMPANY_LIST = 'company_list',
  PERSON_LIST = 'person_list',
  PERSON_PROFILE = 'person_profile',
  COMPANY_PROFILE = 'company_profile',
  INVESTOR_PROFILE = 'investor_profile',
  LISTS = 'lists',
  SEARCHES = 'searches',
  SEARCH_PAGE = 'search_page',
  SEMANTIC = 'semantic'
}

export enum EntityType {
  COMPANY = 'company',
  PERSON = 'person',
  INVESTOR = 'investor'
}

export interface UseDashboardLocationResponse {
  location?: DashboardLocation;
  urn?: string;
  entityType?: EntityType;
}

const useDashboardLocation = (): UseDashboardLocationResponse => {
  const params = useParams<IDashboardURNParams>();
  const { pathname } = useLocation();

  const urn = useMemo(() => params['*']?.match(/urn:(.*)/)?.[0], [params]);
  const location = useMemo(() => {
    if (pathname.includes('/dashboard/companies')) {
      return DashboardLocation.COMPANY_SEARCH;
    } else if (pathname.includes('/dashboard/people_watchlist')) {
      return DashboardLocation.PERSON_LIST;
    } else if (pathname.includes('/dashboard/people')) {
      return DashboardLocation.PEOPLE_SEARCH;
    } else if (pathname.includes('/dashboard/watchlist')) {
      return DashboardLocation.COMPANY_LIST;
    } else if (pathname.includes('/dashboard/company')) {
      return DashboardLocation.COMPANY_PROFILE;
    } else if (pathname.includes('/dashboard/person')) {
      return DashboardLocation.PERSON_PROFILE;
    } else if (pathname.includes('/dashboard/investor')) {
      return DashboardLocation.INVESTOR_PROFILE;
    } else if (pathname.includes('/dashboard/lists')) {
      return DashboardLocation.LISTS;
    } else if (pathname.includes('/dashboard/searches')) {
      return DashboardLocation.SEARCHES;
    } else if (pathname.includes('/dashboard/search')) {
      return DashboardLocation.SEARCH_PAGE;
    } else if (pathname.includes('/dashboard/semantic')) {
      return DashboardLocation.SEMANTIC;
    }
  }, [pathname]);

  return {
    location,
    urn,
    entityType: location?.includes('company')
      ? EntityType.COMPANY
      : EntityType.PERSON
  };
};

export default useDashboardLocation;
