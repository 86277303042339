import styled from 'styled-components';

const SearchFilterGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .search-input {
      display: flex;
      flex-direction: row;
    }

    .list-action {
      display: flex;
      justify-content: flex-end;
      > * {
        text-transform: none;
        margin-left: 10px;
      }
    }
  }

  .filter-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    margin-left: 5px;

    .list-action {
      display: flex;
      justify-content: flex-end;
      > * {
        text-transform: none;
        margin-left: 10px;
      }
    }
  }

  .main-filter {
    width: 100%;
    padding: 0.4rem 1rem 0.5rem 1rem;
    margin: 0.7rem 0 0 0;
  }

  .search-btn-border {
    box-shadow: none;
  }

  .search-outlined-btn {
    text-transform: none;
    border: solid 1px #ffffff;
  }

  .search-outlined-btn: hover {
    border: solid 1px #e9f2fe;
    background-color: #e9f2fe;
    box-shadow: none;
  }

  .search-contained-btn {
    background-color: #e9f2fe;
    border: solid 1px #ffffff;
  }

  .search-contained-btn-hover: hover {
    background-color: #4392f6;
    color: #ffffff;
    box-shadow: none;
  }
`;

const FilterConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .grouped-filters {
    padding: 1rem 1rem 0.5rem 1rem;
    background-color: #f2f2f2;
    border-radius: 10px;
    border: none;
    margin-bottom: 15px;
  }

  // .container {
  //   margin-bottom -15px;
  // }

  .title {
    padding: 0.5rem 0 0.7rem 0.5rem;
    text-transform: capitalize;
  }

  .filter-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .ungrouped-filters {
    border-radius: 10px;
    border: none;
  }
`;

const SingleFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 7px;
  gap: 8px;

  .add-filter {
    display: flex;
    margin-top: 10px;
    margin-left: 15px;
    width: 150px;
    color: var(--color-blue);
    cursor: pointer;

    .label {
    }

    .icon {
      display: flex;
      margin-top: 1px;
      justify-content: center;
      margin-left: 5px;
      & * {
        font-size: 18px;
      }
    }
  }

  .extra-options {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 180px;
  }

  .delete-icon {
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    color: var(--color-dark-grey);

    & * {
      font-size: 23px;
    }
  }

  .field-input {
    width: 200px;

    .MuiInputBase-root {
      background-color: #ffffff;
    }

    input {
      text-overflow: ellipsis;
    }
  }

  .comparator-input {
    width: 200px;

    .MuiInputBase-root {
      background-color: #ffffff;
    }
  }

  .value-input {
    width: 250px;

    .MuiInputBase-root {
      background-color: #ffffff;
    }
  }

  .disabled input:disabled {
    color: black;
  }

  .value-range-input-container {
    display: flex;
    flex-direction: column;
  }
`;

export {
  FilterConditionWrapper,
  SearchFilterGroupWrapper,
  SingleFilterWrapper
};
