import SectorPresetArtificialIntelligence from 'assets/sectors/ai';
import SectorPresetB2bSaas from 'assets/sectors/b2b-saas';
import SectorPresetBiotech from 'assets/sectors/biotech';
import SectorPresetClimate from 'assets/sectors/climate';
import SectorPresetConsumer from 'assets/sectors/consumer';
import SectorPresetConsumerProductGoods from 'assets/sectors/cpg';
import SectorPresetCybersecurity from 'assets/sectors/cybersecurity';
import SectorPresetEdtech from 'assets/sectors/edtech';
import SectorPresetFintech from 'assets/sectors/fintech';
import SectorPresetProptech from 'assets/sectors/proptech';
import React from 'react';

import Button from 'harmonic-components/Button/Button';
import RadioCard from 'harmonic-components/RadioCard/RadioCard';
import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';

export type SectorPreset =
  | 'ARTIFICIAL_INTELLIGENCE'
  | 'CONSUMER_GOODS'
  | 'ED_TECH'
  | 'B2B_SAAS'
  | 'CLIMATE'
  | 'FINTECH'
  | 'BIOTECH'
  | 'CYBERSECURITY'
  | 'PROPTECH'
  | 'CONSUMER_SOFTWARE';

interface ISectorPresetsProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

export const chipDisplayOrder = [
  {
    title: 'Artificial intelligence',
    sectorPreset: 'ARTIFICIAL_INTELLIGENCE',
    icon: SectorPresetArtificialIntelligence
  },
  {
    title: 'Consumer software',
    sectorPreset: 'CONSUMER_SOFTWARE',
    icon: SectorPresetConsumer
  },
  {
    title: 'Edtech',
    sectorPreset: 'ED_TECH',
    icon: SectorPresetEdtech
  },
  {
    title: 'B2B SaaS',
    sectorPreset: 'B2B_SAAS',
    icon: SectorPresetB2bSaas
  },
  {
    title: 'Climate',
    sectorPreset: 'CLIMATE',
    icon: SectorPresetClimate
  },
  {
    title: 'Fintech',
    sectorPreset: 'FINTECH',
    icon: SectorPresetFintech
  },
  {
    title: 'Biotech',
    sectorPreset: 'BIOTECH',
    icon: SectorPresetBiotech
  },
  {
    title: 'Cybersecurity',
    sectorPreset: 'CYBERSECURITY',
    icon: SectorPresetCybersecurity
  },
  {
    title: 'Proptech',
    sectorPreset: 'PROPTECH',
    icon: SectorPresetProptech
  },
  {
    title: 'Consumer goods',
    sectorPreset: 'CONSUMER_GOODS',
    icon: SectorPresetConsumerProductGoods
  }
];

const SectorPresets: React.FC<ISectorPresetsProps> = ({
  searchQuery,
  onUpdateSearchQuery
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const [selectedPreset, setSelectedPreset] = React.useState<string>('');

  React.useEffect(() => {
    setSelectedPreset(
      controlledSearchState?.[
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET
      ]?.preset || ''
    );
  }, [controlledSearchState]);

  const onSectorPresetClick = (selected: boolean, preset: string) => {
    analytics.trackCustomEvent({
      event: CustomTrackEvent.SECTOR_PRESET_CLICK,
      properties: {
        selected,
        preset
      }
    });
    onUpdateSearchQuery(
      getUpdatedQuery(
        searchQuery,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET,
        !selected ? { preset: preset as SectorPreset } : undefined
      )
    );
  };

  const [showAllOptions, setShowAllOptions] = React.useState(false);

  const maxDisplayCount = 9;
  const showToggleButton = chipDisplayOrder.length > maxDisplayCount;
  return (
    <div className="flex flex-col gap-g50">
      <div className="grid grid-cols-3 place-content-around gap-2">
        {chipDisplayOrder
          .slice(0, showAllOptions ? chipDisplayOrder.length : maxDisplayCount)
          .map((chipMeta) => (
            <RadioCard
              key={chipMeta.title}
              label={chipMeta.title}
              selected={selectedPreset === chipMeta.sectorPreset}
              icon={chipMeta.icon}
              onClick={() =>
                onSectorPresetClick(
                  selectedPreset === chipMeta.sectorPreset,
                  chipMeta.sectorPreset
                )
              }
            />
          ))}
      </div>
      {showToggleButton && (
        <div className="flex-shrink">
          <Button
            emphasis="high"
            label={!showAllOptions ? 'Show more' : 'Show less'}
            onClick={() => setShowAllOptions(!showAllOptions)}
          />
        </div>
      )}
    </div>
  );
};
