import classNames from 'classnames';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { concat, uniq } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';

interface OptionType {
  label: string;
  value: string;
}

interface IncludeExcludeMultiselectProps {
  options: OptionType[];
  included: string[];
  excluded: string[];
  disabled?: boolean;
  dataTestId?: string;
  onChangeInclude: (newOptions?: string[]) => void;
  onChangeExclude: (newOptions?: string[]) => void;
  initialFocus?: boolean;
  placeholder?: string;
  afterChange?: () => void;
  freeSolo?: boolean;
  onSearch?: (term: string) => void;
}
const IncludeExcludeMultiselect: React.FC<IncludeExcludeMultiselectProps> = (
  props: IncludeExcludeMultiselectProps
) => {
  const {
    options,
    included,
    excluded,
    onChangeInclude,
    onChangeExclude,
    initialFocus,
    placeholder,
    freeSolo,
    dataTestId,
    onSearch
  } = props;

  const [searchTerm, setSearchTerm] = useState('');

  const selected = uniq(concat(included, excluded));

  const onRemoveValue = (value: string) => {
    if (included.includes(value)) {
      onChangeInclude(included.filter((s) => s !== value));
    }
    if (excluded.includes(value)) {
      onChangeExclude(excluded.filter((s) => s !== value));
    }
  };

  const onClickListItem = (value: string, mode: 'include' | 'exclude') => {
    const optionIsIncluded = included.find((s) => s === value);
    const optionIsExcluded = excluded.find((s) => s === value);

    if (optionIsIncluded || optionIsExcluded) {
      onRemoveValue(value);
    } else if (mode === 'include') {
      onChangeInclude([...included, value]);
    } else if (mode === 'exclude') {
      onChangeExclude([...excluded, value]);
    }

    setSearchTerm('');
  };

  const getLabelFromValue = useCallback(
    (value?: string) => {
      return options.find((option) => option?.value === value)?.label ?? '';
    },
    [options]
  );

  const getTagColorFromValue = useCallback(
    (value: string) =>
      getTagColorBySelectMode(
        included.includes(value)
          ? 'include'
          : excluded.includes(value)
          ? 'exclude'
          : 'select'
      ),
    [included, excluded]
  );

  const getTagIconFromValue = useCallback(
    (value: string) =>
      getTagIconBySelectMode(
        included.includes(value)
          ? 'include'
          : excluded.includes(value)
          ? 'exclude'
          : 'select'
      ),
    [included, excluded]
  );

  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [options, searchTerm]);

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    onSearch?.(term);
  };

  return (
    <Select
      multiple
      filterable
      filterTerm={searchTerm}
      onFilterTermChange={handleSearchTermChange}
      selected={selected}
      onRemove={onRemoveValue}
      initialFocus={initialFocus}
      placeholder={placeholder}
      getLabelFromValue={getLabelFromValue}
      getTagColorFromValue={(value) => getTagColorFromValue(value ?? '')}
      getTagIconFromValue={(value) => getTagIconFromValue(value ?? '')}
      dataTestId={dataTestId}
    >
      <div className="overflow-y-scroll w-full">
        {filteredOptions.map((option) => {
          if (!option) return null;
          return (
            <SelectListItem
              key={`${option.value}-${
                included.includes(option.value) ? 'included' : 'excluded'
              }`}
              value={option.value}
              label={option.label ?? ''}
              selected={selected.includes(option.value)}
              excludeMode={excluded.includes(option.value)}
              onChange={() => onClickListItem(option.value, 'include')}
              onExclude={() => onClickListItem(option.value, 'exclude')}
              variant={ListVariant.checkboxes}
            />
          );
        })}
      </div>

      {searchTerm && onClickListItem && freeSolo && (
        <div
          className={classNames(
            'w-full',
            filteredOptions.length > 0 &&
              'border-t border-solid border-border mt-p20'
          )}
        >
          <SelectListItem
            label={`+ Add ${searchTerm}`}
            key={`add-${searchTerm}`}
            value={searchTerm}
            onClick={() => onClickListItem(searchTerm, 'include')}
            variant={ListVariant.addCustom}
            onExclude={() => onClickListItem(searchTerm, 'exclude')}
          />
        </div>
      )}
    </Select>
  );
};

export default IncludeExcludeMultiselect;
