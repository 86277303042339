import { useMemo } from 'react';
import { useCompanySavedSearchSort } from './Sort/useCompanySavedSearchSort';
import { useCompanySearchSort } from './Sort/useCompanySearchSort';
import { useCompanyWatchlistSort } from './Sort/useCompanyWatchlistSort';
import { usePeopleSavedSearchSort } from './Sort/usePeopleSavedSearchSort';
import { usePeopleSearchSort } from './Sort/usePeopleSearchSort';
import { usePeopleWatchlistSort } from './Sort/usePeopleWatchlistSort';
import useDashboardLocation, {
  DashboardLocation
} from './useDashboardLocation';

export const useSearchSort = () => {
  const { location, urn } = useDashboardLocation();
  const { sort: companySearchSort } = useCompanySearchSort();
  const { sort: peopleSearchSort } = usePeopleSearchSort();
  const { sort: peopleWatchlistSort } = usePeopleWatchlistSort();
  const { sort: companyWatchlistSort } = useCompanyWatchlistSort();
  const { sort: companySavedSearchSort } = useCompanySavedSearchSort();
  const { sort: peopleSavedSearchSort } = usePeopleSavedSearchSort();

  // We are reading the sort value for the current page from zustand and any updates to that sort value will
  // trigger a refetch of the results.
  return useMemo(() => {
    if (location === DashboardLocation.PERSON_LIST) {
      return peopleWatchlistSort;
    }
    if (location === DashboardLocation.COMPANY_LIST) {
      return companyWatchlistSort;
    }
    if (location === DashboardLocation.COMPANY_SEARCH) {
      if (urn) {
        return companySavedSearchSort;
      }
      return companySearchSort;
    }
    if (location === DashboardLocation.PEOPLE_SEARCH) {
      if (urn) {
        return peopleSavedSearchSort;
      }
      return peopleSearchSort;
    }
    return undefined;
  }, [
    location,
    companyWatchlistSort,
    peopleWatchlistSort,
    companySearchSort,
    peopleSearchSort,
    companySavedSearchSort,
    peopleSavedSearchSort,
    urn
  ]);
};
