import { useApolloClient } from '@apollo/client';
import {
  EntityListType,
  EntityListTypeToDashboardPath,
  INITIAL_SEARCH_MODEL,
  ISearchModel
} from 'interfaces/SearchModel/Search';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchVersion } from 'utils/analytics';
import {
  ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE,
  ADD_SAVED_SEARCH_ERROR_MESSAGE
} from 'utils/constants';
import { ISavedSearchInput, createSavedSearch } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import { snakelize } from 'utils/utilities';
import { useLazyFetchSingleSavedSearch } from './useFetchSingleSavedSearch';

interface MakeCopyVariables {
  visibleColumns?: string[] | null;
}

export const useCopySavedSearch = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const { fetchSavedSearchByUrn } = useLazyFetchSingleSavedSearch();

  const makeCopy = async (
    urn?: string,
    name?: string,
    copyVariables?: MakeCopyVariables
  ) => {
    if (!urn) {
      displayToast({
        primaryText: ADD_SAVED_SEARCH_ERROR_MESSAGE,
        mode: 'error'
      });
      return;
    }
    setLoading(true);

    const savedSearch = await fetchSavedSearchByUrn(urn, {
      fetchPolicy: 'network-only'
    });

    if (!savedSearch) {
      displayToast({
        primaryText: ADD_SAVED_SEARCH_ERROR_MESSAGE,
        mode: 'error'
      });
      setLoading(false);
      return;
    }
    const searchName = name ? name : `${savedSearch.name} (copy)`;
    const entityType =
      savedSearch.type === 'COMPANIES_LIST'
        ? EntityListType.COMPANY_SAVED_SEARCH
        : EntityListType.PEOPLE_SAVED_SEARCH;

    let visibleColumns = savedSearch.visibleColumns;
    if (!isNil(copyVariables?.visibleColumns)) {
      visibleColumns = copyVariables?.visibleColumns ?? [];
    }
    const payload: ISavedSearchInput = {
      name: `${searchName}`,
      is_private: true,
      type: entityType,
      keywords: '',
      query: snakelize(
        savedSearch?.searchQuery ?? INITIAL_SEARCH_MODEL
      ) as ISearchModel,
      visible_columns: visibleColumns
    };
    try {
      const response = await createSavedSearch(
        client,
        payload,
        SearchVersion.V2
      );

      displayToast({
        primaryText: 'Successfully created new saved search',
        mode: 'success'
      });

      const toNavigateRoute = `/dashboard/${EntityListTypeToDashboardPath[entityType]}/${response?.entityUrn}`;
      navigate(toNavigateRoute);
    } catch (errorCode) {
      if (errorCode == 409) {
        displayToast({
          primaryText: ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE,
          mode: 'error'
        });
      } else {
        displayToast({
          primaryText: ADD_SAVED_SEARCH_ERROR_MESSAGE,
          mode: 'error'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    makeCopy,
    loading
  };
};
