import classNames from 'classnames';
import { IconProps } from './type';

const UnselectionContentIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      stroke="#B8BECB"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <circle cx="6" cy="6" r="5.25" stroke="#B8BECB" strokeWidth="1.5" />
    </svg>
  );
};
export default UnselectionContentIcon;
