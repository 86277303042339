import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'chartjs-adapter-dayjs-3';
import ApolloWrapper from 'config/ApolloWrapper';
import { config } from 'config/config';
import { Container, createRoot } from 'react-dom/client';
import HttpsRedirect from 'react-https-redirect';
import { IntercomProvider } from 'react-use-intercom';
import {
  API_MOCKING_ENABLED,
  IS_PROD_LIKE_ENV,
  LOCAL_AGAINST_PROD
} from 'utils/constants';
import { logger } from 'utils/logger';
import App from './App';
import { SplitWrapper } from './config/SplitWrapper';
import StoreProvider from './config/StoreProvider';
import './config/firebase';
import './index.css';
import rootStore from './stores/mobx/rootStore';
import './styles/HoverActions.css';
import './styles/ag-theme-harmonic.css';
import './styles/index.css';
import './utils/axiosInterceptor';

async function startApp() {
  if (IS_PROD_LIKE_ENV && !LOCAL_AGAINST_PROD) {
    // Enable logging only in production
    logger.initialize();
  }

  if (API_MOCKING_ENABLED) {
    const { workers } = await import('./mocks/browser');
    workers.start();
  }

  const container = document.getElementById('root');
  const root = createRoot(container as Container);
  const query = new URLSearchParams(window?.location?.search);
  if (query?.get('removeAllCaches')) {
    setTimeout(() => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.replace(window.location.pathname);
    }, 1500);
  }
  root.render(
    <HttpsRedirect>
      <SplitWrapper>
        <ApolloWrapper>
          <StoreProvider rootStore={rootStore}>
            <IntercomProvider appId={config.INTERCOM_APP_ID}>
              <App />
            </IntercomProvider>
          </StoreProvider>
        </ApolloWrapper>
      </SplitWrapper>
    </HttpsRedirect>
  );
}
startApp();
