import { useQuery } from '@apollo/client';
import {
  GetAllTeamMembersWithIntegratedEmailsByCustomerQuery,
  GetAllTeamMembersWithIntegratedEmailsByCustomerQueryVariables
} from '__generated__/graphql';
import GreyLinearProgress from 'components/common/GreyLinearProgress';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { compact, uniq } from 'lodash';
import { GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS } from 'queries/getAllTeamMembersWithIntegratedEmails';
import { useMemo, useState } from 'react';
import { useAuthState } from '../../../hooks/useAppState';

interface IntegratedEmailUserMultiselectProps {
  onChange: (newOptions?: string[]) => void;
  selected: string[];
  labelPrefix?: string;
  disabled?: boolean;
  initialFocus?: boolean;
}

const IntegratedEmailUserMultiselect: React.FC<
  IntegratedEmailUserMultiselectProps
> = ({ onChange, selected, labelPrefix, initialFocus }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const authState = useAuthState();
  const customerUrn = authState.userMetadata?.customer_urn ?? '';

  const { data: usersData, loading } = useQuery<
    GetAllTeamMembersWithIntegratedEmailsByCustomerQuery,
    GetAllTeamMembersWithIntegratedEmailsByCustomerQueryVariables
  >(GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS, {
    variables: { customer_urn: customerUrn }
  });

  const onClickListItem = (email: string) => {
    onChange(uniq([...selected, email]));
    setSearchTerm('');
  };

  const onRemoveItem = (email: string) => {
    const updatedSelectedOptions = selected.filter(
      (option) => option !== email
    );
    onChange(updatedSelectedOptions);
  };

  const getDisplayNameFromEmail = (email: string) => {
    const user =
      usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer?.find((user) =>
        user?.integratedEmails?.some(
          (integratedEmail) => integratedEmail === email
        )
      );
    return user?.name ?? email;
  };

  const filteredEmailAddresses = useMemo(
    () =>
      compact(
        usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer
          ?.flatMap((user) => {
            if (!user) return null;
            return user.integratedEmails?.filter((email) =>
              email?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
          .filter((email) => email && !selected.includes(email))
      ) ?? [],
    [usersData, searchTerm, selected]
  );

  return (
    <div className="min-w-56 w-full">
      <Select
        dataTestId="integrated-email-user-multiselect"
        multiple
        filterable
        fullWidth
        filterTerm={searchTerm}
        onFilterTermChange={setSearchTerm}
        selected={selected}
        labelPrefix={labelPrefix}
        placeholder="Search for people on your team"
        initialFocus={initialFocus}
        onRemove={(company) => onRemoveItem(company)}
        getLabelFromValue={(email) => getDisplayNameFromEmail(email ?? '')}
        hideDropdown={
          !loading &&
          (Boolean(
            usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer
          ) === false ||
            usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer
              ?.length === 0)
        }
      >
        {loading && (
          <div className="h-p20 w-full">
            <GreyLinearProgress />
          </div>
        )}
        {!loading &&
          filteredEmailAddresses.map((email) => (
            <SelectListItem
              key={email}
              value={email ?? ''}
              label={getDisplayNameFromEmail(email)}
              onClick={() => email && onClickListItem(email)}
              variant={ListVariant.default}
            />
          ))}
      </Select>
    </div>
  );
};

export default IntegratedEmailUserMultiselect;
