import {
  DateTypeEnum,
  InternalModeType
} from 'components/QueryBuilder/Common/CustomRange/DateModal';
import React from 'react';
import RangeDatePicker from './RangeDatePicker';
import SingleDatePicker from './SingleDatePicker';
import './datepicker.css';

type DatePickerBaseProps = {
  type?: 'single' | 'range';
  fullWidth?: boolean;
};

export type SingleDatePickerProps = {
  selectedDate: string | null | undefined;
  onChange: (date: string | null) => void;
  dateType?: DateTypeEnum;
  internalModeType?: InternalModeType;
};

export type RangeDatePickerProps = {
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  onChange: (startDate?: string | null, endDate?: string | null) => void;
  dateType?: DateTypeEnum;
};

type DatePickerPropsByType =
  | ({ type: 'single' } & SingleDatePickerProps)
  | ({ type: 'range' } & RangeDatePickerProps);

type DatePickerProps = DatePickerBaseProps & DatePickerPropsByType;

const DatePicker: React.FC<DatePickerProps> = (props) => {
  if (props.type === 'single')
    return (
      <SingleDatePicker
        selectedDate={props.selectedDate}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
      />
    );
  return (
    <RangeDatePicker
      startDate={props.startDate}
      endDate={props.endDate}
      onChange={props.onChange}
    />
  );
};

export default DatePicker;
