import { flip } from '@floating-ui/dom';
import { CalendarCheckIcon } from 'assets/harmonic-icons';
import dayjs from 'dayjs';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { dayjsExt } from '../../config/dayjs';
import DatePickerHeader from './DatePickerHeader';
import DatePickerInput from './DatePickerInput';

interface RangeDatePickerProps {
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  onChange: (startDate?: string | null, endDate?: string | null) => void;
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
  startDate,
  endDate,
  onChange
}) => {
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const onStartDateChange = (date: Date | null) => {
    onChange(date ? dayjs(date).format('YYYY-MM-DD') : null, endDate);
    setStartDateOpen(false);
    setEndDateOpen(true);
  };

  const onEndDateChange = (date: Date | null) => {
    onChange(startDate, date ? dayjs(date).format('YYYY-MM-DD') : null);
    setEndDateOpen(false);
  };

  const onDateChange = (startDate: Date | null, endDate: Date | null) => {
    onChange(
      startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate ? dayjs(endDate).format('YYYY-MM-DD') : null
    );
    setStartDateOpen(false);
    setEndDateOpen(false);
  };

  const startDateObj = startDate ? dayjs(startDate).toDate() : null;
  const endDateObj = endDate ? dayjs(endDate).toDate() : null;
  const listActions = (
    <>
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This week"
        label="This week"
        onClick={() => {
          const today = dayjs();
          const startOfWeek = today.clone().startOf('week');
          onDateChange(startOfWeek.toDate(), today.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This month"
        label="This month"
        onClick={() => {
          const today = dayjs();
          const startOfWeek = today.clone().startOf('month');
          onDateChange(startOfWeek.toDate(), today.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This quarter"
        label="This quarter"
        onClick={() => {
          const today = dayjsExt();
          const startOfWeek = today.clone().startOf('quarter');
          onDateChange(startOfWeek.toDate(), today.toDate());
        }}
      />
      <ListItem
        variant={ListVariant.default}
        primaryIcon={CalendarCheckIcon}
        value="This year"
        label="This year"
        onClick={() => {
          const today = dayjs();
          const startOfWeek = today.clone().startOf('year');
          onDateChange(startOfWeek.toDate(), today.toDate());
        }}
      />
    </>
  );

  const popperModifiers = [
    flip({
      mainAxis: true,
      crossAxis: true,
      fallbackPlacements: ['bottom', 'top', 'left', 'right'],
      padding: 10
    })
  ];

  return (
    <div className="flex items-center">
      <ReactDatePicker
        open={startDateOpen}
        onInputClick={() => setStartDateOpen(true)}
        onClickOutside={() => setStartDateOpen(false)}
        selected={startDateObj}
        onChange={(date) => onStartDateChange(date)}
        dateFormat="yyyy-MM-dd"
        selectsStart
        dropdownMode="select"
        showPopperArrow={false}
        renderCustomHeader={({ ...props }) => {
          return <DatePickerHeader {...props} selectedDate={startDate} />;
        }}
        scrollableMonthYearDropdown={true}
        scrollableYearDropdown={true}
        startDate={startDateObj}
        popperModifiers={popperModifiers}
        endDate={endDateObj}
        calendarClassName="shadow-static-elevation-floating flex flex-col range-date-picker"
        //eslint-disable-next-line
        //@ts-ignore
        customInput={<DatePickerInput />}
      >
        <div className="border-t border-solid border-border p-p30">
          {listActions}
        </div>
      </ReactDatePicker>
      <div className="px-p40">-</div>
      <ReactDatePicker
        open={endDateOpen}
        onInputClick={() => setEndDateOpen(true)}
        onClickOutside={() => setEndDateOpen(false)}
        onChange={(date) => onEndDateChange(date)}
        selected={endDateObj}
        showPopperArrow={false}
        dropdownMode="select"
        selectsEnd
        calendarClassName="shadow-static-elevation-floating flex flex-col"
        dateFormat="yyyy-MM-dd"
        renderCustomHeader={({ ...props }) => {
          return <DatePickerHeader {...props} selectedDate={endDate} />;
        }}
        popperModifiers={popperModifiers}
        startDate={startDateObj}
        endDate={endDateObj}
        minDate={startDateObj}
        //eslint-disable-next-line
        //@ts-ignore
        customInput={<DatePickerInput />}
      >
        <div className="border-t border-solid border-border p-p30">
          <ListItem
            variant={ListVariant.default}
            primaryIcon={CalendarCheckIcon}
            value="Today"
            label="Today"
            onClick={() => {
              onEndDateChange(dayjs().toDate());
            }}
          />

          {listActions}
        </div>
      </ReactDatePicker>
    </div>
  );
};

export default RangeDatePicker;
