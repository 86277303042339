import { gql } from '@apollo/client';

export const GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST = gql`
  query GetUserPeopleImportsByPeopleListUrnOrId(
    $peopleListIdOrUrn: String!
    $page: Int
    $size: Int
  ) {
    getUserPeopleImportsByPeopleListUrnOrId(
      peopleListIdOrUrn: $peopleListIdOrUrn
      page: $page
      size: $size
    ) {
      totalCount
      imports {
        pendingImportsCount
        totalImportsCount
        failedImportsCount
        createdAt
        entityUrn
        foundImportsCount
        fileName
        userId
        userUrn
        user {
          email
          name
        }
        customerId
        customerUrn
        customer {
          name
        }
      }
    }
  }
`;
