import { Popper } from '@material-ui/core';
import { updateSearchModel } from 'actions/dashboardActions';
import classNames from 'classnames';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import useDashboardLocation from 'hooks/useDashboardLocation';
import useFetchSingleSavedSearch from 'hooks/useFetchSingleSavedSearch';
import usePersistedQuery from 'hooks/usePersistedQuery';
import { useQueryBuilderHotkeys } from 'hooks/useQueryBuilderHotkeys';
import { useSearchSort } from 'hooks/useSearchSort';
import {
  EntityListType,
  INITIAL_SEARCH_FILTER_GROUP,
  INITIAL_SEARCH_MODEL,
  ISearchFilterGroup,
  ISearchModel,
  SearchFilterGroupQuery
} from 'interfaces/SearchModel/Search';
import {
  ActiveFilterCountByTabResponse,
  FilterGroupGeneratorId,
  FilterTabs,
  PeopleSearchCurrentExperienceGenerator,
  PeopleSearchPastExperienceGenerator
} from 'interfaces/SearchV2';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import useZustandStore from 'stores/zustandStore';
import { logger } from 'utils/logger';
import { ISavedSearchInput } from 'utils/midtierApi';
import {
  PeopleSearchExperienceGeneratorKey,
  getActiveFilterCountByTab,
  getExpandedFieldsOrderForCurrentOrPastExperienceGenerator,
  getFilterGroupGeneratorsMap
} from 'utils/searchV2/searchV2';
import { displayToast } from 'utils/toasts';
import { useStore } from '../../config/StoreProvider';
import { useDashboardState } from '../../hooks/useAppState';
import { AppState } from '../../hooks/useAppStore';
import useUpdateSavedSearch from '../../hooks/useUpdateSavedSearch';
import { FilterTabSelector, getFilterTabOptions } from './FilterTabSelector';
import {
  DEFAULT_COMPANY_SEARCH_QUERY,
  DEFAULT_PEOPLE_SEARCH_QUERY,
  QueryBuilderPopover
} from './QueryBuilderPopover';

interface QueryPopperWrapperProps {
  entityId?: number;
  tabsToShow: FilterTabs[];
  entityType: EntityListType;
}

export const getQueryFromDashboardSearchModel = (
  dashboardState: AppState['dashboard']
) => {
  if (!dashboardState.searchModel) {
    return cloneDeep({
      filter_group: INITIAL_SEARCH_FILTER_GROUP,
      controlled_filter_group: INITIAL_SEARCH_FILTER_GROUP,
      sort: []
    });
  }
  return cloneDeep({
    filter_group: dashboardState.searchModel.filter_group,
    controlled_filter_group: dashboardState.searchModel
      .controlled_filter_group as ISearchFilterGroup,
    sort: dashboardState.searchModel.sort
  });
};

const QueryPopperWrapper: React.FC<QueryPopperWrapperProps> = ({
  entityId,
  tabsToShow,
  entityType
}) => {
  const filterAnchorEl = React.useRef<null | HTMLDivElement>(null);

  const [selectedFilterTab, setSelectedFilterTab] = useState<FilterTabs>();

  useQueryBuilderHotkeys(selectedFilterTab, setSelectedFilterTab, tabsToShow);
  const dashboardState = useDashboardState();
  const { editFilterStore } = useShallowFilterStore(['editFilterStore']);
  const { currentPageSavedSearch } = useFetchSingleSavedSearch();
  const { updateSavedSearch } = useUpdateSavedSearch();
  const userUrn = useZustandStore((state) => state.userUrn);
  const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;
  const [activeFilterCountByTab, setActiveFilterCountByTab] =
    useState<ActiveFilterCountByTabResponse>();

  const FilterTabOptions = getFilterTabOptions(
    entityType === EntityListType.COMPANY_WATCHLIST ||
      entityType === EntityListType.PEOPLE_WATCHLIST
  );

  const defaultSearchQuery =
    entityType === EntityListType.COMPANY_SAVED_SEARCH
      ? DEFAULT_COMPANY_SEARCH_QUERY
      : DEFAULT_PEOPLE_SEARCH_QUERY;

  const currentPersistedQuery = usePersistedQuery(entityType);
  const [searchQuery, setSearchQuery] = useState(currentPersistedQuery);
  const { onClearNetNewForSearch } = useNetNewCounts();
  const [savedSearchUpdateLoading, setSavedSearchUpdateLoading] =
    useState(false);
  const sort = useSearchSort();
  const { urn } = useDashboardLocation();
  const [currentExperienceExpandedFields, setCurrentExperienceExpandedFields] =
    useState<PeopleSearchExperienceGeneratorKey[]>([]);

  const [pastExperienceExpandedFields, setPastExperienceExpandedFields] =
    useState<PeopleSearchExperienceGeneratorKey[]>([]);

  const { rootStore } = useStore();

  const submitFilter = async (searchQuery: SearchFilterGroupQuery) => {
    const clonedSearchQuery = cloneDeep(searchQuery);
    const searchModel: ISearchModel = {
      ...INITIAL_SEARCH_MODEL,
      filter_group: clonedSearchQuery.filter_group,
      controlled_filter_group: clonedSearchQuery.controlled_filter_group
    };
    updateSearchModel(searchModel);
    rootStore.companiesExploreStore.updateSearchModel({
      searchFilterGroupQuery: clonedSearchQuery,
      pageSort: sort
    });
    const persistStoragePayload = {
      filter_group: clonedSearchQuery.filter_group,
      controlled_filter_group: clonedSearchQuery.controlled_filter_group
    } as SearchFilterGroupQuery;

    if (!entityId) {
      const filterStoreName =
        entityType === EntityListType.COMPANY_SAVED_SEARCH
          ? 'companiesDashboardFilters'
          : 'peopleDashboardFilters';
      editFilterStore(filterStoreName, persistStoragePayload);
    }

    if (isSearchOwner) await updateTheSavedSearch(searchQuery);
  };

  const updateTheSavedSearch = async (searchQuery: SearchFilterGroupQuery) => {
    if (savedSearchUpdateLoading || !entityId) return;
    const clonedSavedSearch = cloneDeep(searchQuery);
    setSavedSearchUpdateLoading(true);
    if (!currentPageSavedSearch) {
      logger.error('Could not find saved search to update');

      displayToast({
        primaryText: 'There was a problem updating the saved search.',
        mode: 'error'
      });
      setSavedSearchUpdateLoading(false);
      return;
    }

    if (urn) {
      onClearNetNewForSearch(urn);
    }

    const payload: Partial<ISavedSearchInput> = {
      query: {
        ...INITIAL_SEARCH_MODEL,
        controlled_filter_group: clonedSavedSearch.controlled_filter_group,
        filter_group: clonedSavedSearch.filter_group,
        sort: sort?.sortField
          ? [
              {
                sort_field: sort.sortField,
                sortField: sort.sortField,
                descending: sort.descending ?? false
              }
            ]
          : dashboardState.searchModel.sort
      },
      clear_net_new: true
    };

    await updateSavedSearch({
      idOrUrn: entityId?.toString() ?? '',
      input: payload,
      clearResultsCache: true
    });

    setSavedSearchUpdateLoading(false);
  };

  const setExpandedFieldsForCurrentAndPastExperience = (
    searchQuery: SearchFilterGroupQuery
  ) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(
      searchQuery.controlled_filter_group
    );
    const currentExperienceGenerator =
      controlledSearchState?.[
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE
      ];
    const pastExperienceGenerator =
      controlledSearchState?.[
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE
      ];
    const currentExpandedFields =
      getExpandedFieldsOrderForCurrentOrPastExperienceGenerator(
        'current',
        currentExperienceGenerator as PeopleSearchCurrentExperienceGenerator
      );
    const pastExpandedFields =
      getExpandedFieldsOrderForCurrentOrPastExperienceGenerator(
        'past',
        pastExperienceGenerator as PeopleSearchPastExperienceGenerator
      );
    setCurrentExperienceExpandedFields(currentExpandedFields);
    setPastExperienceExpandedFields(pastExpandedFields);
  };

  useEffect(() => {
    if (
      entityType === EntityListType.COMPANY_WATCHLIST ||
      entityType === EntityListType.PEOPLE_WATCHLIST
    ) {
      return;
    }
    const clonedQuery = cloneDeep(currentPersistedQuery);
    setSearchQuery(clonedQuery);
    setExpandedFieldsForCurrentAndPastExperience(currentPersistedQuery);
  }, [currentPersistedQuery]);

  // Watchlist filter is not cached at all. Hence, we have this extra check to call from dashboardState
  // When ported to backend, none of this will be needed

  useEffect(() => {
    if (
      entityType === EntityListType.COMPANY_WATCHLIST ||
      entityType === EntityListType.PEOPLE_WATCHLIST
    ) {
      const currentPersistedQuery =
        getQueryFromDashboardSearchModel(dashboardState);
      setSearchQuery(currentPersistedQuery);
    }
  }, [dashboardState.searchModel]);

  const closeModal = (reset = true) => {
    setSelectedFilterTab(undefined);
    if (reset) {
      const clonedQuery = cloneDeep(currentPersistedQuery);
      setSearchQuery(clonedQuery);
    }
  };

  const onResetSearch = () => {
    setSearchQuery(defaultSearchQuery);
    submitFilter(defaultSearchQuery);
  };

  return (
    <>
      <div ref={filterAnchorEl} className="flex-1">
        <FilterTabSelector
          selectedFilterTab={selectedFilterTab}
          setSelectedFilterTab={setSelectedFilterTab}
          activeFilterCountByTab={getActiveFilterCountByTab(searchQuery)}
          tabsToShow={tabsToShow}
          onResetSearch={onResetSearch}
          FilterTabOptions={FilterTabOptions}
        />
      </div>

      <Popper
        anchorEl={filterAnchorEl.current}
        open={!!selectedFilterTab}
        className="z-150 -top-[12px] -left-[12px]"
        modifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          }
        }}
        placement="top-start"
      >
        <div
          className={classNames('rounded-2xl', 'fixed', {
            'shadow-none bg-transparent': selectedFilterTab === undefined,
            'shadow-popover bg-white': selectedFilterTab !== undefined
          })}
        >
          <QueryBuilderPopover
            entityId={entityId}
            selectedFilterTab={selectedFilterTab}
            setSelectedFilterTab={setSelectedFilterTab}
            tabsToShow={tabsToShow}
            entityType={entityType}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            submitFilter={submitFilter}
            onResetSearch={onResetSearch}
            closeModal={closeModal}
            currentExperienceExpandedFields={currentExperienceExpandedFields}
            setCurrentExperienceExpandedFields={
              setCurrentExperienceExpandedFields
            }
            pastExperienceExpandedFields={pastExperienceExpandedFields}
            setPastExperienceExpandedFields={setPastExperienceExpandedFields}
            activeFilterCountByTab={activeFilterCountByTab}
            setActiveFilterCountByTab={setActiveFilterCountByTab}
          />
        </div>
      </Popper>
    </>
  );
};

export default QueryPopperWrapper;
