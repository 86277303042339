import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { BackendCustomRangeModeType } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { useState } from 'react';
import {
  formatDateRangeByMode,
  formatYearRangeByMode
} from 'utils/searchV2/searchV2';
import DateModal, { DateTypeEnum, TwoSidedRangeValue } from './DateModal';

interface CustomRangeProps {
  value?: TwoSidedRangeValue | undefined | null;
  mode?: BackendCustomRangeModeType;
  onChange: (
    mode?: BackendCustomRangeModeType,
    value?: TwoSidedRangeValue
  ) => void;
  dateType?: DateTypeEnum;
}

const CustomRange: React.FC<CustomRangeProps> = ({
  mode,
  value,
  onChange,
  dateType = DateTypeEnum.DAY
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pillLabel =
    dateType === DateTypeEnum.DAY
      ? formatDateRangeByMode('Custom range', mode, value)
      : formatYearRangeByMode('Custom range', mode, value);

  return (
    <div>
      <RadioPill
        dataTestId="CustomRange-Radio-Pill"
        selected={isOpen || !isNil(value)}
        label={pillLabel}
        onClick={handleClick}
      />
      <DateModal
        anchorEl={anchorEl}
        onClose={handleClose}
        open={isOpen}
        mode={mode}
        value={value}
        onChange={onChange}
        dateType={dateType}
      />
    </div>
  );
};

export default CustomRange;
