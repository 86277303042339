import classNames from 'classnames';
import { HarmonicIcon } from './type';

const ChartIcon: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.6666 2.00049C11.1143 2.00049 10.6666 2.4482 10.6666 3.00049V12.9998H9.66663V6.66715C9.66663 6.11487 9.21891 5.66715 8.66663 5.66715H7.33329C6.78101 5.66715 6.33329 6.11487 6.33329 6.66715V12.9998H5.33329V10.3338C5.33329 9.78154 4.88558 9.33382 4.33329 9.33382H2.99996C2.44767 9.33382 1.99996 9.78154 1.99996 10.3338V12.9998H1.16663C0.890484 12.9998 0.666626 13.2237 0.666626 13.4998C0.666626 13.776 0.890484 13.9998 1.16663 13.9998H14.8333C15.1094 13.9998 15.3333 13.776 15.3333 13.4998C15.3333 13.2237 15.1094 12.9998 14.8333 12.9998H14V3.00049C14 2.4482 13.5522 2.00049 13 2.00049H11.6666Z"
      />
    </svg>
  );
};

export default ChartIcon;
