import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const CybersecurityIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C9.23858 2.25 7 4.48858 7 7.25V9H5.75C4.7835 9 4 9.7835 4 10.75V20.25C4 21.2165 4.7835 22 5.75 22H18.25C19.2165 22 20 21.2165 20 20.25V10.75C20 9.7835 19.2165 9 18.25 9H17V7.25C17 4.48858 14.7614 2.25 12 2.25ZM15.5 9V7.25C15.5 5.317 13.933 3.75 12 3.75C10.067 3.75 8.5 5.317 8.5 7.25V9H15.5ZM12 13.25C12.4142 13.25 12.75 13.5858 12.75 14V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V14C11.25 13.5858 11.5858 13.25 12 13.25Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default CybersecurityIcon;
