import { useApolloClient, useMutation } from '@apollo/client';
import {
  SavedSearchUpdateInput,
  UpdateSavedSearchMutation,
  UpdateSavedSearchMutationVariables
} from '__generated__/graphql';
import { getIdFromUrn } from 'common/utils/urn';
import { GET_SAVED_SEARCH } from 'queries/getSavedSearch';
import { UPDATE_SAVED_SEARCH } from 'queries/updateSavedSearch';
import { displayToast } from 'utils/toasts';
import { logger } from '../utils/logger';
import { removeArtifactsFromSearchQuery } from '../utils/search';
import { camelize } from '../utils/utilities';
import { useLocalSearchState } from './useAppState';
import useFetchSingleSavedSearch from './useFetchSingleSavedSearch';

type UseUpdateSavedSearchResults = {
  loading: boolean;
  updateSavedSearch: ({
    idOrUrn,
    input,
    clearResultsCache,
    skipUpsertLocalSearch,
    toast,
    logging
  }: {
    idOrUrn: string;
    input: SavedSearchUpdateInput;
    clearResultsCache?: boolean;
    skipUpsertLocalSearch?: boolean;
    toast?: {
      successMessage?: string;
    };
    logging?: {
      codeArea?: string;
    };
  }) => void;
};

const useUpdateSavedSearch = (): UseUpdateSavedSearchResults => {
  const client = useApolloClient();
  const { currentPageSavedSearch } = useFetchSingleSavedSearch();
  const { updateLocalSearch } = useLocalSearchState();
  const [updateSavedSearchMutation, { loading }] = useMutation<
    UpdateSavedSearchMutation,
    UpdateSavedSearchMutationVariables
  >(UPDATE_SAVED_SEARCH);

  const updateSavedSearch = async ({
    idOrUrn,
    input,
    clearResultsCache,
    skipUpsertLocalSearch,
    toast,
    logging
  }: {
    idOrUrn: string;
    input: SavedSearchUpdateInput;
    clearResultsCache?: boolean;
    skipUpsertLocalSearch?: boolean;
    toast?: {
      successMessage?: string;
      errorMessage?: string;
    };
    logging?: {
      codeArea?: string;
    };
  }) => {
    const updateSavedSearchInput = camelize(input);

    if (input.query) {
      updateSavedSearchInput.query = removeArtifactsFromSearchQuery(
        input.query
      );
    }

    if (!skipUpsertLocalSearch && currentPageSavedSearch) {
      const urn = idOrUrn.startsWith('urn')
        ? idOrUrn
        : `urn:harmonic:saved_search:${idOrUrn}`;

      updateLocalSearch(urn, {
        ...currentPageSavedSearch,
        name: input.name ?? currentPageSavedSearch.name ?? '',
        searchQuery: input.query ?? currentPageSavedSearch.searchQuery ?? '',
        visibleColumns:
          input.visibleColumns ?? currentPageSavedSearch.visibleColumns ?? []
      });

      client.cache.evict({
        id: client.cache.identify({
          __typename: 'SavedSearch',
          id: currentPageSavedSearch?.id
        }),
        fieldName: 'results'
      });

      return;
    }

    const res = await updateSavedSearchMutation({
      variables: {
        idOrUrn,
        updateSavedSearchInput
      },
      ...(currentPageSavedSearch && {
        optimisticResponse: {
          updateSavedSearch: {
            ...currentPageSavedSearch,
            ...updateSavedSearchInput
          }
        }
      }),
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SAVED_SEARCH,
          variables: { idOrUrn: getIdFromUrn(idOrUrn) ?? 'NA' }
        }
      ],
      update: (cache, { data }) => {
        cache.writeQuery({
          query: GET_SAVED_SEARCH,
          variables: { id: data?.updateSavedSearch.id },
          data: {
            getSavedSearch: data?.updateSavedSearch
          }
        });

        // We want to clear the results cache for the saved search if the query has changed
        if (clearResultsCache) {
          cache.evict({
            id: cache.identify({
              __typename: 'SavedSearch',
              id: data?.updateSavedSearch.id
            }),
            fieldName: 'results'
          });
        }
      }
    });

    displayToast({
      primaryText: toast?.successMessage ?? 'Successfully updated saved search',
      mode: 'success'
    });

    if (!res.data?.updateSavedSearch) {
      displayToast({
        primaryText: 'There was a problem updating the saved search',
        mode: 'error'
      });
      logger.error('Company or people saved search update error', {
        error: res.errors,
        code_area: logging?.codeArea
      });
      return null;
    }
    return res.data.updateSavedSearch;
  };

  return {
    loading,
    updateSavedSearch
  };
};
export default useUpdateSavedSearch;
