import { getIdFromUrn } from 'common/utils/urn';
import useDashboardLocation from 'hooks/useDashboardLocation';
import {
  EntityListType,
  INITIAL_SEARCH_MODEL,
  SearchFilterGroupQuery
} from 'interfaces/SearchModel/Search';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import useStore from 'stores/zustandStore';
import { ISavedSearchInput } from 'utils/midtierApi';
import {
  SortHookResponse,
  getModifiedPeopleSortValueAfterValidationCheck
} from 'utils/sort';
import useFetchSingleSavedSearch from '../useFetchSingleSavedSearch';
import usePersistedQuery from '../usePersistedQuery';
import useUpdateSavedSearch from '../useUpdateSavedSearch';

export const usePeopleSavedSearchSort = (): SortHookResponse => {
  const { urn } = useDashboardLocation();
  const userUrn = useStore((state) => state.userUrn);

  const { currentPageSavedSearch } = useFetchSingleSavedSearch();
  const { updateSavedSearch } = useUpdateSavedSearch();
  const currentPersistedQuery = usePersistedQuery(
    EntityListType.PEOPLE_SAVED_SEARCH
  );
  const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;
  const entityId = urn && getIdFromUrn(urn);

  const updateSortDataOnBackend = useCallback(
    async (sortField: string, isDescending: boolean) => {
      const clonedSearchQuery = cloneDeep(
        currentPersistedQuery
      ) as SearchFilterGroupQuery;

      const queryInfo = {
        filter_group: clonedSearchQuery.filter_group,
        controlled_filter_group: clonedSearchQuery.controlled_filter_group,
        sort: [
          {
            sortField: sortField,
            descending: isDescending
          }
        ]
      };
      const mainPayload: Partial<ISavedSearchInput> = {
        query: {
          ...INITIAL_SEARCH_MODEL,
          ...queryInfo
        }
      };
      if (!isSearchOwner) return;
      await updateSavedSearch({
        idOrUrn: entityId ?? '',
        input: mainPayload
      });
    },
    [currentPersistedQuery, isSearchOwner, entityId, updateSavedSearch]
  );

  const sort = useMemo(() => {
    if (currentPageSavedSearch?.searchQuery?.sort) {
      const { sortField, descending } =
        getModifiedPeopleSortValueAfterValidationCheck(
          currentPageSavedSearch?.searchQuery?.sort?.[0]?.sortField,
          currentPageSavedSearch?.searchQuery?.sort?.[0]?.descending
        );
      return {
        sortField,
        descending
      };
    }
  }, [currentPageSavedSearch]);

  const changeSort = useCallback(
    ({ field, descending }: { field: string; descending: boolean }) => {
      updateSortDataOnBackend(field, descending);
    },
    [updateSortDataOnBackend]
  );

  return {
    changeSort,
    sort
  };
};
