import { Popover } from '@headlessui/react';
import { FundingRoundFragment } from '__generated__/graphql';
import { FundingSourcesIcon } from 'assets/harmonic-icons';
import { InvestorPill } from 'components/common/Investors/InvestorPill';
import dayjs from 'dayjs';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import React from 'react';
import { UNKNOWN_VALUE } from 'utils/constants';
import {
  getFormattedFundingTotal,
  getFundingFavIconsByDomain,
  getFundingRoundStyle,
  isExcludedFromFundingTotal
} from 'utils/funding';

export type Investor = {
  name: string;
  entityUrn: string;
  logoUrl?: string;
  investorUrn?: string;
};

export type FundingRoundInvestor = Investor & {
  id: string;
  roundType: string;
  isLead: boolean;
};

type CompanySingleRbrProps = {
  fundingRound?: FundingRoundFragment | null;
  investors: FundingRoundInvestor[];
  investorToFundingRoundTypesMap: {
    [entityUrn: string]: {
      fundingRoundType: string;
      isLead: boolean;
    }[];
  };
};

export const CompanySingleRbrV2: React.FC<CompanySingleRbrProps> = ({
  fundingRound,
  investors,
  investorToFundingRoundTypesMap
}) => {
  const fundingRoundType = getFundingTypeDisplayName(
    fundingRound?.fundingRoundType
  );
  const isExcludedFromTotal = isExcludedFromFundingTotal(
    fundingRound?.fundingRoundType
  );
  const fundingRoundStyle = getFundingRoundStyle(fundingRoundType);
  const FundingRoundIcon = fundingRoundStyle.icon;
  const domain = fundingRound?.sourceUrl
    ? new URL(fundingRound.sourceUrl).hostname.replace('www.', '')
    : '';
  const faviconUrl = getFundingFavIconsByDomain(domain);
  return (
    <div
      className={`${fundingRoundStyle.bgColor} rounded-lg py-0.5 px-0.5 w-full`}
    >
      <div className={`w-full h-[24px] px-2 py-1 flex gap-1 items-center`}>
        <FundingRoundIcon />
        <div className={`text-sm font-medium ${fundingRoundStyle.textColor}`}>
          {`${fundingRoundType}${
            isExcludedFromTotal ? ' (Excluded from total funding)' : ''
          }`}
        </div>
      </div>
      <div className="bg-white rounded-lg p-p20 w-full">
        <div className="grid grid-cols-10 px-p20 py-p40">
          <div
            data-testid="CompanySingleRbrV2-FundingAmount"
            className="col-span-2 sm:col-span-1 text-sm font-medium flex items-center"
          >
            {getFormattedFundingTotal(fundingRound?.fundingAmount, null)}
          </div>
          <div className="col-span-3 sm:col-span-1 text-sm font-medium flex items-center">
            {dayjs(fundingRound?.announcementDate).utc().format('M/D/YYYY') ??
              UNKNOWN_VALUE}
          </div>
          <div className="col-span-4 sm:col-span-7 flex items-center px-3">
            {!!fundingRound?.postMoneyValuation && (
              <div
                className={`text-xs ${fundingRoundStyle.textColor} ${fundingRoundStyle.bgColor} rounded-full px-2 py-0.5`}
              >
                {`${getFormattedFundingTotal(
                  fundingRound?.postMoneyValuation,
                  null
                )} Post-money`}
              </div>
            )}
          </div>
          <div className="col-span-1 text-base font-medium flex items-center justify-end">
            {fundingRound?.sourceUrl && (
              <Popover className="relative">
                <Popover.Button className="bg-[#F0F2F4] rounded-lg w-[34px] h-[28px] flex gap-2 items-center justify-center p-p20 hover:bg-gray-200">
                  <FundingSourcesIcon />
                </Popover.Button>

                <Popover.Panel>
                  {({ close }) => (
                    <div className="absolute z-10 right-0 mt-2 px-1 max-w-48 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 h-[44px] flex items-center justify-center">
                      <div className="min-w-[100px] p-2 flex items-center justify-center truncate rounded-md hover:bg-gray-100 h-[36px]">
                        <a
                          href={fundingRound.sourceUrl!}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="w-full"
                          onClick={() => close()}
                        >
                          <div className="p-2 flex gap-3 items-center justify-center">
                            <img
                              src={faviconUrl}
                              alt={`${domain} favicon`}
                              className="w-4 h-4"
                            />
                            <span className="text-sm font-medium">
                              {domain}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Popover>
            )}
          </div>
        </div>
        {investors.length > 0 && (
          <div className="pt-p10 px-1">
            <hr className="border-t border-solid border-[#E8EAEE]" />
            <div className="flex flex-wrap gap-1 pt-2 pb-1">
              {investors.map((inv) => {
                if (!inv.name) return;
                const otherRoundTypes = new Set(
                  investorToFundingRoundTypesMap[inv.entityUrn]
                    .map((fr) => fr.fundingRoundType)
                    .filter((fr) => fr !== inv.roundType)
                );
                return (
                  <InvestorPill
                    key={inv.name}
                    investorName={inv.name}
                    investorLogoUrl={inv.logoUrl}
                    investorUrn={inv.investorUrn}
                    isLead={inv.isLead}
                    roundTypes={otherRoundTypes}
                    small={true}
                  />
                );
              })}
            </div>
          </div>
        )}
        {!investors.length && (
          <div className="pt-p10 px-1">
            <hr className="border-t border-solid" />
            <p className="text-xs text-[#7C8598] py-1">
              Investors not available
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
