import { useLocation, useParams } from 'react-router-dom';
import { Monitoring } from 'react-scan/monitoring';
import { IS_PROD_LIKE_ENV, LOCAL_AGAINST_PROD } from 'utils/constants';

export const ReactScanMonitoring = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const params = useParams();
  const location = useLocation();
  return (
    <>
      {IS_PROD_LIKE_ENV &&
        !LOCAL_AGAINST_PROD &&
        process.env.REACT_APP_REACT_SCAN_API_KEY && (
          <Monitoring
            apiKey={process.env.REACT_APP_REACT_SCAN_API_KEY}
            url="https://monitoring.react-scan.com/api/v1/ingest"
            commit={process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA}
            branch={process.env.REACT_APP_VERCEL_GIT_COMMIT_REF}
            path={location.pathname}
            params={params as Record<string, string>}
          />
        )}
      {children}
    </>
  );
};
