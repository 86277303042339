import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const SouthAmericaIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6.95595C20 7.02195 19.6453 7.94513 19.6453 7.94513L18.5594 9.21975L18.4486 10.4286L17.7616 12.077C17.7616 12.077 17.2296 12.7582 17.1633 12.7802C17.0968 12.8022 16.454 12.9119 16.454 12.9119L14.9472 13.6811C14.9472 13.6811 14.7479 14.5823 14.7033 14.6483C14.6591 14.7143 12.5314 16.89 12.5314 16.89L11.3347 16.6923L11.7113 17.3955L11.2901 18.0549L9.93834 18.2747L9.78328 18.8241L9.02972 18.8681V19.4833L8.07683 20.4505L8.52001 20.8022L7.43406 21.8131L7.63338 22.6703L8.25499 23H7.34637L6.7036 22.7363L5.95003 21.3517L6.41548 19.967L6.52627 17.7472L7.4349 14.6263L7.83381 11.681L5.75071 10.1646L4 6.95595L4.26585 6.53823L4.11079 6.09878L4.35465 5.17587L4.7313 4.93415L4.84209 4.58243L5.22681 4.18698L5.37407 3.2198L5.39634 2.51663L5.90605 1.76945L7.41262 1L7.25757 1.41745L8.14392 1.30745L8.49857 1.61518L10.05 1.52717L11.3352 2.14262L12.3324 3.06553L13.9723 3.08752L14.8587 4.29642L14.8144 4.9996L16.6316 5.30732L16.8532 5.59305L18.1385 5.52705L19.2687 6.2085H19.8227C19.8227 6.2085 20 6.88995 20 6.95595Z"
        fill=""
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SouthAmericaIcon;
