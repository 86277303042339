import { useQuery } from '@apollo/client';
import {
  GetCompaniesNamesQuery,
  GetCompaniesNamesQueryVariables,
  GetCompaniesWithTypeaheadFragment,
  TypeaheadCompaniesQuery,
  TypeaheadCompaniesQueryVariables
} from '__generated__/graphql';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { SelectedTypeaheadResult } from 'interfaces/SearchV2';
import { compact, toInteger, toString } from 'lodash';
import { getCompaniesNames } from 'queries/getCompaniesNames';
import { TYPEAHEAD_COMPANIES_QUERY } from 'queries/typeahead';
import { useCallback, useState } from 'react';
import {
  SelectMode,
  getTagColorBySelectMode,
  getTagIconBySelectMode
} from './utils';

interface CompanyMultiselectProps {
  onChange: (newOptions?: SelectedTypeaheadResult[]) => void;
  selected: SelectedTypeaheadResult[];
  labelPrefix?: string;
  disabled?: boolean;
  initialFocus?: boolean;
  mode?: SelectMode;
  placeholder?: string;
}

const CompanyMultiselectById: React.FC<CompanyMultiselectProps> = ({
  onChange,
  selected,
  labelPrefix,
  initialFocus,
  mode,
  disabled,
  placeholder
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: companiesData, loading } = useQuery<
    TypeaheadCompaniesQuery,
    TypeaheadCompaniesQueryVariables
  >(TYPEAHEAD_COMPANIES_QUERY, { variables: { query: searchTerm } });

  const { data: companyNameData } = useQuery<
    GetCompaniesNamesQuery,
    GetCompaniesNamesQueryVariables
  >(getCompaniesNames, {
    variables: {
      ids: compact(selected.map((company) => company.id))
    },
    fetchPolicy: 'cache-and-network'
  });

  const onClickListItem = (company: GetCompaniesWithTypeaheadFragment) => {
    const selectedCompany: SelectedTypeaheadResult = {
      name: company.name ?? '',
      id: company.id
    };
    const currentOptionIndex = selected.findIndex((option) =>
      option.id
        ? option.id === selectedCompany.id
        : option.name === selectedCompany.name
    );
    if (currentOptionIndex === -1) {
      onChange([...selected, selectedCompany]);
    }

    setSearchTerm('');
  };

  const onRemoveItem = (companyId: string) => {
    const updatedSelectedOptions = selected.filter(
      (option) => option.id?.toString() !== companyId
    );
    onChange(updatedSelectedOptions);
  };
  const selectedIds = selected.map((option) => option?.id?.toString() ?? '');

  const getLabelFromValue = useCallback(
    (value?: string) => {
      const companyTypeaheadResult = selected?.find(
        (company) => company?.id === toInteger(value)
      );
      const fetchedCompanyName = companyNameData?.getCompaniesByIds?.find(
        (company) => toString(company?.id) === value
      )?.name;

      return fetchedCompanyName ?? companyTypeaheadResult?.name ?? '';
    },
    [selected, companyNameData]
  );

  return (
    <div className="min-w-56 w-full">
      <Select
        multiple
        filterable
        filterTerm={searchTerm}
        onFilterTermChange={setSearchTerm}
        selected={!disabled ? selectedIds : []}
        labelPrefix={labelPrefix}
        placeholder={placeholder || 'Search company'}
        getLabelFromValue={getLabelFromValue}
        initialFocus={initialFocus}
        onRemove={(company) => onRemoveItem(company)}
        hideDropdown={
          !loading && companiesData?.getCompaniesWithTypeahead?.length === 0
        }
        getTagColorFromValue={() => getTagColorBySelectMode(mode)}
        getTagIconFromValue={() => getTagIconBySelectMode(mode)}
        disabled={disabled}
      >
        {loading && (
          <div className="flex w-full items-center justify-center">
            <HarmonicLoader showText={false} />
          </div>
        )}
        {!loading &&
          companiesData?.getCompaniesWithTypeahead?.map((company) => {
            if (!company) return null;
            return (
              <SelectListItem
                key={company.id}
                value={company.name ?? ''}
                label={company.name ?? ''}
                logo="logo"
                src={company.logoUrl ?? ''}
                onClick={() => onClickListItem(company)}
                variant={ListVariant.entity}
              />
            );
          })}
      </Select>
    </div>
  );
};

export default CompanyMultiselectById;
