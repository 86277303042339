import CompaniesExploreStore from './companiesExploreStore';

export class RootStore {
  companiesExploreStore: CompaniesExploreStore;

  constructor() {
    this.companiesExploreStore = new CompaniesExploreStore(this);
  }
}

const rootStore = new RootStore();
export default rootStore;
