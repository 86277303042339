import { HarmonicIcon } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import { MouseEvent } from 'react';

interface RadioPillProps {
  label: string;
  disabled?: boolean;
  selected: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  dataTestId?: string;
  leadingIcon?: HarmonicIcon;
}

const RadioPill: React.FC<RadioPillProps> = ({
  label,
  onClick,
  disabled,
  selected,
  dataTestId,
  leadingIcon
}) => {
  const LeadingIcon = leadingIcon;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      aria-selected={selected}
      data-testid={dataTestId}
      className={classNames(
        'px-p60 py-p40 typography-label flex gap-g30 items-center rounded-br90 focus:shadow-int-focus-outside whitespace-nowrap',
        'bg-int-fill-secondary-enabled text-content-strong hover:bg-int-fill-secondary-hover active:bg-int-fill-secondary-pressed',
        'aria-selected:bg-controls-selected-enabled aria-selected:text-int-fill-secondary-onSelected aria-selected:hover:bg-controls-selected-hover aria-selected:active:bg-controls-selected-pressed',
        'disabled:bg-int-fill-secondary-disabled disabled:text-int-fill-secondary-onDisabled',
        'disabled:aria-selected:bg-controls-selected-disabled disabled:aria-selected:text-int-fill-secondary-onSelected'
      )}
    >
      {LeadingIcon && (
        <span>
          <LeadingIcon
            className={classNames('w-4 h-4', {
              'text-content-standaloneGray': !selected
            })}
          />
        </span>
      )}
      <span> {label}</span>
    </button>
  );
};

export default RadioPill;
