import { ICellRendererParams } from 'ag-grid-community';
import { LoadingCellRenderer } from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import { LOADING_ROW_ID } from 'utils/constants';

export const TextCellComponent = (props: ICellRendererParams) => {
  if (props.data.id == LOADING_ROW_ID) {
    return <LoadingCellRenderer />;
  }

  return (
    <div className="typography-label-small-default text-content-weak p-p60 pr-0">
      {props.value}
    </div>
  );
};
