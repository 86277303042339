import classNames from 'classnames';
import { SVGProps } from 'react';

interface RadioCardProps {
  label: string;
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
  icon: React.FC<SVGProps<SVGSVGElement>>;
  dataTestId?: string;
  fullWidth?: boolean;
}
const RadioCard: React.FC<RadioCardProps> = ({
  label,
  disabled,
  selected,
  icon,
  onClick,
  dataTestId,
  fullWidth
}) => {
  const Icon = icon;
  return (
    <button
      type="button"
      data-testid={dataTestId}
      aria-selected={selected}
      onClick={onClick}
      className={classNames(
        'p-p60 rounded-br40 flex flex-col justify-center gap-g50 items-center typography-label-default-default min-w-40',
        'bg-int-fill-secondary-enabled hover:bg-int-fill-secondary-hover disabled:bg-int-fill-secondary-disabled active:bg-int-fill-secondary-pressed focus-visible:bg-int-fill-secondary-enabled',
        'aria-selected:bg-controls-selected-enabled aria-selected:hover:bg-controls-selected-hover aria-selected:active:bg-controls-selected-pressed aria-selected:focus-visible:bg-controls-selected-enabled',
        'text-int-fill-secondary-onEnabled disabled:text-int-fill-secondary-onDisabled',
        'aria-selected:text-int-fill-secondary-onSelected aria-selected:disabled:text-int-fill-secondary-onSelected',
        'focus-visible:shadow-int-focus-outside',
        'aria-selected:focus-visible:shadow-int-focus-outside',
        {
          'w-full': fullWidth
        }
      )}
      disabled={disabled}
    >
      <Icon className="w-p90 h-p90" />
      <p>{label}</p>
    </button>
  );
};

export default RadioCard;
