import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { isArray } from 'lodash';
import TenureCustomRange from './TenureCustomRange';

interface TenureSelectProps {
  selected?: string | [string, string];
  comparator?: string;
  disabled?: boolean;
  dataTestId?: string;
  onChange: (comparator: string, newValue?: string | [string, string]) => void;
}

const options = [
  {
    label: 'Any',
    value: undefined
  },
  {
    label: '> 1 year',
    value: '1 YEARS'
  },
  {
    label: '> 2 years',
    value: '2 YEARS'
  },
  {
    label: '> 4 years',
    value: '4 YEARS'
  }
];
const TenureSelect: React.FC<TenureSelectProps> = ({
  selected,
  comparator,
  dataTestId,
  onChange
}) => {
  const isCustomRange =
    isArray(selected) ||
    comparator !== 'greaterThan' ||
    !options.map((option) => option.value).includes(selected);

  return (
    <div data-testid={dataTestId} className="flex flex-row flex-wrap gap-g40">
      {options.map((option) => {
        return (
          <RadioPill
            key={option.value}
            label={option.label}
            selected={
              (selected === option.value && comparator === 'greaterThan') ||
              (selected === undefined && option.value === undefined) // for the Any pill.
            }
            onClick={() => onChange('greaterThan', option.value)}
          />
        );
      })}
      <TenureCustomRange
        comparator={comparator}
        value={isCustomRange ? selected : null}
        onChange={onChange}
      />
    </div>
  );
};

export default TenureSelect;
