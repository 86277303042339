import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const ClimateIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1617 17.7687C2.13194 13.7222 2.34242 9.272 5.10581 6.23444C7.95119 3.10675 13.3037 1.69666 19.9215 3.54964C20.1737 3.62027 20.3709 3.8174 20.4415 4.06965C22.2944 10.6875 20.8843 16.04 17.7566 18.8853C14.7191 21.6487 10.2689 21.8591 6.22236 18.8294L4.27918 20.7726C3.98629 21.0655 3.51142 21.0655 3.21852 20.7726C2.92563 20.4797 2.92563 20.0048 3.21852 19.7119L5.1617 17.7687ZM12.6162 7.9421C12.9192 7.5633 13.5195 7.78246 13.5195 8.27189V10.4585H14.9909C15.4205 10.4585 15.6571 10.969 15.385 11.3091L12.386 15.058C12.083 15.4368 11.4827 15.2177 11.4827 14.7282V12.5416H10.0113C9.58169 12.5416 9.34514 12.0311 9.61716 11.6911L12.6162 7.9421Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ClimateIcon;
