import React from 'react';

import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import {
  FilterGroupGeneratorId,
  PeopleEducationExperienceGenerator
} from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import Checkboxes from '../Common/Checkboxes';
import DateWithSelect, { DateWithSelectMode } from '../Common/DateWithSelect';
import IncludeExcludeMultiselect from '../Common/IncludeExcludeMultiselect';
import { SubSectionHeader } from '../Common/Typography';
import { useSchoolTypeahead } from '../Common/hooks/useSchoolTypeahead';

const DegreeOptions = [
  {
    label: 'PhD',
    value: 'PHD'
  },
  {
    label: 'MBA',
    value: 'MASTER_OF_BUSINESS_ADMINISTRATION'
  },
  {
    label: "Master's",
    value: 'MASTERS_DEGREE'
  },
  {
    label: "Bachelor's",
    value: 'BACHELORS_DEGREE'
  },
  {
    label: 'J.D.',
    value: 'JURIS_DOCTOR'
  },
  {
    label: 'M.D.',
    value: 'DOCTOR_OF_MEDICINE'
  }
];

interface EducationSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const EducationSection: React.FC<EducationSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const educationExperienceState =
    controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION];
  const educationExperienceSchools = educationExperienceState?.schools ?? [];
  const educationExperienceSchoolsExcluded =
    educationExperienceState?.schools_exclude_all_of ?? [];
  const degreesList = educationExperienceState?.degrees;

  const startDateComparator =
    (educationExperienceState?.start_date_comparator as DateWithSelectMode) ||
    DateWithSelectMode.AFTER;
  const startDate = parseDateForComponent(educationExperienceState?.start_date);
  const endDateComparator =
    (educationExperienceState?.end_date_comparator as DateWithSelectMode) ||
    DateWithSelectMode.BEFORE;
  const endDate = parseDateForComponent(educationExperienceState?.end_date);

  const updateValues = (newValues: PeopleEducationExperienceGenerator) => {
    onUpdateSearchQuery(
      getUpdatedQuery(
        searchQuery,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION,
        {
          ...(educationExperienceState ?? {}),
          ...newValues
        }
      )
    );
  };

  const { options: schoolTypeaheadOptions, handleSearch } =
    useSchoolTypeahead();

  const handleOnStartDateChange = (
    mode?: DateWithSelectMode,
    date?: string | [string, string] | undefined | null
  ) => {
    if (isNil(mode) || isNil(date)) {
      return updateValues({
        start_date: undefined,
        start_date_comparator: undefined
      });
    }

    updateValues({
      start_date: parseDateForGenerator(date),
      start_date_comparator: mode
    });
  };

  const handleOnEndDateChange = (
    mode?: DateWithSelectMode,
    date?: string | [string, string] | undefined | null
  ) => {
    if (isNil(mode) || isNil(date)) {
      return updateValues({
        start_date: undefined,
        start_date_comparator: undefined
      });
    }
    updateValues({
      end_date: parseDateForGenerator(date),
      end_date_comparator: mode
    });
  };

  return (
    <div className="w-full flex flex-col gap-g80 pt-p40">
      <div className="flex flex-col gap-g10 pr-p50">
        <SubSectionHeader>School</SubSectionHeader>
        <IncludeExcludeMultiselect
          dataTestId="education-experience-schools-field"
          options={schoolTypeaheadOptions}
          included={educationExperienceSchools}
          excluded={educationExperienceSchoolsExcluded}
          onChangeInclude={(newValues) => {
            updateValues({ schools: newValues });
            handleSearch('');
          }}
          onChangeExclude={(newValues) =>
            updateValues({ schools_exclude_all_of: newValues })
          }
          placeholder="Search or add a school"
          freeSolo
          onSearch={handleSearch}
        />
      </div>

      <div className="flex flex-col gap-g10 pr-p50">
        <SubSectionHeader>Degree</SubSectionHeader>
        <Checkboxes
          dataTestId="education-experience-degrees-field"
          options={DegreeOptions}
          selectedValues={degreesList}
          onValueChange={(newValues) => updateValues({ degrees: newValues })}
        />
      </div>
      <div className="grid grid-cols-2 gap-g90 pr-p50">
        <div className="flex flex-col gap-g10 pr-p50">
          <SubSectionHeader>Start date</SubSectionHeader>
          <DateWithSelect
            mode={startDateComparator}
            onChange={handleOnStartDateChange}
            value={startDate}
            label="Add start date"
            dataTestId="education-experience-start-date-field"
          />
        </div>

        <div className="flex flex-col gap-g10 pr-p50">
          <SubSectionHeader>End date</SubSectionHeader>
          <DateWithSelect
            mode={endDateComparator}
            onChange={handleOnEndDateChange}
            value={endDate}
            label="Add end date"
          />
        </div>
      </div>
    </div>
  );
};

const parseDateForGenerator = (
  date: string | [string, string] | undefined | null
) => {
  if (Array.isArray(date)) {
    return date.map((d) => d.replaceAll('/', '-')) as [string, string];
  }
  return date ? date.replaceAll('/', '-') : undefined;
};

const parseDateForComponent = (
  date: string | [string, string] | undefined | null
) => {
  if (Array.isArray(date)) {
    return date.map((d) => d.replaceAll('/', '-')) as [string, string];
  }
  return date ? date.replaceAll('-', '/') : undefined;
};

export default EducationSection;
