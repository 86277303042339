import classNames from 'classnames';
import { OnTabChangeType } from '../Company';
import CompanyOverviewFinancing from './CompanyOverviewFinancing';
import CompanyOverviewTeam from './CompanyOverviewTeam';
import CompanyTimeline from './CompanyTimeline';

interface CompanyOverviewProps {
  companyId: number;
  onTabChange: OnTabChangeType;
  isStealthCompany?: boolean;
}

const CompanyOverview: React.FC<CompanyOverviewProps> = ({
  companyId,
  onTabChange,
  isStealthCompany
}) => {
  return (
    <div data-testid="CompanyOverview" className="min-h-screen">
      {!isStealthCompany && (
        <CompanyTimeline onTabChange={onTabChange} companyId={companyId} />
      )}

      <div
        className={classNames({
          'py-16': !isStealthCompany,
          'pt-1 pb-8': isStealthCompany
        })}
      >
        <CompanyOverviewTeam
          companyId={companyId}
          onTabChange={!isStealthCompany ? onTabChange : undefined}
        />
      </div>
      <CompanyOverviewFinancing
        companyId={companyId}
        onTabChange={!isStealthCompany ? onTabChange : undefined}
      />
    </div>
  );
};

export default CompanyOverview;
