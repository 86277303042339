import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import React from 'react';
import { PeopleSearchExperienceGeneratorKey } from 'utils/searchV2/searchV2';
import CurrentOrPastExperience from './CurrentOrPastExperience';

interface CurrentExperienceSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  expandedFields: PeopleSearchExperienceGeneratorKey[];
  onUpdateExpandedFields: (
    newExpandedFields: PeopleSearchExperienceGeneratorKey[]
  ) => void;
  disabled?: boolean;
}

const CurrentExperienceSection: React.FC<CurrentExperienceSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  expandedFields,
  onUpdateExpandedFields,
  disabled
}) => {
  return (
    <CurrentOrPastExperience
      searchQuery={searchQuery}
      onUpdateSearchQuery={onUpdateSearchQuery}
      expandedFields={expandedFields}
      onUpdateExpandedFields={onUpdateExpandedFields}
      mode="current"
    />
  );
};

export default CurrentExperienceSection;
