import { Divider, LinearProgress, Popover } from '@material-ui/core';
import { PlusIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import {
  FilterPlaceholder,
  FilterStringComparator,
  INITIAL_SEARCH_FILTER,
  INITIAL_SEARCH_FILTER_GROUP,
  ISearchFieldSpec,
  ISearchFilter,
  ISearchFilterGroup,
  ISearchFilterOperation,
  JoinOperatorType
} from 'interfaces/SearchModel/Search';
import { capitalize, cloneDeep, isNumber } from 'lodash';
import React, {
  FC,
  MouseEventHandler,
  createContext,
  useEffect,
  useState
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MAX_FILTER_DEPTH } from 'utils/constants';
import {
  addKeyToFilterGroupAndFilterDeep,
  addNoOperationToFilterDeep
} from 'utils/search';
import { deepOmitFromObject, replaceValueFromObject } from 'utils/utilities';
import { v4 as uuidv4 } from 'uuid';
import ExtraSideActionsButton from './ExtraSideActionsButton';
import FilterQuery from './FilterQuery';
import SearchFilterError from './SearchFilterError';
import { SearchFilterGroupWrapper } from './styles';
import { SearchFilterType, isCustomFieldUrn } from './utils';

interface ISearchFilterMethods {
  changeJoinOperator: (value: JoinOperatorType) => void;
  updateFilterGroup: (filterGroup: ISearchFilterGroup, index?: number) => void;
  duplicateFilterGroup: (index: number) => void;
  removeFilterGroup: (index: number) => void;
  addNewSearchFilter: (filter?: ISearchFilter) => void;
  addNewSearchFilters: (filters: ISearchFilter[]) => void;
  convertFilterToGroup: (index: number) => void;
  removeSearchFilter: (filterIndex: number) => void;
  applyFilterOperation: (
    filterIndex: number,
    operation: ISearchFilterOperation
  ) => void;
  updateSearchFilter: (
    filterIndex: number,
    partialFilter: Partial<ISearchFilter>
  ) => void;
  duplicateSearchFilter: (filter: ISearchFilter) => void;
  addSearchFilterGroup: (
    filters: ISearchFilter[],
    groupName?: string,
    filterDeleteIndex?: number
  ) => void;
}

export interface ISearchFilterContext {
  // The search filter group at the root level of the search model
  rootSearchFilterGroup: ISearchFilterGroup;
  // Methods to manipulate the content of this filter group
  searchFilterMethods: ISearchFilterMethods;
  // Field specifications that determine allowed fields/comparators
  fieldSpecs: ISearchFieldSpec[];
}

export const InitSearchContext = {
  rootSearchFilterGroup: INITIAL_SEARCH_FILTER_GROUP,
  extraOptions: {},
  searchFilterMethods: {
    changeJoinOperator: (): void => {
      null;
    },
    updateFilterGroup: (): void => {
      null;
    },
    duplicateFilterGroup: (): void => {
      null;
    },
    removeFilterGroup: (): void => {
      null;
    },
    addNewSearchFilter: (): void => {
      null;
    },
    addNewSearchFilters: (): void => {
      null;
    },
    applyFilterOperation: (): void => {
      null;
    },
    convertFilterToGroup: (): void => {
      null;
    },
    addSearchFilterGroup: (): void => {
      null;
    },
    removeSearchFilter: (): void => {
      null;
    },
    duplicateSearchFilter: (): void => {
      null;
    },
    updateSearchFilter: (): void => {
      null;
    }
  },
  fieldSpecs: []
};

export const SearchFilterContext =
  createContext<ISearchFilterContext>(InitSearchContext);

export interface SearchFilterGroupBaseProps {
  entityType: SearchFilterType;
  entityId?: number;
  isSearchOwner?: boolean;
  fieldSpecs: ISearchFieldSpec[];
  // Nested level of filter
  // 0 is root level
  // If level is equal to MAX_FILTER_DEPTH, user cant create any more nested filter.
  recursiveLevel: number;
  // For parent component to get access to nested component.
  index?: number;
  // For nested component to send its updated state to parent component to keep track inside filter_groups[]
  updateParentFilterGroup?: (
    newFilterGroup: ISearchFilterGroup,
    index?: number
  ) => void;
  // To prepopulate the state from backend data(existing) rather than INITIAL_FILTER_GROUP
  filterGroup?: ISearchFilterGroup;
  disabled?: boolean;
  QuickFiltersComponent?: React.ReactNode;
  FilterDropdownComponent: ({
    onAddNewFilter
  }: {
    onAddNewFilter: (name: string, urn: string) => void;
  }) => React.ReactNode;
}

const SearchFilterGroup: FC<SearchFilterGroupBaseProps> = (
  props: SearchFilterGroupBaseProps
) => {
  const fieldSpecs = props.fieldSpecs;
  // Remove any field specs that do not have any allowed comparators. This means we cannot filter on that field.
  // const fieldSpecs = fieldSpec?.filter(
  //   (fieldSpec) =>
  //     fieldSpec.allowed_comparators && fieldSpec.allowed_comparators.length > 0
  // );

  const [rootFilterGroup, setRootFilterGroup] = useState<ISearchFilterGroup>(
    props.filterGroup
      ? cloneDeep(props.filterGroup)
      : INITIAL_SEARCH_FILTER_GROUP
  );

  const searchFilterContext = {
    rootSearchFilterGroup: rootFilterGroup,
    searchFilterMethods: {
      changeJoinOperator: (value: JoinOperatorType) => {
        setRootFilterGroup({
          ...rootFilterGroup,
          join_operator: value
        });
      },
      updateFilterGroup: (
        newFilterGroup: ISearchFilterGroup,
        index?: number
      ) => {
        if (!isNumber(index)) return;
        setRootFilterGroup({
          ...rootFilterGroup,
          filter_groups: rootFilterGroup.filter_groups.map((filter_group, i) =>
            i === index ? newFilterGroup : filter_group
          )
        });
      },
      duplicateFilterGroup: (index: number) => {
        setRootFilterGroup({
          ...rootFilterGroup,
          filter_groups: [
            ...rootFilterGroup.filter_groups.slice(0, index + 1),
            { ...rootFilterGroup.filter_groups[index], key: uuidv4() },
            ...rootFilterGroup.filter_groups.slice(index + 1)
          ]
        });
      },
      removeFilterGroup: (index: number) => {
        const updatedFilterGroups = rootFilterGroup.filter_groups.filter(
          (element, i) => i !== index
        );
        setRootFilterGroup({
          ...rootFilterGroup,
          filter_groups: updatedFilterGroups
        });
      },
      addNewSearchFilter: (newFilter?: ISearchFilter) => {
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: [
            ...rootFilterGroup.filters,
            newFilter ? newFilter : INITIAL_SEARCH_FILTER
          ]
        });
        if (props.recursiveLevel === 0) handleClose();
      },
      addNewSearchFilters: (newFilters: ISearchFilter[]) => {
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: [...rootFilterGroup.filters, ...newFilters]
        });
        if (props.recursiveLevel === 0) handleClose();
      },
      addSearchFilterGroup: (
        searchFilters: ISearchFilter[],
        groupName?: string,
        // When creating grouped filters with name like "Person Past Experience",
        // we delete the existing filter.
        filterDeleteIndex?: number
      ) => {
        if (props.recursiveLevel >= MAX_FILTER_DEPTH) {
          return;
        }
        const updatedFilters = rootFilterGroup.filters.filter(
          (element, index) => index !== filterDeleteIndex
        );
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: updatedFilters,
          filter_groups: [
            ...rootFilterGroup.filter_groups,
            {
              name: groupName,
              filters: searchFilters,
              filter_groups: [],
              join_operator: 'and',
              key: uuidv4()
            }
          ]
        });
        if (props.recursiveLevel === 0) handleClose();
      },
      convertFilterToGroup: (index: number) => {
        if (
          props.recursiveLevel >= MAX_FILTER_DEPTH ||
          !searchFilterContext.rootSearchFilterGroup.filters[index]
        ) {
          return;
        }
        const selectedFilter = cloneDeep(
          searchFilterContext.rootSearchFilterGroup.filters[index]
        );

        const updatedFilters = rootFilterGroup.filters.filter(
          (element, i) => i !== index
        );
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: updatedFilters,
          filter_groups: [
            {
              filters: [selectedFilter],
              filter_groups: [],
              join_operator: 'and',
              key: uuidv4()
            },
            ...rootFilterGroup.filter_groups
          ]
        });
      },
      duplicateSearchFilter: (filter: ISearchFilter) => {
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: [...rootFilterGroup.filters, { ...filter, key: uuidv4() }]
        });
      },
      applyFilterOperation: (
        filterIndex: number,
        operation: ISearchFilterOperation
      ) => {
        if (props.recursiveLevel >= MAX_FILTER_DEPTH) return;
        const operationClone = cloneDeep(operation);
        const selectedFilter = rootFilterGroup.filters[filterIndex];
        const updatedFilters = rootFilterGroup.filters.filter(
          (element, index) => index !== filterIndex
        );
        replaceValueFromObject(
          operationClone as unknown as Record<string, unknown>,
          FilterPlaceholder.FIELD,
          selectedFilter.field
        );
        replaceValueFromObject(
          operationClone as unknown as Record<string, unknown>,
          FilterPlaceholder.COMPARATOR,
          selectedFilter.comparator
        );

        replaceValueFromObject(
          operationClone as unknown as Record<string, unknown>,
          FilterPlaceholder.FILTER_VALUE,
          selectedFilter.filter_value
        );

        let newFilterGroup = addKeyToFilterGroupAndFilterDeep(
          operationClone.filter_group
        );
        newFilterGroup = addNoOperationToFilterDeep(newFilterGroup);

        setRootFilterGroup({
          ...searchFilterContext.rootSearchFilterGroup,
          filters: updatedFilters,
          filter_groups: [
            newFilterGroup,
            ...searchFilterContext.rootSearchFilterGroup.filter_groups
          ]
        });
      },
      removeSearchFilter: (filterIndex: number) => {
        const updatedFilters = rootFilterGroup.filters.filter(
          (element, index) => index !== filterIndex
        );
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: updatedFilters
        });
      },
      updateSearchFilter: (
        filterIndex: number,
        partialFilter: Partial<ISearchFilter>
      ) => {
        setRootFilterGroup({
          ...rootFilterGroup,
          filters: rootFilterGroup.filters.map((filter, index) =>
            index === filterIndex ? { ...filter, ...partialFilter } : filter
          )
        });
      }
    },
    fieldSpecs: fieldSpecs
  };
  useEffect(() => {
    // Pass its state to parent component.
    // Parent component will store this component rootfilterGroup inside filter_groups[index]
    if (props.updateParentFilterGroup) {
      props.updateParentFilterGroup(
        searchFilterContext.rootSearchFilterGroup,
        props.index
      );
    }
  }, [searchFilterContext.rootSearchFilterGroup]);

  const onAddNewFilter = (
    fieldName: string | null,
    fieldUrn: string | undefined
  ) => {
    let filteredFieldSpec = fieldSpecs?.filter(
      (spec) => spec.unique_name === fieldName
    );
    if (isCustomFieldUrn(fieldUrn)) {
      filteredFieldSpec = fieldSpecs?.filter((spec) => spec.urn === fieldUrn);
    }
    if (filteredFieldSpec?.length === 0 || !fieldName) return;
    if (filteredFieldSpec) {
      const selectedFieldSpec = filteredFieldSpec[0];
      const defaultComparator =
        selectedFieldSpec.allowed_comparators?.length > 0
          ? selectedFieldSpec.allowed_comparators[0]
          : FilterStringComparator.IS_EMPTY;
      const newFilter: ISearchFilter = {
        field: fieldName,
        filter_value: '',
        comparator: defaultComparator,
        field_urn: selectedFieldSpec.urn,
        key: uuidv4()
      };
      searchFilterContext.searchFilterMethods.addNewSearchFilter(newFilter);
    }
  };

  const onAddNewFilterGroup = () => {
    searchFilterContext.searchFilterMethods.addSearchFilterGroup([]);
  };

  // Anchor for adding filter or FilterGroup.
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleAddFilterClick: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onlyOneFilter =
    searchFilterContext?.rootSearchFilterGroup?.filters?.length === 1;

  const noFilter =
    searchFilterContext?.rootSearchFilterGroup?.filters?.length === 0;

  const moreThanTwoFilter =
    searchFilterContext?.rootSearchFilterGroup?.filters?.length >= 2;

  const onRemoveFilter = (index: number) => {
    searchFilterContext.searchFilterMethods.removeFilterGroup(index);
  };

  return (
    <ErrorBoundary FallbackComponent={SearchFilterError}>
      <SearchFilterContext.Provider value={searchFilterContext}>
        <>
          <SearchFilterGroupWrapper>
            <div className="w-full">
              {!fieldSpecs ||
              //Persisted store has default one fieldSpec. That hides the loader. Hence doing this check
              (Array.isArray(fieldSpecs) && fieldSpecs.length < 1) ? (
                <LinearProgress className="mt-6" />
              ) : (
                <>
                  <div
                    className={`${
                      props.recursiveLevel !== 0
                        ? 'main-filter overflow-visible'
                        : 'my-p70'
                    }`}
                  >
                    <FilterQuery
                      disabled={props.disabled}
                      recursiveLevel={props.recursiveLevel}
                      entityType={props.entityType}
                      QuickFiltersComponent={props.QuickFiltersComponent}
                    />

                    {/* Nested Filters. This component itself is recursively called. */}
                    {searchFilterContext.rootSearchFilterGroup.filter_groups?.map(
                      (filter_group, index) => (
                        // Pay extra attention to setting key. It can cause bugs
                        <div
                          className="flex items-start mb-2 gap-2"
                          key={filter_group.key}
                        >
                          <div id="Group-Prefix" className="w-16 pr-p50">
                            {noFilter && index === 0 && (
                              <div className="typography-label">Where</div>
                            )}

                            {((onlyOneFilter && index === 0) ||
                              (noFilter && index === 1)) && (
                              <div className="w-18 relative -left-2">
                                <Select
                                  multiple={false}
                                  selected={
                                    searchFilterContext.rootSearchFilterGroup
                                      .join_operator
                                  }
                                  getLabelFromValue={(value) =>
                                    capitalize(value)
                                  }
                                >
                                  {['and', 'or'].map((operator) => (
                                    <ListItem
                                      key={operator}
                                      value={operator}
                                      label={capitalize(operator)}
                                      onClick={() => {
                                        searchFilterContext.searchFilterMethods.changeJoinOperator(
                                          operator as JoinOperatorType
                                        );
                                      }}
                                      variant={ListVariant.default}
                                    />
                                  ))}
                                </Select>
                              </div>
                            )}
                            {((onlyOneFilter && index > 0) ||
                              (noFilter && index > 1) ||
                              moreThanTwoFilter) && (
                              <div className="typography-label text-right w-18 pr-p50">
                                <div className="pr-p50">
                                  {capitalize(
                                    searchFilterContext.rootSearchFilterGroup
                                      .join_operator
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            data-testid="SearchFilterGroup-Nested"
                            className={classNames(
                              'p-p40 border border-solid rounded-md w-full',
                              {
                                // Style for one level nested filter
                                'bg-surface-nested border-border':
                                  props.recursiveLevel >= 0,
                                // Style for one level nested filter
                                'bg-surface-raised border-border':
                                  props.recursiveLevel >= 1
                              }
                            )}
                          >
                            <SearchFilterGroup
                              key={filter_group.key}
                              entityType={props.entityType}
                              entityId={props.entityId}
                              recursiveLevel={props.recursiveLevel + 1}
                              index={index}
                              filterGroup={filter_group}
                              updateParentFilterGroup={
                                searchFilterContext.searchFilterMethods
                                  .updateFilterGroup
                              }
                              fieldSpecs={props.fieldSpecs}
                              isSearchOwner={props.isSearchOwner}
                              disabled={props.disabled}
                              QuickFiltersComponent={
                                props.QuickFiltersComponent
                              }
                              FilterDropdownComponent={
                                props.FilterDropdownComponent
                              }
                            />
                          </div>
                          {!props.disabled && (
                            <>
                              <ExtraSideActionsButton
                                duplicateFilter={() => {
                                  searchFilterContext.searchFilterMethods.duplicateFilterGroup(
                                    index
                                  );
                                }}
                                showConvertToGroupOption={false}
                                onRemoveFilter={() => onRemoveFilter(index)}
                                copyToClipboard={() => {
                                  navigator.clipboard.writeText(
                                    JSON.stringify(
                                      deepOmitFromObject(filter_group, ['key']),
                                      null,
                                      2
                                    )
                                  );
                                }}
                              />
                            </>
                          )}
                        </div>
                      )
                    )}
                  </div>

                  {/* Custom group like Person Current Experience cant create nested filters inside them */}
                  {!props.disabled && (
                    <div className="flex gap-g40">
                      <Button
                        dataTestId="SearchFilterGroup-Filter"
                        leadingIcon={PlusIcon}
                        emphasis={props.recursiveLevel == 0 ? 'high' : 'low'}
                        onClick={handleAddFilterClick}
                        label="Add filter"
                      />
                      {props.recursiveLevel < MAX_FILTER_DEPTH && (
                        <Button
                          dataTestId="SearchFilterGroup-Filter-Group"
                          leadingIcon={PlusIcon}
                          emphasis={props.recursiveLevel == 0 ? 'high' : 'low'}
                          onClick={onAddNewFilterGroup}
                          label="Add group"
                        />
                      )}
                      <Popover
                        disableAutoFocus
                        disableEnforceFocus
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        elevation={0}
                        anchorEl={anchorEl}
                        open={anchorEl ? true : false}
                        onClose={handleClose}
                        className="mt-p20"
                        classes={{
                          paper: classNames(
                            'bg-surface-default shadow-static-elevation-floating transition-all',
                            'rounded-br40 border border-border border-solid',
                            'w-fit flex flex-col'
                          )
                        }}
                      >
                        {props.FilterDropdownComponent({ onAddNewFilter })}
                      </Popover>
                    </div>
                  )}
                </>
              )}
            </div>
            <Divider />
          </SearchFilterGroupWrapper>
        </>
      </SearchFilterContext.Provider>
    </ErrorBoundary>
  );
};

export default SearchFilterGroup;
