import { HarmonicIcon } from '../type';

const RoundIconSeed: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M8 14C7.81111 14 7.65278 13.9361 7.525 13.8083C7.39722 13.6806 7.33333 13.5222 7.33333 13.3333V8.68333C6.62222 8.68333 5.93889 8.54722 5.28333 8.275C4.62778 8.00278 4.05 7.61667 3.55 7.11667C3.05 6.61667 2.66667 6.03889 2.4 5.38333C2.13333 4.72778 2 4.04444 2 3.33333V2.66667C2 2.47778 2.06389 2.31944 2.19167 2.19167C2.31944 2.06389 2.47778 2 2.66667 2H3.33333C4.03333 2 4.71111 2.13611 5.36667 2.40833C6.02222 2.68056 6.6 3.06667 7.1 3.56667C7.44444 3.91111 7.73056 4.28889 7.95833 4.7C8.18611 5.11111 8.36111 5.55 8.48333 6.01667C8.53889 5.93889 8.6 5.86389 8.66667 5.79167C8.73333 5.71944 8.80556 5.64444 8.88333 5.56667C9.38333 5.06667 9.96111 4.68056 10.6167 4.40833C11.2722 4.13611 11.9556 4 12.6667 4H13.3333C13.5222 4 13.6806 4.06389 13.8083 4.19167C13.9361 4.31944 14 4.47778 14 4.66667V5.33333C14 6.04444 13.8639 6.72778 13.5917 7.38333C13.3194 8.03889 12.9333 8.61667 12.4333 9.11667C11.9333 9.61667 11.3583 10 10.7083 10.2667C10.0583 10.5333 9.37778 10.6667 8.66667 10.6667V13.3333C8.66667 13.5222 8.60278 13.6806 8.475 13.8083C8.34722 13.9361 8.18889 14 8 14Z"
      fill="#3A82A6"
    />
  </svg>
);

export default RoundIconSeed;
