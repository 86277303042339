import { ChevronDownV2, ChevronUpV2 } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import React, { useState } from 'react';

interface AcceleratorItemProps {
  name: string;
  count?: number;
  checked: boolean;
  indeterminate?: boolean;
  onChange: () => void;
  isParent?: boolean;
  children?: React.ReactNode;
}

const AcceleratorItem: React.FC<AcceleratorItemProps> = ({
  name,
  count,
  checked,
  indeterminate,
  onChange,
  isParent,
  children
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div>
      <div
        className={classNames(
          'flex justify-between items-center p-p40 rounded-br20 cursor-pointer',
          isHovered && 'bg-gray-50'
        )}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          if (isParent) {
            setIsOpen(!isOpen);
          } else {
            onChange();
          }
        }}
      >
        <div className="flex items-center">
          <Checkbox
            checked={checked}
            variant={indeterminate ? 'indeterminate' : 'default'}
            onChange={onChange}
            label={name}
            id={`accelerator-checkbox-${name}`}
            dataTestId={`accelerator-checkbox-${name}`}
            stopPropagation={true}
          />
          {count !== undefined && (
            <span className="ml-1 text-content-weak">({count})</span>
          )}
        </div>
        {isParent && (
          <div
            className="flex ml-4 h-full justify-end"
            data-testid={`expand-${name}`}
          >
            {isOpen ? <ChevronUpV2 /> : <ChevronDownV2 />}
          </div>
        )}
      </div>
      {isParent && isOpen && children && (
        <div className="ml-p60">{children}</div>
      )}
    </div>
  );
};

export default AcceleratorItem;
