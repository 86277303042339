import { ChevronDown, ChevronUp } from 'assets/harmonic-icons';
import Accordion from 'harmonic-components/Accordion/Accordion';
import { PropsWithChildren, useState } from 'react';
interface ListGroupProps {
  title: string;
  defaultOpen?: boolean;
  hideChevron?: boolean;
}
const ListGroup: React.FC<PropsWithChildren<ListGroupProps>> = ({
  title,
  children,
  defaultOpen = true,
  hideChevron = false
}) => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <div data-testid="group" className="w-full">
      <button
        aria-expanded={open}
        data-testid="group-expand-button"
        onClick={(e) => {
          setOpen(!open);
          e.stopPropagation();
        }}
        className="w-full flex px-p40 pt-p30 pb-p20 gap-g40 items-center self-stretch typography-label-small-default text-content-weak"
      >
        <div className="flex flex-1">
          <p>{title}</p>
        </div>
        {!hideChevron &&
          (open ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          ))}
      </button>
      <Accordion open={open}>{children}</Accordion>
    </div>
  );
};

export default ListGroup;
