import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import TextField from 'components/common/lib/TextField';
import { ISearchFieldSpec } from 'interfaces/SearchModel/Search';
import React, { useCallback } from 'react';
import SearchFilterDropdown from '../SearchFilterDropdown';
import { SearchFilterType, isCustomFieldUrn } from '../utils';

const FieldInput = (props: {
  field: string;
  field_urn?: string;
  fieldSpecs: ISearchFieldSpec[];
  recursiveLevel: number;
  valueInputRef: React.RefObject<HTMLInputElement | HTMLDivElement>;
  onUpdateComparatorName: (
    newValue: string | null,
    newUrn: string | null
  ) => void;
  disabled?: boolean;
  entityType: SearchFilterType;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const currentFieldSpec = props.fieldSpecs.find((fieldSpec) => {
    if (isCustomFieldUrn(props?.field_urn)) {
      return fieldSpec.urn == props.field_urn;
    }
    return fieldSpec.unique_name == props.field;
  });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onUpdateComparatorName = (
    newValue: string | null,
    newUrn: string | null
  ) => {
    handleClose();
    props.onUpdateComparatorName(newValue, newUrn);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'search-field-input-popover' : undefined;

  return (
    <>
      <TextField
        dataTestId="SingleFilter-FieldInput-TextField"
        size="small"
        readOnly={true}
        value={currentFieldSpec?.display_name || ''}
        aria-describedby={id}
        showDropdownIcon={true}
        disabled={props.disabled}
        onClick={handleClick}
        cursorPointer={true}
        autoComplete="off"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        elevation={0}
        className="mt-p20"
        classes={{
          paper: classNames(
            'bg-surface-default shadow-static-elevation-floating transition-all',
            'rounded-br40 border border-border border-solid',
            'w-fit flex flex-col'
          )
        }}
      >
        <SearchFilterDropdown
          entityType={props.entityType}
          fields={props.fieldSpecs}
          onUpdateComparatorName={onUpdateComparatorName}
          selectedField={props.field}
        />
      </Popover>
    </>
  );
};

export default FieldInput;
