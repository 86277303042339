import SmartFieldIcon from 'assets/harmonic-icons/smart-field';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import { FilterGroupGeneratorId, SearchData } from 'interfaces/SearchV2';
import { clone } from 'lodash';
import React, { useEffect } from 'react';
import CollapsibleFieldWrapper from './CollapsibleFieldWrapper';
import CorrespondenceDateSelect from './CorrespondenceDateSelect';
import IntegratedEmailUserMultiselect from './IntegratedEmailUserMultiselect';

type CorrespondencesGeneratorParams =
  | SearchData[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES]
  | SearchData[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES];

interface CorrespondencesSubSectionProps {
  generatorArgs: CorrespondencesGeneratorParams;
  onUpdateGeneratorArgs: (newArgs?: CorrespondencesGeneratorParams) => void;
  addTopMarginOnFieldOpen?: boolean;
}

const CorrespondencesSubSection: React.FC<CorrespondencesSubSectionProps> = ({
  generatorArgs,
  onUpdateGeneratorArgs,
  addTopMarginOnFieldOpen = false
}) => {
  const [fieldsOpen, setFieldsOpen] = React.useState<string[]>([]);
  useEffect(() => {
    const initialFieldsOpen = [];
    if (generatorArgs?.last_meeting_date_range_value) {
      initialFieldsOpen.push('lastMeetingDate');
    }
    if (generatorArgs?.last_meeting_with) {
      initialFieldsOpen.push('lastMeetingWith');
    }
    if (generatorArgs?.last_email_date_range_value) {
      initialFieldsOpen.push('lastEmailDate');
    }
    if (generatorArgs?.last_email_with) {
      initialFieldsOpen.push('lastEmailWith');
    }
    setFieldsOpen(initialFieldsOpen);
  }, []);

  const removeArguments = (keys: string[]) => {
    let newArgs = clone(generatorArgs);
    if (newArgs) {
      keys.forEach((key) => {
        delete newArgs?.[key as keyof CorrespondencesGeneratorParams];
      });
    }
    if (!newArgs || Object.keys(newArgs).length === 0) {
      newArgs = undefined;
    }
    onUpdateGeneratorArgs(newArgs);
  };

  const openField = (field: string) => {
    if (!fieldsOpen.includes(field)) {
      setFieldsOpen([...fieldsOpen, field]);
    }
  };

  const closeField = (field: string) => {
    setFieldsOpen(fieldsOpen.filter((f) => f !== field));
  };

  const lastMeetingDateOpen = fieldsOpen.includes('lastMeetingDate');
  const lastMeetingWithOpen = fieldsOpen.includes('lastMeetingWith');
  const lastEmailDateOpen = fieldsOpen.includes('lastEmailDate');
  const lastEmailWithOpen = fieldsOpen.includes('lastEmailWith');
  const anyFieldOpen = fieldsOpen.length > 0;

  const LastMeetingDateFieldInput = (
    <CollapsibleFieldWrapper
      onRemove={() => {
        closeField('lastMeetingDate');
        removeArguments([
          'last_meeting_date_range_value',
          'last_meeting_date_custom_range'
        ]);
      }}
      title={
        <div className="flex gap-g20">
          <p>Last meeting date</p>
          <SmartFieldIcon />
        </div>
      }
    >
      <CorrespondenceDateSelect
        selected={generatorArgs?.last_meeting_date_range_value}
        customRangeValue={generatorArgs?.last_meeting_date_custom_range}
        onChange={(newValue, newCustomValue) => {
          const updatedValues: CorrespondencesGeneratorParams = {
            last_meeting_date_range_value: newValue
          };
          if (newCustomValue) {
            updatedValues.last_meeting_date_custom_range = newCustomValue;
          }
          onUpdateGeneratorArgs({ ...generatorArgs, ...updatedValues });
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const LastMeetingWithFieldInput = (
    <CollapsibleFieldWrapper
      onRemove={() => {
        closeField('lastMeetingWith');
        removeArguments(['last_meeting_with']);
      }}
      title={
        <div className="flex gap-g20">
          <p>Last meeting with</p>
          <SmartFieldIcon />
        </div>
      }
    >
      <IntegratedEmailUserMultiselect
        selected={generatorArgs?.last_meeting_with ?? []}
        onChange={(newEmails) => {
          onUpdateGeneratorArgs({
            ...generatorArgs,
            last_meeting_with: newEmails
          });
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const LastEmailDateFieldInput = (
    <CollapsibleFieldWrapper
      onRemove={() => {
        closeField('lastEmailDate');
        removeArguments([
          'last_email_date_range_value',
          'last_email_date_custom_range'
        ]);
      }}
      title={
        <div className="flex gap-g20">
          <p>Last email date</p>
          <SmartFieldIcon />
        </div>
      }
    >
      <CorrespondenceDateSelect
        selected={generatorArgs?.last_email_date_range_value}
        customRangeValue={generatorArgs?.last_email_date_custom_range}
        onChange={(newValue, newCustomValue) => {
          const updatedValues: CorrespondencesGeneratorParams = {
            last_email_date_range_value: newValue
          };
          if (newCustomValue) {
            updatedValues.last_email_date_custom_range = newCustomValue;
          }
          onUpdateGeneratorArgs({ ...generatorArgs, ...updatedValues });
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const LastEmailWithFieldInput = (
    <CollapsibleFieldWrapper
      onRemove={() => {
        closeField('lastEmailWith');
        removeArguments(['last_email_with']);
      }}
      title={
        <div className="flex gap-g20">
          <p>Last email with</p>
          <SmartFieldIcon />
        </div>
      }
    >
      <IntegratedEmailUserMultiselect
        selected={generatorArgs?.last_email_with ?? []}
        onChange={(newEmails) => {
          onUpdateGeneratorArgs({
            ...generatorArgs,
            last_email_with: newEmails
          });
        }}
      />
    </CollapsibleFieldWrapper>
  );

  const ExpandedFields = (
    <div
      className={classNames(
        'flex flex-col gap-g90',
        // TODO: Remove this conditional margin.
        // This is a hacky stopgap until we can fully reevaluate the
        // hierarchy of headings across company/people search
        anyFieldOpen && addTopMarginOnFieldOpen && 'mt-p90'
      )}
    >
      {fieldsOpen.map((field) => {
        switch (field) {
          case 'lastMeetingDate':
            return LastMeetingDateFieldInput;
          case 'lastMeetingWith':
            return LastMeetingWithFieldInput;
          case 'lastEmailDate':
            return LastEmailDateFieldInput;
          case 'lastEmailWith':
            return LastEmailWithFieldInput;
          default:
            return null;
        }
      })}
    </div>
  );

  const ExpandableButtons = () => (
    <div className="flex gap-g40">
      {!lastMeetingDateOpen && (
        <Button
          dataTestId={`CorrespondencesFilters-ExpandableButton-lastMeetingDate`}
          leadingIcon={SmartFieldIcon}
          label="Last meeting date"
          key="Last meeting date"
          type="secondary"
          emphasis="high"
          onClick={() => openField('lastMeetingDate')}
        />
      )}
      {!lastMeetingWithOpen && (
        <Button
          dataTestId={`CorrespondencesFilters-ExpandableButton-lastMeetingWith`}
          leadingIcon={SmartFieldIcon}
          label="Last meeting with"
          key="Last meeting with"
          type="secondary"
          emphasis="high"
          onClick={() => openField('lastMeetingWith')}
        />
      )}
      {!lastEmailDateOpen && (
        <Button
          dataTestId={`CorrespondencesFilters-ExpandableButton-lastEmailDate`}
          leadingIcon={SmartFieldIcon}
          label="Last email date"
          key="Last email date"
          type="secondary"
          emphasis="high"
          onClick={() => openField('lastEmailDate')}
        />
      )}
      {!lastEmailWithOpen && (
        <Button
          dataTestId={`CorrespondencesFilters-ExpandableButton-lastEmailWith`}
          leadingIcon={SmartFieldIcon}
          label="Last email with"
          key="Last email with"
          type="secondary"
          emphasis="high"
          onClick={() => openField('lastEmailWith')}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-g50">
      {ExpandedFields}
      <ExpandableButtons />
    </div>
  );
};

export default CorrespondencesSubSection;
