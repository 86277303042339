import { FieldFunctionOptions, Reference } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import {
  TExistingRelay,
  TIncomingRelay,
  TRelayEdge
} from '@apollo/client/utilities/policies/pagination';
import { AppState } from '../hooks/useAppStore';
import { logger } from '../utils/logger';

type CustomFieldValue = {
  updatedAt: string;
};

export const mergeCustomFieldValue = (
  existing: CustomFieldValue,
  incoming: CustomFieldValue,
  {
    readField,
    mergeObjects
  }: FieldFunctionOptions<
    Record<string, CustomFieldValue>,
    Record<string, CustomFieldValue>
  >
) => {
  if (existing && incoming) {
    const existingUpdatedAt = readField<string>('updatedAt', existing);
    const incomingUpdatedAt = readField<string>('updatedAt', incoming);

    if (
      existingUpdatedAt &&
      incomingUpdatedAt &&
      new Date(existingUpdatedAt).getTime() >=
        new Date(incomingUpdatedAt).getTime()
    ) {
      return existing;
    }
  }
  return mergeObjects(existing, incoming);
};

export const filteredRelayStylePagination = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyArgs: (args: Record<string, any> | null) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldFilterFunction: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: FieldFunctionOptions<any, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    edge: TRelayEdge<any>
  ) => boolean
) => {
  const config = relayStylePagination(keyArgs);
  return {
    ...config,
    merge: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      existing: TExistingRelay<any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      incoming: TIncomingRelay<any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: FieldFunctionOptions<any, any>
    ) => {
      const { merge } = config;
      if (typeof merge !== 'function') return existing;

      const filteredIncomingEdges = incoming.edges?.filter((edge) => {
        return fieldFilterFunction(options, edge);
      });

      const filteredIncoming = {
        ...incoming,
        edges: filteredIncomingEdges
      };

      return merge(existing, filteredIncoming, options);
    }
  };
};

export const mergeAppStore = (
  persistedState: unknown,
  currentState: AppState
) => {
  const mergedState = {
    ...currentState,
    auth: {
      ...currentState.auth,
      ...(persistedState as AppState).auth
    },
    // Prevent persisting dashboard state
    dashboard: currentState.dashboard,
    localSearch: {
      ...currentState.localSearch,
      ...(persistedState as AppState).localSearch
    },
    collection: {
      ...currentState.collection,
      ...(persistedState as AppState).collection
    }
  };
  return mergedState;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReadFieldFn = (fieldName: string, reference?: Readonly<Reference>) => any;

/**
 * Creates a merge field filter for Apollo cache that validates required nested fields
 * @param pathToCheck Array of field names to traverse and validate
 * @param finalField The final field that must exist
 * @returns A filter function for Apollo cache merge fields
 */
export const createMergeFieldFilter = (
  pathToCheck: string[],
  finalField: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ({ readField }: { readField: ReadFieldFn }, edge: any) => {
    let currentRef: Readonly<Reference> | undefined = readField('node', edge);

    // Check each field in the path
    for (const field of pathToCheck) {
      if (!currentRef) return true;
      currentRef = readField(field, currentRef);
    }

    if (!currentRef) return true;

    const field = readField(finalField, currentRef);

    if (!field) {
      logger.info('Filtering out edges without required field', {
        pathToCheck,
        finalField,
        currentRef
      });
    }

    return !!field;
  };
};
