import { HarmonicIcon } from '../type';

const RoundIconMna: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M7.99984 11.634C7.91095 11.634 7.8305 11.62 7.7585 11.592C7.68606 11.5644 7.6165 11.5229 7.54984 11.4673C7.07206 11.0451 6.69428 10.52 6.4165 9.89198C6.13873 9.26443 5.99984 8.63398 5.99984 8.00065C5.99984 7.36732 6.13873 6.73665 6.4165 6.10865C6.69428 5.4811 7.0665 4.95621 7.53317 4.53398C7.59984 4.47843 7.67206 4.43398 7.74984 4.40065C7.82761 4.36732 7.91095 4.35065 7.99984 4.35065C8.08873 4.35065 8.16939 4.36732 8.24184 4.40065C8.31384 4.43398 8.38317 4.47843 8.44984 4.53398C8.92761 4.95621 9.30539 5.4811 9.58317 6.10865C9.86095 6.73665 9.99984 7.36732 9.99984 8.00065C9.99984 8.63398 9.86095 9.26443 9.58317 9.89198C9.30539 10.52 8.93317 11.0451 8.4665 11.4673C8.41095 11.5229 8.34161 11.5644 8.2585 11.592C8.17495 11.62 8.08873 11.634 7.99984 11.634ZM10.6665 12.6673C10.3665 12.6673 10.0749 12.6395 9.79184 12.584C9.50828 12.5284 9.23317 12.4451 8.9665 12.334C9.58873 11.9229 10.0832 11.2784 10.4498 10.4007C10.8165 9.52287 10.9998 8.72287 10.9998 8.00065C10.9998 7.27843 10.8165 6.47843 10.4498 5.60065C10.0832 4.72287 9.58873 4.07843 8.9665 3.66732C9.23317 3.55621 9.50828 3.47287 9.79184 3.41732C10.0749 3.36176 10.3665 3.33398 10.6665 3.33398C11.9665 3.33398 13.0692 3.78665 13.9745 4.69198C14.8803 5.59776 15.3332 6.70065 15.3332 8.00065C15.3332 9.30065 14.8803 10.4033 13.9745 11.3087C13.0692 12.2144 11.9665 12.6673 10.6665 12.6673ZM5.33317 12.6673C4.03317 12.6673 2.9305 12.2144 2.02517 11.3087C1.11939 10.4033 0.666504 9.30065 0.666504 8.00065C0.666504 6.70065 1.11939 5.59776 2.02517 4.69198C2.9305 3.78665 4.03317 3.33398 5.33317 3.33398C5.63317 3.33398 5.92473 3.36176 6.20784 3.41732C6.49139 3.47287 6.7665 3.55621 7.03317 3.66732C6.41095 4.07843 5.9165 4.72287 5.54984 5.60065C5.18317 6.47843 4.99984 7.27843 4.99984 8.00065C4.99984 8.72287 5.18317 9.52287 5.54984 10.4007C5.9165 11.2784 6.41095 11.9229 7.03317 12.334C6.7665 12.4451 6.49139 12.5284 6.20784 12.584C5.92473 12.6395 5.63317 12.6673 5.33317 12.6673Z"
      fill="#3AA691"
    />
  </svg>
);

export default RoundIconMna;
