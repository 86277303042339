import { gql, useQuery } from '@apollo/client';
import {
  EducationMetadata,
  EmployeeGroupType,
  ExperienceMetadata,
  GetCompanyFoundersQuery,
  GetCompanyFoundersQueryVariables,
  Person,
  SearchResultNode
} from '__generated__/graphql';
import { ArrowRightIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Badge } from 'common/components';
import PersonCardV2, {
  PersonCard,
  PersonPastExperience
} from 'components/common/PersonCard/PersonCardV2';
import ReportDataIssue from 'components/common/ReportDataIssue';
import IconButton from 'harmonic-components/IconButton/IconButton';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import { compact, isNil, uniq } from 'lodash';
import { shouldLinkCompanyId } from 'utils/company';
import { HighlightCategoryMap } from 'utils/constants';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';

export const CompanyFoundersContentLoader = () => {
  return (
    <div
      className="mt-3 flex flex-col gap-4"
      data-testid="CompanyFounders-Loader"
    >
      {[...Array(3)].map((_, index) => (
        <div key={index} className="w-full h-36 animated-box-pulse" />
      ))}
    </div>
  );
};

export const getCompanyFounders = gql`
  fragment CompanyFounderEmployeesPerson on Person {
    linkedinHeadline
    fullName
    id
    profilePictureUrl
    education {
      school {
        entityUrn
        name
      }
      degree
      startDate
      endDate
    }
    highlights {
      text
      category
    }
    experience {
      title
      endDate
      startDate
      isCurrentPosition
      company {
        logoUrl
        id
        name
        highlights {
          text
          category
        }
      }
    }
    socials {
      linkedin {
        url
      }
    }
    userConnections {
      user {
        name
        email
      }
    }
  }

  query GetCompanyFounders(
    $companyUrn: CompanyUrn!
    $page: Int!
    $size: Int!
    $employeeGroupType: EmployeeGroupType!
  ) {
    getEmployeesByCompanyId(
      companyUrn: $companyUrn
      page: $page
      size: $size
      employeeGroupType: $employeeGroupType
    ) {
      edges {
        cursor
        node {
          ...CompanyFounderEmployeesPerson
        }
      }
      totalCount
    }
  }
`;

const SCHOOL_TO_FILTER_OUT = [
  //Y-Combinator
  'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e3'
];

interface CompanyFoundersCeoProps {
  companyId: number;
  onTabChange?: (newTabOption: CompanyNavbarOptions) => void;
}

export const SeeAllTeamButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={onClick}
      icon={ArrowRightIcon}
      size="compact"
      type="secondary"
      emphasis="high"
    />
  );
};

export const getFormattedPastExperiences = (
  experiences: (ExperienceMetadata | null)[]
) => {
  const companyExperiencesMap: Record<number | string, PersonPastExperience> =
    {};
  const filteredExperiences = experiences?.filter(
    (exp) => exp
  ) as ExperienceMetadata[];

  for (const experience of filteredExperiences) {
    const companyId = experience?.company?.id;
    const companyName = experience.company?.name ?? '';

    // Employee highlights are pre formatted. Company highlights are not.
    const companyHighlights =
      experience?.company?.highlights?.map(
        (highlight) => HighlightCategoryMap?.[highlight?.category ?? '']
      ) ?? [];
    const hashKey = shouldLinkCompanyId(companyId) ? companyId : companyName;
    if (!companyExperiencesMap?.[hashKey]) {
      companyExperiencesMap[hashKey] = {
        companyName: experience.company.name ?? '',
        logoUrl: experience?.company?.logoUrl ?? '',
        id: companyId,
        roles: [],
        highlights: companyHighlights
      };
    }

    companyExperiencesMap[hashKey].roles.push({
      title: experience?.title ?? '',
      startDate: experience?.startDate ?? '',
      endDate: experience?.endDate ?? '',
      isCurrentPosition: Boolean(experience?.isCurrentPosition)
    });
  }

  const formattedExperiences = Object.values(companyExperiencesMap);

  // Sort the roles. Recent appears at top and oldest at later index of array
  for (const experience of formattedExperiences) {
    experience?.roles?.sort((a, b) => {
      if (!a?.startDate || !b?.startDate) return -1;
      const startDateA = new Date(a?.startDate as string).getTime();
      const startDateB = new Date(b?.startDate as string).getTime();
      return startDateB - startDateA;
    });
  }

  formattedExperiences.sort((a, b) => {
    let startDateA = null;
    let startDateB = null;
    if (a?.roles?.length > 0) {
      startDateA = new Date(
        a?.roles?.[a.roles?.length - 1]?.startDate as string
      ).getTime();
    }
    if (b?.roles?.length > 0) {
      startDateB = new Date(
        b?.roles?.[b.roles?.length - 1]?.startDate as string
      ).getTime();
    }
    if (!startDateA) return 1;
    if (!startDateB) return -1;
    return startDateB - startDateA;
  });

  return formattedExperiences;
};

export const getRecentUniversity = (
  education: (EducationMetadata | null)[],
  schoolToFilterOut: string[] = []
) => {
  const schoolToFilterOutSet = new Set(schoolToFilterOut);
  const filteredEducation = education.filter(
    (edu) => edu?.startDate && !schoolToFilterOutSet.has(edu?.school?.entityUrn)
  );
  filteredEducation.sort(
    (a, b) =>
      new Date(b?.startDate).getTime() - new Date(a?.startDate).getTime()
  );
  return filteredEducation?.[0]?.school?.name ?? '';
};

export const formatEmployees = (employees: Person[], companyId: number) => {
  const formattedPerson: PersonCard[] = [];
  employees.forEach((employee) => {
    const currentExperince = employee?.experience?.find(
      (exp) => exp?.isCurrentPosition && exp.company.id === companyId
    );
    const pastExperiences = getFormattedPastExperiences(
      employee?.experience ?? []
    );
    const recentUniversity = getRecentUniversity(
      employee?.education ?? [],
      SCHOOL_TO_FILTER_OUT
    );
    const employeeHighlights =
      uniq(
        compact(employee?.highlights?.map((highlight) => highlight?.category))
      ) ?? [];
    formattedPerson.push({
      id: employee?.id as number,
      fullName: employee?.fullName as string,
      profilePictureUrl: employee?.profilePictureUrl,
      universityName: recentUniversity,
      highlights: employeeHighlights as string[],
      currentStartDate: currentExperince?.startDate ?? '',
      currentTitle: currentExperince?.title as string,
      pastExperiences: pastExperiences,
      linkedinUrl: employee?.socials?.linkedin?.url,
      userConnections:
        compact(
          employee?.userConnections?.map((connection) => connection?.user)
        ) ?? []
    });
  });

  return formattedPerson;
};

export const FETCH_FOUNDERS_CEO_COUNT = 50;

const CompanyFoundersCeo: React.FC<CompanyFoundersCeoProps> = ({
  companyId,
  onTabChange
}) => {
  const { data } = useQuery<
    GetCompanyFoundersQuery,
    GetCompanyFoundersQueryVariables
  >(getCompanyFounders, {
    variables: {
      companyUrn: `urn:harmonic:company:${companyId}`,
      page: 0,
      size: FETCH_FOUNDERS_CEO_COUNT,
      employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
    },
    fetchPolicy: 'cache-first'
  });

  const employeeNodes = (
    (data?.getEmployeesByCompanyId?.edges as SearchResultNode[]) ?? []
  ).map((edg) => edg.node);

  const formattedEmployees = formatEmployees(
    employeeNodes as Person[],
    companyId
  );
  const totalCount = formattedEmployees.length;
  const loading = isNil(data);
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center w-full border-b border-solid border-border pb-p70">
          <div className="flex items-center gap-g40">
            <p
              className={classNames('text-content-title flex items-center', {
                'typography-title-medium': isNil(onTabChange),
                'typography-title-small': !isNil(onTabChange)
              })}
            >
              Founders & CEO
            </p>
            {totalCount !== 0 && (
              <Badge label={totalCount?.toString() ?? ''} color="neutral" />
            )}
          </div>
        </div>
        {onTabChange === undefined && (
          <ReportDataIssue
            reportParams={{
              companyUrn: 'urn:company:harmonic:' + companyId,
              reportSourceView: ReportSourceView.COMPANY,
              reportSourceSection: ReportSourceSection.FINANCING
            }}
            placeholderText="The company is showing incorrect founders"
          />
        )}
        {onTabChange !== undefined && (
          <IconButton
            onClick={() => onTabChange(CompanyNavbarOptions.TEAM)}
            icon={ArrowRightIcon}
            size="compact"
          />
        )}
      </div>
      <div className="pt-6">
        {loading ? (
          <CompanyFoundersContentLoader />
        ) : (
          <div data-testid="CompanyFoundersAndCEO">
            {(totalCount as number) > 0 && (
              <>
                <div className="flex flex-col gap-7">
                  {formattedEmployees.map((employee) => (
                    <PersonCardV2 key={employee?.fullName} {...employee} />
                  ))}
                </div>
              </>
            )}
            {totalCount === 0 && (
              <p className="pt-3 text-content-default typography-label-large">
                No founders found
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyFoundersCeo;
