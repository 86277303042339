import { HarmonicIcon } from '../type';

const LastFundingDateIcon: HarmonicIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45833 1.66699C6.80351 1.66699 7.08333 1.94681 7.08333 2.29199V3.33366H12.9167V2.29199C12.9167 1.94681 13.1965 1.66699 13.5417 1.66699C13.8868 1.66699 14.1667 1.94681 14.1667 2.29199V3.33366H16.0417C16.8471 3.33366 17.5 3.98658 17.5 4.79199V16.042C17.5 16.8474 16.8471 17.5003 16.0417 17.5003H3.95833C3.15292 17.5003 2.5 16.8474 2.5 16.042V4.79199C2.5 3.98658 3.15292 3.33366 3.95833 3.33366H5.83333V2.29199C5.83333 1.94681 6.11316 1.66699 6.45833 1.66699ZM3.75 8.75033V16.042C3.75 16.1571 3.84327 16.2503 3.95833 16.2503H16.0417C16.1567 16.2503 16.25 16.1571 16.25 16.042V8.75033H3.75Z"
      fill="#7C8598"
    />
  </svg>
);

export default LastFundingDateIcon;
