import { CircleCheckIcon } from 'assets/harmonic-icons';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
import { HarmonicIcon } from 'assets/harmonic-icons/type';
import { ColorShorthand } from 'common/utils/design';
import { ISearchFieldSpec } from 'interfaces/SearchModel/Search';

export type SelectMode = 'include' | 'select' | 'exclude' | null;
export const getTagColorBySelectMode = (
  mode?: SelectMode
): ColorShorthand | undefined => {
  if (!mode) return undefined;
  if (mode === 'include') return 'positive';
  if (mode === 'select') return 'informative';
  if (mode === 'exclude') return 'negative';
};

export const getTagIconBySelectMode = (
  mode?: SelectMode
): HarmonicIcon | undefined => {
  if (!mode) return undefined;
  if (mode === 'include') return CirclePlus;
  if (mode === 'select') return CircleCheckIcon;
  if (mode === 'exclude') return CircleMinus;
};

export const getAllowedValuesFromFieldSpec = (
  fieldSpecs: ISearchFieldSpec[],
  field: string
) => {
  const roleSpecField = fieldSpecs?.find((spec) => spec.unique_name === field);

  return (
    roleSpecField?.value_restricted_to
      ?.filter((value) => value !== null)
      .map((value) => ({
        label: value,
        value: value
      })) || []
  );
};
