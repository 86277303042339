import { ReactComponent as AddIcon } from 'assets/plus.svg';
import classNames from 'classnames';
import { ISearchFilterOperation } from 'interfaces/SearchModel/Search';
import React from 'react';
import {
  ISearchFilterContext,
  SearchFilterContext
} from '../SearchFilterGroupBase';

const OperationsInput = (props: {
  //Index of filter in context state
  index: number;
  operations?: ISearchFilterOperation[];
  disabled?: boolean;
}) => {
  const { index, operations } = props;
  const searchFilterContext: ISearchFilterContext =
    React.useContext(SearchFilterContext);
  if (operations?.length === 0) return null;

  return (
    <>
      {operations && (
        <div className="flex flex-wrap items-center">
          {operations?.map((operation) => {
            return (
              <button
                key={operation?.label}
                className={classNames(
                  'flex items-center bg-blue-lightest border border-solid px-2 py-1 gap-0.5',
                  'text-ink-lighter hover:bg-blue-lighter border-blue rounded text-xs cursor-pointer'
                )}
                onClick={() => {
                  searchFilterContext.searchFilterMethods.applyFilterOperation(
                    index,
                    operation
                  );
                }}
                disabled={props.disabled}
              >
                <AddIcon />
                {operation?.label}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export default OperationsInput;
