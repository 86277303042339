import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const ProptechIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 5.75C6.66602 4.7835 7.44952 4 8.41602 4H16.916C17.8825 4 18.666 4.7835 18.666 5.75V8H20.916C21.8825 8 22.666 8.7835 22.666 9.75V18.5H22.916C23.3302 18.5 23.666 18.8358 23.666 19.25C23.666 19.6642 23.3302 20 22.916 20H2.41602C2.0018 20 1.66602 19.6642 1.66602 19.25C1.66602 18.8358 2.0018 18.5 2.41602 18.5H2.66602V9.75C2.66602 8.7835 3.44952 8 4.41602 8H6.66602V5.75ZM6.66602 9.5H4.41602C4.27794 9.5 4.16602 9.61193 4.16602 9.75V18.5H6.66602V9.5ZM18.666 18.5H21.166V9.75C21.166 9.61193 21.0541 9.5 20.916 9.5H18.666V18.5ZM14.666 18.5V15.25C14.666 15.1119 14.5541 15 14.416 15H10.916C10.7779 15 10.666 15.1119 10.666 15.25V18.5H14.666Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ProptechIcon;
