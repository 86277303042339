import { ColumnHeaderOrder } from 'interfaces/Grid';
import { EntityListType, ResultsView } from 'interfaces/SearchModel/Search';
import { difference, union } from 'lodash';
import { create } from 'zustand';
import { ListCustomFieldType } from '../__generated__/graphql';
import { useShallowStoreGeneric } from './util';

type CommonSidebarPayload = {
  open: boolean;
};

type CustomizeFieldModalPayload = CommonSidebarPayload & {
  open: boolean;
  mode?: 'add' | 'edit' | 'duplicate' | 'add-to-left' | 'add-to-right';
  colId?: string;
  type?: ListCustomFieldType;
  groupBy?: boolean;
};

type DisplayMenuSidebarPayload = CommonSidebarPayload & {
  focusNameInput?: boolean;
};

type EditFieldsSidebarPayload = CommonSidebarPayload & {
  entityType?: EntityListType;
};

interface TableStoreState {
  customizeFieldModal: CustomizeFieldModalPayload;
  displayMenuSidebar: DisplayMenuSidebarPayload;
  editFieldsSidebar: EditFieldsSidebarPayload;
  gridRowHeight: number;
  selectedRowIds: number[];
  selectedNetNewIds: number[];
  actionsBarOpen: boolean;
  addToListOpenInActionsBar: boolean;
  allRowsSelected: boolean | null;
  tableColumns: ColumnHeaderOrder[];
  totalCount: number;
  companyListLocalSearchTerm: { [key: string]: string };
  personListLocalSearchTerm: { [key: string]: string };
  addToListHoverActionOpenForCompanyId: number | null; // @deprecated
  view: ResultsView;
  lastGridLoadingTime: number | null;
  editTableStoreData<
    Key extends keyof Omit<
      TableStoreState,
      'customizeFieldModal' | 'displayMenuSidebar' | 'editFieldsSidebar'
    >
  >(
    key: Key,
    payload: TableStoreState[Key]
  ): void;
  editSidebar<
    Key extends keyof Pick<
      TableStoreState,
      'customizeFieldModal' | 'displayMenuSidebar' | 'editFieldsSidebar'
    >
  >(
    key: Key,
    payload: TableStoreState[Key]
  ): void;
  isSidebarOpen: () => boolean;
  setAddToListHoverActionOpenForCompanyId(id: number | null): void; // @deprecated
  addSelectedRowIds(rowIds: number[]): void;
  removeSelectedRowIds(rowIds: number[]): void;
  addSelectedNetNewIds(netNewIds: number[] | undefined): void;
  removeSelectedNetNewIds(netNewIds: number[]): void;
  loadedExtendedUrns: { [key: string]: boolean };
  selectAllStateEnabled: boolean;
  addToLoadedExtendedUrns: (urns: string[]) => void;
}

const useTableStore = create<TableStoreState>((set, get) => ({
  customizeFieldModal: {
    open: false
  },
  displayMenuSidebar: {
    open: false
  },
  editFieldsSidebar: {
    open: false
  },
  gridRowHeight: 78,
  selectedRowIds: [],
  selectedNetNewIds: [],
  actionsBarOpen: false,
  addToListOpenInActionsBar: false,
  allRowsSelected: false,
  tableColumns: [],
  totalCount: 0,
  addToListHoverActionOpenForCompanyId: null,
  view: ResultsView.GRID,
  lastGridLoadingTime: null,
  companyListLocalSearchTerm: {},
  personListLocalSearchTerm: {},
  selectAllStateEnabled: false,
  loadedExtendedUrns: {},
  setAddToListHoverActionOpenForCompanyId: (id: number | null) => {
    set(() => ({ addToListHoverActionOpenForCompanyId: id }));
  },
  addToLoadedExtendedUrns: (ids: string[]) => {
    set((state) => ({
      ...state,
      loadedExtendedUrns: ids.reduce(
        (acc, urn) => ({ ...acc, [urn]: true }),
        state.loadedExtendedUrns
      )
    }));
  },
  editTableStoreData: (key, payload) =>
    set((state) => ({
      ...state,
      [key]: payload
    })),
  editSidebar: (key, payload) => {
    // Close all sidebars and apply the new state of the sidebar that is being edited
    set((state) => ({
      ...state,
      customizeFieldModal: {
        ...state.customizeFieldModal,
        open: false
      },
      displayMenuSidebar: {
        ...state.displayMenuSidebar,
        open: false
      },
      editFieldsSidebar: {
        ...state.editFieldsSidebar,
        open: false
      },
      [key]: payload
    }));
  },
  isSidebarOpen: () => {
    return (
      get().customizeFieldModal.open ||
      get().displayMenuSidebar.open ||
      get().editFieldsSidebar.open
    );
  },
  addSelectedRowIds: (rowIds: number[]) => {
    set((state) => {
      const currentlySelectedRows = state.selectedRowIds;
      const updatedRowIds = union(currentlySelectedRows, rowIds);
      return {
        selectedRowIds: updatedRowIds
      };
    });
  },
  removeSelectedRowIds: (rowIds: number[]) => {
    set((state) => {
      const currentlySelectedRows = state.selectedRowIds;
      const updatedRowIds = difference(currentlySelectedRows, rowIds);
      return {
        selectedRowIds: updatedRowIds
      };
    });
  },
  addSelectedNetNewIds: (rowIds: number[]) => {
    set((state) => {
      const currentlySelectedRows = state.selectedNetNewIds;
      const updatedRowIds = union(currentlySelectedRows, rowIds);
      return {
        selectedNetNewIds: updatedRowIds
      };
    });
  },
  removeSelectedNetNewIds: (rowIds: number[]) => {
    set((state) => {
      const currentlySelectedRows = state.selectedNetNewIds;
      const updatedRowIds = difference(currentlySelectedRows, rowIds);
      return {
        selectedNetNewIds: updatedRowIds
      };
    });
  }
}));

// Only re-render the component when the selected keys change
export const useShallowTableStore = (keys: (keyof TableStoreState)[]) =>
  useShallowStoreGeneric(useTableStore, keys);

export default useTableStore;
