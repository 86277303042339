import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { useFieldSpec } from 'hooks/useFieldSpec';
import {
  EntityListType,
  EntitySearchListType
} from 'interfaces/SearchModel/Search';
import { capitalize } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  SelectMode,
  getTagColorBySelectMode,
  getTagIconBySelectMode
} from './utils';

interface RoleTypeMultiselectProps {
  selected: string[];
  disabled?: boolean;
  dataTestId?: string;
  onChange: (newOptions?: string[]) => void;
  initialFocus?: boolean;
  mode?: SelectMode;
  entityType: EntitySearchListType;
}
const RoleTypeMultiselect: React.FC<RoleTypeMultiselectProps> = ({
  selected,
  onChange,
  initialFocus,
  mode = null,
  entityType
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { fieldSpec } = useFieldSpec();
  const onClickListItem = (value: string) => {
    const selectedOption = selected.find((s) => s === value);
    if (selectedOption) {
      onChange(selected.filter((s) => s !== value));
    } else {
      onChange([...selected, value]);
    }

    setSearchTerm('');
  };

  const fieldSpecs = useMemo(() => {
    return fieldSpec?.filter(
      (fieldSpec) =>
        fieldSpec.allowed_comparators &&
        fieldSpec.allowed_comparators.length > 0
    );
  }, [fieldSpec]);

  const roleTypeListSpecName =
    entityType === EntityListType.COMPANY_SAVED_SEARCH
      ? 'employee_role_type'
      : 'person_position_role_type';
  const roleOptions = useMemo(() => {
    const roleSpecField = fieldSpecs?.find(
      (spec) => spec.unique_name === roleTypeListSpecName
    );

    const roleFieldSpecs =
      roleSpecField?.value_restricted_to
        ?.filter((value) => value !== null)
        .map((value) => ({
          label: capitalize(value.toLowerCase()),
          value: value
        })) || [];

    return roleFieldSpecs.filter((role) =>
      role.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [fieldSpecs, searchTerm]);

  const getLabelFromValue = useCallback(
    (value?: string) => {
      return roleOptions.find((option) => option?.value === value)?.label ?? '';
    },
    [roleOptions]
  );

  return (
    <Select
      multiple
      filterable
      filterTerm={searchTerm}
      onFilterTermChange={setSearchTerm}
      selected={selected}
      onRemove={(key) => {
        const newSelected = selected.filter((s) => s !== key);
        onChange(newSelected.length > 0 ? newSelected : undefined);
      }}
      initialFocus={initialFocus}
      placeholder="Select roles to include"
      getLabelFromValue={getLabelFromValue}
      getTagColorFromValue={() => getTagColorBySelectMode(mode)}
      getTagIconFromValue={() => getTagIconBySelectMode(mode)}
    >
      {roleOptions.map((role) => {
        if (!role) return null;
        return (
          <SelectListItem
            key={role.value}
            value={role.value}
            label={role.label ?? ''}
            selected={selected.includes(role.value)}
            onChange={() => onClickListItem(role.value)}
            variant={ListVariant.checkboxes}
          />
        );
      })}
    </Select>
  );
};

export default RoleTypeMultiselect;
