import { useQuery } from '@apollo/client';
import {
  GetPeopleNamesByIdsQuery,
  GetPeopleNamesByIdsQueryVariables,
  GetPeopleWithTypeaheadFragment,
  TypeaheadPeopleQuery,
  TypeaheadPeopleQueryVariables
} from '__generated__/graphql';
import GreyLinearProgress from 'components/common/GreyLinearProgress';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { SelectedTypeaheadResult } from 'interfaces/SearchV2';
import { compact, toString } from 'lodash';
import { GET_PEOPLE_NAMES_BY_IDS } from 'queries/getPeopleNames';
import { TYPEAHEAD_PEOPLE_QUERY } from 'queries/typeahead';
import { useState } from 'react';

interface PersonMultiSelectByIdProps {
  onChange: (newOptions?: SelectedTypeaheadResult[]) => void;
  selected: SelectedTypeaheadResult[];
  labelPrefix?: string;
  disabled?: boolean;
  initialFocus?: boolean;
}

const PersonMultiSelectById: React.FC<PersonMultiSelectByIdProps> = ({
  onChange,
  selected,
  labelPrefix,
  initialFocus
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: peopleData, loading } = useQuery<
    TypeaheadPeopleQuery,
    TypeaheadPeopleQueryVariables
  >(TYPEAHEAD_PEOPLE_QUERY, { variables: { query: searchTerm } });

  const { data: peopleNameData } = useQuery<
    GetPeopleNamesByIdsQuery,
    GetPeopleNamesByIdsQueryVariables
  >(GET_PEOPLE_NAMES_BY_IDS, {
    variables: {
      ids: compact(selected.map((person) => person.id))
    }
  });

  const onClickListItem = (person: GetPeopleWithTypeaheadFragment) => {
    const selectedPerson: SelectedTypeaheadResult = {
      name: person.fullName ?? '',
      id: person.id
    };
    const currentOptionIndex = selected.findIndex((option) =>
      option.id
        ? option.id === selectedPerson.id
        : option.name === selectedPerson.name
    );
    if (currentOptionIndex === -1) {
      onChange([...selected, selectedPerson]);
    }

    setSearchTerm('');
  };

  const onRemoveItem = (companyId: string) => {
    const updatedSelectedOptions = selected.filter(
      (option) => option.id?.toString() !== companyId
    );
    onChange(updatedSelectedOptions);
  };
  const selectedIds = selected.map((option) => option?.id?.toString() ?? '');

  return (
    <div className="min-w-56 w-full">
      <Select
        multiple
        filterable
        fullWidth
        filterTerm={searchTerm}
        onFilterTermChange={setSearchTerm}
        selected={selectedIds}
        labelPrefix={labelPrefix}
        placeholder="Search people"
        getLabelFromValue={(value) => {
          return (
            peopleNameData?.getPersonsByIds?.find(
              (person) => toString(person?.id) === value
            )?.fullName ?? ''
          );
        }}
        initialFocus={initialFocus}
        onRemove={(company) => onRemoveItem(company)}
        hideDropdown={
          !loading &&
          (Boolean(peopleData?.getPeopleWithTypeahead) === false ||
            peopleData?.getPeopleWithTypeahead?.length === 0)
        }
      >
        {loading && (
          <div className="h-p20 w-full">
            <GreyLinearProgress />
          </div>
        )}
        {!loading &&
          peopleData?.getPeopleWithTypeahead?.map((person) => {
            if (!person) return null;
            return (
              <SelectListItem
                key={person.id}
                value={person.fullName ?? ''}
                label={person.fullName ?? ''}
                logo="logo"
                src={person.profilePictureUrl ?? ''}
                onClick={() => onClickListItem(person)}
                variant={ListVariant.entity}
              />
            );
          })}
      </Select>
    </div>
  );
};

export default PersonMultiSelectById;
