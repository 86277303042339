import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import {
  BackendCustomRangeModeType,
  DateRangeShortHand
} from 'interfaces/SearchV2';
import { isCustomRangeMode } from 'utils/searchV2/searchV2';
import CustomRange from './CustomRange/CustomRange';
import { TwoSidedRangeValue } from './CustomRange/DateModal';

interface DateAddedToHarmonicSelectProps {
  selected?: string;
  customRangeValue?: TwoSidedRangeValue;
  disabled?: boolean;
  dataTestId?: string;
  onChange: (newOptions?: string, customValue?: TwoSidedRangeValue) => void;
}

const options = [
  {
    label: 'Any',
    value: undefined
  },
  {
    label: 'In the last week',
    value: DateRangeShortHand.PAST_WEEK
  },
  {
    label: 'In the last month',
    value: DateRangeShortHand.PAST_THIRTY_DAYS
  },
  {
    label: 'In the last 90 days',
    value: DateRangeShortHand.PAST_THREE_MONTHS
  }
];
const DateAddedToHarmonicSelect: React.FC<DateAddedToHarmonicSelectProps> = ({
  selected,
  customRangeValue,
  dataTestId,
  onChange
}) => {
  const isCustomMode = isCustomRangeMode(selected as string);
  return (
    <div data-testid={dataTestId} className="flex flex-row flex-wrap gap-g40">
      {options.map((option) => {
        return (
          <RadioPill
            key={option.value}
            label={option.label}
            selected={selected === option.value}
            onClick={() => onChange(option.value)}
          />
        );
      })}
      {/* //eslint-disable-next-line
        //@ts-ignore */}
      <CustomRange
        mode={
          isCustomMode ? (selected as BackendCustomRangeModeType) : undefined
        }
        value={customRangeValue}
        onChange={(mode, value) => {
          onChange(mode, value);
        }}
      />
    </div>
  );
};

export default DateAddedToHarmonicSelect;
