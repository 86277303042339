import classNames from 'classnames';
import { IconProps } from './type';

const VerticalMenuIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#677183"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.99998 1.33333C7.35565 1.33333 6.83331 1.85567 6.83331 2.5C6.83331 3.14433 7.35565 3.66667 7.99998 3.66667C8.64431 3.66667 9.16665 3.14433 9.16665 2.5C9.16665 1.85567 8.64431 1.33333 7.99998 1.33333Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.99998 6.83333C7.35565 6.83333 6.83331 7.35567 6.83331 8C6.83331 8.64433 7.35565 9.16667 7.99998 9.16667C8.64431 9.16667 9.16665 8.64433 9.16665 8C9.16665 7.35567 8.64431 6.83333 7.99998 6.83333Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.99998 12.3333C7.35565 12.3333 6.83331 12.8557 6.83331 13.5C6.83331 14.1443 7.35565 14.6667 7.99998 14.6667C8.64431 14.6667 9.16665 14.1443 9.16665 13.5C9.16665 12.8557 8.64431 12.3333 7.99998 12.3333Z"
      />
    </svg>
  );
};

export default VerticalMenuIcon;
