import { ICellRendererParams } from 'ag-grid-community';
import EntityListSettingsDropdown from 'components/Dashboard/DashboardLayout/EntityListSettingsDropdown';
import { LOADING_ROW_ID } from 'utils/constants';

const RowActionsComponent = (rowData: ICellRendererParams) => {
  const { data } = rowData;
  const { entityType, id, userOptions } = data;
  if (rowData.data.id === LOADING_ROW_ID) {
    return null;
  }
  return (
    <div className="flex justify-center h-full">
      <div className="my-auto">
        <EntityListSettingsDropdown
          entityId={id.toString()}
          entityType={entityType}
          isPinned={userOptions?.isPinned}
        />
      </div>
    </div>
  );
};

export default RowActionsComponent;
