import { FC } from 'react';
import { logger } from 'utils/logger';

const SearchFilterError: FC<{ error: Error }> = ({ error }) => {
  logger.error(`Uncaught client error: ${error.message}`);
  return (
    <div className="content">
      <div className="error">
        <div className="typography-label">
          There was a problem loading the filters. Our team has been notified.
        </div>
      </div>
    </div>
  );
};

export default SearchFilterError;
