import classNames from 'classnames';
import { Badge } from 'common/components';
import { isNil } from 'lodash';

interface FilterPillProps {
  id?: string;
  label: string;
  onClick: () => void;
  isSelected: boolean;
  badgeCount?: number;
  dataTestId?: string;
}

const FilterPill: React.FC<FilterPillProps> = ({
  id,
  label,
  onClick,
  isSelected,
  badgeCount,
  dataTestId
}) => {
  return (
    <button
      id={id}
      data-testid={dataTestId}
      onClick={onClick}
      className={classNames(
        'px-p60 py-p40 typography-label flex items-center rounded-br90 focus:shadow-int-focus-outside gap-g30',
        'shadow-none focus:shadow-none',
        // HACK: need to add 2px margin at the bottom because otherwise the border and shadow do nto render on click
        // this is an odd behavior, but I couldn't find other way to fix it.
        'mb-p10',
        {
          'bg-filterPill-enabled text-content-strong hover:bg-filterPill-hover active:bg-filterPill-pressed':
            !isSelected,
          'bg-filterPill-selected-enabled text-int-fill-primary-onSelected hover:bg-filterPill-selected-hover active:bg-filterPill-selected-pressed':
            isSelected
        }
      )}
    >
      {label}
      {!isNil(badgeCount) && badgeCount !== 0 && (
        <Badge
          size="small"
          color={isSelected ? 'neutral-inverted' : 'informative'}
          intensity="highlight"
          borderRadius="square"
          label={badgeCount.toString()}
        />
      )}
    </button>
  );
};

export default FilterPill;
