import { useCallback } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import { SortHookResponse } from 'utils/sort';

export const usePeopleSearchSort = (): SortHookResponse => {
  const { editFilterStore, peopleExplorePageSort } = useShallowFilterStore([
    'editFilterStore',
    'peopleExplorePageSort'
  ]);

  const changeSort = useCallback(
    () =>
      ({ field, descending }: { field: string; descending: boolean }) => {
        editFilterStore('peopleExplorePageSort', {
          sortField: field,
          descending: descending
        });
      },
    [editFilterStore]
  );

  return {
    changeSort,
    sort: peopleExplorePageSort
  };
};
