import classNames from 'classnames';
import {
  CompanyFoundationDateOptions,
  CompanyInitializedOrUpdatedDateOptions,
  DateRangeGenericFilterGenerator,
  FilterGroupGeneratorId
} from 'interfaces/SearchV2';
import React from 'react';
import ChipWithCustomRange from '../../Common/ChipWithCustomRange';

import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
import FoundingDateSelect from 'components/QueryBuilder/Common/FoundingDateSelect';
import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import {
  formatCustomRange,
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import AddLocation from '../../Common/AddLocation';
import ButtonWrapper from '../../Common/ButtonWrapper';
import ListMultiselect from '../../Common/ListMultiselect';
import {
  SectionDivider,
  SmallSectionHeader,
  SubSectionHeader
} from '../../Common/Typography';
import { AffinityBanner } from './AffinityBanner';

import Banner from 'harmonic-components/Banner/Banner';
import { SIMPLE_SEARCH_PRODUCT_BANNER_DISMISSED_KEY } from 'utils/constants';
const CompanyInitializedOrUpdatedMultiselectOptions = [
  {
    label: 'Any',
    value: undefined
  },
  {
    label: 'In the last week',
    value: 'past_week'
  },
  {
    label: 'In the last month',
    value: 'past_thirty_days'
  }
];

const setAffinityBannerDismissLocalStorageState = (dismissed: boolean) => {
  localStorage.setItem(
    'simpleSearchAffinityBannerDismissed',
    JSON.stringify(dismissed)
  );
};

const getAffinityBannerDismissLocalStorageState = (): boolean => {
  const affinityBannerDismissed = localStorage.getItem(
    'simpleSearchAffinityBannerDismissed'
  );
  return affinityBannerDismissed ? JSON.parse(affinityBannerDismissed) : false;
};

const setProductBannerDismissLocalStorageState = (dismissed: boolean) => {
  localStorage.setItem(
    SIMPLE_SEARCH_PRODUCT_BANNER_DISMISSED_KEY,
    JSON.stringify(dismissed)
  );
};

const getProductBannerDismissLocalStorageState = (): boolean => {
  const productBannerDismissed = localStorage.getItem(
    SIMPLE_SEARCH_PRODUCT_BANNER_DISMISSED_KEY
  );
  return productBannerDismissed ? JSON.parse(productBannerDismissed) : false;
};

interface GeneralSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const GeneralSection: React.FC<GeneralSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );
  const [affinityBannerDismissed, setAffinityBannerDismissed] = React.useState(
    getAffinityBannerDismissLocalStorageState()
  );
  const [productBannerDismissed, setProductBannerDismissed] = React.useState(
    getProductBannerDismissLocalStorageState()
  );

  const companyFoundingDate =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE
    ];

  const companyLocation =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION
    ];

  const companyInitializedDate =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE
    ];

  const includeFromCompanyWatchlistIds =
    controlledSearchState?.[
      FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST
    ]?.watchlist_ids;

  const excludeFromCompanyWatchlistIds =
    controlledSearchState?.[
      FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST
    ]?.watchlist_ids;

  const onDismissAffinityBanner = () => {
    setAffinityBannerDismissed(true);
    setAffinityBannerDismissLocalStorageState(true);
  };

  const onDismissProductBanner = () => {
    setProductBannerDismissed(true);
    setProductBannerDismissLocalStorageState(true);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-g90">
        {!productBannerDismissed && (
          <Banner
            text="Semantic and keyword filters have moved to the Product tab"
            showIcon
            type="informative"
            onClose={onDismissProductBanner}
          />
        )}
        <div id="Location" className="w-full">
          <SubSectionHeader>Location</SubSectionHeader>

          <AddLocation
            locationState={companyLocation}
            onValueChange={(newLocationValue) => {
              return onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION,
                  newLocationValue
                )
              );
            }}
          />
        </div>
        <div id="Founding-Date">
          <SubSectionHeader>Founding year</SubSectionHeader>
          <FoundingDateSelect
            selected={companyFoundingDate?.range_value}
            customRangeValue={
              (companyFoundingDate as DateRangeGenericFilterGenerator)
                ?.custom_range
            }
            onChange={(newValue, newCustomValue) => {
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE,
                  newValue !== undefined
                    ? {
                        range_value: newValue as CompanyFoundationDateOptions,
                        custom_range: newCustomValue as [string, string]
                      }
                    : undefined
                )
              );
            }}
          />
        </div>
        <div id="Initialized-Date">
          <SubSectionHeader>Added to Harmonic</SubSectionHeader>
          <ChipWithCustomRange
            dataTestId="Company-Date-Initialized"
            options={CompanyInitializedOrUpdatedMultiselectOptions}
            onValueChange={(newValue) =>
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE,
                  newValue?.value !== undefined
                    ? {
                        range_value:
                          newValue.value as CompanyInitializedOrUpdatedDateOptions,
                        custom_range: formatCustomRange(newValue.customRange)
                      }
                    : undefined
                )
              )
            }
            selectedValue={companyInitializedDate?.range_value}
            disabled={disabled}
            selectedCustomRange={companyInitializedDate?.custom_range}
          />
        </div>
        <SectionDivider />
        <div id="Filter-By-List">
          <SmallSectionHeader>Filter companies by list</SmallSectionHeader>
          {!affinityBannerDismissed && (
            <div className="mb-p70">
              <AffinityBanner onDismiss={onDismissAffinityBanner} />
            </div>
          )}
          <div className={classNames('flex flex-row gap-g60')}>
            <div className="w-1/2">
              <SubSectionHeader>Include lists</SubSectionHeader>
              <ButtonWrapper
                label="Include lists"
                leadingIcon={CirclePlus}
                skipToChildren={
                  (includeFromCompanyWatchlistIds ?? []).length > 0 ?? false
                }
              >
                <ListMultiselect
                  data-testid="Include-Companies-From-List"
                  placeholder="Select company lists to include"
                  mode="company_watchlist"
                  initialFocus={
                    (includeFromCompanyWatchlistIds ?? []).length === 0
                  }
                  onChange={(newOptions) =>
                    onUpdateSearchQuery(
                      getUpdatedQuery(
                        searchQuery,
                        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST,
                        newOptions !== undefined && newOptions.length > 0
                          ? {
                              watchlist_ids: newOptions
                            }
                          : undefined
                      )
                    )
                  }
                  selected={includeFromCompanyWatchlistIds ?? []}
                  separateAffinityLists
                />
              </ButtonWrapper>
            </div>
            <div className="w-1/2">
              <SubSectionHeader>Exclude lists</SubSectionHeader>
              <ButtonWrapper
                label="Exclude lists"
                leadingIcon={CircleMinus}
                skipToChildren={
                  (excludeFromCompanyWatchlistIds ?? []).length > 0 ?? false
                }
              >
                <ListMultiselect
                  data-testid="Exclude-Companies-From-List"
                  placeholder="Select company lists to exclude"
                  mode="company_watchlist"
                  excludeMode
                  initialFocus={
                    (excludeFromCompanyWatchlistIds ?? []).length === 0
                  }
                  onChange={(newOptions) =>
                    onUpdateSearchQuery(
                      getUpdatedQuery(
                        searchQuery,
                        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST,
                        newOptions !== undefined && newOptions.length > 0
                          ? {
                              watchlist_ids: newOptions
                            }
                          : undefined
                      )
                    )
                  }
                  selected={excludeFromCompanyWatchlistIds ?? []}
                  separateAffinityLists
                />
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSection;
