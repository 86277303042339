import { Column, IRowNode } from 'ag-grid-community';
import EmployeeAttribute from 'harmonic-components/PersonAttribute/EmployeeAttribute';
import PersonAttribute from 'harmonic-components/PersonAttribute/PersonAttribute';
import useFlags from 'hooks/useFlags';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { SPLITS } from 'utils/constants';
import { Person } from '../../../../../__generated__/graphql';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { EventLocations } from '../../../../../utils/analytics';
import { NoDataSubRenderer } from '../CellRenderers';

export const PeopleList: React.FC<{
  value: ICompany['person_relationships'];
  column?: Column;
  node?: IRowNode<{
    company?: ICompany;
  }>;
}> = (props) => {
  const headerName = props.column?.getDefinition()?.headerName ?? '';
  const headerId = props.column?.getColId();
  const shouldLinkToLinkedin = headerName.includes('LinkedIn');

  const userConnections = props.node?.data?.company?.user_connections;

  const { enabled: useNetworkMappingNewTooltips } = useFlags(
    SPLITS.networkMappingNewTooltips
  );

  if (!headerId) {
    return null;
  }

  const company = get(props, 'node.data.company');
  const relationshipFieldName = headerId.split('.')[1];

  const personRelationships = useMemo(() => {
    const relevantPersonRelationships: Person[] = (
      get(company, relationshipFieldName) ?? []
    ).filter(
      (person: Person): person is Exclude<typeof person, null> => person != null
    );

    return relevantPersonRelationships.map((personRelationship) => {
      if (personRelationship) {
        return {
          ...personRelationship,
          person: {
            id: personRelationship.id,
            full_name: personRelationship.fullName,
            linkedin_url: personRelationship.socials?.linkedin?.url,
            personal_emails: personRelationship.contact?.emails
          },
          person_company_emails: personRelationship?.contact?.emails
        };
      }
    });
  }, [company?.person_relationships]);

  const team = useMemo(() => {
    const peopleInNetwork =
      userConnections?.flatMap(
        (userConnection) =>
          'urn:harmonic:person:' + userConnection.target_person?.id
      ) ?? [];
    const teamMembers =
      personRelationships?.filter(
        (relationship) => relationship?.person?.full_name
      ) || [];

    return teamMembers
      .map((person) => {
        const teamMemberHarmonicProfileLink = get(person, 'person.id', null)
          ? `/dashboard/person/${get(person, 'person.id')}`
          : undefined;
        const useLinkToLinkedIn =
          shouldLinkToLinkedin && Boolean(get(person, 'person.linkedin_url'));
        return {
          text: person?.person?.full_name ?? '',
          entityUrn: get(person, 'entityUrn') as unknown as string,
          href: useLinkToLinkedIn
            ? get(person, 'person.linkedin_url')
            : teamMemberHarmonicProfileLink,
          openedFromLocation: !useLinkToLinkedIn
            ? EventLocations.GRID_TABLE_TEAM
            : undefined,
          profilePicSrc: get(person, 'profilePictureUrl'),
          isInNetwork: peopleInNetwork.includes(
            get(person, 'entityUrn') as unknown as string
          )
        };
      })
      .filter((listItem) => Boolean(listItem.text))
      .sort((a, b) => {
        // Sort by network status (in network first)
        if (a.isInNetwork && !b.isInNetwork) return -1;
        if (!a.isInNetwork && b.isInNetwork) return 1;
        // If network status is the same, maintain original order
        return 0;
      });
  }, [personRelationships, shouldLinkToLinkedin, userConnections]);

  if (team.length === 0) {
    return <NoDataSubRenderer />;
  }

  if (useNetworkMappingNewTooltips) {
    return (
      <div className="inline-block">
        <TruncatedList height={54}>
          {team.map((person, index) => (
            <EmployeeAttribute
              key={index}
              src={person.profilePicSrc ?? ''}
              href={person.href ?? ''}
              name={person.text}
              isInNetwork={person.isInNetwork}
              entityUrn={person.entityUrn}
            />
          ))}
        </TruncatedList>
      </div>
    );
  }

  return (
    <div className="inline-block">
      <TruncatedList wrapperClassName="-ml-p30" height={54}>
        {team.map((person, index) => (
          <PersonAttribute
            key={index}
            src={person.profilePicSrc ?? ''}
            href={person.href ?? ''}
            name={person.text ?? ''}
          />
        ))}
      </TruncatedList>
    </div>
  );
};
