import { HarmonicIcon } from '../type';

const FundingTotalIcon: HarmonicIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003ZM10.6251 4.79199C10.6251 4.44681 10.3453 4.16699 10.0001 4.16699C9.65491 4.16699 9.37508 4.44681 9.37508 4.79199V5.67153C8.91034 5.74362 8.47584 5.90474 8.11882 6.18621C7.58219 6.60929 7.30268 7.23962 7.30268 8.00101C7.30268 8.88749 7.66293 9.48952 8.21187 9.89381C8.65367 10.2192 9.21388 10.4065 9.66226 10.5564C9.7093 10.5722 9.75511 10.5875 9.79943 10.6025L9.90278 10.6375C10.4007 10.8058 10.7719 10.9313 11.047 11.1339C11.28 11.3055 11.4475 11.5359 11.4475 12.0202C11.4475 12.4553 11.3016 12.7003 11.1074 12.8534C10.8903 13.0246 10.528 13.1458 10.0001 13.1458C9.33022 13.1458 8.90152 12.84 8.68798 12.4567C8.51999 12.1552 8.13936 12.0469 7.83782 12.2149C7.53627 12.3829 7.428 12.7635 7.59599 13.065C7.95347 13.7067 8.57648 14.1745 9.37508 14.335V15.2087C9.37508 15.5538 9.65491 15.8337 10.0001 15.8337C10.3453 15.8337 10.6251 15.5538 10.6251 15.2087V14.3497C11.0898 14.2776 11.5243 14.1164 11.8813 13.835C12.418 13.4119 12.6975 12.7816 12.6975 12.0202C12.6975 11.1337 12.3372 10.5317 11.7883 10.1274C11.3465 9.80199 10.7863 9.61467 10.3379 9.46475C10.2909 9.44902 10.2451 9.4337 10.2007 9.41868L10.0974 9.38371C9.59948 9.21541 9.22822 9.08992 8.95314 8.88732C8.72013 8.71571 8.55268 8.48533 8.55268 8.00101C8.55268 7.56587 8.69856 7.32091 8.89273 7.16783C9.1099 6.99661 9.47214 6.87543 10.0001 6.87543C10.6699 6.87543 11.0986 7.18115 11.3122 7.56447C11.4802 7.86602 11.8608 7.97429 12.1624 7.8063C12.4639 7.63831 12.5722 7.25768 12.4042 6.95614C12.0467 6.31446 11.4237 5.84669 10.6251 5.68615V4.79199Z"
      fill="#7C8598"
    />
  </svg>
);
export default FundingTotalIcon;
