import React from 'react';
import { IconProps } from './type';

const CustomFieldKanbanHeaderIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00026 1.23334C8.33163 1.23337 8.60024 1.50202 8.60021 1.83339L8.6 4.16672C8.59998 4.49809 8.33132 4.7667 7.99995 4.76667C7.66858 4.76664 7.39998 4.49799 7.4 4.16662L7.40021 1.83329C7.40024 1.50191 7.66889 1.23331 8.00026 1.23334Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.21543 3.21507C3.44976 2.98077 3.82966 2.98081 4.06396 3.21514L5.71373 4.8652C5.94802 5.09954 5.94799 5.47943 5.71365 5.71373C5.47932 5.94802 5.09942 5.94799 4.86513 5.71365L3.21535 4.0636C2.98106 3.82926 2.98109 3.44936 3.21543 3.21507Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.7851 3.21558C13.0194 3.44989 13.0194 3.82979 12.7851 4.06411L11.1352 5.71402C10.9009 5.94834 10.521 5.94834 10.2866 5.71402C10.0523 5.47971 10.0523 5.09981 10.2866 4.86549L11.9366 3.21558C12.1709 2.98126 12.5508 2.98126 12.7851 3.21558Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.23334 8.00047C1.23334 7.6691 1.50197 7.40047 1.83334 7.40047H4.16667C4.49804 7.40047 4.76667 7.6691 4.76667 8.00047C4.76667 8.33184 4.49804 8.60047 4.16667 8.60047H1.83334C1.50197 8.60047 1.23334 8.33184 1.23334 8.00047Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.2333 8.00047C11.2333 7.6691 11.502 7.40047 11.8333 7.40047H14.1667C14.498 7.40047 14.7667 7.6691 14.7667 8.00047C14.7667 8.33184 14.498 8.60047 14.1667 8.60047H11.8333C11.502 8.60047 11.2333 8.33184 11.2333 8.00047Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.2865 10.2862C10.5208 10.0519 10.9007 10.0519 11.135 10.2862L12.7849 11.9361C13.0192 12.1704 13.0192 12.5503 12.7849 12.7846C12.5506 13.0189 12.1707 13.0189 11.9364 12.7846L10.2865 11.1347C10.0521 10.9004 10.0521 10.5205 10.2865 10.2862Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.71402 10.2866C5.94834 10.521 5.94834 10.9009 5.71402 11.1352L4.06411 12.7851C3.82979 13.0194 3.44989 13.0194 3.21558 12.7851C2.98126 12.5508 2.98126 12.1709 3.21558 11.9366L4.86549 10.2866C5.09981 10.0523 5.47971 10.0523 5.71402 10.2866Z"
        fill="#9FA63A"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.00021 11.2333C8.33158 11.2333 8.60021 11.502 8.60021 11.8333V14.1667C8.60021 14.498 8.33158 14.7667 8.00021 14.7667C7.66884 14.7667 7.40021 14.498 7.40021 14.1667V11.8333C7.40021 11.502 7.66884 11.2333 8.00021 11.2333Z"
        fill="#9FA63A"
      />
    </svg>
  );
};

export default CustomFieldKanbanHeaderIcon;
