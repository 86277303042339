import { useQuery } from '@apollo/client';
import {
  GetCompaniesViewRecordsQuery,
  GetCompaniesViewRecordsQueryVariables,
  SearchType
} from '__generated__/graphql';
import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import InternalLink from 'components/common/InternalLink';
import {
  CompanySearchGridContext,
  CompanyWatchlistGridContext
} from 'components/common/ResultsWrapper/GridResultsView';
import Avatar from 'harmonic-components/Avatar/Avatar';
import useDashboardLocation, {
  DashboardLocation
} from 'hooks/useDashboardLocation';
import { ICompany } from 'interfaces/DataModel/Company';
import { get, uniqBy } from 'lodash';
import { getCompaniesViewRecords } from 'queries/getCompanyViewRecords';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useTableStore from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import analytics, { CustomTrackEvent, EventLocations } from 'utils/analytics';
import { shouldLinkCompanyId } from 'utils/company';
import { LoadingCellRenderer, useStyles } from '../CellRenderers';
import { CheckboxSubRenderer } from './Checkbox';
import UnviewedNewDot from './UnviewedNewDot';

export const CompanyNameWithCheckboxCellRenderer: React.FC<
  ICellRendererParams
> = (props: ICellRendererParams) => {
  const pageTitle = useStore((state) => state.pageTitle);
  const editStoreData = useStore((state) => state.editStoreData);
  const company: ICompany = get(props.node.data, 'company');
  const currentUrl: string = window.location.pathname;
  const companyId = get(company, 'id', undefined);
  const companyName = get(company, 'name', '');
  const userConnections = get(company, 'user_connections', []);
  const usersInNetwork = uniqBy(userConnections, 'user.email');

  const { urn, location: dashboardLocation } = useDashboardLocation();
  const isSearchPage = dashboardLocation == DashboardLocation.COMPANY_SEARCH;
  const classes = useStyles();
  const location = useLocation();
  const addSelectedRowIds = useTableStore((state) => state.addSelectedRowIds);
  const removeSelectedRowIds = useTableStore(
    (state) => state.removeSelectedRowIds
  );
  const addNetNewRowIds = useTableStore((state) => state.addSelectedNetNewIds);
  const removeSelectedNetNewIds = useTableStore(
    (state) => state.removeSelectedNetNewIds
  );
  const selectedRowIds = useTableStore((state) => state.selectedRowIds);
  const selectAllStateEnabled = useTableStore(
    (state) => state.selectAllStateEnabled
  );

  const params = new URLSearchParams(window.location.search || '');

  const logoUrl = get(company, 'logo_url');

  // net new stuff
  const { hasNetNew, onClearNetNewByIds } = useNetNewCounts();
  const countExists = hasNetNew(urn);
  const netNewId: number | undefined = get(props.data, 'netNewId');
  const isNetNew: boolean = useMemo(() => {
    return !!(countExists && netNewId);
  }, [countExists, netNewId]);

  const context = props.context as
    | CompanyWatchlistGridContext
    | CompanySearchGridContext
    | undefined;
  const companyViewRecordResponse = useQuery<
    GetCompaniesViewRecordsQuery,
    GetCompaniesViewRecordsQueryVariables
  >(getCompaniesViewRecords, {
    variables: { ids: [companyId as number], extended: false },
    fetchPolicy: 'cache-only',
    skip: !companyId
  });

  if (!companyId) return <LoadingCellRenderer />;

  const isCheckboxChecked =
    selectedRowIds.includes(companyId) || selectAllStateEnabled;
  const isExplorePage = isSearchPage && (urn === '' || !urn);

  // Since we don't have a boolean to check viewed state, we need to differentiate
  // between "data isn't ready yet in cache" and "no view record exists". If the data
  // response isn't available at all, we can assume it hasn't loaded in cache yet.
  // Unfortuntately companyViewRecordResponse.loading will return false before it's actually
  // done loading, so we can't depend on that.
  const viewRecordLoading = !companyViewRecordResponse.data;
  const companyViewRecord =
    companyViewRecordResponse.data?.getCompaniesByIds?.[0]?.viewRecord;

  const onCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.node.setSelected(event.target.checked);
    if (event.target.checked) {
      addSelectedRowIds([companyId]);
      netNewId && addNetNewRowIds([netNewId]);
    } else {
      removeSelectedRowIds([companyId]);
      netNewId && removeSelectedNetNewIds([netNewId]);
    }
  };
  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center gap-g50 w-full">
        {!currentUrl.includes('/person/') && (
          <CheckboxSubRenderer
            onCheckboxClick={onCheckboxClick}
            isChecked={isCheckboxChecked}
          />
        )}
        <div className="flex w-full flex-row justify-between items-center">
          {shouldLinkCompanyId(companyId) ? (
            <InternalLink
              onMouseDown={(e) => {
                e.stopPropagation();
                editStoreData(
                  'previousPageTitle',
                  isExplorePage ? 'Explore' : pageTitle
                );
                if (isNetNew && urn && netNewId)
                  onClearNetNewByIds({
                    ids: [netNewId],
                    savedSearchUrn: urn,
                    type: SearchType.COMPANIES_LIST
                  });
                // We explicitly mark viewed so that people opening in new tab
                // will immediately see that company marked as viewed.
                if (!viewRecordLoading && !companyViewRecord) {
                  context?.setViewed([companyId]);
                }
                analytics.trackCustomEvent({
                  event: CustomTrackEvent.COMPANY_PROFILE_CLICK,
                  properties: {
                    companyId,
                    openedFromLocation: EventLocations.GRID_TABLE
                  }
                });
              }}
              className={`${classes['internal-link']} typography-label text-content-strong`}
              to={{
                pathname: `/dashboard/company/${companyId}`
              }}
              data-testid="CompanyNameCellRenderer-Name"
              state={{
                from: location.pathname,
                params: params.toString()
              }}
              openedFromLocation={
                EventLocations.GRID_TABLE_COMPANY_NAME_COMPANY_SEARCH
              }
            >
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Avatar
                    src={logoUrl}
                    kind="logo"
                    size="medium"
                    alt="company-logo"
                  />
                </div>
                <div
                  data-testid="CompanyNameCellRenderer-Name"
                  className="line-clamp-1 pl-p30"
                >
                  <p
                    className={classNames(
                      'typography-label text-content-strong'
                    )}
                  >
                    {companyName}
                  </p>
                </div>
              </div>
            </InternalLink>
          ) : (
            <span>{companyName}</span>
          )}
        </div>
        <UnviewedNewDot
          entityId={companyId}
          isUnviewed={!viewRecordLoading && !companyViewRecord}
          context={context}
          netNewId={isNetNew ? netNewId : undefined}
        />
      </div>
    </div>
  );
};
