import {
  GetAllListsQuery,
  GetUseFetchSavedSearches_WithUserOptionsQuery,
  Owner,
  PeopleWatchlistType,
  SearchType,
  User,
  UserWatchlistType
} from '__generated__/graphql';
import { PostSortRowsParams } from 'ag-grid-community';
import { EntityType } from 'hooks/useDashboardLocation';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { isNil } from 'lodash';
import {
  CollectionType,
  CreatorEnum,
  CurrentTabEnum,
  IGridFilterSettings,
  VisibilityEnum
} from './types';
import { initialGridFilterState } from './useCollectionsStore';

type RowData = ReturnType<typeof getFilteredData>['all'][0];

export const SPECIAL_LIST_TYPES = [
  UserWatchlistType.USER_FAVORITED_ENTITIES,
  UserWatchlistType.USER_HIDDEN_ENTITIES,
  PeopleWatchlistType.TEAM_CONNECTIONS,
  PeopleWatchlistType.USER_CONNECTIONS
];

const CREATED_BY_HARMONIC = {
  name: 'Harmonic',
  email: undefined,
  entityUrn: undefined
};

interface IGetFilteredData {
  data: GetAllListsQuery | GetUseFetchSavedSearches_WithUserOptionsQuery;
  currentUser: string;
  currentTab: CurrentTabEnum;
  gridFilterSettings: IGridFilterSettings;
  isList: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Condition = (item: any) => boolean;

// Type guard so TypeScript doesn't complain
export function isNotNull<T>(value: T | null | undefined): value is T {
  return !isNil(value);
}

type SimplifiedUser = {
  __typename?: 'User';
  entityUrn?: string | null;
  email?: string | null;
  name?: string | null;
};
type SimplifiedCustomer = {
  __typename?: 'Customer';
  identifier?: string | null;
  name?: string | null;
};
type SimplifiedOwner = SimplifiedUser | SimplifiedCustomer | null | undefined;

export const isOwnerThisUser = (
  owner?: SimplifiedOwner,
  userUrn?: string
): boolean => {
  return !!(
    owner?.__typename === 'User' &&
    userUrn &&
    owner.entityUrn === userUrn
  );
};

// Type guard to check if an object has `owner`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasOwner(entity: any): entity is { owner: Owner } {
  return entity && 'owner' in entity && entity.owner?.entityUrn !== undefined;
}

// Type guard to check if an object has `creator`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasCreator(entity: any): entity is { creator: User } {
  return (
    entity && 'creator' in entity && entity.creator?.entityUrn !== undefined
  );
}

export const getOwnerEmail = (owner?: SimplifiedOwner): string | null => {
  return owner?.__typename === 'User' ? owner.email || null : null;
};

export const getOwnerName = (owner?: SimplifiedOwner): string | null => {
  return owner?.name || null;
};

// Common conditions for both lists and saved searches
const getCommonConditions = (
  gridFilterSettings: IGridFilterSettings,
  currentUser: string
): Condition[] => {
  const conditions: Condition[] = [];

  if (gridFilterSettings.creator !== CreatorEnum.anyone) {
    conditions.push(
      (item: RowData) =>
        !!item?.creator &&
        'entityUrn' in item.creator &&
        item?.creator?.entityUrn === currentUser
    );
  }

  if (gridFilterSettings.visibility !== VisibilityEnum.any) {
    conditions.push((item) =>
      gridFilterSettings.visibility === VisibilityEnum.private
        ? !item.sharedWithTeam
        : item.sharedWithTeam
    );
  }

  return conditions;
};

// List-specific filtering and mapping functions
const filterListData = (
  listData: GetAllListsQuery,
  currentTab: CurrentTabEnum,
  conditions: Condition[]
) => {
  const currentTabData = [
    ...(listData.getCompanyWatchlistsForTeam || []),
    ...(listData.getPeopleWatchlistsForTeam || [])
  ];

  const data = currentTabData
    .filter(isNotNull)
    .map((item) => {
      // check if the list is a company watchlist or a people watchlist else throw an error
      if (
        !item.entityUrn.includes('company_watchlist') &&
        !item.entityUrn.includes('people_watchlist')
      ) {
        throw new Error('Invalid list type');
      }

      const entityType = item.entityUrn.includes('company_watchlist')
        ? EntityListType.COMPANY_WATCHLIST
        : EntityListType.PEOPLE_WATCHLIST;

      const isCreatedByHarmonic = SPECIAL_LIST_TYPES.includes(
        item.userWatchlistType as UserWatchlistType
      );

      return {
        ...item,
        url: getUrlFromEntityListType(entityType, item.entityUrn),
        entityType: entityType,
        creator: isCreatedByHarmonic ? CREATED_BY_HARMONIC : item.owner
      };
    })
    .filter((item) => conditions.every((condition) => condition(item)));
  return {
    all: data,
    companies: data.filter(
      (item) => item.entityType === EntityListType.COMPANY_WATCHLIST
    ),
    people: data.filter(
      (item) => item.entityType === EntityListType.PEOPLE_WATCHLIST
    )
  };
};

// Saved search-specific filtering and mapping functions
const filterSavedSearchData = (
  savedSearchData: GetUseFetchSavedSearches_WithUserOptionsQuery,
  currentTab: CurrentTabEnum,
  conditions: Condition[]
) => {
  const data = (savedSearchData.getSavedSearchesForTeam || [])
    .filter(isNotNull)
    .filter(
      (item) =>
        item.type === SearchType.COMPANIES_LIST ||
        item.type === SearchType.PERSONS
    )
    .map((item) => {
      const entityListType =
        item.type === SearchType.COMPANIES_LIST
          ? EntityListType.COMPANY_SAVED_SEARCH
          : EntityListType.PEOPLE_SAVED_SEARCH;

      return {
        ...item,
        sharedWithTeam: !item.isPrivate,
        url: getUrlFromEntityListType(entityListType, item.entityUrn),
        entityType: entityListType
      };
    })
    .filter((item) => conditions.every((condition) => condition(item)));
  return {
    all: data,
    companies: data.filter(
      (item) => item.entityType === EntityListType.COMPANY_SAVED_SEARCH
    ),
    people: data.filter(
      (item) => item.entityType === EntityListType.PEOPLE_SAVED_SEARCH
    )
  };
};

export const getFilteredData = ({
  data,
  currentUser,
  currentTab,
  gridFilterSettings,
  isList
}: IGetFilteredData) => {
  if (!data) {
    return { all: [], companies: [], people: [] };
  }

  const conditions = getCommonConditions(gridFilterSettings, currentUser);

  if (isList) {
    const listData = data as GetAllListsQuery;
    return filterListData(listData, currentTab, conditions);
  } else {
    const savedSearchData =
      data as GetUseFetchSavedSearches_WithUserOptionsQuery;
    return filterSavedSearchData(savedSearchData, currentTab, conditions);
  }
};

export const getCollectionTypeFromEntityListType = new Map<
  EntityListType,
  CollectionType
>([
  [EntityListType.COMPANY_WATCHLIST, CollectionType.LISTS],
  [EntityListType.PEOPLE_WATCHLIST, CollectionType.LISTS],
  [EntityListType.COMPANY_SAVED_SEARCH, CollectionType.SEARCHES],
  [EntityListType.PEOPLE_SAVED_SEARCH, CollectionType.SEARCHES]
]);

export const getEntityTypeFromEntityListType = new Map<
  EntityListType,
  EntityType
>([
  [EntityListType.COMPANY_WATCHLIST, EntityType.COMPANY],
  [EntityListType.PEOPLE_WATCHLIST, EntityType.PERSON],
  [EntityListType.COMPANY_SAVED_SEARCH, EntityType.COMPANY],
  [EntityListType.PEOPLE_SAVED_SEARCH, EntityType.PERSON]
]);

export const getActiveFiltersCount = (
  gridFilterSettings: IGridFilterSettings
) => {
  const initialFilterSettings = initialGridFilterState.gridFilterSettings;
  return Object.keys(gridFilterSettings).reduce(
    (acc, key) =>
      gridFilterSettings[key as keyof IGridFilterSettings] !==
      initialFilterSettings[key as keyof IGridFilterSettings]
        ? acc + 1
        : acc,
    0
  );
};

export const getFavoritedLists = (data?: GetAllListsQuery) => {
  if (!data) {
    return [];
  }
  const companyLists = data.getCompanyWatchlistsForTeam || [];
  const peopleLists = data.getPeopleWatchlistsForTeam || [];

  const combinedlists = [
    ...companyLists.map((item) => ({
      ...item,
      entityListType: EntityListType.COMPANY_WATCHLIST
    })),
    ...peopleLists.map((item) => ({
      ...item,
      entityListType: EntityListType.PEOPLE_WATCHLIST
    }))
  ].filter(isNotNull);

  return combinedlists.filter((list) => list.userOptions?.isPinned);
};

export const getUrlFromEntityListType = (
  entityType: EntityListType,
  entityUrn: string
) => {
  if (!entityUrn || !entityType) {
    throw new Error('entityUrn and entityType are required');
  }
  switch (entityType) {
    case EntityListType.COMPANY_WATCHLIST:
      return `/dashboard/watchlist/${entityUrn}`;
    case EntityListType.PEOPLE_WATCHLIST:
      return `/dashboard/people_watchlist/${entityUrn}`;
    case EntityListType.COMPANY_SAVED_SEARCH:
      return `/dashboard/companies/${entityUrn}`;
    case EntityListType.PEOPLE_SAVED_SEARCH:
      return `/dashboard/people/${entityUrn}`;
  }
};

export const getInputVariableByType = ({
  type,
  id
}: {
  type: EntityListType;
  id: string;
}) => {
  const entityType = getEntityTypeFromEntityListType.get(type);

  return entityType === EntityType.COMPANY
    ? { companyCollectionId: id }
    : { peopleWatchlistId: id };
};

/**
 * sorts net new searches and special watchlist types to the top of the grid view
 * if no sort is currently applied
 */
export const sortRowsToTop = (
  params: PostSortRowsParams,
  hasNetNew: (entityUrn: string) => boolean,
  sortModel: { sort?: string | null }[] | undefined
) => {
  // only if no sort is currently applied then we bring it to the top
  if (sortModel?.some((sort) => sort.sort)) return;

  const rowNodes = params.nodes;
  let nextInsertPos = 0;
  for (let i = 0; i < rowNodes.length; i++) {
    const userWatchlistType = rowNodes[i].data?.userWatchlistType;

    if (
      SPECIAL_LIST_TYPES.includes(userWatchlistType) ||
      hasNetNew(rowNodes[i]?.data?.entityUrn)
    ) {
      rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
      nextInsertPos++;
    }
  }
};
