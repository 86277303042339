import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import AddLocation from '../Common/AddLocation';
import { SubSectionHeader } from '../Common/Typography';

interface GeneralLocationSubSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const GeneralLocationSubSection: React.FC<GeneralLocationSubSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const peopleLocation =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION
    ];
  return (
    <div data-testid="Location-Section" className="w-full">
      <SubSectionHeader>Location</SubSectionHeader>

      <AddLocation
        locationState={peopleLocation}
        onValueChange={(newLocationValue) => {
          return onUpdateSearchQuery(
            getUpdatedQuery(
              searchQuery,
              FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION,
              newLocationValue
            )
          );
        }}
      />
    </div>
  );
};

export default GeneralLocationSubSection;
