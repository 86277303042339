import { HarmonicIcon } from '../type';

const RoundIconStrategic: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V6C2 5.63333 2.13056 5.31944 2.39167 5.05833C2.65278 4.79722 2.96667 4.66667 3.33333 4.66667H4.66667V3.33333C4.66667 2.96667 4.79722 2.65278 5.05833 2.39167C5.31944 2.13056 5.63333 2 6 2H10C10.3667 2 10.6806 2.13056 10.9417 2.39167C11.2028 2.65278 11.3333 2.96667 11.3333 3.33333V7.33333H12.6667C13.0333 7.33333 13.3472 7.46389 13.6083 7.725C13.8694 7.98611 14 8.3 14 8.66667V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H8.66667V11.3333H7.33333V14H3.33333ZM3.33333 12.6667H4.66667V11.3333H3.33333V12.6667ZM3.33333 10H4.66667V8.66667H3.33333V10ZM3.33333 7.33333H4.66667V6H3.33333V7.33333ZM6 10H7.33333V8.66667H6V10ZM6 7.33333H7.33333V6H6V7.33333ZM6 4.66667H7.33333V3.33333H6V4.66667ZM8.66667 10H10V8.66667H8.66667V10ZM8.66667 7.33333H10V6H8.66667V7.33333ZM8.66667 4.66667H10V3.33333H8.66667V4.66667ZM11.3333 12.6667H12.6667V11.3333H11.3333V12.6667ZM11.3333 10H12.6667V8.66667H11.3333V10Z"
      fill="#57A63A"
    />
  </svg>
);

export default RoundIconStrategic;
