import classNames from 'classnames';

interface FundingMetricCardProps {
  dataTestId?: string;
  value: string | number | React.ReactNode;
  label: string;
  icon: React.FC<{ className?: string }>;
}

export const FundingMetricCard: React.FC<FundingMetricCardProps> = ({
  value,
  label,
  icon
}) => {
  const Icon = icon;
  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-5 sm:col-span-3 flex items-center">
          <div className="flex gap-2 items-center">
            <Icon className="flex-shrink-0 w-4 h-4" />
            <p className={classNames('text-sm font-normal truncate')}>
              {label}
            </p>
          </div>
        </div>
        <div className="col-span-7 sm:col-span-9 flex items-center text-sm font-medium">
          {value}
        </div>
      </div>
      <hr className="border-solid border-b-border-strong" />
    </>
  );
};
