import { makeAutoObservable, runInAction } from 'mobx';
import {
  SearchCompaniesQuery,
  SearchCompaniesQueryVariables,
  SortInfo
} from '../../__generated__/graphql';
import client from '../../config/client';
import {
  INITIAL_COMPANY_SEARCH_SORT_QUERY,
  INITIAL_SEARCH_FILTER_GROUP,
  INITIAL_SEARCH_MODEL,
  ISearchModel,
  SearchFilterGroupQuery
} from '../../interfaces/SearchModel/Search';
import { SearchStateFilter } from '../../interfaces/SearchV2';
import { COMPANY_SEARCH_QUERY } from '../../queries/companySearch';
import { GRID_INITIAL_PAGE_SIZE } from '../../utils/constants';
import { transformSearchModelForApiv2 } from '../../utils/search';
import { camelize } from '../../utils/utilities';
import useFilterStore from '../filterStore';
import { RootStore } from './rootStore';

class CompaniesExploreStore {
  rootStore: RootStore;
  searchModel: ISearchModel;
  totalCount?: number = undefined;
  error: Error | null = null;
  subscription: { unsubscribe: () => void } | null = null;

  /**
   * Initializes the companies explore store.
   * @param rootStore - The root store instance.
   */
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.searchModel = INITIAL_SEARCH_MODEL;
    this.observeZustandStore();
    makeAutoObservable(this);
  }

  updateSearchModel({
    searchFilterGroupQuery,
    pageSort
  }: {
    searchFilterGroupQuery?: SearchFilterGroupQuery;
    pageSort?: SortInfo;
  }) {
    this.searchModel = {
      filter_group:
        searchFilterGroupQuery?.filter_group ?? INITIAL_SEARCH_FILTER_GROUP,
      controlled_filter_group:
        searchFilterGroupQuery?.controlled_filter_group ??
        INITIAL_SEARCH_FILTER_GROUP,
      sort: pageSort?.sortField
        ? [
            {
              sort_field: pageSort.sortField,
              descending: pageSort.descending ?? false
            }
          ]
        : INITIAL_COMPANY_SEARCH_SORT_QUERY,
      pagination: {
        page_size: GRID_INITIAL_PAGE_SIZE,
        start: 0
      }
    };
    this.observeApolloQuery();
  }

  /**
   * Observes the Zustand store for changes in the companies dashboard filters and sort.
   * Updates the search model accordingly.
   */
  observeZustandStore() {
    const { companiesDashboardFilters, companyExplorePageSort } =
      useFilterStore.getState();

    this.updateSearchModel({
      searchFilterGroupQuery: companiesDashboardFilters,
      pageSort: companyExplorePageSort
    });

    useFilterStore.subscribe(
      ({ companiesDashboardFilters, companyExplorePageSort }) => {
        runInAction(() => {
          this.updateSearchModel({
            searchFilterGroupQuery: companiesDashboardFilters,
            pageSort: companyExplorePageSort
          });
        });
      }
    );
  }

  /**
   * Observes the Apollo query for changes in the search results.
   * Updates the data and error states accordingly.
   */
  observeApolloQuery() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const query = {
      ...camelize(transformSearchModelForApiv2(this.searchModel)),
      filterGroup:
        this.searchModel.filterGroup ?? camelize(INITIAL_SEARCH_FILTER_GROUP)
    };

    const observableQuery = client.watchQuery<
      SearchCompaniesQuery,
      SearchCompaniesQueryVariables
    >({
      query: COMPANY_SEARCH_QUERY,
      variables: {
        query,
        first: GRID_INITIAL_PAGE_SIZE,
        after: null,
        state: SearchStateFilter.NONE,
        skipExtended: true
      },
      fetchPolicy: 'cache-only',
      returnPartialData: true
    });

    this.subscription = observableQuery.subscribe({
      next: (result) => {
        runInAction(() => {
          this.totalCount = result.data?.searchCompanies?.totalCount;
        });
      },
      error: (err) => {
        runInAction(() => {
          this.error = err;
        });
      }
    });
  }
}

export default CompaniesExploreStore;
