import { ICellRendererParams } from 'ag-grid-community';
import {
  BellFilledIcon,
  CompanyIcon,
  ListIcon,
  PersonIcon,
  SavedSearchIcon
} from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import { LoadingCellRenderer } from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import usePeopleWatchlistEnabledDigests from 'hooks/digests/usePeopleWatchlistEnabledDigests';
import { EntityType } from 'hooks/useDashboardLocation';
import useSavedSearchEnabledSubscriptions from 'hooks/useSavedSearchEnabledSubscriptions';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { Link } from 'react-router-dom';
import { LOADING_ROW_ID } from 'utils/constants';
import useNetNewCounts from '../Searches/NetNew/useNetNewCounts';
import { CollectionType } from '../types';
import {
  getCollectionTypeFromEntityListType,
  getEntityTypeFromEntityListType
} from '../utils';
import Favorites from './Favorites';

const EntityLeadingIcon = ({ entityType }: { entityType: EntityListType }) => {
  const type = getCollectionTypeFromEntityListType.get(entityType);

  return (
    <div className="w-5 h-5 rounded-br20 bg-surface-sentiment-neutral flex items-center justify-center">
      {type === CollectionType.LISTS ? (
        <ListIcon className="text-content-default w-4 h-4" />
      ) : (
        <SavedSearchIcon className="text-content-default w-4 h-4" />
      )}
    </div>
  );
};

const EntityPostfixIcon = ({ entityType }: { entityType: EntityListType }) => {
  const type = getEntityTypeFromEntityListType.get(entityType);

  return (
    <div className="flex items-center justify-center">
      {type === EntityType.PERSON ? (
        <Badge
          label="People"
          leadingIcon={PersonIcon}
          color="expressive-5"
          intensity="subtle"
        />
      ) : (
        <Badge
          label="Companies"
          leadingIcon={CompanyIcon}
          color="expressive-22"
          intensity="subtle"
        />
      )}
    </div>
  );
};

const NetNewRowBadge = ({ savedSearchUrn }: { savedSearchUrn: string }) => {
  const { hasNetNew } = useNetNewCounts();
  if (!hasNetNew(savedSearchUrn)) return null;

  return (
    <Badge label="New results!" intensity="subtle" color="expressive-37" />
  );
};

const NameCellComponent = (rowData: ICellRendererParams) => {
  const { hasSavedSearchSubscription } = useSavedSearchEnabledSubscriptions();
  const { hasEnabledPeopleWatchlistDigest } =
    usePeopleWatchlistEnabledDigests();

  if (rowData.data.id == LOADING_ROW_ID) {
    return <LoadingCellRenderer />;
  }
  return (
    <div className="typography-label-default-default text-content-strong p-p60">
      <Link to={rowData.data.url} className="flex flex-row gap-g30">
        <EntityLeadingIcon entityType={rowData.data.entityType} />
        <span className="truncate min-w-[1ch]">{rowData.data.name}</span>
        <EntityPostfixIcon entityType={rowData.data.entityType} />
        {(rowData.data.entityType === EntityListType.COMPANY_SAVED_SEARCH ||
          rowData.data.entityType === EntityListType.PEOPLE_SAVED_SEARCH) && (
          <NetNewRowBadge savedSearchUrn={rowData.data.entityUrn} />
        )}
        {(hasSavedSearchSubscription(rowData?.data?.entityUrn) ||
          hasEnabledPeopleWatchlistDigest(rowData?.data?.entityUrn)) && (
          <BellFilledIcon className="self-center text-int-fill-net-new-selected-enabled cursor-default min-w-4" />
        )}
        <Favorites
          isFavourited={rowData.data?.userOptions?.isPinned}
          id={rowData.data.id}
          type={rowData.data.entityType}
        />
      </Link>
    </div>
  );
};

export default NameCellComponent;
