import { DocumentNode, useMutation } from '@apollo/client';
import {
  CompanyListNamedView,
  CompanyListNamedViewUpsertInput,
  GetCompanyWatchlistQuery,
  GetPeopleWatchlistQuery,
  ListNamedViewDisplayType,
  PersonListNamedView,
  PersonListNamedViewUpsertInput,
  UpsertCompanyWatchlistNamedViewMutationVariables,
  UpsertPeopleWatchlistNamedViewMutationVariables
} from '__generated__/graphql';
import { useCallback } from 'react';
import { setSelectedNamedViewIdQueryParam } from 'utils/namedViews';
import { displayToast } from 'utils/toasts';
import { useLocalSearchState } from './useAppState';

type GenericNamedView = CompanyListNamedView | PersonListNamedView;

export type GenericWatchlist =
  | GetCompanyWatchlistQuery['getCompanyWatchlistByIdOrUrn']
  | GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn'];
export type GenericNamedViewUpsertInput =
  | CompanyListNamedViewUpsertInput
  | PersonListNamedViewUpsertInput;
type GenericUpsertMutationVariables =
  | UpsertCompanyWatchlistNamedViewMutationVariables
  | UpsertPeopleWatchlistNamedViewMutationVariables;

type UseUpsertWatchlistNamedViewsProps<W extends GenericWatchlist> = {
  upsertMutation: DocumentNode;
  watchlistFragment: DocumentNode;
  watchlistCacheIdName: string;
  watchlistFragmentName: string;
  namedViewCacheIdName: string;
  backendResponseKey: string;
  customFieldCacheIdName?: string;
  getSelectedNamedViewIndex: (
    watchlist: W,
    selectedNamedView: Record<string, string>
  ) => number;
  watchlist: W;
  selectedNamedView: Record<string, string>;
  gridViewTypeDefaultColumns?: string[];
  kanbanViewTypeDefaultColumns?: string[];
};

type UseUpsertWatchlistNamedViewsResults<
  T extends CompanyListNamedView | PersonListNamedView
> = {
  loading: boolean;
  upsertCurrentSelectedNamedView: ({
    variables,
    skipUpsertLocalSearch
  }: {
    variables: GenericNamedViewUpsertInput;
    skipUpsertLocalSearch?: boolean;
  }) => void;
  upsertNamedView: ({
    variables,
    namedViewUrn,
    watchlistUrn,
    skipUpsertLocalSearch
  }: {
    variables: GenericNamedViewUpsertInput;
    namedViewUrn: string;
    watchlistUrn: string;
    skipUpsertLocalSearch?: boolean;
  }) => void;
  createNamedView: ({
    variables,
    watchlistUrn
  }: {
    variables: GenericNamedViewUpsertInput;
    watchlistUrn: string;
  }) => Promise<T | undefined>;
  duplicateNamedView: (urn: string) => Promise<T | undefined>;
  removeColumnFromCurrentNamedView: (columnUrn: string) => void;
};

const useUpsertWatchlistNamedViews = <
  T extends CompanyListNamedView | PersonListNamedView,
  W extends GenericWatchlist
>({
  upsertMutation,
  watchlistFragment,
  getSelectedNamedViewIndex,
  namedViewCacheIdName,
  watchlistFragmentName,
  backendResponseKey,
  watchlistCacheIdName,
  watchlist,
  customFieldCacheIdName,
  selectedNamedView,
  kanbanViewTypeDefaultColumns = [],
  gridViewTypeDefaultColumns = []
}: UseUpsertWatchlistNamedViewsProps<W>): UseUpsertWatchlistNamedViewsResults<T> => {
  const { updateLocalSearch } = useLocalSearchState();

  const [upsertWatchlistNamedView, { loading }] = useMutation<
    { [key: string]: T },
    GenericUpsertMutationVariables
  >(upsertMutation);

  const upsertNamedView = async ({
    variables,
    namedViewUrn,
    watchlistUrn,
    skipUpsertLocalSearch = false
  }: {
    variables: GenericNamedViewUpsertInput;
    namedViewUrn: string;
    watchlistUrn: string;
    skipUpsertLocalSearch?: boolean;
  }) => {
    const selectedNamedViewIndex =
      watchlist?.namedViews?.findIndex(
        (namedView) => namedView.entityUrn === namedViewUrn
      ) ?? 0;

    const namedViewsId = watchlist?.namedViews?.[selectedNamedViewIndex]?.id;
    let modifiedVariables = variables;
    const groupByFieldUrn =
      (variables as CompanyListNamedViewUpsertInput)?.groupByField ??
      (watchlist?.namedViews?.[selectedNamedViewIndex] as CompanyListNamedView)
        ?.groupByField?.urn;
    let visibleColumns =
      variables.visibleColumns ??
      watchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns ??
      null;

    if (variables.displayType && !visibleColumns) {
      visibleColumns =
        variables.displayType === ListNamedViewDisplayType.GRID
          ? gridViewTypeDefaultColumns
          : kanbanViewTypeDefaultColumns;
      visibleColumns = [
        ...(watchlist?.customFields?.map((cf) => cf.urn) ?? []),
        ...visibleColumns
      ];
      modifiedVariables = {
        ...variables,
        visibleColumns
      };
    }
    let optimistcResponseObj = {
      __typename: namedViewCacheIdName,
      id: namedViewsId as number,
      entityUrn:
        watchlist?.namedViews?.[selectedNamedViewIndex]?.entityUrn ?? '',
      name:
        variables.name ??
        watchlist?.namedViews?.[selectedNamedViewIndex]?.name ??
        '',
      visibleColumns,
      searchQuery:
        variables.searchQuery ??
        watchlist?.namedViews?.[selectedNamedViewIndex]?.searchQuery ??
        null
    } as unknown as T;

    optimistcResponseObj = {
      ...optimistcResponseObj,
      displayType:
        variables.displayType ??
        watchlist?.namedViews?.[selectedNamedViewIndex]?.displayType ??
        null,
      groupByField: groupByFieldUrn
        ? {
            __typename: customFieldCacheIdName as string,
            urn: groupByFieldUrn
          }
        : null,
      hideEmptyColumns:
        (variables as CompanyListNamedViewUpsertInput)?.hideEmptyColumns ??
        (
          watchlist?.namedViews?.[
            selectedNamedViewIndex
          ] as CompanyListNamedView
        )?.hideEmptyColumns ??
        false
    };

    if (!skipUpsertLocalSearch) {
      updateLocalSearch(namedViewUrn, optimistcResponseObj);
      return;
    }

    const res = await upsertWatchlistNamedView({
      variables: {
        watchlistUrn: watchlistUrn as string,
        namedViewUrn: namedViewUrn,
        namedViewInput: modifiedVariables
      },
      optimisticResponse: {
        [backendResponseKey]: optimistcResponseObj
      },
      onError: () => {
        displayToast({
          mode: 'error',
          primaryText: 'Failed to update named view'
        });
      }
    });

    if (!res.data?.[backendResponseKey]) {
      return displayToast({
        mode: 'error',
        primaryText: 'Failed to update named view'
      });
    }

    return res.data[backendResponseKey];
  };

  const duplicateNamedView = async (urn: string) => {
    const existingNamedView = (
      watchlist?.namedViews as GenericNamedView[]
    )?.find((namedView) => namedView.entityUrn === urn);
    let createNamedViewInput: GenericNamedViewUpsertInput = {
      name: `${existingNamedView?.name} (Copy)`,
      visibleColumns: existingNamedView?.visibleColumns,
      searchQuery: existingNamedView?.searchQuery,
      displayType: existingNamedView?.displayType
    };
    createNamedViewInput = {
      ...createNamedViewInput,
      groupByField: (existingNamedView as CompanyListNamedView)?.groupByField
        ?.urn
    };
    return createNamedView({
      variables: createNamedViewInput,
      watchlistUrn: watchlist?.entityUrn ?? ''
    });
  };

  const upsertCurrentSelectedNamedView = useCallback(
    async ({
      variables,
      skipUpsertLocalSearch = false
    }: {
      variables: GenericNamedViewUpsertInput;
      skipUpsertLocalSearch?: boolean;
    }) => {
      const selectedNamedViewIndex = getSelectedNamedViewIndex(
        watchlist,
        selectedNamedView
      );

      return upsertNamedView({
        variables,
        namedViewUrn:
          watchlist?.namedViews?.[selectedNamedViewIndex]?.entityUrn,
        watchlistUrn: watchlist?.entityUrn ?? '',
        skipUpsertLocalSearch
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchlist, selectedNamedView]
  );

  // This function is used to create a new named view with the filter display type copied over from the existing named view.
  // Until we have save UX, create button will port over things. We can remove it later
  const createNamedViewWithFilterDisplayCopiedOver = async ({
    variables,
    watchlistUrn
  }: {
    variables: GenericNamedViewUpsertInput;
    watchlistUrn: string;
  }) => {
    const selectedNamedViewIndex = getSelectedNamedViewIndex(
      watchlist,
      selectedNamedView
    );
    const existingNamedView = (watchlist?.namedViews as GenericNamedView[])?.[
      selectedNamedViewIndex
    ];

    let createNamedViewInput: GenericNamedViewUpsertInput = {
      visibleColumns: existingNamedView?.visibleColumns,
      searchQuery: existingNamedView?.searchQuery
    };
    createNamedViewInput = {
      ...createNamedViewInput,
      ...variables
    };
    return createNamedView({
      variables: createNamedViewInput,
      watchlistUrn: watchlistUrn
    });
  };

  const createNamedView = async ({
    variables,
    watchlistUrn
  }: {
    variables: GenericNamedViewUpsertInput;
    watchlistUrn: string;
  }): Promise<T> => {
    const defaultColumns =
      variables.displayType === ListNamedViewDisplayType.GRID
        ? gridViewTypeDefaultColumns
        : kanbanViewTypeDefaultColumns;

    let visibleColumns = defaultColumns;
    visibleColumns = [
      ...(watchlist?.customFields?.map((cf) => cf.urn) ?? []),
      ...defaultColumns
    ];

    const res = await upsertWatchlistNamedView({
      variables: {
        watchlistUrn,
        namedViewUrn: null,
        namedViewInput: {
          ...variables,
          visibleColumns
        }
      },
      update: (cache, { data }) => {
        const cachedWatchlist = cache.readFragment({
          id: `${watchlistCacheIdName}:${watchlist?.id}`,
          fragment: watchlistFragment,
          fragmentName: watchlistFragmentName
        }) as GenericWatchlist;
        const updatedWatchlist = {
          ...cachedWatchlist,
          namedViews: [
            ...(cachedWatchlist?.namedViews ?? []),
            data?.[backendResponseKey]
          ]
        };
        cache.writeFragment({
          id: `${watchlistCacheIdName}:${watchlist?.id}`,
          fragment: watchlistFragment,
          fragmentName: watchlistFragmentName,
          data: updatedWatchlist
        });
        setSelectedNamedViewIdQueryParam(
          data?.[backendResponseKey]?.id as number
        );
      }
    });
    return res.data?.[backendResponseKey] as T;
  };

  const removeColumnFromCurrentNamedView = async (columnUrn: string) => {
    const selectedNamedViewIndex = getSelectedNamedViewIndex(
      watchlist,
      selectedNamedView
    );
    const visibleColumns =
      watchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns;
    const updatedVisibleColumns = visibleColumns?.filter(
      (column) => column !== columnUrn
    );
    return upsertNamedView({
      variables: {
        visibleColumns: updatedVisibleColumns
      },
      namedViewUrn: watchlist?.namedViews?.[selectedNamedViewIndex]?.entityUrn,
      watchlistUrn: watchlist?.entityUrn ?? ''
    });
  };
  return {
    loading,
    upsertCurrentSelectedNamedView,
    upsertNamedView,
    createNamedView: createNamedViewWithFilterDisplayCopiedOver,
    duplicateNamedView,
    removeColumnFromCurrentNamedView
  };
};

export default useUpsertWatchlistNamedViews;
