import { LocationType } from '__generated__/graphql';
import { TwoSidedRangeValue } from 'components/QueryBuilder/Common/CustomRange/DateModal';
import { DateWithSelectMode } from 'components/QueryBuilder/Common/DateWithSelect';
import { SelectorOption } from 'components/QueryBuilder/Common/SelectorWithValue';
import { SectorPreset } from 'components/QueryBuilder/CompanySearch/ProductSection/SectorPresets';
import { HighlightCategory } from 'utils/constants';
import { ISearchFilterGroup } from './SearchModel/Search';

export enum CompanyFilterTabs {
  GENERAL = 'company_general',
  FUNDING = 'company_funding',
  TEAM = 'company_team',
  PRODUCT = 'company_product',
  CUSTOM = 'company_custom'
}

export enum PeopleFilterTabs {
  FILTER = 'filter',
  GENERAL = 'people_general',
  CURRENT_EXP = 'people_current_exp',
  PAST_EXP = 'people_past_exp',
  EDUCATION = 'people_education',
  CUSTOM = 'people_custom'
}

export type FilterTabs = CompanyFilterTabs | PeopleFilterTabs;

export enum SearchStateFilter {
  VIEWED = 'VIEWED',
  UNVIEWED = 'UNVIEWED',
  NONE = 'NONE'
}

export enum SectorTagsRowTypes {
  INDUSTRY = 'industry',
  PRODUCT = 'product',
  CUSTOMER = 'customer',
  TECHNOLOGY_TYPE = 'technology_type',
  MARKET_VERTICAL = 'market_vertical'
}

export enum FilterGroupGeneratorId {
  SEARCH_V2_COMPANY_FUNDING_STAGE = 'search_v2_company_funding_stage',
  SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE = 'search_v2_company_funding_total_range',
  SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_TOTAL_RANGE = 'search_v2_company_funding_last_round_total_range',
  SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS = 'search_v2_company_funding_include_investors',
  SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS = 'search_v2_company_funding_exclude_investors',
  SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE = 'search_v2_company_funding_last_round_date',
  SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE = 'search_v2_company_funding_foundation_date',
  SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE = 'search_v2_company_team_headcount_range',
  SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE = 'search_v2_company_team_headcount_percent_growth_range',
  SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS = 'search_v2_company_team_highlights',
  SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS = 'search_v2_company_sector_include_business_tags',
  SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS = 'search_v2_company_sector_exclude_business_tags',
  SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS = 'search_v2_company_sector_include_industry_tags',
  SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS = 'search_v2_company_sector_exclude_industry_tags',
  SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS = 'search_v2_company_sector_include_technology_tags',
  SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS = 'search_v2_company_sector_exclude_technology_tags',
  SEARCH_V2_COMPANY_SECTOR_PRESET = 'search_v2_company_sector_preset',
  SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS = 'search_v2_company_sector_include_tags',
  SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS = 'search_v2_company_sector_exclude_tags',
  SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS = 'search_v2_company_sector_include_keywords',
  SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS = 'search_v2_company_sector_exclude_keywords',
  SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS = 'search_v2_company_sector_match_keywords',
  SEARCH_V2_COMPANY_SECTOR_PRODUCT_CATEGORIES = 'search_v2_company_sector_product_categories',
  SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE = 'search_v2_company_team_founding_experience',
  SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE = 'search_v2_company_team_member_experience',
  SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST = 'search_v2_company_list_and_more_include_from_watchlist',
  SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST = 'search_v2_company_list_and_more_exclude_from_watchlist',
  SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE = 'search_v2_company_list_and_more_initialized_date',
  SEARCH_V2_COMPANY_LIST_AND_MORE_UPDATED_DATE = 'search_v2_company_list_and_more_updated_date',
  SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS = 'search_v2_company_list_and_more_accelerators',
  SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION = 'search_v2_company_list_and_more_location',
  SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST = 'search_v2_company_list_and_more_include_from_people_list',
  SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST = 'search_v2_company_list_and_more_exclude_from_people_list',
  SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES = 'search_v2_company_team_correspondences',
  //People searches
  SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE = 'search_v2_people_current_experience',
  SEARCH_V2_PEOPLE_EDUCATION = 'search_v2_people_education_experience',
  SEARCH_V2_PEOPLE_PAST_EXPERIENCE = 'search_v2_people_past_experience',
  SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS = 'search_v2_people_general_highlights',
  SEARCH_V2_PEOPLE_GENERAL_LOCATION = 'search_v2_people_general_location',
  SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS = 'search_v2_people_general_connections',
  SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES = 'search_v2_people_general_correspondences',
  SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS = 'search_v2_people_general_people_lists',
  SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES = 'search_v2_people_general_linkedin_headlines',
  // Accelerators
  SEARCH_V2_ACCELERATOR = 'search_v2_accelerator',
  // Semantic search
  SEARCH_V2_SEMANTIC_SEARCH = 'search_v2_semantic_search_filter'
}

export const FilterTabToFilterGroupGeneratorId: Record<
  FilterTabs,
  FilterGroupGeneratorId[]
> = {
  [CompanyFilterTabs.GENERAL]: [
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_UPDATED_DATE
  ],
  [CompanyFilterTabs.FUNDING]: [
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_TOTAL_RANGE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS,
    FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR
  ],
  [CompanyFilterTabs.TEAM]: [
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES
  ],
  [CompanyFilterTabs.PRODUCT]: [
    FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRODUCT_CATEGORIES
  ],
  [CompanyFilterTabs.CUSTOM]: [],
  [PeopleFilterTabs.FILTER]: [],
  [PeopleFilterTabs.CURRENT_EXP]: [
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE
  ],
  [PeopleFilterTabs.PAST_EXP]: [
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE
  ],
  [PeopleFilterTabs.EDUCATION]: [
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION
  ],
  [PeopleFilterTabs.GENERAL]: [
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS,
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION,
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS,
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES,
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES,
    FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS
  ],
  [PeopleFilterTabs.CUSTOM]: []
};

export enum FundingLastRoundDateOptions {
  PAST_FOUR_TO_TWELVE_MONTHS = 'past_four_to_twelve_months',
  PAST_TWELVE_TO_TWENTY_FOUR_MONTHS = 'past_twelve_to_twenty_four_months',
  CUSTOM = 'custom_rolling'
}

export enum CompanyInitializedOrUpdatedDateOptions {
  PAST_SIX_MONTHS = 'past_six_months',
  PAST_THIRTY_DAYS = 'past_thirty_days',
  CUSTOM = 'custom_rolling'
}

export enum CompanyFoundationDateOptions {
  PAST_YEAR = 'past_year',
  PAST_TWO_YEARS = 'past_two_years',
  CUSTOM = 'custom_rolling'
}

export enum HeadcountPercentGrowthTimeline {
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  ONE_YEAR = 'one_year'
}
export type ActiveFilterCountByTabResponse = Record<FilterTabs, number>;

// Default value for headcount data and funding date
// Note: If value equals the default value, those filters are ignored altogether.
// Lots of companies don't have headcount or funding data. having a default headcount range filter effects results. So, default are treated as none
export const companyTeamHeadcountRangeDefaultValue = {
  range: [0, Number.POSITIVE_INFINITY] as [number, number]
};

export const companyTeamHeadcountPercentGrowthDefaultValue = {
  timeline: HeadcountPercentGrowthTimeline.THREE_MONTHS,
  range: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY] as NumberRange
};

export const companyFundingTotalDefaultValue = {
  range: [0, Number.POSITIVE_INFINITY] as NumberRange,
  include_undisclosed: false
};

const isSelectedTypeaheadResultType = (
  typeaheadResult: string | SelectedTypeaheadResult
): typeaheadResult is SelectedTypeaheadResult => {
  return (typeaheadResult as SelectedTypeaheadResult).name !== undefined;
};

// TODO(APPS-2812):
// Utility function to maintain backwards compatibility with searches created prior to searching against the id. These searches only store the company name(s) as arguments.
// We can remove this once we have backfilled all existing searches that use the SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE and SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE generators.
export const convertStringArrayToSelectedTypeaheadResultArray = (
  input?: string[] | SelectedTypeaheadResult[]
): SelectedTypeaheadResult[] => {
  return (
    input?.map((item) =>
      isSelectedTypeaheadResultType(item) ? item : { name: item }
    ) ?? []
  );
};

export const fundingSliderStepRanges = [
  0,
  25000,
  50000,
  75000,
  100000,
  250000,
  500000,
  750000,
  //1 million
  1000000,
  2000000,
  3000000,
  5000000,
  7500000,
  //10 million
  10000000,
  15000000,
  25000000,
  50000000,
  75000000,
  100000000,
  1000000000,
  Number.POSITIVE_INFINITY
];

export const headcountStepRanges = [
  0,
  1,
  5,
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  250,
  500,
  750,
  1000,
  5000,
  10000,
  50_000,
  100_000,
  500_000,
  Number.POSITIVE_INFINITY
];

export const headcountGrowthStepRanges = [
  Number.NEGATIVE_INFINITY,
  -100,
  -50,
  -25,
  0,
  25,
  50,
  100,
  200,
  300,
  500,
  1000,
  Number.POSITIVE_INFINITY
];

export type DateRangeCustomFilterGenerator = {
  range_value?: CompanyFoundationDateOptions.CUSTOM;
  custom_range?: CustomDateRange;
};
export type DateRangeGenericFilterGenerator = {
  range_value?: CompanyFoundationDateOptions;
  custom_range?: [string, string];
};
export type DateRangeFilterGenerator =
  | DateRangeGenericFilterGenerator
  | DateRangeCustomFilterGenerator;

// People search interfaces
export interface PeopleSearchCurrentExperienceGenerator {
  current_or_past?: 'current' | 'past';
  role_title_contains_all_of?: string[];
  role_title_contains_any_of?: string[];
  role_title_contains_none_of?: string[];
  role_department_any_of?: string[];
  role_department_none_of?: string[];
  role_type_any_of?: string[];
  role_type_none_of?: string[];
  company_id_any_of?: number[];

  company_initialized_date_range_value?: string;
  company_initialized_date_custom_range?: string[];

  company_funding_range?: NumberRange;
  company_headcount_range?: NumberRange;
  company_investor_names_any_of?: string[];
  company_in_watchlist_ids_any_of?: string[];
  company_in_watchlist_ids_none_of?: string[];
  company_keywords_contains_all_of?: string[];
  company_keywords_contains_any_of?: string[];
  company_keywords_contains_none_of?: string[];

  company_founding_date_range_value?: string;
  company_founding_date_custom_range?: string[];

  company_last_funding_date_range_value?: string;
  company_last_funding_date_custom_range?: string[];

  company_industry_tags_include_any_of?: string[];
  company_industry_tags_exclude_all_of?: string[];
  company_technology_tags_include_any_of?: string[];
  company_technology_tags_exclude_all_of?: string[];
  company_customer_type_tags_include_any_of?: string[];
  company_customer_type_tags_exclude_all_of?: string[];

  select_stealth_companies?: boolean;

  role_start_date_mode?: string;
  role_start_date_value?: string | [string, string];

  role_end_date_mode?: string;
  role_end_date_value?: string | [string, string];

  company_funding_stage_any_of?: string[];
  company_funding_stage_none_of?: string[];

  role_tenure_comparator?: string;
  role_tenure_value?: string | string[];
  role_tenure_funding_stage_any_of?: string[];
}

export enum BackendCustomRangeModeType {
  CUSTOM = 'custom',
  CUSTOM_ROLLING = 'custom_rolling'
}
export interface PeopleSearchPastExperienceGenerator
  extends PeopleSearchCurrentExperienceGenerator {
  employment_timeline_end?: {
    comparator: DateWithSelectMode;
    value: string | null | undefined;
  };
}

export interface PeopleEducationExperienceGenerator {
  schools?: string[];
  schools_exclude_all_of?: string[];
  degrees?: string[];
  start_date_comparator?: string;
  start_date?: string | [string, string];
  end_date_comparator?: string;
  end_date?: string | [string, string];
}

export interface PeopleSearchGeneralHighlightsGenerator {
  highlights: HighlightCategory[];
}

export interface SearchData {
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE]?: {
    stages: string[];
  };

  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE]?: {
    range: NumberRange;
    include_undisclosed: boolean;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_TOTAL_RANGE]?: {
    range: NumberRange;
  };
  // TODO(APPS-2814): update SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS and SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS generators to accept SelectedTypeaheadResult[] instead of string[]
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS]?: {
    investors: string[];
  };
  // TODO(APPS-2814): update SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS and SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS generators to accept SelectedTypeaheadResult[] instead of string[]
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS]?: {
    investors: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE]?: {
    range_value?: FundingLastRoundDateOptions;
    custom_range?: [string, string];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE]?: DateRangeFilterGenerator;
  // Team section
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE]?: {
    range: NumberRange;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE]?: {
    timeline: HeadcountPercentGrowthTimeline;
    range: NumberRange;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS]?: {
    highlights: HighlightCategory[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE]?: {
    past_roles: string[];
    past_role_titles: string[];
    past_companies: string[] | SelectedTypeaheadResult[]; // TODO(APPS-2812): remove the string[] once we have backfilled the SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE and SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE generators
    past_schools: string[];
    past_company_list_ids: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE]?: {
    past_roles: string[];
    past_role_titles: string[];
    past_companies: string[] | SelectedTypeaheadResult[]; // TODO(APPS-2812): remove the string[] once we have backfilled the SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE and SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE generators
    past_schools: string[];
    past_company_list_ids: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES]?: {
    last_email_with?: string[];
    last_email_date_range_value?: string;
    last_email_date_custom_range?: TwoSidedRangeValue;

    last_meeting_with?: string[];
    last_meeting_date_range_value?: string;
    last_meeting_date_custom_range?: TwoSidedRangeValue;

    connection_status: ConnectionScope;
  };
  //Sector section
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRODUCT_CATEGORIES]?: {
    categories: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET]?: {
    preset: SectorPreset;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS]?: {
    industry_tags: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS]?: {
    industry_tags: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS]?: {
    technology_tags: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS]?: {
    technology_tags: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS]?: {
    business_tags: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS]?: {
    business_tags: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS]?: {
    tags: string[];
    use_version_two_tags?: boolean;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS]?: {
    tags: string[];
    use_version_two_tags?: boolean;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS]?: {
    keywords: string[];
    include_website_text_content: boolean;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS]?: {
    keywords: string[];
    include_website_text_content: boolean;
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS]?: {
    keywords: string[];
  };
  // Semantic
  [FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH]?: {
    query: string;
    k: number;
    similarity: number;
  };
  //List and more section
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST]?: {
    watchlist_ids: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST]?: {
    watchlist_ids: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE]?: {
    range_value?: CompanyInitializedOrUpdatedDateOptions;
    custom_range?: [string, string];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_UPDATED_DATE]?: {
    range_value?: CompanyInitializedOrUpdatedDateOptions;
    custom_range?: [string, string];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION]?: {
    city?: string[];
    state?: string[];
    country?: string[];
    region?: string[];
    locations?: ILocation[];
    excluded_locations?: ILocation[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS]?: {
    accelerators: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR]?: {
    accelerator_groups: string[];
    accelerators: string[];
    accelerator_cohorts: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST]?: {
    watchlist_ids: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST]?: {
    watchlist_ids: string[];
  };
  //People searches
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE]?: PeopleSearchCurrentExperienceGenerator;
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE]?: PeopleSearchPastExperienceGenerator;
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION]?: PeopleEducationExperienceGenerator;
  customBuilderFilterGroup: ISearchFilterGroup;
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS]?: PeopleSearchGeneralHighlightsGenerator;
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION]?: {
    city?: string[];
    state?: string[];
    country?: string[];
    region?: string[];
    locations?: ILocation[];
    excluded_locations?: ILocation[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS]?: {
    in_lists?: string[];
    not_in_lists?: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES]?: {
    last_email_with?: string[];
    last_email_date_range_value?: string;
    last_email_date_custom_range?: TwoSidedRangeValue;

    last_meeting_with?: string[];
    last_meeting_date_range_value?: string;
    last_meeting_date_custom_range?: TwoSidedRangeValue;
  };
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS]?: {
    in_lists?: string[];
    not_in_lists?: string[];
  };
  [FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES]?: {
    any_headlines: string[];
    all_headlines: string[];
    not_any_headlines: string[];
  };
}

export enum ConnectionScope {
  ANY = 'ANY',
  MY_CONNECTIONS = 'MY_CONNECTIONS',
  TEAM_CONNECTIONS = 'TEAM_CONNECTIONS',
  NO_CONNECTIONS = 'NO_CONNECTIONS'
}

export enum TimeUnit {
  TODAY = 'TODAY',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
  INFINITY = 'INFINITY'
}

export enum DateRangeShortHand {
  PAST_WEEK = 'past_week',
  PAST_TWO_WEEKS = 'past_two_weeks',
  PAST_ONE_MONTH = 'past_one_month',
  PAST_THREE_MONTHS = 'past_three_months',
  PAST_FOUR_MONTHS = 'past_four_months',
  PAST_SIX_MONTHS = 'past_six_months',

  PAST_THIRTY_DAYS = 'past_thirty_days',

  PAST_YEAR = 'past_year',
  PAST_TWO_YEARS = 'past_two_years',

  PAST_FOUR_TO_TWELVE_MONTHS = 'past_four_to_twelve_months',
  PAST_TWELVE_TO_TWENTY_FOUR_MONTHS = 'past_twelve_to_twenty_four_months',

  CUSTOM = 'custom',
  CUSTOM_ROLLING = 'custom_rolling'
}

interface CustomDate {
  timeUnit: TimeUnit;
  value?: number;
}

export interface SelectedTypeaheadResult {
  id?: number;
  name: string;
}

export interface CustomDateRange {
  start: CustomDate;
  end: CustomDate;
}

export const DEFAULT_DATE_SELECTOR_OPTIONS: SelectorOption[] = [
  { value: TimeUnit.TODAY, label: 'Today' },
  { value: TimeUnit.DAYS, label: 'Days ago' },
  { value: TimeUnit.WEEKS, label: 'Weeks ago' },
  { value: TimeUnit.MONTHS, label: 'Months ago' },
  { value: TimeUnit.YEARS, label: 'Years ago' }
];

export const YEAR_ONLY_DATE_SELECTOR_OPTIONS: SelectorOption[] = [
  { value: TimeUnit.TODAY, label: 'This year' },
  { value: TimeUnit.YEARS, label: 'Years ago' }
];

export interface ILocation {
  city?: string;
  state?: string;
  country?: string;
  entity_urn: string;
  location_type: LocationType;
  text: string;
}
