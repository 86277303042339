import {
  ToastCautionIcon,
  ToastNegativeIcon,
  ToastPositiveIcon
} from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

type ToastMode = 'success' | 'info' | 'warn' | 'error';

interface DisplayToastProps {
  primaryText: string;
  secondaryText?: string;
  link?: string;
  mode?: ToastMode;
  cta?: {
    text: string;
    onClick: () => void;
  };
}

export const ToastComponent = ({
  primaryText,
  secondaryText,
  link,
  cta
}: DisplayToastProps) => (
  <div className="flex-1 flex flex-row justify-between">
    <div className="flex flex-col gap-g20 py-p20">
      <p className="typography-paragraph text-content-inverted-strong">
        {primaryText}
      </p>
      {(link || secondaryText) && (
        <div className="typography-paragraph-small-default text-content-inverted-default">
          {link ? (
            <Link to={link}>{secondaryText}</Link>
          ) : (
            <p>{secondaryText}</p>
          )}
        </div>
      )}
    </div>
    {cta && (
      <Button
        type="inverted"
        emphasis="low"
        size="compact"
        label={cta.text}
        onClick={cta.onClick}
      />
    )}
  </div>
);
export const displayToast = ({
  primaryText,
  secondaryText,
  link,
  mode = 'success',
  cta
}: DisplayToastProps) => {
  const toastContent = (
    <ToastComponent
      primaryText={primaryText}
      secondaryText={secondaryText}
      link={link}
      cta={cta}
    />
  );
  if (mode === 'success')
    toast.success(toastContent, {
      icon: () => (<ToastPositiveIcon />) as unknown as React.ReactNode
    });
  if (mode === 'info')
    toast.info(toastContent, {
      icon: () => (<ToastCautionIcon />) as unknown as React.ReactNode
    });
  if (mode === 'warn')
    toast.warn(toastContent, {
      icon: () => (<ToastCautionIcon />) as unknown as React.ReactNode
    });
  if (mode === 'error')
    toast.error(toastContent, {
      icon: () => (<ToastNegativeIcon />) as unknown as React.ReactNode
    });
};
