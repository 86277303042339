import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { GetCompanyWatchlistQuery } from '__generated__/graphql';
import { GET_WATCHLIST } from 'queries/getWatchlist';
import { useBaseFetchSingleWatchlist } from './useBaseFetchSingleWatchlist';
import { DashboardLocation } from './useDashboardLocation';

interface UseFetchSingleWatchlistResults {
  companyWatchlist?:
    | GetCompanyWatchlistQuery['getCompanyWatchlistByIdOrUrn']
    | null;
  companyWatchlistLoading: boolean;
  companyWatchlistError?: ApolloError;
}

const useFetchSingleWatchlist = ({
  fetchPolicy = 'cache-first',
  skipLocalSearch = false
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  skipLocalSearch?: boolean;
} = {}): UseFetchSingleWatchlistResults => {
  const { data, loading, error } = useBaseFetchSingleWatchlist<
    GetCompanyWatchlistQuery,
    GetCompanyWatchlistQuery['getCompanyWatchlistByIdOrUrn']
  >({
    fetchPolicy,
    query: GET_WATCHLIST,
    location: DashboardLocation.COMPANY_LIST,
    transformData: (data) => data?.getCompanyWatchlistByIdOrUrn,
    skipLocalSearch
  });

  return {
    companyWatchlist: data,
    companyWatchlistLoading: loading,
    companyWatchlistError: error
  };
};

export default useFetchSingleWatchlist;
