import { getIdFromUrn, getUrnFragment } from 'common/utils/urn';
import {
  EntityListType,
  EntityURNTypeToDashboardPath
} from 'interfaces/SearchModel/Search';
import {
  COMPANIES_ROUTE,
  COMPANY_ROUTE,
  DASHBOARD_ROUTE,
  PERSON_ROUTE,
  WATCHLIST_ROUTE
} from './constants';

export const updateIdtoURN = (id: string, entity: EntityListType): string => {
  return `urn:harmonic:${EntityURNTypeToDashboardPath[entity]}:${id}`;
};

export const getHarmonicUrlFromUrn = (urn: string) => {
  const type = getUrnFragment(urn, 2);
  if (!type) {
    return undefined;
  }
  const id = getIdFromUrn(urn);
  const typeToUrlMap: Record<string, string> = {
    company: `${COMPANY_ROUTE}/${id}`,
    person: `${PERSON_ROUTE}/${id}`,
    saved_search: `${COMPANIES_ROUTE}/${urn}`,
    company_watchlist: `${WATCHLIST_ROUTE}/${urn}`
  };
  if (!Object.keys(typeToUrlMap).includes(type)) {
    return undefined;
  }
  return `${DASHBOARD_ROUTE}${typeToUrlMap[type]}`;
};
