import SelectionPill from 'harmonic-components/SelectionPill/SelectionPill';

interface TenureFundingStageMultiselectProps {
  selected: string[];
  disabled?: boolean;
  dataTestId?: string;
  onChange: (newOptions?: string[]) => void;
}

const options = [
  {
    label: 'Pre-seed',
    value: 'PRE_SEED'
  },
  {
    label: 'Seed',
    value: 'SEED'
  },
  { label: 'Series A', value: 'SERIES_A' },
  { label: 'Series B', value: 'SERIES_B' },
  {
    label: 'Series C',
    value: 'SERIES_C'
  },
  { label: 'Series D+', value: 'SERIES_D_PLUS' }
];
const TenureFundingStageMultiselect: React.FC<
  TenureFundingStageMultiselectProps
> = ({ selected, dataTestId, onChange }) => {
  return (
    <div data-testid={dataTestId} className="flex flex-row flex-wrap gap-g40">
      {options.map((option) => {
        return (
          <SelectionPill
            key={option.value}
            label={option.label}
            selected={selected.includes(option.value)}
            onClick={() => {
              if (selected.includes(option.value)) {
                onChange(selected.filter((value) => value !== option.value));
              } else {
                onChange([...selected, option.value]);
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default TenureFundingStageMultiselect;
