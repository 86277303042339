import { Fade, Typography } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { ReactComponent as InfoIcon } from 'assets/bx-info-circle.svg';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import useFlags from 'hooks/useFlags';
import {
  FilterArrayComparator,
  FilterComparator,
  FilterDateComparator,
  FilterStringComparator,
  ISearchFieldSpec,
  ISearchFilter,
  JoinOperatorType,
  TableFilterType
} from 'interfaces/SearchModel/Search';
import { capitalize } from 'lodash';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import toInteger from 'lodash/toInteger';
import union from 'lodash/union';
import React, { useMemo } from 'react';
import { MAX_FILTER_DEPTH, SPLITS } from 'utils/constants';
import { returnNumberFromString } from 'utils/utilities';
import { ArrowTooltip } from '../../styles';
import ExtraSideActionsButton from '../ExtraSideActionsButton';
import {
  ISearchFilterContext,
  SearchFilterContext
} from '../SearchFilterGroupBase';
import { SingleFilterWrapper } from '../styles';
import {
  SearchFilterType,
  getFieldAllowedValues,
  isCustomFieldUrn
} from '../utils';
import ComparatorInput from './ComparatorInput';
import FieldInput from './FieldInput';
import OperationsInput from './OperationsInput';
import ValueInput from './ValueInput';

interface ISingleFilterProps extends ISearchFilter {
  index: number;
  showWhereClause: boolean;
  showJoinToggle: boolean;
  recursiveLevel: number;
  disabled?: boolean;
  entityType: SearchFilterType;
}

const SingleFilter = (props: ISingleFilterProps): JSX.Element => {
  const {
    field,
    comparator,
    field_urn,
    filter_value,
    index,
    showWhereClause,
    showJoinToggle,
    disabled
  } = props;

  const { enabled: showBetaPersonHighlights } = useFlags(
    SPLITS.showBetaPersonHighlights
  );
  const searchFilterContext: ISearchFilterContext =
    React.useContext(SearchFilterContext);

  const filterGroup = searchFilterContext.rootSearchFilterGroup;
  const filterDefinition: ISearchFilter = filterGroup.filters[index];
  const valueInputRef = React.useRef<HTMLInputElement | HTMLDivElement | null>(
    null
  );
  const getFieldSpec = useMemo(() => {
    return searchFilterContext.fieldSpecs.find((spec) => {
      if (isCustomFieldUrn(field_urn)) {
        return spec.urn === field_urn;
      }
      return spec.unique_name === field;
    });
  }, [field]);

  // Special type assignments for specific field specs + field/comparator combinations
  const getFilterType = (fieldSpec?: ISearchFieldSpec) => {
    if (fieldSpec?.format === 'date') {
      if (
        comparator === FilterDateComparator.IN_DAYS_AGO_RANGE ||
        comparator === FilterDateComparator.NOT_IN_DAYS_AGO_RANGE
      ) {
        return TableFilterType.INTEGER;
      }
      return TableFilterType.DATE;
    }

    if (comparator === FilterStringComparator.EXACT_MATCH) {
      return TableFilterType.STRING;
    }

    return get(fieldSpec, 'type', TableFilterType.STRING);
  };

  const [filterType, setFilterType] = React.useState(
    getFilterType(getFieldSpec)
  );

  React.useEffect(() => {
    // Update filterType
    const newFilterType = getFilterType(getFieldSpec);
    setFilterType(newFilterType);

    // Update display format type
    setDisplayFormatType(getFieldSpec?.display_format);
  }, [searchFilterContext.rootSearchFilterGroup]);

  const [allowedComparators, setAllowedComparators] = React.useState<
    FilterComparator[]
  >(() => {
    let comparators = union(getFieldSpec?.allowed_comparators || []);
    if (field === 'company_keywords') {
      comparators = comparators.filter(
        (comparator) => comparator !== 'isEmpty' && comparator !== 'isNotEmpty'
      );
    }

    return comparators;
  });

  const [allowedValues, setAllowedValues] = React.useState<string[] | null>(
    getFieldAllowedValues(getFieldSpec, showBetaPersonHighlights)
  );
  const [displayFormatType, setDisplayFormatType] = React.useState(
    getFieldSpec?.display_format
  );

  const filterValueIsRange =
    isString(filter_value) && filter_value.includes('~');
  const [rangeFromValue, setRangeFromValue] = React.useState(
    filterValueIsRange ? get((filter_value as string).split('~'), 0) : ''
  );
  const [rangeToValue, setRangeToValue] = React.useState(
    filterValueIsRange ? get((filter_value as string).split('~'), 1) : ''
  );

  // For array types, store multiple values
  const [multiSelectValues, setMultiSelectValues] = React.useState<string[]>(
    Array.isArray(filterDefinition?.filter_value)
      ? filterDefinition?.filter_value
      : []
  );

  const onRemoveFilter = () => {
    searchFilterContext.searchFilterMethods.removeSearchFilter(index);
  };

  const onUpdateComparatorName = (
    newValue: string | null,
    newUrn: string | null | undefined
  ) => {
    const newField = newValue || '';
    const previousField = field;
    // Update the right comparator filter.
    const currentFieldSpec: ISearchFieldSpec | undefined =
      searchFilterContext.fieldSpecs.find((spec) => {
        if (isCustomFieldUrn(newUrn ?? '')) {
          return spec.urn === newUrn;
        }
        return spec.unique_name === newField;
      });

    let allowedComparators = union(currentFieldSpec?.allowed_comparators || []);
    if (newField === 'company_keywords') {
      allowedComparators = allowedComparators.filter(
        (comparator) => comparator !== 'isEmpty' && comparator !== 'isNotEmpty'
      );
      if (previousField === 'company_description') {
        if (!isEmpty(filter_value) && isString(filter_value)) {
          setMultiSelectValues([
            ...multiSelectValues,
            ...filter_value.split(' ')
          ]);
        }
      }
    }

    if (newField === 'typed_tag_values' && previousField === 'tag_type') {
      setMultiSelectValues([]);
    }

    setAllowedValues(
      getFieldAllowedValues(currentFieldSpec, showBetaPersonHighlights)
    );

    const toUpdateFilter: ISearchFilter = {
      field: newField,
      comparator: allowedComparators[0],
      field_urn: currentFieldSpec?.urn,
      filter_value: ''
    };
    setMultiSelectValues([]);
    setAllowedComparators(allowedComparators);
    // Below update context function will create the current entire component(SingleFilter.tsx) from scratch
    // So above setAllowedValues and setAllowedComparators will get reset
    // They will only take effect if same filter option is clicked twice as context remains same.
    searchFilterContext.searchFilterMethods.updateSearchFilter(
      index,
      toUpdateFilter
    );
  };

  const onUpdateComparatorType = (value: string) => {
    const comparatorValue = value as FilterComparator;
    const isEmptyComparator =
      comparatorValue === FilterStringComparator.IS_EMPTY ||
      comparatorValue === FilterStringComparator.IS_NOT_EMPTY;

    // Do not reset value for array types
    if (
      !(
        Object.values(FilterArrayComparator).includes(
          comparatorValue as unknown as FilterArrayComparator
        ) || field === 'company_description'
      ) ||
      isEmptyComparator
    ) {
      let filterValue: null | string = '';
      if (isEmptyComparator) {
        filterValue = null;
      }
      // On comparator change for string/number/date input, reset value of field to empty
      searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
        filter_value: filterValue,
        comparator: comparatorValue
      });

      return;
    }
    searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
      comparator: comparatorValue
    });
  };

  const onUpdateComparatorValue = (
    event?: React.ChangeEvent<HTMLInputElement>,
    multiSelectValues?: string[]
  ) => {
    let value =
      multiSelectValues || isBoolean(multiSelectValues)
        ? multiSelectValues
        : event?.target.value;
    const type = Array.isArray(filterType) ? filterType[0] : filterType;
    if (type == TableFilterType.INTEGER) {
      value = (value as string).replace(/[^-?\d]/g, '');
      searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
        filter_value: value !== '' ? toInteger(value) : ''
      });
    } else if (type == TableFilterType.ARRAY) {
      // Roll out any comma delimited values in input as separate array items
      value = multiSelectValues?.flatMap((item) => item?.split(';')) || [];
      const uniqueArray = [...new Set(value)];
      setMultiSelectValues(uniqueArray as unknown as string[]);
      searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
        filter_value: uniqueArray
      });
    } else {
      searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
        filter_value: value
      });
    }
  };

  const onUpdateDateRangeValue = (
    startDate: string | null | undefined,
    endDate: string | null | undefined
  ) => {
    setRangeFromValue(startDate as string);
    setRangeToValue(endDate as string);
    searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
      filter_value: `${startDate}~${endDate}`
    });
  };

  const onUpdateComparatorRangeFromValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;
    if (event.target.type === 'text') {
      value = returnNumberFromString(event.target.value);
    }
    setRangeFromValue(value);
    searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
      // Specify 0 as default value to allow for one-sided ranges
      filter_value: `${value}~${rangeToValue || 0}`
    });
  };

  const onUpdateComparatorRangeToValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value: string = event.target.value;
    if (event.target.type === 'text') {
      value = returnNumberFromString(event.target.value);
    }
    setRangeToValue(value);
    searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
      filter_value: `${rangeFromValue || 0}~${value}`
    });
  };

  const onAddMultiSelectValue = (newValue: string) => {
    // Split the newValue by comma and trim the values, dedupe entries
    const formattedValues = newValue
      .split(',')
      ?.map((value) => value.trim())
      .filter(Boolean);

    const newMultiSelectValuesSet = new Set([
      ...multiSelectValues,
      ...formattedValues
    ]);

    const newMultiSelectValues = [...newMultiSelectValuesSet];
    searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
      filter_value: newMultiSelectValues
    });
    setMultiSelectValues(newMultiSelectValues);
  };

  const onRemoveMultiSelectValue = (value: string) => {
    const newMultiSelectValues = multiSelectValues.filter(
      (msValue) => !(msValue === value)
    );
    searchFilterContext.searchFilterMethods.updateSearchFilter(index, {
      filter_value: newMultiSelectValues
    });
    setMultiSelectValues(newMultiSelectValues);
  };

  const duplicateFilter = () => {
    searchFilterContext.searchFilterMethods.duplicateSearchFilter({
      field,
      comparator,
      filter_value
    });
  };

  React.useEffect(() => {
    valueInputRef?.current?.focus();

    // For autocomplete component, we cant directly access input. We only have access to wrapper div.
    const inputElement = valueInputRef?.current?.querySelector('input');
    if (inputElement) inputElement.focus();
  }, [valueInputRef, comparator]);

  const showWatchlistIdWarning = ['employee_experience_company_name'].includes(
    field
  );

  const showDeprecationWarning = getFieldSpec?.deprecated;

  const suggestedFieldToUseInstead = useMemo(() => {
    if (!getFieldSpec?.deprecated) return null;

    return searchFilterContext.fieldSpecs.find(
      (spec) => spec.unique_name === getFieldSpec?.deprecated_use_instead
    );
  }, [field]);

  const deprecationMessage = `${getFieldSpec?.display_name} is deprecated, `;

  const suggestionLink = () => {
    return (
      <span
        onClick={(e) =>
          suggestedFieldToUseInstead
            ? onUpdateComparatorName(
                suggestedFieldToUseInstead?.unique_name ?? '',
                undefined
              )
            : onRemoveFilter()
        }
        className="text-blue-600 cursor-pointer typography-label"
      >
        {suggestedFieldToUseInstead
          ? `use ${suggestedFieldToUseInstead.display_name} instead`
          : `remove this filter`}
      </span>
    );
  };

  return (
    <>
      <SingleFilterWrapper className="py-1 flex items-center">
        <div id="Row-Prefix" className="min-w-16">
          {!showJoinToggle && (
            <div className="typography-label w-full text-right pr-p50">
              {showWhereClause
                ? 'Where'
                : capitalize(
                    searchFilterContext.rootSearchFilterGroup.join_operator
                  )}
            </div>
          )}
          {showJoinToggle && (
            <div className="w-18 -ml-2">
              <Select
                multiple={false}
                selected={
                  searchFilterContext.rootSearchFilterGroup.join_operator
                }
                getLabelFromValue={(value) => capitalize(value)}
              >
                {['and', 'or'].map((operator) => (
                  <ListItem
                    key={operator}
                    value={operator}
                    label={capitalize(operator)}
                    onClick={() => {
                      searchFilterContext.searchFilterMethods.changeJoinOperator(
                        operator as JoinOperatorType
                      );
                    }}
                    variant={ListVariant.default}
                  />
                ))}
              </Select>
            </div>
          )}
        </div>
        <div className="min-w-56">
          <FieldInput
            field={field}
            field_urn={field_urn}
            valueInputRef={valueInputRef}
            fieldSpecs={searchFilterContext.fieldSpecs}
            onUpdateComparatorName={onUpdateComparatorName}
            recursiveLevel={props.recursiveLevel}
            disabled={disabled}
            entityType={props.entityType}
          />
          {showDeprecationWarning && (
            <div
              data-testid="deprecation-warning"
              className="pl-2 text-ink-light"
            >
              <InfoRounded fontSize="inherit" className="text-red-light mb-1" />
              <span className="ml-2">
                <Typography variant="caption">
                  {deprecationMessage}
                  {suggestionLink()}
                </Typography>
              </span>
            </div>
          )}
        </div>
        <div className="min-w-32">
          <ComparatorInput
            field={field}
            comparator={comparator}
            allowedComparators={allowedComparators}
            displayFormatType={displayFormatType}
            onUpdateComparatorType={onUpdateComparatorType}
            disabled={disabled}
          />
        </div>
        <ValueInput
          comparator={comparator}
          rangeFromValue={rangeFromValue}
          rangeToValue={rangeToValue}
          field={field}
          filterType={filterType as TableFilterType}
          filter_value={filter_value}
          allowedValues={allowedValues}
          displayFormatType={displayFormatType}
          valueInputRef={valueInputRef}
          selectValues={getFieldSpec?.select_values}
          onUpdateComparatorRangeFromValue={onUpdateComparatorRangeFromValue}
          onUpdateComparatorRangeToValue={onUpdateComparatorRangeToValue}
          onUpdateDateRangeValue={onUpdateDateRangeValue}
          onUpdateComparatorValue={onUpdateComparatorValue}
          multiSelectValues={multiSelectValues}
          onAddMultiSelectValue={onAddMultiSelectValue}
          onRemoveMultiSelectValue={onRemoveMultiSelectValue}
          disabled={disabled}
        />
        {!props.noOperation && props.recursiveLevel < MAX_FILTER_DEPTH && (
          <OperationsInput
            index={index}
            operations={getFieldSpec?.operations}
            disabled={disabled}
          />
        )}

        {showWatchlistIdWarning && (
          <ArrowTooltip
            arrow
            data-testid="watchlist-warning"
            TransitionComponent={Fade}
            className="text-ink-light"
            title={
              <Typography variant="caption" className="text-ink-light">
                When searching by watchlist id only the first 1k records on a
                watchlist are supported
              </Typography>
            }
          >
            <InfoIcon />
          </ArrowTooltip>
        )}
        {!props.disabled && (
          <>
            <ExtraSideActionsButton
              showConvertToGroupOption={props.recursiveLevel < MAX_FILTER_DEPTH}
              onConvertToGroup={() => {
                searchFilterContext.searchFilterMethods.convertFilterToGroup(
                  props.index
                );
              }}
              duplicateFilter={duplicateFilter}
              onRemoveFilter={onRemoveFilter}
              copyToClipboard={() => {
                navigator.clipboard.writeText(
                  JSON.stringify(
                    { field, comparator, filter_value } as ISearchFilter,
                    null,
                    2
                  )
                );
              }}
            />
          </>
        )}
      </SingleFilterWrapper>
    </>
  );
};

export default SingleFilter;
