import { FC } from 'react';

const TrendingUpIcon: FC = () => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M2.8 2v11.333c0 .369.298.667.667.667H14.8v-1.333H4.133V2H2.8z"
      ></path>
      <path
        fill="currentColor"
        d="M10.995 9.805c.261.26.682.26.943 0l3.333-3.334-.942-.942-2.862 2.862-1.529-1.529a.666.666 0 00-.943 0l-3.333 3.333.943.943 2.862-2.862 1.528 1.529z"
      ></path>
    </svg>
  );
};

export default TrendingUpIcon;
