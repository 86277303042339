import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const AfricaIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2449 16.0549L16.6342 17.3848L16.7347 18.2515L16.0398 18.7897V19.0708L14.3523 20.7753L12.8376 21L12.4925 20.2694L11.8261 19.201L11.6107 18.0405L10.9204 16.898L11.3423 15.437C11.3423 15.437 11.2849 14.5006 11.2539 14.4105C11.2233 14.3204 10.1341 12.5525 10.1341 12.5525L10.4024 11.5786L9.52031 11.2226L8.94521 10.6608C8.94521 10.6608 7.50375 11.0325 7.45764 11.0534C7.41152 11.074 6.01142 11.1293 6.01142 11.1293L5.06859 10.4704L3.83331 8.39936L4.17069 7.57019L4.68837 5.79065L6.03403 4.66329L7.25771 3.20577C7.25771 3.20577 8.15115 3.34818 8.18982 3.32318C8.2285 3.2979 10.1725 3 10.1725 3L10.5226 3.74431L12.8379 4.68596L13.324 4.06429L14.8512 4.4799L15.4263 4.31133L16.1382 4.32557L16.312 4.63393L16.27 5.24775L15.8098 4.79814L17.1331 7.40424L17.5357 7.58908L17.8489 8.19621L18.5517 8.73155L19.1651 9.53689L20.8333 9.06869C20.8333 9.06869 20.2588 10.8601 20.2201 10.9209C20.1818 10.9816 18.7049 12.4778 18.7049 12.4778L17.8805 13.7702L18.2449 16.0549ZM19.95 16.0177L19.1553 16.4237L19.0036 17.3098L18.6284 17.9283L18.7626 18.7525L19.4145 18.7151L20.6417 16.2612L20.4275 15.4289L19.95 16.0177Z"
        fill=""
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default AfricaIcon;
