import classNames from 'classnames';
import {
  CustomDateRange,
  DEFAULT_DATE_SELECTOR_OPTIONS,
  TimeUnit
} from 'interfaces/SearchV2';
import React, { useMemo } from 'react';
import {
  DEFAULT_CUSTOM_RANGE,
  parseCustomRange
} from 'utils/searchV2/searchV2';
import ChipSelect, { ChipSelectProps } from './ChipSelect';
import SelectorWithValue from './SelectorWithValue';

interface OptionValue {
  value: string | undefined;
  customRange?: CustomDateRange;
}
interface ChipWithCustomRangeProps
  extends Omit<ChipSelectProps, 'onValueChange'> {
  onValueChange: (newValue: OptionValue) => void;
  selectedCustomRange?: [string, string];
  dataTestId?: string;
  disabled?: boolean;
  key?: string;
}

const ChipWithCustomRange: React.FC<ChipWithCustomRangeProps> = ({
  selectedValue,
  onValueChange,
  options,
  selectedCustomRange,
  disabled,
  dataTestId
}) => {
  const lastEditedCustomRange = useMemo(() => {
    return selectedCustomRange === undefined
      ? undefined
      : parseCustomRange(selectedCustomRange);
  }, [selectedCustomRange]);

  const chipOptions = [
    ...options,
    {
      label: 'Custom range',
      value: 'custom_rolling'
    }
  ];

  const onChipValueChange = (newValue?: string) => {
    if (newValue === 'custom_rolling') {
      onValueChange({
        value: newValue,
        customRange: lastEditedCustomRange ?? DEFAULT_CUSTOM_RANGE
      });
    } else {
      onValueChange({
        value: newValue,
        customRange: undefined
      });
    }
  };

  return (
    <div data-testid={dataTestId}>
      <ChipSelect
        selectedValue={selectedValue}
        options={chipOptions}
        onValueChange={onChipValueChange}
        disabled={disabled}
      />
      {selectedValue === 'custom_rolling' && (
        <div
          className={classNames(
            'mt-2 inline-flex flex rounded-md py-3 px-2 bg-surface-raised typography-label'
          )}
        >
          <div>
            <div className="typography-label">Between</div>
            <div className="mt-g40 flex flex-row items-center">
              {lastEditedCustomRange?.start.timeUnit !== undefined && (
                <SelectorWithValue
                  selector={{
                    options: DEFAULT_DATE_SELECTOR_OPTIONS,
                    value: lastEditedCustomRange?.start.timeUnit,
                    onChange: (value) => {
                      onValueChange({
                        value: selectedValue,
                        customRange: {
                          start: {
                            timeUnit: value as TimeUnit,
                            value: 0
                          },
                          end: lastEditedCustomRange.end
                        }
                      });
                    }
                  }}
                  value={lastEditedCustomRange?.start.value}
                  hideValueInput={
                    lastEditedCustomRange?.start.timeUnit === TimeUnit.TODAY
                  }
                  onValueChange={(e) => {
                    // Use a regular expression to remove non-numeric characters
                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                    onValueChange({
                      value: selectedValue,
                      customRange: {
                        start: {
                          timeUnit: lastEditedCustomRange?.start.timeUnit,
                          value: newValue ? parseInt(newValue) : undefined
                        },
                        end: lastEditedCustomRange.end
                      }
                    });
                  }}
                />
              )}
              <span className="mx-g50 typography-label">-</span>
            </div>
          </div>
          {lastEditedCustomRange?.end.timeUnit !== undefined && (
            <div>
              <div className="typography-label">and</div>
              <div className="mt-g40">
                <SelectorWithValue
                  selector={{
                    options: DEFAULT_DATE_SELECTOR_OPTIONS,
                    value: lastEditedCustomRange?.end.timeUnit,
                    onChange: (value) => {
                      onValueChange({
                        value: selectedValue,
                        customRange: {
                          start: lastEditedCustomRange?.start,
                          end: {
                            timeUnit: value as TimeUnit,
                            value: 0
                          }
                        }
                      });
                    }
                  }}
                  value={lastEditedCustomRange?.end.value}
                  hideValueInput={
                    lastEditedCustomRange?.end.timeUnit === TimeUnit.TODAY
                  }
                  onValueChange={(e) => {
                    // Use a regular expression to remove non-numeric characters
                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                    onValueChange({
                      value: selectedValue,
                      customRange: {
                        start: lastEditedCustomRange?.start,
                        end: {
                          timeUnit: lastEditedCustomRange?.end.timeUnit,
                          value: newValue ? parseInt(newValue) : undefined
                        }
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChipWithCustomRange;
