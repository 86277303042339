import { FundingRoundFragment } from '__generated__/graphql';
import {
  RoundIconCrowdsourcing,
  RoundIconDebt,
  RoundIconGrant,
  RoundIconIPO,
  RoundIconMna,
  RoundIconPE,
  RoundIconSecondary,
  RoundIconSeed,
  RoundIconSeries,
  RoundIconStrategic,
  RoundIconUnknown
} from 'assets/harmonic-icons';
import { ColorShorthand } from 'common/utils/design';
import { getIdFromUrn } from 'common/utils/urn';
import {
  SearchValueToDisplayNameMap,
  getFundingTypeDisplayName
} from 'interfaces/SearchModel/Search';
import { get, isNil, isNumber, uniqBy } from 'lodash';
import { truncateMoneyValue } from './utilities';

import cryptoRankFavicon from 'assets/funding-favicons/cryptorank.png';
import euStartupsFavicon from 'assets/funding-favicons/eustartups.png';
import finsmesFavicon from 'assets/funding-favicons/finsmes.png';
import prNewsWireFavicon from 'assets/funding-favicons/prnewswire.png';
import techcrunchFavicon from 'assets/funding-favicons/techcrunch.png';
import techEuFavicon from 'assets/funding-favicons/techeu.png';
import techfundingnewsFavicon from 'assets/funding-favicons/techfundingnews.png';
import theSaasNewsFavicon from 'assets/funding-favicons/thesaasnews.png';

const FundingRoundBadgeColorMap: Record<string, string> = {
  Debt: 'expressive-42',
  'Debt Financing': 'expressive-42',
  'Venture Debt': 'expressive-42',
  'Private Equity': 'expressive-42',
  'Series K+': 'expressive-42',
  'Series Pre-Seed': 'expressive-37',
  Preseed: 'expressive-37',
  'Pre-Seed': 'expressive-37',
  Seed: 'expressive-1',
  'Series Seed': 'expressive-1',
  'Series A': 'expressive-5',
  'Series A (Ext)': 'expressive-5',
  'Series B': 'expressive-9',
  'Series B (Ext)': 'expressive-9',
  'Series C': 'expressive-12',
  'Series C (Ext)': 'expressive-12',
  'Series D': 'expressive-18',
  'Series D (Ext)': 'expressive-18',
  'Series E': 'expressive-27',
  'Series E (Ext)': 'expressive-27',
  'Series F': 'expressive-32',
  'Series F (Ext)': 'expressive-32',
  'Series G': 'expressive-37',
  'Series G (Ext)': 'expressive-37',
  'Series H': 'expressive-40',
  'Series I': 'expressive-42',
  'Series J': 'expressive-44',
  'Series 1': 'expressive-2',
  'Series A1': 'expressive-6',
  'Series A2': 'expressive-7',
  'Series 2': 'expresive-8',
  'Series A3': 'expressive-10',
  'Series C1': 'expressive-13',
  'Series B2': 'expressive-11',
  'Series AA': 'expressive-12',
  'Series 3': 'expressive-4',
  'Series D1': 'expressive-19',
  'Series C2': 'expressive-20',
  'Series B3': 'expressive-21',
  'Series BB': 'expressive-22',
  'Series E1': 'expressive-23',
  'Series D2': 'expressive-24',
  'Series C3': 'expressive-25',
  'Series AAA': 'expressive-30',
  'Series CC': 'expressive-45',
  'Series E2': 'expressive-41',
  'Series D3': 'expressive-42'
};

const FundingRoundChartColorMap: Record<string, string> = {
  Debt: '#A63A74',
  'Debt Financing': '#A63A74',
  'Pre-Seed': '#913AA6',
  Seed: '#A63A3A',
  'Series A': '#A6743A',
  'Series B': '#9FA63A',
  'Series C': '#74A63A',
  'Series D': '#3AA657',
  'Series E': '#3A74A6',
  'Series F': '#493AA6',
  'Series G': '#913AA6',
  'Series H': '#A63A91',
  'Series I': '#A63A74',
  'Series J': '#A63A57',
  'Series 1': '#A6493A',
  'Series A1': '#A6823A',
  'Series A2': '#A6913A',
  'Series 2': '#A69F3A',
  'Series A3': '#91A63A',
  'Series C1': '#65A63A',
  'Series B2': '#82A63A',
  'Series AA': '#74A63A',
  'Series 3': '#A6653A',
  'Series D1': '#3AA665',
  'Series C2': '#3AA674',
  'Series B3': '#3AA682',
  'Series BB': '#3AA691',
  'Series E1': '#3AA69F',
  'Series D2': '#3A9FA6',
  'Series C3': '#3A91A6',
  'Series AAA': '#3A49A6',
  'Series CC': '#A63A49',
  'Series E2': '#A63A82',
  'Series D3': '#A63A74'
};

export const getFundingStageBadgeColor = (stage: string) => {
  if (!get(FundingRoundBadgeColorMap, stage)) return 'neutral';
  return (FundingRoundBadgeColorMap?.[stage] ??
    'expressive-32') as ColorShorthand;
};
export const getFundingStageGraphColor = (stage: string) => {
  return FundingRoundChartColorMap?.[stage?.trim()] || '#493AA6';
};

export interface InvestorFundingRoundMapValue {
  isLead: boolean;
  name: string;
}
// Eg: [investor:1]: [Seed A, Seed B, Series A]
export const getInvestorFundingRoundsMap = (
  fundingRounds: (FundingRoundFragment | null)[]
) => {
  const investorFundingRoundsMap: Record<
    number,
    InvestorFundingRoundMapValue[]
  > = {};

  // Reversing so the oldest rounds are first. This is to maintain the order of Pre-seed, seed, Series A and so on
  const reversedFundingRounds = [...fundingRounds].reverse();

  for (const fundingRound of reversedFundingRounds) {
    const fundingDisplayName = getFundingTypeDisplayName(
      fundingRound?.fundingRoundType
    );
    for (const investorInfo of fundingRound?.investors ?? []) {
      if (investorInfo?.entityUrn) {
        const currentInvestments =
          investorFundingRoundsMap[investorInfo.entityUrn] || [];

        const hasExistingEntry = currentInvestments.some(
          (investment) => investment.name === fundingDisplayName
        );

        if (!hasExistingEntry)
          investorFundingRoundsMap[investorInfo.entityUrn] = [
            ...currentInvestments,
            {
              isLead: investorInfo.isLead ?? false,
              name: fundingDisplayName
            }
          ];
      }
    }
  }

  return investorFundingRoundsMap;
};

export const getFormattedFundingTotal = (
  fundingTotal: number | string,
  fundingAttributeNullStatus?: string | null
) => {
  const fundingTotalValue = !isNil(fundingTotal)
    ? parseFloat(fundingTotal as string)
    : null;

  if (
    isNumber(fundingTotalValue) &&
    !isNaN(fundingTotalValue) &&
    fundingTotalValue !== 0
  ) {
    const truncated = truncateMoneyValue(fundingTotalValue);
    // If it's a whole number (ends in .00), remove the decimals
    const match = truncated.match(/^(.*?)(M|K|B)?$/);
    if (match) {
      const [, number, suffix] = match;
      // If it ends in .00, remove the decimals
      if (number.endsWith('.00')) {
        return `${number.slice(0, -3)}${suffix || ''}`;
      }
      // If it ends in 0 after decimal (e.g., 56.30), remove trailing 0
      if (number.includes('.') && number.endsWith('0')) {
        return `${number.slice(0, -1)}${suffix || ''}`;
      }
      return truncated;
    }

    return truncated;
  }

  return get(
    SearchValueToDisplayNameMap,
    fundingAttributeNullStatus || 'UNKNOWN_VALUE'
  );
};

export const getSortedFundingRoundInvestors = (
  fundingRounds: FundingRoundFragment[]
) => {
  const allInvestors = fundingRounds.flatMap((round) => round.investors);
  allInvestors.sort((a, b) => (b?.isLead ? 1 : -1) - (a?.isLead ? 1 : -1));

  const uniqueInvestors = uniqBy(allInvestors, (i) => {
    const id = getIdFromUrn(i?.entityUrn);
    return id?.toString() !== '-1' ? i?.entityUrn : i?.investor?.name;
  });

  return uniqueInvestors;
};

type FundingRoundStyle = {
  icon: React.FC;
  textColor: string;
  bgColor: string;
  pillString: string;
  pillBgColor: string;
};

export const getFundingRoundStyle = (
  fundingRoundType: string
): FundingRoundStyle => {
  const defaultStyle: FundingRoundStyle = {
    icon: RoundIconUnknown,
    textColor: 'text-[#5A2020]',
    bgColor: 'bg-[#F4E1E1]',
    pillString: 'U',
    pillBgColor: 'bg-[#A63A3A] bg-opacity-20'
  };

  if (fundingRoundType.toLowerCase().includes('unknown')) {
    return defaultStyle;
  }

  if (fundingRoundType.toLowerCase().includes('seed')) {
    return {
      icon: RoundIconSeed,
      textColor: 'text-[#20465A]',
      bgColor: 'bg-[#E1EEF4]',
      pillString: fundingRoundType.toLowerCase().includes('pre') ? 'PS' : 'S',
      pillBgColor: 'bg-[#3A82A6] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('series')) {
    return {
      icon: RoundIconSeries,
      textColor: 'text-[#465A20]',
      bgColor: 'bg-[#EEF4E1]',
      pillString: fundingRoundType.toLowerCase().includes('_')
        ? fundingRoundType.split('_')[1]
        : 'U',
      pillBgColor: 'bg-[#82A63A] bg-opacity-20'
    };
  }
  if (
    fundingRoundType.toLowerCase().includes('debt') ||
    fundingRoundType.toLowerCase().includes('convertible')
  ) {
    return {
      icon: RoundIconDebt,
      textColor: 'text-[#5A4620]',
      bgColor: 'bg-[#F4EEE1]',
      pillString: fundingRoundType.toLowerCase().includes('convertible')
        ? 'CN'
        : 'Debt',
      pillBgColor: 'bg-[#A6823A] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('grant')) {
    return {
      icon: RoundIconGrant,
      textColor: 'text-[#493AA6]',
      bgColor: 'bg-[#E3E1F4]',
      pillString: 'Grant',
      pillBgColor: 'bg-[#493AA6] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('ipo')) {
    return {
      icon: RoundIconIPO,
      textColor: 'text-[#205A4E]',
      bgColor: 'bg-[#E1F4F0]',
      pillString: 'IPO',
      pillBgColor: 'bg-[#3AA691] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('secondary')) {
    return {
      icon: RoundIconSecondary,
      textColor: 'text-[#677183]',
      bgColor: 'bg-[#E8EAEE]',
      pillString: 'ST',
      pillBgColor: 'bg-[#677183] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('crowdsourcing')) {
    return {
      icon: RoundIconCrowdsourcing,
      textColor: 'text-[#20305A]',
      bgColor: 'bg-[#E1E6F4]',
      pillString: 'CS',
      pillBgColor: 'bg-[#3A57A6] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('strategic')) {
    return {
      icon: RoundIconStrategic,
      textColor: 'text-[#305A20]',
      bgColor: 'bg-[#E6F4E1]',
      pillString: 'Strategic',
      pillBgColor: 'bg-[#57A63A] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('m_and_a')) {
    return {
      icon: RoundIconMna,
      textColor: 'text-[#205A4E]',
      bgColor: 'bg-[#E1F4F0]',
      pillString: 'M&A',
      pillBgColor: 'bg-[#3AA691] bg-opacity-20'
    };
  }
  if (fundingRoundType.toLowerCase().includes('private')) {
    return {
      icon: RoundIconPE,
      textColor: 'text-[#205A30]',
      bgColor: 'bg-[#E1F4E6]',
      pillString: 'PE',
      pillBgColor: 'bg-[#57A63A] bg-opacity-20'
    };
  }
  return defaultStyle;
};

// TODO AbK: Push this to the BE and send a flag to FE to exclude from total funding
const EXCLUDED_FROM_FUNDING_TOTAL_ROUND_TYPES = ['SECONDARY_TRANSACTION'];
export const isExcludedFromFundingTotal = (
  fundingRoundType: string | undefined | null
) => {
  if (!fundingRoundType) return false;
  return EXCLUDED_FROM_FUNDING_TOTAL_ROUND_TYPES.includes(fundingRoundType);
};

export const getFundingFavIconsByDomain = (domain: string) => {
  if (domain.includes('techcrunch.com')) return techcrunchFavicon;
  if (domain.includes('techfundingnews.com')) return techfundingnewsFavicon;
  if (domain.includes('thesaasnews.com')) return theSaasNewsFavicon;
  if (domain.includes('eustartups.com')) return euStartupsFavicon;
  if (domain.includes('finsmes.com')) return finsmesFavicon;
  if (domain.includes('prnewswire.com')) return prNewsWireFavicon;
  if (domain.includes('techeu.com')) return techEuFavicon;
  if (domain.includes('cryptorank.com')) return cryptoRankFavicon;
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
};
