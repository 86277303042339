import {
  AcceleratorCohortDataResponse,
  AcceleratorDataResponse,
  AcceleratorFilterQueryResponse,
  AcceleratorGroupDataResponse,
  AcceleratorQuerySelections
} from 'interfaces/Accelerator';

export function mapInitialStateToSelections(
  acceleratorData: AcceleratorFilterQueryResponse,
  initialState: {
    accelerator_groups?: string[];
    accelerators?: string[];
    accelerator_cohorts?: string[];
  }
): string[] {
  const selectedCohorts = new Set<string>(
    initialState.accelerator_cohorts || []
  );

  // Add cohorts from selected groups
  initialState.accelerator_groups?.forEach((groupUrn) => {
    const group = acceleratorData.getAcceleratorFilters.acceleratorGroups.find(
      (g: AcceleratorGroupDataResponse) => g.entityUrn === groupUrn
    );
    group?.accelerators.forEach((acc: AcceleratorDataResponse) => {
      acc.cohorts.forEach((cohort) => {
        selectedCohorts.add(cohort.entityUrn);
      });
    });
  });

  // Add cohorts from selected accelerators
  initialState.accelerators?.forEach((accUrn) => {
    // First check in top-level accelerators
    let accelerator = acceleratorData.getAcceleratorFilters.accelerators.find(
      (a: AcceleratorDataResponse) => a.entityUrn === accUrn
    );

    // If not found, check in groups
    if (!accelerator) {
      acceleratorData.getAcceleratorFilters.acceleratorGroups.forEach(
        (group) => {
          const groupAcc = group.accelerators.find(
            (a) => a.entityUrn === accUrn
          );
          if (groupAcc) accelerator = groupAcc;
        }
      );
    }

    accelerator?.cohorts.forEach((cohort: AcceleratorCohortDataResponse) => {
      selectedCohorts.add(cohort.entityUrn);
    });
  });

  return Array.from(selectedCohorts);
}

export function convertToBackendData(
  cohortUrns: string[],
  acceleratorData: AcceleratorFilterQueryResponse
): AcceleratorQuerySelections | undefined {
  const selectedCohorts = new Set(cohortUrns);
  const selectedAccelerators = new Set<string>();
  const selectedGroups = new Set<string>();

  // Check groups first
  const acceleratorGroups =
    acceleratorData.getAcceleratorFilters.acceleratorGroups;
  for (const group of acceleratorGroups) {
    const allGroupCohorts = group.accelerators.flatMap((acc) =>
      acc.cohorts.map((c) => c.entityUrn)
    );
    const hasAllGroupCohorts = allGroupCohorts.every((cohort) =>
      selectedCohorts.has(cohort)
    );

    if (hasAllGroupCohorts) {
      selectedGroups.add(group.entityUrn);
      // Remove these cohorts from individual tracking
      allGroupCohorts.forEach((c) => selectedCohorts.delete(c));
      continue; // Skip checking individual accelerators in this group
    }

    // Only check individual accelerators if the entire group isn't selected
    group.accelerators.forEach((accelerator) => {
      const acceleratorCohorts = accelerator.cohorts.map((c) => c.entityUrn);
      if (acceleratorCohorts.every((c) => selectedCohorts.has(c))) {
        selectedAccelerators.add(accelerator.entityUrn);
        acceleratorCohorts.forEach((c) => selectedCohorts.delete(c));
      }
    });
  }

  // Check remaining top-level accelerators
  acceleratorData.getAcceleratorFilters.accelerators.forEach((accelerator) => {
    const acceleratorCohorts = accelerator.cohorts.map((c) => c.entityUrn);
    if (acceleratorCohorts.every((c) => selectedCohorts.has(c))) {
      selectedAccelerators.add(accelerator.entityUrn);
      acceleratorCohorts.forEach((c) => selectedCohorts.delete(c));
    }
  });

  const result = {
    accelerator_groups: Array.from(selectedGroups),
    accelerators: Array.from(selectedAccelerators),
    accelerator_cohorts: Array.from(selectedCohorts)
  };

  // Return undefined if all arrays are empty
  if (
    result.accelerator_groups.length === 0 &&
    result.accelerators.length === 0 &&
    result.accelerator_cohorts.length === 0
  ) {
    return undefined;
  }

  return result;
}
