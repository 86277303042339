import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { GetPeopleWatchlistQuery } from '__generated__/graphql';
import { GET_PEOPLE_WATCHLIST } from 'queries/getPeopleWatchlist';
import { useBaseFetchSingleWatchlist } from './useBaseFetchSingleWatchlist';
import { DashboardLocation } from './useDashboardLocation';

interface UseFetchSingleWatchlistResults {
  peopleWatchlist?:
    | GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn']
    | null;
  peopleWatchlistLoading: boolean;
  peopleWatchlistError?: ApolloError;
}

const useFetchSinglePeopleWatchlist = ({
  fetchPolicy = 'cache-first',
  skipLocalSearch = false
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  skipLocalSearch?: boolean;
} = {}): UseFetchSingleWatchlistResults => {
  const { data, loading, error } = useBaseFetchSingleWatchlist<
    GetPeopleWatchlistQuery,
    GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn']
  >({
    fetchPolicy,
    query: GET_PEOPLE_WATCHLIST,
    location: DashboardLocation.PERSON_LIST,
    transformData: (data) => data?.getPeopleWatchlistByIdOrUrn,
    skipLocalSearch
  });

  return {
    peopleWatchlist: data,
    peopleWatchlistLoading: loading,
    peopleWatchlistError: error
  };
};

export default useFetchSinglePeopleWatchlist;
