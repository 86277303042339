import { gql } from '@apollo/client';

export const GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS = gql`
  fragment SavedSearchNotificationSubscription on SavedSearchNotificationSubscription {
    savedSearchUrn
    slackIntegrationUrn
    subscriptionUrn
    fieldsToShow
    frequency
    isEnabled
    notificationCap
  }
  query GetSavedSearchNotificationSubscriptions {
    getSavedSearchNotificationSubscriptions {
      ...SavedSearchNotificationSubscription
    }
  }
`;
export const GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS_MOCKED_RESPONSE = {
  getSavedSearchNotificationSubscriptions: []
};

export const GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS_MOCK = {
  request: {
    query: GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS
  },
  result: {
    data: {
      getSavedSearchNotificationSubscriptions: [
        {
          savedSearchUrn: 'urn:harmonic:saved_search:1',
          slackIntegrationUrn: 'urn:harmonic:slack_integration:1',
          subscriptionUrn:
            'urn:harmonic:saved_search_notification_subscription:1',
          fieldsToShow: [
            'HEADCOUNT',
            'FOUNDING_DATE',
            'FUNDING_TOTAL',
            'LOCATION'
          ],
          frequency: '00 16 * * *',
          isEnabled: true,
          notificationCap: 5,
          __typename: 'SavedSearchNotificationSubscription'
        }
      ]
    }
  }
};
