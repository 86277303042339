import { HarmonicIcon } from '../type';

const LeadInvestorIcon: HarmonicIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M6.31388 1.66978C6.24461 1.56385 6.12658 1.5 6.00001 1.5C5.87345 1.5 5.75542 1.56385 5.68615 1.66978L3.75616 4.62153L1.06397 3.05108C0.936197 2.97655 0.776553 2.98409 0.656377 3.07033C0.5362 3.15657 0.477949 3.3054 0.507655 3.45031L1.70699 9.30072C1.79043 9.70777 2.14865 10 2.56416 10H9.43587C9.85138 10 10.2096 9.70777 10.293 9.30072L11.4924 3.45031C11.5221 3.3054 11.4638 3.15657 11.3437 3.07033C11.2235 2.98409 11.0638 2.97655 10.9361 3.05108L8.24387 4.62153L6.31388 1.66978Z"
      fill="#653AA6"
    />
  </svg>
);
export default LeadInvestorIcon;
