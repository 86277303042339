import { useMutation } from '@apollo/client';
import {
  ConnectionIntegrationType,
  CustomerIntegrationPrivacySetting,
  UpdateCustomerIntegrationMutation,
  UpdateCustomerIntegrationMutationVariables
} from '__generated__/graphql';
import CircleCheckFilledIcon from 'assets/harmonic-icons/circle-check-filled';
import MicrosoftIcon from 'assets/harmonic-icons/microsoft';
import { Tooltip } from 'common';
import Button from 'harmonic-components/Button/Button';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import useUser from 'hooks/useUser';
import { UPDATE_CUSTOMER_INTEGRATION_MUTATION } from 'queries/updateCustomerIntegration';
import React, { useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL, SPLITS } from 'utils/constants';
import { authenticateMicrosoftIntegration } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import EmailCalendarTermsModal from './EmailCalendarTermsModal';

enum AuthStatus {
  None,
  GettingStarted,
  ReadyToSign,
  Pending,
  MissingScopes,
  Valid,
  Scheduled,
  Error,
  NonValidEmail
}

const scopes = [
  'User.Read',
  'Mail.ReadBasic',
  'Calendars.ReadBasic',
  'Calendars.Read',
  'offline_access',
  'profile',
  'email',
  'openid'
];

const clientId = '2826eb6d-68db-4346-b5a0-042ccf7f5406';

const authorizationEndpoint =
  'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

const authorizationUrl = new URL(authorizationEndpoint);
authorizationUrl.searchParams.append('client_id', clientId);
authorizationUrl.searchParams.append('response_type', 'code');
authorizationUrl.searchParams.append(
  'redirect_uri',
  `${BASE_URL}/integration-auth/microsoft`
);
authorizationUrl.searchParams.append('scope', scopes.join(' '));

interface IntegrateOutlookButtonProps {
  isIntegrated?: boolean;
  type: 'primary' | 'secondary';
  onSuccess?: () => void;
  labelOverride?: string;
}

const IntegrateOutlookButton: React.FC<IntegrateOutlookButtonProps> = ({
  isIntegrated = false,
  type,
  onSuccess,
  labelOverride
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { show, close } = useModal();

  const { enabled: restrictNetworkMappingMetadataOnly } = useFlags(
    SPLITS.restrictNetworkMappingMetadataOnly
  );
  const [updateCustomerIntegration] = useMutation<
    UpdateCustomerIntegrationMutation,
    UpdateCustomerIntegrationMutationVariables
  >(UPDATE_CUSTOMER_INTEGRATION_MUTATION);

  const { enabled: blockEmailIntegration } = useFlags(
    SPLITS.blockEmailIntegration
  );

  const { setIntegrationStatus } = useUser();

  const [authStatus, setAuthStatus] = React.useState<AuthStatus>(
    AuthStatus.ReadyToSign
  );

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status') || null;
    const authCode = params.get('code') || '';

    // If something goes wrong while fetching the auth code from the redirect
    if (status && status == 'error') {
      setAuthStatus(AuthStatus.Error);
      displayToast({
        primaryText: 'Some problem occured. Please try again later',
        mode: 'error'
      });
    }

    // Pull the auth code from the redirect and send to our backend for token exchange
    if (authCode) {
      //remove auth code to prevent retriggering
      searchParams.delete('code');
      setSearchParams(searchParams);

      setAuthStatus(AuthStatus.Pending);
      authenticateMicrosoftIntegration(authCode)
        .then(async (response) => {
          const statusText = response.data.status;
          if (statusText === 'success') {
            // TODO: Update authenticate endpoint to pass this in as part of initial request
            if (restrictNetworkMappingMetadataOnly) {
              await updateCustomerIntegration({
                variables: {
                  input: {
                    integrationType: ConnectionIntegrationType.MICROSOFT,
                    privacySetting: CustomerIntegrationPrivacySetting.LIMITED
                  }
                }
              });
            }

            setAuthStatus(AuthStatus.Valid);
            setIntegrationStatus(ConnectionIntegrationType.MICROSOFT, true);

            toast.success(
              <p>
                Microsoft account connected successfully. Data may take up to 24
                hours to appear in app. You may check sync status in your{' '}
                <Link className="underline" to="/settings?t=emailCalendar">
                  Settings
                </Link>
                .
              </p>,
              { autoClose: false }
            );
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((error) => {
          const errorDetails = error.response.data.detail;
          if (errorDetails == 'Missing scopes') {
            setAuthStatus(AuthStatus.MissingScopes);
            toast.error(
              'To set up this integration, you need to grant permissions for both email and calendar. Please try again.',
              { autoClose: false }
            );
            return;
          } else if (errorDetails == 'Email not active for user') {
            setAuthStatus(AuthStatus.NonValidEmail);
            toast.error(
              'To set up this integration, you need to have a valid Outlook account associated with your user. Please try again.',
              { autoClose: false }
            );
            return;
          } else {
            setAuthStatus(AuthStatus.Error);
            displayToast({
              primaryText: 'Some problem occured. Please try again later',
              mode: 'error'
            });
          }
        });
    }
  }, [
    location.search,
    onSuccess,
    restrictNetworkMappingMetadataOnly,
    updateCustomerIntegration,
    searchParams,
    setSearchParams,
    setIntegrationStatus
  ]);

  const handleLogin = async () => {
    window.location.href = authorizationUrl.toString();
  };

  const onClick = () => {
    show(
      <EmailCalendarTermsModal
        handleClose={close}
        onConfirmClick={() => {
          handleLogin();
          close();
        }}
        integrationType={ConnectionIntegrationType.MICROSOFT}
      />
    );
  };

  let ButtonComponent = null;

  const isLoading = authStatus === AuthStatus.Pending;
  if (type === 'primary') {
    ButtonComponent = () => (
      <Button
        dataTestId="integrate-Outlook-button"
        leadingIcon={() => <MicrosoftIcon />}
        trailingIcon={
          isIntegrated
            ? () => (
                <CircleCheckFilledIcon
                  className="text-content-sentiment-positive"
                  applyCurrentColor={true}
                />
              )
            : undefined
        }
        isDisabled={isLoading || isIntegrated || blockEmailIntegration}
        loading={isLoading}
        type={isIntegrated ? 'secondary' : 'primary'}
        size="compact"
        emphasis="high"
        label={labelOverride || (isIntegrated ? 'Connected' : 'Connect')}
        onClick={onClick}
      />
    );
  } else {
    ButtonComponent = () => (
      <Button
        dataTestId="integrate-Outlook-button"
        leadingIcon={MicrosoftIcon}
        trailingIcon={
          isIntegrated
            ? () => <CircleCheckFilledIcon applyCurrentColor={false} />
            : undefined
        }
        isDisabled={isLoading || isIntegrated || blockEmailIntegration}
        loading={isLoading}
        type="secondary"
        size="compact"
        emphasis="high"
        label={
          labelOverride ||
          (isIntegrated ? 'Connected' : 'Connect Microsoft account')
        }
        onClick={onClick}
      />
    );
  }

  return (
    <Tooltip
      title={
        blockEmailIntegration
          ? 'Email integration disabled by your administrator.'
          : ''
      }
    >
      <ButtonComponent />
    </Tooltip>
  );
};

const AuthProviderWrapper: React.FC<IntegrateOutlookButtonProps> = (props) => {
  return <IntegrateOutlookButton {...props} />;
};

export default AuthProviderWrapper;
