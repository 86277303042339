import {
  CompanyListCustomFieldValueFragment,
  Highlight,
  Person,
  User
} from '__generated__/graphql';
import {
  ApiResourceType,
  GraphQLResourceType,
  ICompanyViewRecord
} from './ApiResource';

export interface IInvestor {
  type: ApiResourceType.Company | ApiResourceType.Person;
  __typename?: ApiResourceType.Company | ApiResourceType.Person;
  typename?: GraphQLResourceType.Company | GraphQLResourceType.Person;
  id: number;
  name: string;
  fullName?: string;
  logo_url?: string;
  investor_urn?: string;
}
interface IGrowthMetric {
  percent_change_14d_ago: number;
  percent_change_30d_ago: number;
  percent_change_90d_ago: number;
  percent_change_180d_ago: number;
  percent_change_365d_ago: number;
  real_change_14d_ago: number;
  real_change_30d_ago: number;
  real_change_90d_ago: number;
  real_change_180d_ago: number;
  real_change_365d_ago: number;
  value_14d_ago: number;
  value_30d_ago: number;
  value_90d_ago: number;
  value_180d_ago: number;
  value_365d_ago: number;
}

export enum CompanyCustomTextScope {
  USER = 'USER',
  TEAM = 'TEAM'
}

interface ICompanySnapshot {
  id: number;
  created_at: string;
  headcount: number;
  funding_total: number;
  instagram_num_followers: number;
  facebook_total_page_likes: number;
  twitter_total_followers: number;
  linkedin_follower_count: number;
}

export interface ICompanyWrapped {
  company: ICompany;
  entryUrn?: string;
  customFieldValues?: CompanyListCustomFieldValueFragment[];
  netNewId?: number;
}

interface IPerson {
  id: number;
  fullName: string;
}
interface IUserConnection {
  target_person: IPerson;
  user: User;
}

export interface ICompany {
  id: number;
  entity_urn: string;
  name: string;
  logo_url: string;
  legal_name: string;
  headquarters: string;
  country: string;
  state: string;
  city: string;
  stage: string;

  description: string;
  highlights: Highlight[];
  employee_highlights: Highlight[];
  funding_total: number;
  headcount: number;
  founding_date: Date;
  founding_year: number;

  company_emails: string[];
  contact_numbers: string[];

  investors: IInvestor[];
  num_funding_rounds: number;
  last_funding_at: Date;
  last_funding_total: number;
  funding_stage: string;
  last_funding_type: string;
  funding_per_employee: number;
  funding_attribute_null_status: string;

  linkedin_follower_count: number;
  // TODO: These property names are not right (and maybe other ones too), you can see the real
  // shape of the company class looking at the object returned by mapCompaniesGQLToLegacyAPI.
  // In that function we do trick the compiler into thinking that the object is an ICompany
  // but it isn't. We should fix this.
  twitter_follower_count: number;
  instagram_follower_count: number;
  facebook_page_like_count: number;

  likelihood_of_backing: number;
  ownership_status: string;
  website_domain: string;

  linkedin_url: string;
  twitter_url: string;
  twitter_handle: string;
  crunchbase_url: string;
  pitchbook_url: string;
  instagram_url: string;
  facebook_url: string;
  angellist_url: string;
  indeed_url: string;
  website_url: string;
  youtube_url: string;
  monster_url: string;
  stackoverflow_url: string;

  customer_type: string;
  industry_tags: string[];
  technology_tags: string[];
  accelerators: string[];

  person_relationships: Person[];
  company_followed_by: string[];
  updated_at: string;
  traction_metrics: Record<string, IGrowthMetric>;
  company_snapshots: ICompanySnapshot[];
  team_custom_fields?: {
    custom_text: string;
  };
  view_record: ICompanyViewRecord;

  user_connections: IUserConnection[];
}

export enum SocialMetricsType {
  WEB_TRAFFIC = 'WEB_TRAFFIC',
  FACEBOOK_LIKES = 'FACEBOOK_LIKES',
  TWITTER_FOLLOWERS = 'TWITTER_FOLLOWERS',
  INSTAGRAM_FOLLOWERS = 'INSTAGRAM_FOLLOWERS',
  LINKEDIN_FOLLOWERS = 'LINKEDIN_FOLLOWERS'
}

export enum HeadcountMetricsType {
  CORRECTED_HEADCOUNT = 'CORRECTED_HEADCOUNT',
  LINKEDIN_HEADCOUNT = 'LINKEDIN_HEADCOUNT'
}

export enum InitialScrollToSection {
  SOCIALS_HEADCOUNT = 'SOCIALS_HEADCOUNT'
}
