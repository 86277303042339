import { HarmonicIcon } from '../type';

const LeadInvestorIconGrey: HarmonicIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M10.5233 2.78297C10.4079 2.60641 10.2111 2.5 10.0002 2.5C9.78924 2.5 9.59252 2.60641 9.47708 2.78297L6.26043 7.70255L1.77344 5.08514C1.56049 4.96092 1.29442 4.97348 1.09412 5.11722C0.89383 5.26096 0.796744 5.50901 0.846254 5.75051L2.84514 15.5012C2.98422 16.1796 3.58124 16.6667 4.27377 16.6667H15.7266C16.4191 16.6667 17.0162 16.1796 17.1552 15.5012L19.1541 5.75051C19.2036 5.50901 19.1065 5.26096 18.9063 5.11722C18.706 4.97348 18.4399 4.96092 18.2269 5.08514L13.7399 7.70255L10.5233 2.78297Z"
      fill="#7C8598"
    />
  </svg>
);

export default LeadInvestorIconGrey;
