import {
  PeopleWatchlistFragment,
  WatchlistFragment
} from '__generated__/graphql';

export enum UserWatchlistType {
  USER_MANAGED_WATCHLIST = 'USER_MANAGED_WATCHLIST',
  USER_FAVORITED_ENTITIES = 'USER_FAVORITED_ENTITIES',
  USER_HIDDEN_ENTITIES = 'USER_HIDDEN_ENTITIES',
  CUSTOMER_AFFINITY_SYNCED_LIST = 'CUSTOMER_AFFINITY_SYNCED_LIST',
  CUSTOMER_MANAGED_WATCHLIST = 'CUSTOMER_MANAGED_WATCHLIST'
}

export const getSingletonWatchlist = (
  watchlists: WatchlistFragment[],
  userWatchlistType: UserWatchlistType
): WatchlistFragment | null => {
  const filteredWatchlists = watchlists.filter(
    (watchlist) => watchlist.userWatchlistType == userWatchlistType
  );
  if (filteredWatchlists.length !== 1) {
    return null;
  }
  return filteredWatchlists[0];
};

export const sortWatchlists = (watchlists: PeopleWatchlistFragment[]) => {
  if (!watchlists) {
    return [];
  }
  return watchlists.sort((a, b) => {
    if (!a || !b) {
      return 0;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export const isUserListTypeEditable = (
  userWatchlistType: UserWatchlistType
) => {
  return (
    userWatchlistType === UserWatchlistType.USER_MANAGED_WATCHLIST ||
    userWatchlistType === UserWatchlistType.CUSTOMER_MANAGED_WATCHLIST ||
    userWatchlistType === UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST
  );
};
