import { GridApi } from 'ag-grid-community';
import { useCallback, useContext } from 'react';
import { SelectListCustomFieldValueOption } from '../../../../../__generated__/graphql';
import Select, {
  MultiSelectProps,
  SelectBaseProps
} from '../../../../../harmonic-components/Select/Select';

import { ColorShorthand } from 'common/utils/design';
import { BoardContext } from '../../../../common/Board/BoardView';

export const CustomFieldSelect = (
  props: React.PropsWithChildren<
    SelectBaseProps &
      MultiSelectProps & {
        size?: 'small';
        options: SelectListCustomFieldValueOption[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        api: GridApi<any>;
      }
  >
) => {
  const { isDragging } = useContext(BoardContext);
  const { options, api, children, ...rest } = props;
  const getLabelFromValue = useCallback(
    (value?: string) => {
      return options.find((option) => option.urn === value)?.name ?? '';
    },
    [options]
  );

  const getTagColorFromValue = useCallback(
    (value?: string) => {
      return (
        (options.find((option) => option.urn === value)
          ?.color as ColorShorthand) || 'neutral'
      );
    },
    [options]
  );

  const handleClose = useCallback(() => {
    api.stopEditing();
  }, [api]);

  return (
    <Select
      {...rest}
      initialFocus
      alwaysOpen
      multiple
      filterable
      onClose={handleClose}
      hideDropdown={isDragging}
      getLabelFromValue={getLabelFromValue}
      getTagColorFromValue={getTagColorFromValue}
      dropdownMaxHeight="33vh"
      size={props.size}
      minHeight={77}
      {...(props.size === 'small' && {
        minHeight: 28
      })}
    >
      {children}
    </Select>
  );
};
