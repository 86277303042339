import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import {
  GetPersonContactInformationQuery,
  GetPersonContactInformationQueryVariables
} from '__generated__/graphql';
import { ReactComponent as EmailUnlockImage } from 'assets/email-unlock.svg';
import { ReactComponent as RefreshIcon } from 'assets/harmonic-icons/refresh.svg';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import Email from 'harmonic-components/Email/Email';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import Tag from 'harmonic-components/Tag/Tag';
import useRefreshPersonEmails from 'hooks/useRefreshPersonEmails';
import { compact } from 'lodash';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import React, { useCallback } from 'react';

const GettingAddress = () => (
  <div className="flex gap-g40 bg-int-fill-secondary-disabled text-int-fill-secondary-onDisabled py-p20 px-p40 rounded-br20">
    <p className="typography-label-default-default">Getting address...</p>
    <div>
      <CircularProgress color="inherit" size={12} />
    </div>
  </div>
);

interface EmailUnlockProps {
  onUnlock: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const EmailUnlock: React.FC<EmailUnlockProps> = ({
  onUnlock,
  disabled,
  loading
}) => {
  if (disabled) {
    return (
      <div
        data-testid="EmailUnlock"
        className="flex flex-col justify-center items-center gap-g40"
      >
        <p
          className="flex justify-center typography-label-default-strong pt-p50"
          data-testid="NotEnoughCredits"
        >
          You have insufficient credits
        </p>
        <div className="typography-label-small-default text-content-weak text-center">
          <p className="flex items-center gap-g20">
            You need <Tag label="1" /> more credit to
          </p>
          <p>request this email.</p>
        </div>
      </div>
    );
  }
  return (
    <div
      data-testid="EmailUnlock"
      className="flex flex-col justify-center items-center gap-g60"
    >
      <p className="flex justify-center typography-label-default-strong pt-p50">
        Get this person’s email address
      </p>
      {loading ? (
        <GettingAddress />
      ) : (
        <Button
          size="compact"
          onClick={onUnlock}
          isDisabled={disabled}
          label="Request email address"
        />
      )}
    </div>
  );
};

interface EmailListProps {
  emails: string[];
}

const EmailList: React.FC<EmailListProps> = ({ emails }) => {
  return (
    <div>
      <p className="flex justify-center typography-label-default-strong pt-p50">
        Click to copy the email address
      </p>
      <div
        data-testid="EmailList"
        className="flex flex-row items-start justify-center p-4"
      >
        <div className="flex flex-col pl-2 gap-g20">
          {emails.map((email) => (
            <div
              className="flex flex-col items-center justify-center"
              key={email}
            >
              <Email key={email} email={email} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const EmailUnavailable = () => (
  <div
    className="flex flex-col justify-center items-center gap-g40 px-p100"
    data-testid="EmailUnavailable"
  >
    <p className="flex justify-center typography-label-default-strong pt-p50">
      Email address unavailable
    </p>
    <p className="typography-label-small-default text-content-weak text-center">
      Unfortunately, this person’s email address <br /> isn’t available right
      now.
    </p>
  </div>
);

interface PersonEmailUnlockModalProps {
  personId: number;
}

const PersonEmailUnlockModal: React.FC<PersonEmailUnlockModalProps> = ({
  personId
}) => {
  const { refreshPersonEmails, loading } = useRefreshPersonEmails();
  const { data: personContactInformation } = useQuery<
    GetPersonContactInformationQuery,
    GetPersonContactInformationQueryVariables
  >(getPersonContactInformation, {
    variables: {
      id: personId
    },
    fetchPolicy: 'cache-only'
  });
  const name = personContactInformation?.getPersonById?.fullName ?? '';
  const emails = compact(
    personContactInformation?.getPersonById?.contact?.emails ?? []
  );
  const emailEnrichedAt =
    personContactInformation?.getPersonById?.emailEnrichmentStatus?.enrichedAt;
  const isUnlockable = emails.length === 0 && emailEnrichedAt === null;
  const emailAddressText =
    emails.length > 1 ? 'email addresses' : 'email address';

  const onUnlock = useCallback(() => {
    refreshPersonEmails(`urn:harmonic:person:${personId}`);
  }, [personId, refreshPersonEmails]);

  const lastRequestedDate = emailEnrichedAt
    ? dayjs(emailEnrichedAt).format('MM/DD/YYYY')
    : null;

  const showRefreshButton = emailEnrichedAt
    ? dayjs(emailEnrichedAt).isBefore(dayjs().subtract(1, 'months'))
    : true;

  const LastUpdated = useCallback(
    () => (
      <div
        data-testid="LastUpdated"
        className="flex flex-col justify-center items-center pt-p40 px-4 pb-2 gap-g40"
      >
        <p className="typography-label-small text-content-weak">
          Last updated: {lastRequestedDate ? lastRequestedDate : 'N/A'}
        </p>

        {showRefreshButton && !loading && (
          <div className="flex flex-col items-center justify-center gap-g40">
            <button
              id="RequestEmailsButton"
              onClick={onUnlock}
              className="flex justify-center items-center hover:underline typography-label-small text-content-title gap-g20"
            >
              <RefreshIcon />
              <p>Refresh</p>
            </button>
          </div>
        )}
        {loading && <GettingAddress />}
      </div>
    ),
    [lastRequestedDate, loading, onUnlock, showRefreshButton]
  );

  return (
    <div className="w-px-480">
      {/* HEADER */}
      <ModalTitle
        title={
          isUnlockable
            ? `Request ${name}'s ${emailAddressText}`
            : `${name}'s ${emailAddressText}`
        }
      />

      {/* BODY */}
      <div className="px-p50 py-p80">
        <div className="flex w-full justify-center">
          <EmailUnlockImage />
        </div>
        {isUnlockable ? (
          <EmailUnlock loading={loading} onUnlock={onUnlock} disabled={false} />
        ) : emails.length > 0 ? (
          <EmailList emails={emails} />
        ) : (
          <EmailUnavailable />
        )}
        {emails.length > 0 && <LastUpdated />}
      </div>
    </div>
  );
};

export default PersonEmailUnlockModal;
