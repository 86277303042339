import classNames from 'classnames';
import { IconProps } from './type';

const FilterIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M1.8335 3.16666H14.1668M5.8335 12.8333H10.1668M3.8335 7.99999H12.1668"
      />
    </svg>
  );
};

export default FilterIcon;
