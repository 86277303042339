import { useApolloClient, useMutation } from '@apollo/client';
import {
  UpsertUserListConfigMutation,
  UpsertUserListConfigMutationVariables
} from '__generated__/graphql';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { UpsertUseListConfig } from 'queries/upsertUseListConfig';
import { logger } from 'utils/logger';
import { displayToast } from 'utils/toasts';
import { getInputVariableByType } from '../components/Dashboard/Collections/utils';

interface UseUpdateFavoriteListProps {
  id: string;
  type: EntityListType;
  isPinned: boolean;
}

const useUpdateFavoriteList = ({
  id,
  type,
  isPinned
}: UseUpdateFavoriteListProps) => {
  const client = useApolloClient();
  const [updateFavoriteList] = useMutation<
    UpsertUserListConfigMutation,
    UpsertUserListConfigMutationVariables
  >(UpsertUseListConfig, {
    onError: (error) => {
      if (error) {
        logger.error('Error updating favorite list', { error });
      }
    },
    optimisticResponse: {
      upsertUserListConfig: {
        id: id,
        isPinned: !isPinned
      }
    },
    update: (existingCache, res) => {
      existingCache.modify({
        id: client.cache.identify({
          __typename:
            type === EntityListType.PEOPLE_WATCHLIST
              ? 'PeopleWatchlist'
              : 'CompanyWatchlist',
          id: id
        }),
        fields: {
          userOptions() {
            return {
              isPinned: res.data?.upsertUserListConfig.isPinned,
              id: res.data?.upsertUserListConfig.id
            };
          }
        },
        broadcast: false
      });
    }
  });

  const toggleFavoriteList = async () => {
    try {
      await updateFavoriteList({
        variables: {
          input: {
            ...getInputVariableByType({ type, id }),
            isPinned: isPinned ? false : true
          }
        }
      });
    } catch (err) {
      displayToast({
        primaryText: `Unable to ${isPinned ? 'unpin' : 'pin'} the list`,
        mode: 'error'
      });
    }
  };
  return toggleFavoriteList;
};

export default useUpdateFavoriteList;
