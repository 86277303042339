import { CrossSmallIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import IconButton from 'harmonic-components/IconButton/IconButton';

interface CollapsibleFieldWrapperProps {
  children: React.ReactNode;
  onRemove: () => void;
  title: string | React.ReactNode;
  closeBtnTestId?: string;
}

const CollapsibleFieldWrapper: React.FC<CollapsibleFieldWrapperProps> = ({
  children,
  onRemove,
  title,
  closeBtnTestId
}) => {
  return (
    <div>
      <div className="pb-p40 mb-g50 border-solid border-b border-border w-full flex justify-between">
        <p
          className={classNames(
            'typography-title-extraSmall text-content-title '
          )}
        >
          {title}
        </p>
        <Tooltip title="Remove filter" alignment="left">
          <IconButton
            icon={CrossSmallIcon}
            dataTestId={closeBtnTestId}
            type="secondary"
            emphasis="high"
            size="tiny"
            onClick={onRemove}
          />
        </Tooltip>
      </div>
      {children}
    </div>
  );
};

export default CollapsibleFieldWrapper;
