import React from 'react';
import { IconProps } from './type';

const NotInNetwork: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(applyCurrentColor && { fill: 'currentColor' })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33163 5.48306L0.947533 2.09896C0.752271 1.9037 0.752271 1.58712 0.947533 1.39185C1.1428 1.19659 1.45938 1.19659 1.65464 1.39185L10.5857 10.3229C10.781 10.5182 10.781 10.8348 10.5857 11.03C10.3904 11.2253 10.0739 11.2253 9.8786 11.03L7.86234 9.01377H4.29573C4.06156 9.70447 3.40783 10.2015 2.638 10.2015C1.6715 10.2015 0.888001 9.41805 0.888001 8.45155C0.888001 7.48505 1.6715 6.70155 2.638 6.70155C2.95056 6.70155 3.24397 6.78349 3.49794 6.92706L4.33163 5.48306ZM6.86234 8.01377H4.3328C4.30359 7.90039 4.26332 7.79147 4.21319 7.68821L5.06368 6.21511L6.86234 8.01377Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M6.77629 4.4804L7.7309 6.13384L11.1688 9.57172C11.4219 9.26821 11.5742 8.87766 11.5742 8.45155C11.5742 7.48505 10.7907 6.70155 9.82421 6.70155C9.62898 6.70155 9.44122 6.73352 9.26588 6.7925L7.62482 3.95011C7.8971 3.64162 8.06231 3.23639 8.06231 2.79257C8.06231 1.82607 7.27881 1.04257 6.31231 1.04257C5.34581 1.04257 4.56231 1.82607 4.56231 2.79257C4.56231 2.85404 4.56548 2.91477 4.57167 2.97461L6.13028 4.53322C6.19011 4.5394 6.25084 4.54257 6.31231 4.54257C6.47294 4.54257 6.62852 4.52093 6.77629 4.4804Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default NotInNetwork;
