import React from 'react';

import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import { PeopleSearchExperienceGeneratorKey } from 'utils/searchV2/searchV2';
import CurrentOrPastExperience from './CurrentOrPastExperience';

interface PastExperienceSectionPropsProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  expandedFields: PeopleSearchExperienceGeneratorKey[];
  onUpdateExpandedFields: (
    newExpandedFields: PeopleSearchExperienceGeneratorKey[]
  ) => void;
  disabled?: boolean;
}

const PastExperienceSectionProps: React.FC<PastExperienceSectionPropsProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  expandedFields,
  onUpdateExpandedFields,
  disabled
}) => {
  return (
    <CurrentOrPastExperience
      searchQuery={searchQuery}
      onUpdateSearchQuery={onUpdateSearchQuery}
      expandedFields={expandedFields}
      onUpdateExpandedFields={onUpdateExpandedFields}
      disabled={disabled}
      mode="past"
    />
  );
};

export default PastExperienceSectionProps;
