import { useMemo } from 'react';
import { camelize } from 'utils/utilities';

import { GetWatchlistWithCompaniesQueryVariables } from '__generated__/graphql';
import { getIdFromUrn } from 'common/utils/urn';
import {
  INITIAL_COMPANY_SORT,
  INITIAL_SEARCH_MODEL,
  ISearchModel
} from 'interfaces/SearchModel/Search';
import { get } from 'lodash';
import { isValidSortField } from 'utils/sort';
import useDashboardLocation from './useDashboardLocation';
import { useSearchSort } from './useSearchSort';

type SearchVariablesHookResult = {
  watchlistSearchVariables: GetWatchlistWithCompaniesQueryVariables;
};

const useWatchlistSearchVariables = (
  watchlistSearchQuery?: ISearchModel
): SearchVariablesHookResult => {
  const { urn } = useDashboardLocation();

  const sort = useSearchSort();

  const formattedSort = useMemo(() => {
    if (!sort) {
      return undefined;
    }
    const validSortField = isValidSortField(sort.sortField ?? '');

    if (!validSortField) {
      return [
        {
          sortField: INITIAL_COMPANY_SORT.sortField,
          descending: INITIAL_COMPANY_SORT.sortDescending
        }
      ];
    }
    return [sort];
  }, [sort]);

  const watchlistSearchVariables = useMemo(() => {
    const sortDescending = get(
      formattedSort,
      '[0].descending',
      INITIAL_COMPANY_SORT.sortDescending
    );
    return {
      idOrUrn: getIdFromUrn(urn) ?? '',
      sortField: formattedSort?.[0].sortField || INITIAL_COMPANY_SORT.sortField,
      sortDescending,
      searchQuery: watchlistSearchQuery
        ? camelize(watchlistSearchQuery)
        : camelize(INITIAL_SEARCH_MODEL),
      skipExtended: true
    } as GetWatchlistWithCompaniesQueryVariables;
  }, [urn, formattedSort, watchlistSearchQuery]);

  return {
    watchlistSearchVariables
  };
};

export default useWatchlistSearchVariables;
