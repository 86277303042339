import {
  GetPeopleWatchlistQuery,
  PersonListNamedView
} from '__generated__/graphql';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from 'queries/getPeopleWatchlists';
import upsertPeopleWatchlistNamedViewMutation from 'queries/upsertPeopleWatchlistNamedViewMutation';
import useStore from 'stores/zustandStore';
import {
  PERSON_LIST_DEFAULT_COLUMNS,
  getSelectedPersonNamedViewIndex
} from 'utils/namedViews';
import useFetchSinglePeopleWatchlist from './useFetchSinglePeopleWatchlist';
import useUpsertWatchlistNamedViews from './useUpsertWatchlistNamedViews';

const useUpsertPeopleWatchlistNamedViews = () => {
  const personListSelectedNamedView = useStore(
    (state) => state.personListSelectedNamedView
  );
  const { peopleWatchlist } = useFetchSinglePeopleWatchlist({
    fetchPolicy: 'cache-only'
  });

  return useUpsertWatchlistNamedViews<
    PersonListNamedView,
    GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn']
  >({
    upsertMutation: upsertPeopleWatchlistNamedViewMutation,
    watchlistFragment: GET_PEOPLE_WATCHLIST_FRAGMENT,
    watchlistCacheIdName: 'PeopleWatchlist',
    watchlistFragmentName: 'PeopleWatchlist',
    namedViewCacheIdName: 'PersonListNamedView',
    backendResponseKey: 'upsertPeopleListNamedView',
    getSelectedNamedViewIndex: getSelectedPersonNamedViewIndex,
    watchlist:
      peopleWatchlist as GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn'],
    selectedNamedView: personListSelectedNamedView,
    gridViewTypeDefaultColumns: PERSON_LIST_DEFAULT_COLUMNS,
    kanbanViewTypeDefaultColumns: PERSON_LIST_DEFAULT_COLUMNS
  });
};

export default useUpsertPeopleWatchlistNamedViews;
