import Select from 'harmonic-components/Select/Select';
import { uniq } from 'lodash';
import { useState } from 'react';
import {
  getTagColorBySelectMode,
  getTagIconBySelectMode,
  SelectMode
} from './utils';

interface MultiselectWithMode {
  selected: string[];
  disabled?: boolean;
  dataTestId?: string;
  onChange: (newOptions?: string[]) => void;
  initialFocus?: boolean;
  label?: string;
  mode?: SelectMode;
  placeholder?: string;
}

const MultiselectWithMode: React.FC<MultiselectWithMode> = ({
  selected,
  onChange,
  initialFocus = false,
  label,
  mode = null,
  placeholder = 'Add role title',
  dataTestId
}) => {
  const [userInput, setUserInput] = useState('');
  const handleOnSubmit = (inputValue: string) => {
    if (inputValue === '') return;

    let inputValuesList = inputValue.split(',');
    // Trim values if there are more than one to avoid list separated by comma and spaces
    // In the case that there is only one value we allow spaces so we can match words
    if (inputValuesList.length > 1) {
      inputValuesList = inputValuesList.map((value) => value.trim());
    }

    const filteredInputValuesList = uniq([...selected, ...inputValuesList]);

    onChange(filteredInputValuesList);
  };

  const handleOnDelete = (value: string) => {
    const updatedValues = selected.filter(
      (selectedValue) => selectedValue !== value
    );
    onChange(updatedValues);
  };

  return (
    <div className="flex flex-col gap-g20">
      {label && (
        <label className="text-content-weak typography-label">{label}</label>
      )}
      <Select
        multiple
        selected={selected}
        onRemove={(keyword) => handleOnDelete(keyword)}
        filterable
        filterTerm={userInput}
        onFilterTermChange={(value) => setUserInput(value)}
        freeSolo
        placeholder={placeholder}
        initialFocus={initialFocus}
        getTagColorFromValue={() => getTagColorBySelectMode(mode)}
        getTagIconFromValue={() => getTagIconBySelectMode(mode)}
        onAdd={(value) => {
          handleOnSubmit(value);
          setUserInput('');
        }}
        hideDropdown={true}
        hideChevronDown={true}
        dataTestId={dataTestId}
      />
    </div>
  );
};

export default MultiselectWithMode;
