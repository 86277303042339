import { gql } from '@apollo/client';

export const GET_PEOPLE_NAMES_BY_IDS = gql`
  query GetPeopleNamesByIds($ids: [Int!]!) {
    getPersonsByIds(ids: $ids) {
      id
      fullName
    }
  }
`;
