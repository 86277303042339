import { FC } from 'react';

const CoinIcon: FC = () => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M8 1.333A6.674 6.674 0 001.333 8 6.674 6.674 0 008 14.667 6.674 6.674 0 0014.667 8 6.674 6.674 0 008 1.333zm0 12A5.34 5.34 0 012.667 8 5.34 5.34 0 018 2.667 5.34 5.34 0 0113.333 8 5.34 5.34 0 018 13.333z"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        fill="currentColor"
        d="M8 7.333c-1.333 0-1.333-.417-1.333-.666C6.667 6.344 7.134 6 8 6c.79 0 .924.425.933.679l.667-.012h.667c0-.684-.444-1.646-1.6-1.92v-.739H7.333v.715c-1.314.221-2 1.085-2 1.944 0 .746.347 2 2.667 2 1.333 0 1.333.45 1.333.666C9.333 9.61 8.92 10 8 10c-1.227 0-1.326-.571-1.333-.667H5.333c0 .612.441 1.702 2 1.947V12h1.334v-.723c1.314-.221 2-1.085 2-1.944 0-.746-.347-2-2.667-2z"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  );
};

export default CoinIcon;
