import React from 'react';
import { IconProps } from './type';

const HarmonicNoneIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="43"
      height="50"
      viewBox="0 0 43 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.7457 0L21.4913 6.20363L21.4913 18.6109L10.7457 24.8145L0 18.6109L0 6.20363L10.7457 0ZM0 31.0181L0 43.4254L10.7457 49.6271L21.4913 43.4254V31.0181L10.7457 24.8145L0 31.0181ZM21.5068 18.6109V31.0181L32.2524 37.2218L43 31.0181L43 18.6109L32.2524 12.4111L21.5068 18.6109Z"
        fill="url(#paint0_linear_2494_35929)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2494_35929"
          x1="-74.3993"
          y1="35.3049"
          x2="110.942"
          y2="35.3049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BEC3D0" />
          <stop offset="0.135417" stopColor="#B8BDCB" />
          <stop offset="0.260417" stopColor="#A5ABBC" />
          <stop offset="0.390625" stopColor="#B9BECC" />
          <stop offset="0.713542" stopColor="#CDD1DB" />
          <stop offset="0.8125" stopColor="#C1C6D2" />
          <stop offset="1" stopColor="#BEC3D0" />
          <stop offset="1" stopColor="#C0C5D2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HarmonicNoneIcon;
