import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import { useShallowTableStore } from 'stores/tableStore';

interface CheckboxSubRendererProps {
  isChecked: boolean;
  onCheckboxClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const CheckboxSubRenderer: React.FC<CheckboxSubRendererProps> = ({
  isChecked,
  onCheckboxClick
}) => {
  const { selectAllStateEnabled } = useShallowTableStore([
    'selectAllStateEnabled'
  ]);

  return (
    <Checkbox
      dataTestId="company-select-checkbox"
      checked={isChecked}
      disabled={selectAllStateEnabled}
      onChange={onCheckboxClick}
    />
  );
};
