import {
  CompanyListCustomField,
  ListCustomFieldType,
  NumberListCustomFieldValueFormat,
  PeopleListCustomField,
  SelectListCustomFieldMetadata
} from '__generated__/graphql';
import dayjs from 'dayjs';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import {
  EntityListType,
  FieldCategory,
  FieldEntityType,
  FieldGrouping,
  FilterArrayComparator,
  FilterBooleanComparator,
  FilterDateComparator,
  FilterDisplayFormatType,
  FilterNumberComparator,
  FilterStringComparator,
  ISearchFieldSpec,
  ISearchFilterGroup,
  TableFilterType
} from 'interfaces/SearchModel/Search';
import { cloneDeep, get, isFinite, toString } from 'lodash';
import { removeInvalidValuesFromAFilterGroup } from 'utils/search';
import { addCommasToNumber, deepOmitFromObject } from 'utils/utilities';

export const getGroupTitle = (groupName: string): string => {
  const name = groupName.split('_');
  if (name.length > 0 && name[0] === 'EMPLOYEE') {
    name[0] = 'PERSON';
  }
  return name.join(' ').toLocaleLowerCase();
};

const companiesfilterGroups: string[] = [
  FieldGrouping.EMPLOYEE_CURRENT_EXPERIENCE,
  FieldGrouping.EMPLOYEE_PAST_EXPERIENCE,
  FieldGrouping.EMPLOYEE_EDUCATION_EXPERIENCE,
  FieldGrouping.TAG,
  FieldGrouping.HEADCOUNT,
  FieldGrouping.HEADCOUNT_ADVISOR,
  FieldGrouping.HEADCOUNT_CUSTOMER_SUCCESS,
  FieldGrouping.HEADCOUNT_DATA,
  FieldGrouping.HEADCOUNT_DESIGN,
  FieldGrouping.HEADCOUNT_ENGINEERING,
  FieldGrouping.HEADCOUNT_FINANCE,
  FieldGrouping.HEADCOUNT_LEGAL,
  FieldGrouping.HEADCOUNT_MARKETING,
  FieldGrouping.HEADCOUNT_OPERATIONS,
  FieldGrouping.HEADCOUNT_OTHER,
  FieldGrouping.HEADCOUNT_PEOPLE,
  FieldGrouping.HEADCOUNT_PRODUCT,
  FieldGrouping.HEADCOUNT_SALES,
  FieldGrouping.HEADCOUNT_SUPPORT,
  FieldGrouping.FUNDING,
  FieldGrouping.LINKEDIN,
  FieldGrouping.TWITTER,
  FieldGrouping.FACEBOOK,
  FieldGrouping.INSTAGRAM,
  FieldGrouping.WEB_TRAFFIC
];

const peoplefilterGroups: string[] = [
  FieldGrouping.PERSON_EDUCATION_EXPERIENCE,
  FieldGrouping.PERSON_PAST_EXPERIENCE,
  FieldGrouping.PERSON_CURRENT_EXPERIENCE,
  FieldGrouping.PERSON_LAST_EXPERIENCE,
  FieldGrouping.PERSON_HIGHLIGHTS
];

const entityFilterGroupsMap: {
  [key in ApiResourceType]?: string[];
} = {
  [ApiResourceType.PeopleList]: peoplefilterGroups,
  [ApiResourceType.CompaniesList]: companiesfilterGroups
};

export const getFieldsByCategory = (
  searchFilters: ISearchFieldSpec[],
  entityType: ApiResourceType = ApiResourceType.CompaniesList
): Record<string, ISearchFieldSpec[]> => {
  let listCategories: Record<string, ISearchFieldSpec[]> =
    entityType === ApiResourceType.CompaniesList
      ? {
          [FieldCategory.PERSON]: [],
          [FieldCategory.COMPANY]: [],
          [FieldCategory.FINANCING]: [],
          [FieldCategory.TRACTION]: [],
          OTHER: []
        }
      : {
          [FieldCategory.PERSON]: [],
          [FieldCategory.ROLE]: [],
          [FieldCategory.COMPANY]: [],
          [FieldCategory.EDUCATION]: [],
          OTHER: []
        };

  const customFields = searchFilters.filter((field) =>
    isCustomFieldUrn(field.urn)
  );

  if (customFields.length > 0) {
    listCategories = {
      ...listCategories,
      [FieldCategory.CUSTOM]: []
    };
  }
  searchFilters.forEach((searchFilter) => {
    const category = searchFilter.category;
    const filtersGroup = entityFilterGroupsMap[entityType] || [];
    if (searchFilter.grouping) {
      if (!filtersGroup.includes(searchFilter.grouping)) {
        get(listCategories, category)?.push(searchFilter);
      }
    } else {
      get(listCategories, category)?.push(searchFilter);
    }
  });

  return listCategories;
};

// Add necessary $ or comma symbols based on display type.
export const getFormattedFilterValueBasedOnDisplayType = (
  filterValue?: string | number | boolean | string[] | null,
  displayType?: FilterDisplayFormatType
): string => {
  filterValue = toString(filterValue);
  if (displayType === FilterDisplayFormatType.MONEY) {
    filterValue = '$' + filterValue;
    filterValue = addCommasToNumber(filterValue);
    return filterValue;
  }

  if (displayType === FilterDisplayFormatType.YEAR) {
    return filterValue;
  }

  // Number should not be a date
  if (
    isFinite(parseInt(filterValue)) &&
    !dayjs(filterValue, 'YYYY-MM-DD', true).isValid()
  ) {
    return addCommasToNumber(filterValue);
  }
  return filterValue;
};

const BETA_HIGHLIGHTS = ['Vested Former Founder'];

const HIGHLIGHT_FIELDS = [
  'combined_highlight_categories',
  'past_week_combined_highlight_categories',
  'person_highlights_category',
  'person_highlights_category_list'
];

export const filterHighlightAllowedValues = (
  allHighlightAllowedValues: string[],
  showBetaPersonHighlights?: boolean
) => {
  if (showBetaPersonHighlights) {
    return allHighlightAllowedValues;
  }
  return allHighlightAllowedValues.filter(
    (value) => !BETA_HIGHLIGHTS.includes(value)
  );
};

export const getFieldAllowedValues = (
  fieldSpec?: ISearchFieldSpec,
  showBetaPersonHighlights?: boolean
): string[] | null => {
  if (!fieldSpec) {
    return null;
  }

  if (HIGHLIGHT_FIELDS.includes(fieldSpec.unique_name)) {
    return filterHighlightAllowedValues(
      fieldSpec.value_restricted_to || [],
      showBetaPersonHighlights
    );
  }
  return fieldSpec.value_restricted_to ?? [];
};

export type SearchFilterType =
  | EntityListType.COMPANY_SAVED_SEARCH
  | EntityListType.PEOPLE_SAVED_SEARCH
  | EntityListType.COMPANY_WATCHLIST
  | EntityListType.PEOPLE_WATCHLIST;

export const isCustomFieldUrn = (fieldUrn: string | undefined): boolean => {
  return (
    (fieldUrn?.startsWith('urn:harmonic:company_list_custom_field') ||
      fieldUrn?.startsWith('urn:harmonic:person_list_custom_field')) ??
    false
  );
};

export const generateCompanyFieldSpecsForCustomFields = (
  customFields: CompanyListCustomField[]
) => {
  return generateFieldSpecsForCustomFields(customFields, 'company');
};

export const generatePersonFieldSpecsForCustomFields = (
  customFields: PeopleListCustomField[]
) => {
  return generateFieldSpecsForCustomFields(customFields, 'person');
};

const generateFieldSpecsForCustomFields = (
  customFields: (CompanyListCustomField | PeopleListCustomField)[],
  mode: 'company' | 'person'
) => {
  const output: ISearchFieldSpec[] = [];
  const fieldEntityType =
    mode === 'company' ? FieldEntityType.COMPANY : FieldEntityType.PERSON;
  for (const customField of customFields) {
    if (customField.type === ListCustomFieldType.TEXT) {
      output.push({
        display_name: customField.name,
        type: [TableFilterType.ARRAY, TableFilterType.STRING],
        unique_name: `${mode}_custom_field_text`,
        urn: customField.urn,
        allowed_comparators: [
          FilterArrayComparator.SUBSTRING_MATCH,
          FilterArrayComparator.NOT_SUBSTRING_MATCH,
          FilterStringComparator.EXACT_MATCH
        ],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Text Custom Field'
      });
    }

    if (customField.type === ListCustomFieldType.NUMBER) {
      const numberMetadata = //eslint-disable-next-line
        //@ts-ignore
        customField.metadata?.numberFormat as NumberListCustomFieldValueFormat;

      output.push({
        display_name: customField.name,
        type: [TableFilterType.INTEGER, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_number`,
        urn: customField.urn,
        allowed_comparators: [
          ...Object.values(FilterNumberComparator),
          FilterStringComparator.IS_EMPTY
        ],
        display_format:
          numberMetadata === NumberListCustomFieldValueFormat.US_DOLLAR
            ? FilterDisplayFormatType.MONEY
            : numberMetadata === NumberListCustomFieldValueFormat.PERCENT
            ? FilterDisplayFormatType.PERCENTAGE
            : undefined,
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Number Custom Field'
      });
    }

    if (customField.type === ListCustomFieldType.DATE) {
      output.push({
        display_name: customField.name,
        type: [TableFilterType.DATE, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_date`,
        urn: customField.urn,
        allowed_comparators: [
          ...Object.values(FilterDateComparator),
          FilterStringComparator.IS_EMPTY
        ],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Date Custom Field'
      });
    }

    if (customField.type === ListCustomFieldType.SINGLE_SELECT) {
      const selectOptions = (
        customField.metadata as SelectListCustomFieldMetadata
      ).options;

      const formattedSelectOptions = selectOptions.map((option) => {
        return {
          label: option.name,
          value: option.urn,
          color: option.color
        };
      });
      output.push({
        display_name: customField.name,
        type: [TableFilterType.ARRAY, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_single_select`,
        urn: customField.urn,
        allowed_comparators: [
          FilterArrayComparator.ANY_OF_ARRAY,
          FilterArrayComparator.NOT_ANY_OF_ARRAY,
          FilterStringComparator.IS_EMPTY,
          FilterStringComparator.IS_NOT_EMPTY
        ],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Single Select Custom Field',
        select_values: formattedSelectOptions
      });
    }
    if (customField.type === ListCustomFieldType.STATUS) {
      const selectOptions = (
        customField.metadata as SelectListCustomFieldMetadata
      ).options;

      const formattedSelectOptions = selectOptions.map((option) => {
        return {
          label: option.name,
          value: option.urn,
          color: option.color
        };
      });
      output.push({
        display_name: customField.name,
        type: [TableFilterType.ARRAY, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_status`,
        urn: customField.urn,
        allowed_comparators: [
          FilterArrayComparator.ANY_OF_ARRAY,
          FilterArrayComparator.NOT_ANY_OF_ARRAY,
          FilterStringComparator.IS_EMPTY,
          FilterStringComparator.IS_NOT_EMPTY
        ],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Status Custom Field',
        select_values: formattedSelectOptions
      });
    }

    if (customField.type === ListCustomFieldType.MULTI_SELECT) {
      const selectOptions = (
        customField.metadata as SelectListCustomFieldMetadata
      ).options;

      const formattedSelectOptions = selectOptions.map((option) => {
        return {
          label: option.name,
          value: option.urn,
          color: option.color
        };
      });
      output.push({
        display_name: customField.name,
        type: [TableFilterType.ARRAY, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_multi_select`,
        urn: customField.urn,
        allowed_comparators: [
          FilterArrayComparator.ANY_OF_ARRAY,
          FilterArrayComparator.NOT_ANY_OF_ARRAY,
          FilterStringComparator.IS_EMPTY,
          FilterStringComparator.IS_NOT_EMPTY
        ],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Multi Select Custom Field',
        select_values: formattedSelectOptions
      });
    }
    if (customField.type === ListCustomFieldType.PERSON) {
      output.push({
        display_name: customField.name,
        type: [TableFilterType.ARRAY, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_person`,
        urn: customField.urn,
        allowed_comparators: [
          FilterArrayComparator.ANY_OF_ARRAY,
          FilterArrayComparator.NOT_ANY_OF_ARRAY,
          FilterStringComparator.IS_EMPTY,
          FilterStringComparator.IS_NOT_EMPTY
        ],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Person Custom Field'
      });
    }
    if (customField.type === ListCustomFieldType.CHECKBOX) {
      output.push({
        display_name: customField.name,
        type: [TableFilterType.BOOLEAN, TableFilterType.NULL],
        unique_name: `${mode}_custom_field_checkbox`,
        urn: customField.urn,
        allowed_comparators: [FilterBooleanComparator.IS],
        category: FieldCategory.CUSTOM,
        entity_type: fieldEntityType,
        value_restricted_to: null,
        description: 'Checkbox Custom Field'
      });
    }
  }
  return output;
};

export const formatListQueryBeforeSave = (searchQuery: ISearchFilterGroup) => {
  let filteredQuery = cloneDeep(searchQuery);
  filteredQuery = deepOmitFromObject(searchQuery, [
    'key'
  ]) as ISearchFilterGroup;
  filteredQuery = removeInvalidValuesFromAFilterGroup(filteredQuery);
  return filteredQuery;
};

export const getActiveFiltersCountInFilterGroup = (
  searchFilterGroup: ISearchFilterGroup
): number => {
  let count = 0;
  if (!searchFilterGroup?.filter_groups) return count;
  searchFilterGroup.filter_groups.forEach((group) => {
    count += getActiveFiltersCountInFilterGroup(group);
  });
  count += searchFilterGroup.filters.length;
  return count;
};
