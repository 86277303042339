import React from 'react';
import { IconProps } from './type';

const CompanyAffinityListIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M10.3437 19.0835C10.3437 19.3838 10.2124 19.6744 9.9598 19.8368C8.33588 20.8807 6.14937 20.6923 4.72855 19.2714C3.09048 17.6334 3.09048 14.9775 4.72855 13.3395L5.5384 12.5296C5.43007 12.1855 5.43007 11.8145 5.5384 11.4704L4.72855 10.6605C3.09048 9.02245 3.09048 6.36662 4.72855 4.72855C6.36662 3.09048 9.02245 3.09048 10.6605 4.72855L11.4704 5.5384C11.8145 5.43007 12.1855 5.43007 12.5296 5.5384L13.3395 4.72855C14.9775 3.09048 17.6334 3.09048 19.2714 4.72855C20.9095 6.36662 20.9095 9.02245 19.2714 10.6605L18.5417 11.3903C18.7353 11.7686 18.3817 12.1364 17.9567 12.1364H15.7C15.4357 12.1364 15.2181 11.9138 15.2975 11.6617L12.5296 8.8939C12.1855 9.00223 11.8145 9.00223 11.4704 8.8939L8.8939 11.4704C9.00224 11.8145 9.00223 12.1855 8.8939 12.5296L10.0508 13.6865C10.2383 13.8741 10.3437 14.1284 10.3437 14.3936V15.31C10.3437 15.6663 9.91283 15.8448 9.66085 15.5928L7.74578 13.6778C7.4016 13.7861 7.0307 13.7861 6.68653 13.6778L5.87668 14.4876C4.8727 15.4916 4.8727 17.1193 5.87668 18.1233C6.88065 19.1273 8.50842 19.1273 9.5124 18.1233L9.66085 17.9749C9.91283 17.7229 10.3437 17.9014 10.3437 18.2577V19.0835ZM18.1233 9.5124L17.1903 10.4455C16.9412 10.415 16.6868 10.4377 16.4456 10.5136L13.6778 7.74578C13.7861 7.40161 13.7861 7.0307 13.6778 6.68653L14.4876 5.87668C15.4916 4.8727 17.1193 4.8727 18.1233 5.87668C19.1273 6.88065 19.1273 8.50842 18.1233 9.5124ZM10.3222 7.74578L7.74578 10.3222C7.40161 10.2139 7.0307 10.2139 6.68653 10.3222L5.87668 9.5124C4.8727 8.50842 4.8727 6.88065 5.87667 5.87668C6.88065 4.8727 8.50842 4.8727 9.5124 5.87668L10.3222 6.68653C10.2139 7.0307 10.2139 7.4016 10.3222 7.74578Z"
        fill="#484E5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M13.1506 14.0903C12.6806 14.0903 12.2996 14.4925 12.2996 14.9886C12.2996 15.4847 12.6806 15.8869 13.1506 15.8869H22.228C22.698 15.8869 23.0791 15.4847 23.0791 14.9886C23.0791 14.4925 22.698 14.0903 22.228 14.0903H13.1506ZM12.2996 18.5818C12.2996 18.0857 12.6806 17.6835 13.1506 17.6835H22.228C22.698 17.6835 23.0791 18.0857 23.0791 18.5818C23.0791 19.0779 22.698 19.4801 22.228 19.4801H13.1506C12.6806 19.4801 12.2996 19.0779 12.2996 18.5818ZM13.1506 21.2767C12.6806 21.2767 12.2996 21.6788 12.2996 22.1749C12.2996 22.6711 12.6806 23.0732 13.1506 23.0732H17.728C18.198 23.0732 18.5791 22.6711 18.5791 22.1749C18.5791 21.6788 18.198 21.2767 17.728 21.2767H13.1506Z"
        fill="#484E5B"
      />
    </svg>
  );
};

export default CompanyAffinityListIcon;
