import { ApolloError, WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import {
  GetAllTeamMembersByCustomerQuery,
  GetAllTeamMembersByCustomerQueryVariables,
  TeamStatus
} from '__generated__/graphql';
import { GET_ALL_TEAM_MEMBERS } from 'queries/getAllTeamMembers';
import { useMemo } from 'react';
import useStore from 'stores/zustandStore';

interface UseTeamMembersResult {
  loading: boolean;
  activeTeamMembers: GetAllTeamMembersByCustomerQuery['getAllTeamMembersByCustomer'];
  error?: ApolloError;
}

interface UseTeamMembersParams {
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}
const useTeamMembers = ({
  skip,
  fetchPolicy = 'cache-first'
}: UseTeamMembersParams = {}): UseTeamMembersResult => {
  const customerUrn = useStore((state) => state.customerUrn);
  const { data, loading, error } = useQuery<
    GetAllTeamMembersByCustomerQuery,
    GetAllTeamMembersByCustomerQueryVariables
  >(GET_ALL_TEAM_MEMBERS, {
    variables: {
      urn: customerUrn as string
    },
    fetchPolicy,
    skip: !customerUrn || skip
  });

  const activeTeamMembers = useMemo(
    () =>
      (data?.getAllTeamMembersByCustomer || []).filter(
        (member) =>
          member?.status !== TeamStatus.PAUSED &&
          member?.status !== TeamStatus.PENDING_PILOT_AGREEMENT_ACCEPTANCE
      ),
    [data]
  );

  return {
    activeTeamMembers,
    loading,
    error
  };
};

export default useTeamMembers;
