import AffinityListsGraphic from 'assets/affinity-lists-graphic';
import Button from 'harmonic-components/Button/Button';
import LeftImageBanner from 'harmonic-components/LeftImageBanner/LeftImageBanner';
import { useNavigate } from 'react-router-dom';

export const AffinityBanner = (props: { onDismiss: () => void }) => {
  const navigate = useNavigate();
  return (
    <LeftImageBanner leftGraphic={<AffinityListsGraphic />}>
      <h3 className="typography-label-large-strong text-content-strong">
        You can now filter across your Affinity lists
      </h3>
      <p className="typography-paragraph-default-default text-content-default">
        Integrate with Affinity to include or exclude companies from your
        team&apos;s Affinity lists in your search results.
      </p>
      <div className="flex gap-g20 mt-p40">
        <Button
          label="Set up integration"
          emphasis="medium"
          onClick={() => {
            navigate('/settings?t=integrations');
            props.onDismiss();
          }}
        />
        <Button label="Dismiss" emphasis="low" onClick={props.onDismiss} />
      </div>
    </LeftImageBanner>
  );
};
