import * as React from 'react';

import { Popover } from '@headlessui/react';
import VerticalMenuIcon from 'assets/harmonic-icons/vertical-menu';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useFlags from 'hooks/useFlags';
import { toast } from 'react-toastify';
import { SPLITS } from 'utils/constants';

interface IExtraSideActionsButtonProps {
  showConvertToGroupOption?: boolean;
  duplicateFilter: () => void;
  onConvertToGroup?: () => void;
  onRemoveFilter?: () => void;
  copyToClipboard?: () => void;
}

const ExtraSideActionsButton: React.FC<IExtraSideActionsButtonProps> = ({
  duplicateFilter,
  showConvertToGroupOption,
  onConvertToGroup,
  onRemoveFilter,
  copyToClipboard
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-settings' : undefined;

  const { enabled: showCopyJson } = useFlags(SPLITS.searchCopyJsonToClipboard);

  return (
    <>
      <Popover className="relative">
        <Popover.Button>
          <IconButton
            icon={VerticalMenuIcon}
            emphasis="low"
            aria-describedby={id}
            onClick={handleClick}
          />
        </Popover.Button>
        <Popover.Panel className="absolute z-1400 w-40 mt-p20 right-0">
          <Dropdown>
            {onRemoveFilter && (
              <ListItem
                variant={ListVariant.default}
                onClick={() => {
                  onRemoveFilter();
                  handleClose();
                }}
                label="Remove"
                value="Remove"
              />
            )}
            <ListItem
              variant={ListVariant.default}
              onClick={() => {
                duplicateFilter();
                handleClose();
              }}
              label="Duplicate"
              value="Duplicate"
            />
            {showConvertToGroupOption && (
              <ListItem
                variant={ListVariant.default}
                onClick={() => {
                  if (onConvertToGroup) onConvertToGroup();
                  handleClose();
                }}
                label="Convert to group"
                value="Convert to group"
              />
            )}
            {showCopyJson && copyToClipboard && (
              <ListItem
                variant={ListVariant.default}
                onClick={() => {
                  copyToClipboard();
                  toast.success('Copied JSON to clipboard');
                  handleClose();
                }}
                label="Copy JSON"
                value="Copy JSON"
              />
            )}
          </Dropdown>
        </Popover.Panel>
      </Popover>
    </>
  );
};
export default ExtraSideActionsButton;
