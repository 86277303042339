import EmailIcon from 'assets/harmonic-icons/email-icon';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import React from 'react';
import PersonEmailStaticModal from './PersonEmailStaticModal';

interface ContactButtonProps {
  name: string;
  emailAddress: string;
}

const ContactButton: React.FC<ContactButtonProps> = ({
  name,
  emailAddress
}) => {
  const { show } = useModal();

  const handleOnUnlock = () => {
    show(<PersonEmailStaticModal name={name} emailAddress={emailAddress} />);
  };

  return (
    <IconButton
      icon={EmailIcon}
      onClick={handleOnUnlock}
      type="secondary"
      emphasis="high"
      size="compact"
      dataTestId="ContactStaticEmailIcon"
    />
  );
};

export default ContactButton;
