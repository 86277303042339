import { useLazyQuery } from '@apollo/client';
import {
  GetCompanyUserConnectionsWithOverviewAndEventsQuery,
  GetCompanyUserConnectionsWithOverviewAndEventsQueryVariables,
  GetPersonUserConnectionsQuery,
  GetPersonUserConnectionsQueryVariables
} from '__generated__/graphql';
import NetworkAttributeBadge from 'common/components/Network/NetworkAttributeBadge';
import NetworkBadge from 'common/components/Network/NetworkBadge';
import NetworkSummaryBadge from 'common/components/Network/NetworkSummaryBadge';
import NetworkTooltip from 'common/components/Network/NetworkTooltip';
import { UserConnectionInNetwork, UserInNetwork } from 'common/types/network';
import { getIdFromUrn, getUrnFragment } from 'common/utils/urn';
import { useAppState } from 'hooks/useAppState';
import { compact } from 'lodash';
import { getCompanyUserConnectionsWithOverviewAndEvents } from 'queries/getCompanyUserConnections';
import getPersonUserConnections from 'queries/getPersonUserConnections';

interface NetworkBadgeWithTooltipProps {
  usersInNetwork?: UserInNetwork[];
  includeSummaryText?: boolean;
  targetEntityUrn: string;
  targetName: string;
  variant?: 'badge' | 'attribute';
}

const NetworkBadgeWithTooltip = (props: NetworkBadgeWithTooltipProps) => {
  const {
    usersInNetwork = [],
    includeSummaryText,
    variant = 'badge',
    targetEntityUrn,
    targetName
  } = props;

  const entityType = getUrnFragment(targetEntityUrn, 2);
  const entityId = getIdFromUrn(targetEntityUrn);
  if ((entityType != 'person' && entityType != 'company') || !entityId) {
    throw new Error('Invalid URN');
  }

  const connectionsQuery =
    entityType === 'person'
      ? getPersonUserConnections
      : getCompanyUserConnectionsWithOverviewAndEvents;

  const [
    loadConnections,
    { data: connectionsData, loading: connectionsLoading }
  ] = useLazyQuery<
    | GetPersonUserConnectionsQuery
    | GetCompanyUserConnectionsWithOverviewAndEventsQuery,
    | GetPersonUserConnectionsQueryVariables
    | GetCompanyUserConnectionsWithOverviewAndEventsQueryVariables
  >(connectionsQuery, {
    variables: {
      id: parseInt(entityId)
    }
  });

  const currentUserEmail = useAppState<string>((state) => {
    return state.auth.user?.email ?? '';
  });

  const BadgeComponent =
    variant === 'attribute'
      ? NetworkAttributeBadge
      : includeSummaryText
      ? NetworkSummaryBadge
      : NetworkBadge;

  const userConnections = compact(
    (connectionsData as GetPersonUserConnectionsQuery)?.getPersonById
      ?.userConnections ||
      (connectionsData as GetCompanyUserConnectionsWithOverviewAndEventsQuery)
        ?.getCompanyById?.userConnections
  );

  return (
    <div data-testid="network-badge-tooltip">
      <NetworkTooltip
        userConnections={
          connectionsLoading
            ? undefined
            : (userConnections as UserConnectionInNetwork[])
        }
        loadConnections={loadConnections}
        currentUserEmail={currentUserEmail}
        targetName={targetName}
      >
        <BadgeComponent
          usersInNetwork={usersInNetwork}
          currentUserEmail={currentUserEmail}
        />
      </NetworkTooltip>
    </div>
  );
};

export default NetworkBadgeWithTooltip;
