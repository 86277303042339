import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const B2BSaasIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2721 3.68187C5.60342 3.25188 6.11542 3 6.65812 3H18.0079C18.5506 3 19.0626 3.25188 19.3939 3.68187L22.177 7.29378C22.2782 7.42504 22.333 7.5861 22.333 7.75183V8.75222C22.333 9.74304 21.9401 10.6414 21.3052 11.3086V19.2493C21.3052 20.2162 20.5217 21 19.5552 21H5.11079C4.14429 21 3.36079 20.2162 3.36079 19.2493V11.3086C2.7259 10.6414 2.33301 9.74304 2.33301 8.75222V7.75183C2.33301 7.5861 2.38786 7.42504 2.489 7.29378L5.2721 3.68187ZM4.86079 12.2803V19.2493C4.86079 19.3875 4.97271 19.4994 5.11079 19.4994H9.52745L9.52746 17.2485C9.52746 15.7099 10.8031 14.4975 12.333 14.4975C13.863 14.4975 15.1386 15.7099 15.1386 17.2486L15.1386 19.4994H19.5552C19.6933 19.4994 19.8052 19.3874 19.8052 19.2493V12.2803C19.3972 12.4249 18.9573 12.5037 18.4997 12.5037C17.244 12.5037 16.1179 11.9086 15.4163 10.9824C14.7147 11.9086 13.5887 12.5037 12.333 12.5037C11.0773 12.5037 9.95128 11.9087 9.24967 10.9824C8.54807 11.9087 7.42204 12.5037 6.16634 12.5037C5.70867 12.5037 5.26885 12.4249 4.86079 12.2803Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default B2BSaasIcon;
