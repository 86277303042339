import {
  FieldCategory,
  FieldEntityType,
  FilterArrayComparator,
  ISearchFieldSpec,
  TableFilterType
} from 'interfaces/SearchModel/Search';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallowStoreGeneric } from './util';

const defaultFieldSpec: ISearchFieldSpec = {
  allowed_comparators: [FilterArrayComparator.ANY_OF],
  beta: true,
  category: FieldCategory.COMPANY,
  description: '',
  display_name: '',
  entity_type: FieldEntityType.COMPANY,
  format: null,
  type: TableFilterType.BOOLEAN,
  urn: 'urn:harmonic:company_field:default',
  unique_name: 'default',
  value_restricted_to: null
};
interface PersistedFieldSpecStoreState {
  companiesFieldSpec: ISearchFieldSpec[];
  peopleFieldSpec: ISearchFieldSpec[];
  setCompaniesFieldSpec: (payload: ISearchFieldSpec[]) => void;
  setPeopleFieldSpec: (payload: ISearchFieldSpec[]) => void;
}

const usePersistedFieldSpecStore = create(
  persist<PersistedFieldSpecStoreState>(
    (set) => ({
      companiesFieldSpec: [defaultFieldSpec],
      peopleFieldSpec: [defaultFieldSpec],
      setCompaniesFieldSpec: (payload: ISearchFieldSpec[]) => {
        set((state) => ({
          ...state,
          companiesFieldSpec: payload
        }));
      },
      setPeopleFieldSpec: (payload: ISearchFieldSpec[]) => {
        set((state) => ({
          ...state,
          peopleFieldSpec: payload
        }));
      }
    }),
    {
      // Bump this any time you want to refresh the field specs store (i.e. adding additional fields, updating existing fields, etc...)
      // Ensure that your schema change is deployed in backend before bumping
      version: 66,
      name: `persisted-field-spec-store`
    }
  )
);

export const useShallowPersistedFieldSpecStore = (
  keys: (keyof PersistedFieldSpecStoreState)[]
) => useShallowStoreGeneric(usePersistedFieldSpecStore, keys);
