import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const BiotechIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.333 5.29688C12.8642 4.76744 12.864 4.76722 12.8638 4.76699L12.8622 4.76542L12.8596 4.76284L12.8527 4.756L12.8318 4.73591C12.8148 4.71975 12.7916 4.69819 12.7624 4.672C12.7041 4.61966 12.6213 4.54865 12.5152 4.4653C12.4607 4.42254 12.3999 4.37641 12.333 4.32781C12.5265 4.18729 12.7716 4.02609 13.0649 3.86553C13.8544 3.43347 15.0055 3 16.4441 3C17.9309 3 19.4159 3.5977 20.5251 4.78484C21.6377 5.97563 22.333 7.71515 22.333 9.9375C22.333 13.2536 19.9819 16.0247 17.7257 17.8948C16.5776 18.8464 15.4065 19.6049 14.4687 20.1276C13.9999 20.3889 13.5822 20.5954 13.2481 20.7391C13.0817 20.8106 12.9277 20.8702 12.7933 20.9134C12.6841 20.9485 12.5084 21 12.333 21C12.1576 21 11.9819 20.9485 11.8727 20.9134C11.7383 20.8702 11.5843 20.8106 11.418 20.7391C11.0838 20.5954 10.6661 20.3889 10.1973 20.1276C9.25953 19.6049 8.0884 18.8464 6.94033 17.8948C4.68415 16.0247 2.33301 13.2536 2.33301 9.9375C2.33301 7.71515 3.02828 5.97563 4.1409 4.78484C5.25009 3.5977 6.73509 3 8.2219 3C9.66053 3 10.8116 3.43347 11.6011 3.86553C11.8944 4.02609 12.1395 4.18729 12.333 4.32781L11.2834 4.54688C11.963 4.92563 12.333 5.29688 12.333 5.29688Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BiotechIcon;
