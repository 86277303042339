import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const CPGIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.27757 4.64413C4.33355 3.72044 5.09898 3 6.02436 3H18.6419C19.5673 3 20.3327 3.72044 20.3887 4.64413L21.2675 19.1441C21.3285 20.1504 20.5288 21 19.5207 21H5.14557C4.13744 21 3.33779 20.1504 3.39878 19.1441L4.27757 4.64413ZM10.0831 8C10.0831 7.58579 9.74735 7.25 9.33313 7.25C8.91892 7.25 8.58313 7.58579 8.58313 8C8.58313 10.0711 10.2621 11.75 12.3331 11.75C14.4042 11.75 16.0831 10.0711 16.0831 8C16.0831 7.58579 15.7473 7.25 15.3331 7.25C14.9189 7.25 14.5831 7.58579 14.5831 8C14.5831 9.24264 13.5758 10.25 12.3331 10.25C11.0905 10.25 10.0831 9.24264 10.0831 8Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default CPGIcon;
