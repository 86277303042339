import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const FintechIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66602 6.25C1.66602 5.2835 2.44952 4.5 3.41602 4.5H21.916C22.8825 4.5 23.666 5.2835 23.666 6.25V17.75C23.666 18.7165 22.8825 19.5 21.916 19.5H3.41602C2.44952 19.5 1.66602 18.7165 1.66602 17.75V6.25ZM4.41602 7C4.0018 7 3.66602 7.33579 3.66602 7.75C3.66602 8.16421 4.0018 8.5 4.41602 8.5H5.41602C5.83023 8.5 6.16602 8.16421 6.16602 7.75C6.16602 7.33579 5.83023 7 5.41602 7H4.41602ZM19.166 16.25C19.166 15.8358 19.5018 15.5 19.916 15.5H20.916C21.3302 15.5 21.666 15.8358 21.666 16.25C21.666 16.6642 21.3302 17 20.916 17H19.916C19.5018 17 19.166 16.6642 19.166 16.25ZM10.166 12C10.166 10.6193 11.2853 9.5 12.666 9.5C14.0467 9.5 15.166 10.6193 15.166 12C15.166 13.3807 14.0467 14.5 12.666 14.5C11.2853 14.5 10.166 13.3807 10.166 12Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default FintechIcon;
