import { HarmonicIcon } from '../type';

const RoundIconSeries: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M4.66683 9.33366V13.3337C4.66683 13.5225 4.60294 13.6809 4.47516 13.8087C4.34739 13.9364 4.18905 14.0003 4.00016 14.0003C3.81127 14.0003 3.65294 13.9364 3.52516 13.8087C3.39738 13.6809 3.3335 13.5225 3.3335 13.3337V3.33366C3.3335 3.14477 3.39738 2.98644 3.52516 2.85866C3.65294 2.73088 3.81127 2.66699 4.00016 2.66699H8.7835C8.93905 2.66699 9.07794 2.71699 9.20016 2.81699C9.32239 2.91699 9.40016 3.04477 9.4335 3.20033L9.60016 4.00033H12.6668C12.8557 4.00033 13.0141 4.06421 13.1418 4.19199C13.2696 4.31977 13.3335 4.4781 13.3335 4.66699V10.0003C13.3335 10.1892 13.2696 10.3475 13.1418 10.4753C13.0141 10.6031 12.8557 10.667 12.6668 10.667H9.21683C9.06127 10.667 8.92239 10.617 8.80016 10.517C8.67794 10.417 8.60016 10.2892 8.56683 10.1337L8.40016 9.33366H4.66683Z"
      fill="#82A63A"
    />
  </svg>
);

export default RoundIconSeries;
