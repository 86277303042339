import CoinIcon from 'icons/CoinIcon';
import CompanyBuildingOutlineIcon from 'icons/CompanyBuildingOutlineIcon';
import PersonOutlineIcon from 'icons/PersonOutlineIcon';
import ThreeDotIcon from 'icons/ThreeDotIcon';
import TrendingUpIcon from 'icons/TrendingUpIcon';
import { EntityListType, FieldCategory } from 'interfaces/SearchModel/Search';

const CompanyTabs = [
  {
    category: FieldCategory.COMPANY,
    icon: <CompanyBuildingOutlineIcon />
  },
  {
    category: FieldCategory.PERSON,
    icon: <PersonOutlineIcon />
  },
  {
    category: FieldCategory.FINANCING,
    icon: <CoinIcon />
  },
  {
    category: FieldCategory.TRACTION,
    icon: <TrendingUpIcon />
  },
  {
    category: FieldCategory.OTHER,
    icon: <ThreeDotIcon />
  }
];

const CompanyListTabs = [...CompanyTabs];

const PersonTabs = [
  {
    category: FieldCategory.PERSON,
    icon: <PersonOutlineIcon />
  },
  {
    category: FieldCategory.ROLE,
    icon: <TrendingUpIcon />
  },
  {
    category: FieldCategory.COMPANY,
    icon: <CompanyBuildingOutlineIcon />
  },
  {
    category: FieldCategory.EDUCATION,
    icon: <CompanyBuildingOutlineIcon />
  },
  {
    category: FieldCategory.OTHER,
    icon: <ThreeDotIcon />
  }
];

const PersonListTabs = [...PersonTabs];

export const EntityFilterCategoryMap: {
  [key in EntityListType]?: { category: FieldCategory; icon: JSX.Element }[];
} = {
  [EntityListType.PEOPLE_SAVED_SEARCH]: PersonTabs,
  [EntityListType.COMPANY_SAVED_SEARCH]: CompanyTabs,
  [EntityListType.COMPANY_WATCHLIST]: CompanyListTabs,
  [EntityListType.PEOPLE_WATCHLIST]: PersonListTabs
};
