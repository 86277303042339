import { logout } from 'actions/authActions';
import axios, { AxiosError, AxiosResponse, CancelToken } from 'axios';
import { LogoutReason } from 'interfaces/Auth';
import {
  ApiResourceType,
  IApiResource,
  ICapTableEntry
} from 'interfaces/DataModel/ApiResource';

import {
  ApolloCache,
  ApolloClient,
  DefaultContext,
  FetchResult,
  MutationUpdaterFunction,
  NormalizedCacheObject,
  OperationVariables,
  QueryOptions
} from '@apollo/client';
import { getFirebaseToken } from 'actions/fetchActions';
import { config } from 'config/config';
import { DocumentNode } from 'graphql';
import { ISearchFieldSpec } from 'interfaces/SearchModel/Search';
import { API_V2_SEARCH_FIELD_SPEC } from './constants';

import { useAppStore } from '../hooks/useAppStore';
import { logger } from './logger';

/**
 * Search Model
 */

export const makeGraphqlMutationWithApolloClient = async <
  Response = unknown,
  Variables extends OperationVariables = OperationVariables
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  mutation: DocumentNode,
  variables: Variables,
  refetchQueries?:
    | 'all'
    | 'active'
    | Array<string | DocumentNode | QueryOptions>,
  update?: MutationUpdaterFunction<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    Variables,
    DefaultContext,
    ApolloCache<NormalizedCacheObject>
  >
): Promise<FetchResult<Response>> => {
  const response = await client.mutate({
    mutation,
    variables,
    refetchQueries,
    update
  });
  return response;
};
/**
 * API Resources
 */

const getApiResource = (
  apiUrl: string,
  requestParams?: Record<string, unknown>,
  preFetch?: boolean, // fetch in background, do not show loading in UI
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody?: any,
  cancelToken?: CancelToken,
  requestHeaders?: Record<string, string>
): Promise<IApiResource> => {
  const axiosRequest = requestBody
    ? axios.post(apiUrl, requestBody, {
        params: requestParams,
        cancelToken: cancelToken,
        headers: requestHeaders
      })
    : axios.get(apiUrl, {
        params: requestParams,
        cancelToken: cancelToken,
        headers: requestHeaders
      });

  return axiosRequest.then(
    (value: AxiosResponse<IApiResource>) => {
      if (value.data) {
        return Promise.resolve(value.data);
      }
      // No data in response
      else {
        logger.error(`Data fetch failure: non 200 response or empty data`);
        // setError(DATA_FETCH_ERROR_MESSAGE);
        return Promise.reject('No data in response');
      }
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        // If unauthorized response, then session has expired. Log user out.
        logout(LogoutReason.SessionExpired);
      } else if (error.response?.status === 404) {
        null;
      } else if (error.response?.status === 403) {
        // Pass for now
        // TODO: Move all error handling out of API util layer
        null;
      } else if (axios.isCancel(error)) {
        null;
      } else {
        const user = useAppStore.getState().auth.user;

        const formattedUser = user
          ? {
              email: user.email,
              id: user.user_id,
              username: user.displayName
            }
          : undefined;

        logger.error((error as Error).message || 'Unknown error', {
          error,
          user: formattedUser,
          extra: {
            apiUrl,
            requestBody: JSON.stringify(requestBody),
            requestHeaders,
            requestParams
          }
        });
        // setError(DATA_FETCH_ERROR_MESSAGE);
      }

      return Promise.reject(error);
    }
  );
};

export const getPeopleListSearchFieldSpec = async (
  requestParams?: Record<string, unknown>,
  preFetch = true
): Promise<ISearchFieldSpec[]> => {
  const firebaseToken = await getFirebaseToken();

  const headers = {
    authorization: firebaseToken
  };
  const apiUrl = `${config.BASE_MIDTIER_API_URL}search/searchFieldSpec?search_type=PERSONS`;
  return getApiResource(
    apiUrl,
    requestParams,
    preFetch,
    null,
    undefined,
    headers
  ) as Promise<ISearchFieldSpec[]>;
};

export const getCompaniesListSearchFieldSpec = async (
  requestParams?: Record<string, unknown>,
  preFetch = true
): Promise<ISearchFieldSpec[]> => {
  const firebaseToken = await getFirebaseToken();

  const headers = {
    authorization: firebaseToken
  };

  const apiUrl = `${config.BASE_MIDTIER_API_URL}${API_V2_SEARCH_FIELD_SPEC}`;
  return getApiResource(
    apiUrl,
    { ...requestParams, search_type: ApiResourceType.CompaniesList },
    preFetch,
    undefined,
    undefined,
    headers
  ) as Promise<ISearchFieldSpec[]>;
};

export const getCapTableForCompanyId = async (
  companyId: number
): Promise<ICapTableEntry[]> => {
  const requestUrl = `https://assets.harmonic.ai/captables/${companyId}/captables.json`;
  const res = await fetch(requestUrl);
  const jsonRes = await res.json();
  return JSON.parse(jsonRes.captable);
};
