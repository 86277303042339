import useDashboardLocation, {
  DashboardLocation
} from 'hooks/useDashboardLocation';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  CreatorEnum,
  CurrentTabEnum,
  IGridFilterActions,
  IGridFilterState,
  VisibilityEnum
} from './types';

export const initialGridFilterState: IGridFilterState = {
  currentTab: CurrentTabEnum.all,
  searchInput: '',
  gridFilterSettings: {
    visibility: VisibilityEnum.any,
    creator: CreatorEnum.anyone
  },
  displaySettings: {
    showCreator: true,
    showVisibility: true,
    // showLastViewDate: true,
    showLastUpdatedDate: true,
    showCreationDate: true
  },
  sort: {
    field: '',
    descending: false
  }
};

const createCollectionsFilterStore = (type: 'lists' | 'savedSearches') =>
  create<IGridFilterState & IGridFilterActions>()(
    persist(
      (set) => ({
        ...initialGridFilterState,
        setSearchInput: (input) => set({ searchInput: input }),
        setGridFilterSettings: (settings) =>
          set({ gridFilterSettings: settings }),
        setDisplaySettings: (settings) => set({ displaySettings: settings }),
        setCurrentTab: (tab) => set({ currentTab: tab }),
        resetSearchInput: () => set({ searchInput: '' }),
        setSort: (sort) => set({ sort: sort })
      }),
      {
        name: type
      }
    )
  );

const useListsStore = createCollectionsFilterStore('lists');
const useSavedSearchStore = createCollectionsFilterStore('savedSearches');

export const useCollectionsStore = () => {
  const currentLocation = useDashboardLocation().location;

  if (
    currentLocation &&
    ![DashboardLocation.LISTS, DashboardLocation.SEARCHES].includes(
      currentLocation
    )
  )
    throw new Error('Collections store is for lists and saved searches only.');

  const store =
    currentLocation === DashboardLocation.LISTS
      ? useListsStore
      : useSavedSearchStore;

  return store((state) => ({
    ...state
  }));
};
