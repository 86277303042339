import RangeInput from 'harmonic-components/RangeInput/RangeInput';
import {
  companyFundingTotalDefaultValue,
  FilterGroupGeneratorId,
  FundingLastRoundDateOptions,
  fundingSliderStepRanges
} from 'interfaces/SearchV2';
import React, { ChangeEvent } from 'react';
import ChipWithCustomRange from '../Common/ChipWithCustomRange';

import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import {
  formatCustomRange,
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';

import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import ButtonWrapper from '../Common/ButtonWrapper';
import Checkboxes, { CheckboxItem } from '../Common/Checkboxes';
import InvestorMultiselectByName from '../Common/InvestorMultiselectByName';
import { SubSectionHeader } from '../Common/Typography';
import FundingSectionAccelerator from './FundingSectionAccelerator';
const LastFundingDateOptions = [
  {
    label: 'Any',
    value: undefined
  },
  {
    label: 'Past 4-12 months',
    value: FundingLastRoundDateOptions.PAST_FOUR_TO_TWELVE_MONTHS
  },
  {
    label: 'Past 12-24 months',
    value: FundingLastRoundDateOptions.PAST_TWELVE_TO_TWENTY_FOUR_MONTHS
  }
];

const fundingStageOptions: CheckboxItem[] = [
  {
    label: 'Stealth',
    value: 'STEALTH'
  },
  {
    label: 'Series A',
    value: 'SERIES_A'
  },
  {
    label: 'Pre-seed',
    value: 'PRE_SEED'
  },
  {
    label: 'Series B',
    value: 'SERIES_B'
  },
  {
    label: 'Seed',
    value: 'SEED'
  },
  {
    label: 'Series C+',
    value: 'SERIES_C_PLUS'
  }
];

interface FundingSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const FundingSection: React.FC<FundingSectionProps> = ({
  searchQuery,
  onUpdateSearchQuery,
  disabled
}) => {
  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const includeInvestors =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS
    ]?.investors;

  const excludeInvestors =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS
    ]?.investors;

  const fundingStages =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE
    ]?.stages;

  const fundingTotalRangeField =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE
    ];

  const fundingTotalRangeValue = fundingTotalRangeField?.range;
  const fundingUndisclosed = fundingTotalRangeField?.include_undisclosed;

  const fundingLastRoundDate =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE
    ];

  const updateFundingUndisclosed = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdateSearchQuery(
      getUpdatedQuery(
        searchQuery,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE,
        {
          range: fundingTotalRangeValue as NumberRange,
          include_undisclosed: e.target.checked
        }
      )
    );

  return (
    <div className="w-full">
      <div className="flex flex-col gap-g90">
        <div>
          <SubSectionHeader>Company stage</SubSectionHeader>
          <Checkboxes
            dataTestId="Funding-Stage-Checkboxes"
            options={fundingStageOptions}
            selectedValues={fundingStages}
            onValueChange={(newValues) =>
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE,
                  newValues !== undefined ? { stages: newValues } : undefined
                )
              )
            }
            disabled={disabled}
          />
        </div>
        <div data-testid="total-funding-raised-section">
          <SubSectionHeader>Total funding raised</SubSectionHeader>
          <div className="w-1/2">
            <RangeInput
              dataTestId="Total-Funding-Raised-Input"
              value={fundingTotalRangeValue}
              defaultValue={companyFundingTotalDefaultValue.range}
              sliderStepRanges={fundingSliderStepRanges}
              prefix="$"
              disabled={disabled}
              onValueChange={(newValues) =>
                onUpdateSearchQuery(
                  getUpdatedQuery(
                    searchQuery,
                    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE,
                    newValues !== undefined
                      ? {
                          range: newValues
                        }
                      : undefined
                  )
                )
              }
            />
          </div>
          {fundingTotalRangeValue && (
            <div className="mt-2 flex gap-2">
              <Checkbox
                onChange={updateFundingUndisclosed}
                label="Include undisclosed"
                checked={fundingUndisclosed}
              />
            </div>
          )}
        </div>
        <div data-testid="last-funding-date-section">
          <SubSectionHeader>Last funding date</SubSectionHeader>
          <ChipWithCustomRange
            key="funding-date"
            dataTestId="Last-Funding-Date-Input"
            onValueChange={(newValue) =>
              onUpdateSearchQuery(
                getUpdatedQuery(
                  searchQuery,
                  FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE,
                  newValue?.value !== undefined
                    ? {
                        range_value:
                          newValue.value as FundingLastRoundDateOptions,
                        custom_range: formatCustomRange(newValue.customRange)
                      }
                    : undefined
                )
              )
            }
            disabled={disabled}
            selectedValue={fundingLastRoundDate?.range_value}
            selectedCustomRange={fundingLastRoundDate?.custom_range}
            options={LastFundingDateOptions}
          />
        </div>
        <div data-testid="investors-section">
          <div className="grid grid-cols-2 gap-g90">
            <div>
              <SubSectionHeader>Include investors</SubSectionHeader>
              <ButtonWrapper
                label="Include investors"
                leadingIcon={CirclePlus}
                skipToChildren={
                  (includeInvestors && includeInvestors.length > 0) ?? false
                }
              >
                <InvestorMultiselectByName
                  placeholder="Select investors to include"
                  onChange={(newValues) =>
                    onUpdateSearchQuery(
                      getUpdatedQuery(
                        searchQuery,
                        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS,
                        newValues !== undefined && newValues.length > 0
                          ? {
                              investors: newValues
                            }
                          : undefined
                      )
                    )
                  }
                  initialFocus={(includeInvestors ?? []).length === 0}
                  selected={includeInvestors ?? []}
                />
              </ButtonWrapper>
            </div>
            <div>
              <SubSectionHeader>Exclude investors</SubSectionHeader>
              <ButtonWrapper
                label="Exclude investors"
                leadingIcon={CircleMinus}
                skipToChildren={
                  (excludeInvestors && excludeInvestors.length > 0) ?? false
                }
              >
                <InvestorMultiselectByName
                  placeholder="Select investors to exclude"
                  onChange={(newValues) =>
                    onUpdateSearchQuery(
                      getUpdatedQuery(
                        searchQuery,
                        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS,
                        newValues !== undefined && newValues.length > 0
                          ? {
                              investors: newValues
                            }
                          : undefined
                      )
                    )
                  }
                  initialFocus={(excludeInvestors ?? []).length === 0}
                  selected={excludeInvestors ?? []}
                />
              </ButtonWrapper>
            </div>
          </div>
        </div>
        <FundingSectionAccelerator
          controlledSearchState={controlledSearchState}
          searchQuery={searchQuery}
          onUpdateSearchQuery={onUpdateSearchQuery}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default FundingSection;
