import { ChevronDown, SearchIcon } from 'assets/harmonic-icons';
import {
  DEFAULT_SEMANTIC_INPUTS,
  SEMANTIC_OPTIONS
} from 'components/Dashboard/Semantic/types';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useOutsideClick } from 'utils/hooks';

type SemanticInput = {
  query: string;
  k: number;
  similarity: number;
};

type SemanticInputProps = {
  value: SemanticInput | undefined;
  onChange: (input: SemanticInput) => void;
};

const SemanticInput = ({
  value = DEFAULT_SEMANTIC_INPUTS,
  onChange
}: SemanticInputProps) => {
  const selectedSemantic =
    SEMANTIC_OPTIONS.find((option) => option.value === value?.similarity) ||
    SEMANTIC_OPTIONS[1];
  const [showSelect, setShowSelect] = useState(false);
  const outsideClickRef = useOutsideClick(() => setShowSelect(false));

  return (
    <div className="flex flex-col gap-g20 w-full">
      <div className="flex flex-row gap-g40" data-testid="semantic-input">
        <TextInput
          value={value.query}
          onChange={(e) => {
            onChange({ ...value, query: e.target.value });
          }}
          placeholder="Describe the company's focus, e.g. AI startups in fintech focused on..."
          leadingIcon={SearchIcon}
          borderOverrideClasses="rounded-md"
          dataTestId="semantic-text-input"
          data-testid="semantic-text-input"
        />

        <div className="min-w-34 relative">
          <Button
            label={selectedSemantic.label}
            onClick={() => setShowSelect(!showSelect)}
            emphasis="high"
            size="default"
            leadingIcon={() => (
              <span className="icon icon-outlined icon-20">tune</span>
            )}
            trailingIcon={ChevronDown}
            fullWidth={true}
          />
          {showSelect && (
            <div ref={outsideClickRef} className="absolute w-full z-100">
              <Dropdown dataTestId="semantic-options-dropdown">
                {SEMANTIC_OPTIONS.map((option) => (
                  <ListItem
                    testId={`semantic-option-${option.label}`}
                    key={option.value}
                    value={option.value}
                    variant={ListVariant.default}
                    label={option.label}
                    onClick={() => {
                      onChange({ ...value, similarity: option.value });
                      setShowSelect(false);
                    }}
                  />
                ))}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <p
        className="typography-label-small-default text-content-weak"
        data-testid="semantic-input-helper-text"
      >
        Experiment with the{' '}
        <Link
          className="text-blue-500 font-extrabold typography-label-small-strong"
          to={'/dashboard/semantic'}
        >
          search agent
        </Link>{' '}
        for more powerful natural language search
      </p>
    </div>
  );
};

export default SemanticInput;
