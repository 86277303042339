import { Provider } from 'mobx-react';
import { createContext, useContext } from 'react';
import { RootStore } from '../stores/mobx/rootStore';

const StoreContext = createContext<{
  rootStore: RootStore | null;
}>({
  rootStore: null
});

export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context.rootStore) {
    throw new Error('useStore must be used within StoreProvider');
  }
  return context as { rootStore: RootStore };
};

const StoreProvider = ({
  children,
  rootStore
}: {
  children: React.ReactNode;
  rootStore: RootStore;
}) => {
  return (
    <StoreContext.Provider value={{ rootStore }}>
      <Provider rootStore={rootStore}>{children}</Provider>
    </StoreContext.Provider>
  );
};

export default StoreProvider;
