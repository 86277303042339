import React from 'react';
import { IconProps } from './type';

const TeamNetworkList: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...(applyCurrentColor && { fill: 'currentColor' })}
    >
      <path
        d="M4.241 9.02407C4.241 9.99057 3.4575 10.7741 2.491 10.7741C1.5245 10.7741 0.740997 9.99057 0.740997 9.02407C0.740997 8.05757 1.5245 7.27407 2.491 7.27407C3.4575 7.27407 4.241 8.05757 4.241 9.02407Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.5872 9.02407C11.5872 9.99057 10.8037 10.7741 9.83722 10.7741C8.87072 10.7741 8.08722 9.99057 8.08722 9.02407C8.08722 8.05757 8.87072 7.27407 9.83722 7.27407C10.8037 7.27407 11.5872 8.05757 11.5872 9.02407Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M7.77017 2.70949C7.77017 3.67599 6.98667 4.45949 6.02017 4.45949C5.05367 4.45949 4.27017 3.67599 4.27017 2.70949C4.27017 1.74299 5.05367 0.959488 6.02017 0.959488C6.98667 0.959488 7.77017 1.74299 7.77017 2.70949Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M6.89354 3.73692L6.02751 4.23692L8.42158 8.38356L9.2876 7.88356L6.89354 3.73692Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M5.16148 3.73742L6.02751 4.23692L3.63345 8.38406L2.76742 7.88406L5.16148 3.73742Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M8.41423 9.38406V8.38406H3.6261V9.38406H8.41423Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default TeamNetworkList;
