export const HotkeyBinding = {
  // 'meta' is the command key on Mac
  OPEN_GENERIC_SEARCH_WINDOWS: ['ctrl+/'],
  OPEN_GENERIC_SEARCH_MAC: ['meta+/'],
  OPEN_HELP_MODAL: '/',

  // These hotkeys are in the 'grid' scope
  MOVE_UP_OR_DOWN: ['↑', '↓'],
  MOVE_LEFT_OR_RIGHT: ['←', '→'],
  TOGGLE_LIKE: 'l',
  TOGGLE_HIDE: 'h',
  ADD_TO_LIST: 'a',
  REMOVE_FROM_LIST: 'backspace',
  TOGGLE_VIEWED: 'v',
  OPEN_CLOSE_SORT: 's',
  OPEN_CLOSE_FILTERS: 'f',

  // These hotkeys are in the 'filters' scope
  FILTER_GENERAL_TAB: 'g',
  FILTER_TEAM_TAB: 't',
  FILTER_SECTOR_TAB: 's',
  FILTER_FUNDING_TAB: 'f',
  FILTER_CUSTOM_TAB: 'c',
  FILTER_SEE_RESULTS: 'enter',
  FILTER_CLOSE: 'esc'
};

enum HotkeyCategory {
  APP = 'General',
  GRID = 'Navigating the grid',
  FILTERS = 'Sort and filter results'
}

type HotkeyType = keyof typeof HotkeyBinding;

type HotkeyMetadata = {
  keyBinding: string | string[];
  category: HotkeyCategory;
  displayName: string;
  prefixKey?: string;
};

export const HotkeyDescriptionMap: Record<HotkeyType, HotkeyMetadata> = {
  OPEN_HELP_MODAL: {
    keyBinding: HotkeyBinding.OPEN_HELP_MODAL,
    category: HotkeyCategory.APP,
    displayName: 'Open/close keyboard glossary'
  },
  OPEN_GENERIC_SEARCH_WINDOWS: {
    keyBinding: HotkeyBinding.OPEN_GENERIC_SEARCH_WINDOWS,
    category: HotkeyCategory.APP,
    displayName: 'Open/close global search windows'
  },
  OPEN_GENERIC_SEARCH_MAC: {
    keyBinding: HotkeyBinding.OPEN_GENERIC_SEARCH_MAC,
    category: HotkeyCategory.APP,
    displayName: 'Open/close global search mac'
  },
  MOVE_UP_OR_DOWN: {
    keyBinding: HotkeyBinding.MOVE_UP_OR_DOWN,
    category: HotkeyCategory.GRID,
    displayName: 'Move up/down'
  },
  MOVE_LEFT_OR_RIGHT: {
    keyBinding: HotkeyBinding.MOVE_LEFT_OR_RIGHT,
    category: HotkeyCategory.GRID,
    displayName: 'Move left/right'
  },
  TOGGLE_LIKE: {
    keyBinding: HotkeyBinding.TOGGLE_LIKE,
    category: HotkeyCategory.GRID,
    displayName: 'Like/unlike'
  },
  TOGGLE_HIDE: {
    keyBinding: HotkeyBinding.TOGGLE_HIDE,
    category: HotkeyCategory.GRID,
    displayName: 'Hide from searches'
  },
  ADD_TO_LIST: {
    keyBinding: HotkeyBinding.ADD_TO_LIST,
    category: HotkeyCategory.GRID,
    displayName: 'Add to list'
  },
  REMOVE_FROM_LIST: {
    keyBinding: HotkeyBinding.REMOVE_FROM_LIST,
    category: HotkeyCategory.GRID,
    displayName: 'Remove from current list'
  },
  TOGGLE_VIEWED: {
    keyBinding: HotkeyBinding.TOGGLE_VIEWED,
    category: HotkeyCategory.GRID,
    displayName: 'Mark viewed/unviewed'
  },
  OPEN_CLOSE_SORT: {
    keyBinding: HotkeyBinding.OPEN_CLOSE_SORT,
    category: HotkeyCategory.FILTERS,
    displayName: 'Open/close sort options'
  },
  OPEN_CLOSE_FILTERS: {
    keyBinding: HotkeyBinding.OPEN_CLOSE_FILTERS,
    category: HotkeyCategory.FILTERS,
    displayName: 'Open/close filters'
  },

  FILTER_GENERAL_TAB: {
    keyBinding: HotkeyBinding.FILTER_GENERAL_TAB,
    category: HotkeyCategory.FILTERS,
    displayName: 'Go to general tab',
    prefixKey: 'F'
  },
  FILTER_FUNDING_TAB: {
    keyBinding: HotkeyBinding.FILTER_FUNDING_TAB,
    category: HotkeyCategory.FILTERS,
    displayName: 'Go to funding tab',
    prefixKey: 'F'
  },
  FILTER_TEAM_TAB: {
    keyBinding: HotkeyBinding.FILTER_TEAM_TAB,
    category: HotkeyCategory.FILTERS,
    displayName: 'Go to team tab',
    prefixKey: 'F'
  },
  FILTER_SECTOR_TAB: {
    keyBinding: HotkeyBinding.FILTER_SECTOR_TAB,
    category: HotkeyCategory.FILTERS,
    displayName: 'Go to sector tab',
    prefixKey: 'F'
  },
  FILTER_CUSTOM_TAB: {
    keyBinding: HotkeyBinding.FILTER_CUSTOM_TAB,
    category: HotkeyCategory.FILTERS,
    displayName: 'Go to custom tab',
    prefixKey: 'F'
  },
  FILTER_SEE_RESULTS: {
    keyBinding: HotkeyBinding.FILTER_SEE_RESULTS,
    category: HotkeyCategory.FILTERS,
    displayName: 'See results',
    prefixKey: 'F'
  },
  FILTER_CLOSE: {
    keyBinding: HotkeyBinding.FILTER_CLOSE,
    category: HotkeyCategory.FILTERS,
    displayName: 'Close filters',
    prefixKey: 'F'
  }
};
