import { useCallback } from 'react';
import useStore from 'stores/zustandStore';
import { ENVIRONMENT, FrontendEnvironments } from 'utils/constants';
import { LoggerEvent, logger } from 'utils/logger';

export const usePerformanceLogging = () => {
  const setLogging = useStore((state) => state.setLogging);
  const logging = useStore((state) => state.logging);
  const isCanary = ENVIRONMENT === FrontendEnvironments.Canary;

  const logTiming = (event: LoggerEvent) => {
    if (isCanary) return;
    logger.sendTiming(event);
  };

  /**
   * Log when the grid is ready to receive data
   */
  const logGridReady = useCallback(() => {
    if (!logging?.didLogGridReady) {
      logTiming(LoggerEvent.GRID_READY);
      setLogging({ didLogGridReady: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logging?.didLogGridReady]);

  /**
   * Log first time data is rendered in the grid
   */
  const logGridFirstDataRendered = () => {
    // Only log when the user has not left the tab
    if (!logging?.didLogGridFirstDataRendered) {
      logTiming(LoggerEvent.GRID_FIRST_DATA_RENDERED);
      setLogging({ didLogGridFirstDataRendered: true });
    }
  };

  /**
   * Log first time extended data is rendered in the grid
   */
  const logGridFirstExtendedDataRendered = () => {
    // Only log when the user has not left the tab
    if (!logging?.didLogExtendedGridFirstDataRendered) {
      logTiming(LoggerEvent.GRID_FIRST_EXTENDED_DATA_RENDERED);
      setLogging({ didLogExtendedGridFirstDataRendered: true });
    }
  };

  /**
   * Log the start of the grid data loading
   */
  const logGridDataLoadingStarted = () => {
    if (!logging?.didLogGridDataLoadingStarted) {
      logTiming(LoggerEvent.GRID_DATA_LOADING_STARTED);
      setLogging({ didLogGridDataLoadingStarted: true });
    }
  };

  /**
   * Log the completion of the grid data loading
   */
  const logGridDataLoadingCompleted = () => {
    if (!logging?.didLogGridDataLoadingCompleted) {
      logTiming(LoggerEvent.GRID_DATA_LOADING_COMPLETED);
      setLogging({ didLogGridDataLoadingCompleted: true });
    }
  };

  /**
   * Log the start of the extended grid data loading
   */
  const logGridExtendedDataLoadingStarted = () => {
    if (!logging?.didLogGridExtendedDataLoadingStarted) {
      logTiming(LoggerEvent.GRID_DATA_EXTENDED_LOADING_STARTED);
      setLogging({ didLogGridExtendedDataLoadingStarted: true });
    }
  };

  /**
   * Log the completion of the extended grid data loading
   */
  const logGridExtendedDataLoadingCompleted = () => {
    if (!logging?.didLogGridExtendedDataLoadingCompleted) {
      logTiming(LoggerEvent.GRID_DATA_EXTENDED_LOADING_COMPLETED);
      setLogging({ didLogGridExtendedDataLoadingCompleted: true });
    }
  };

  const logGridNextDataLoadingStarted = () => {
    logTiming(LoggerEvent.GRID_NEXT_DATA_LOADING_STARTED);
  };

  const logGridNextDataLoadingCompleted = () => {
    logTiming(LoggerEvent.GRID_NEXT_DATA_LOADING_COMPLETED);
  };

  const logGridNextExtendedDataLoadingStarted = () => {
    logTiming(LoggerEvent.GRID_NEXT_EXTENDED_DATA_LOADING_STARTED);
  };

  const logGridNextExtendedDataLoadingCompleted = () => {
    logTiming(LoggerEvent.GRID_NEXT_EXTENDED_DATA_LOADING_COMPLETED);
  };

  const logBoardFirstColumnRendered = () => {
    if (!logging?.didLogBoardFirstColumnRendered) {
      logTiming(LoggerEvent.BOARD_FIRST_COLUMN_RENDERED);
      setLogging({ didLogBoardFirstColumnRendered: true });
    }
  };

  const logBoardFirstCardRendered = () => {
    if (!logging?.didLogBoardFirstCardRendered) {
      logTiming(LoggerEvent.BOARD_FIRST_CARD_RENDERED);
      setLogging({ didLogBoardFirstCardRendered: true });
    }
  };

  return {
    logTiming,
    logGridReady,
    logGridFirstDataRendered,
    logGridFirstExtendedDataRendered,
    logGridDataLoadingStarted,
    logGridDataLoadingCompleted,
    logGridExtendedDataLoadingStarted,
    logGridExtendedDataLoadingCompleted,
    logGridNextDataLoadingStarted,
    logGridNextDataLoadingCompleted,
    logGridNextExtendedDataLoadingStarted,
    logGridNextExtendedDataLoadingCompleted,
    logBoardFirstColumnRendered,
    logBoardFirstCardRendered
  };
};
