import { HotkeyBinding } from 'config/hotkeys';
import { CompanyFilterTabs, FilterTabs } from 'interfaces/SearchV2';
import { first } from 'lodash';
import { useEffect } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { HOTKEYS_FILTERS_SCOPE, HOTKEYS_GRID_SCOPE } from 'utils/constants';
import useHotkeys from './useHotkeys';

export const useQueryBuilderHotkeys = (
  selectedFilterTab: FilterTabs | undefined,
  setSelectedFilterTab: (tab?: FilterTabs) => void,
  tabsToShow: FilterTabs[]
) => {
  // Activate only the filters scope so we don't clash with grid hotkey listeners
  const { disableScope, enableScope } = useHotkeysContext();
  useEffect(() => {
    if (selectedFilterTab) {
      enableScope(HOTKEYS_FILTERS_SCOPE);
      disableScope(HOTKEYS_GRID_SCOPE);
    } else {
      enableScope(HOTKEYS_GRID_SCOPE);
      disableScope(HOTKEYS_FILTERS_SCOPE);
    }
  }, [selectedFilterTab]);

  useHotkeys({
    keys: HotkeyBinding.OPEN_CLOSE_FILTERS,
    callback: () =>
      !selectedFilterTab
        ? setSelectedFilterTab(first(tabsToShow))
        : setSelectedFilterTab(undefined)
  });
  useHotkeys({
    keys: HotkeyBinding.FILTER_CLOSE,
    callback: () => setSelectedFilterTab(undefined),
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      description: 'Close filters'
    }
  });
  useHotkeys({
    keys: HotkeyBinding.FILTER_GENERAL_TAB,
    callback: () => setSelectedFilterTab(CompanyFilterTabs.GENERAL),
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      enabled: tabsToShow.includes(CompanyFilterTabs.GENERAL),
      description: 'Go to general tab'
    }
  });
  useHotkeys({
    keys: HotkeyBinding.FILTER_TEAM_TAB,
    callback: () => setSelectedFilterTab(CompanyFilterTabs.TEAM),
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      enabled: tabsToShow.includes(CompanyFilterTabs.TEAM),
      description: 'Go to team tab'
    }
  });
  useHotkeys({
    keys: HotkeyBinding.FILTER_SECTOR_TAB,
    callback: () => setSelectedFilterTab(CompanyFilterTabs.PRODUCT),
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      enabled: tabsToShow.includes(CompanyFilterTabs.PRODUCT),
      description: 'Go to product tab'
    }
  });
  useHotkeys({
    keys: HotkeyBinding.FILTER_FUNDING_TAB,
    callback: () => setSelectedFilterTab(CompanyFilterTabs.FUNDING),
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      enabled: tabsToShow.includes(CompanyFilterTabs.FUNDING),
      description: 'Go to funding tab'
    }
  });
  useHotkeys({
    keys: HotkeyBinding.FILTER_CUSTOM_TAB,
    callback: () => setSelectedFilterTab(CompanyFilterTabs.CUSTOM),
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      enabled: tabsToShow.includes(CompanyFilterTabs.CUSTOM),
      description: 'Go to custom tab'
    }
  });
};
