import { colorOptions } from 'components/Dashboard/Grids/EditField/SelectOptions/EditOptionItem';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import useTeamMembers from 'hooks/useTeamMembers';
import { useState } from 'react';
import { SelectMode } from './utils';

interface UserMultiSelectProps {
  onChange: (newOptions?: string[]) => void;
  selected: string[];
  placeholder?: string;
  disabled?: boolean;
  initialFocus?: boolean;
  freeSolo?: boolean;
  mode?: SelectMode;
}

const UserMultiSelectByName: React.FC<UserMultiSelectProps> = ({
  onChange,
  selected,
  placeholder,
  initialFocus = false,
  freeSolo,
  mode = null
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { activeTeamMembers, loading } = useTeamMembers({
    fetchPolicy: 'cache-only'
  });
  const options =
    activeTeamMembers?.map((member) => ({
      value: member?.user?.entityUrn as string,
      label: member?.user?.name as string
    })) ?? [];
  const filteredOptions = options
    .filter((option) =>
      option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLocaleLowerCase())
    );

  const onClickListItem = (option: { label: string; value: string }) => {
    const selectedOption = selected.find((s) => s === option.value);
    if (selectedOption) {
      onChange(selected.filter((s) => s !== option.value));
    } else {
      onChange([...selected, option.value ?? '']);
    }

    setSearchTerm('');
  };

  return (
    <Select
      multiple
      filterable
      freeSolo={freeSolo}
      onAdd={(value) => {
        onChange([...selected, value]);
        setSearchTerm('');
      }}
      filterTerm={searchTerm}
      onFilterTermChange={setSearchTerm}
      selected={selected}
      placeholder={placeholder}
      getLabelFromValue={(value) =>
        options.find((o) => o.value === value)?.label ?? ''
      }
      getTagColorFromValue={(value) => {
        const valueIndex = options.findIndex(
          (option) => option.value === value
        );
        const color = colorOptions[valueIndex % colorOptions.length]?.color;
        return color;
      }}
      onRemove={(key) => onChange(selected.filter((s) => s !== key))}
      initialFocus={initialFocus}
      clampValues
      dropdownMaxHeight="300px"
    >
      {loading && (
        <div className="flex w-full items-center justify-center">
          <HarmonicLoader showText={false} />
        </div>
      )}
      {!loading &&
        filteredOptions?.map((option) => {
          const color =
            colorOptions[options.indexOf(option) % colorOptions.length]?.color;
          if (!option) return null;
          return (
            <SelectListItem
              key={option.value}
              value={option.value ?? ''}
              label={option.label ?? ''}
              color={color}
              onClick={() => onClickListItem(option)}
              variant={ListVariant.tag}
            />
          );
        })}
    </Select>
  );
};

export default UserMultiSelectByName;
