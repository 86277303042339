import Button from 'harmonic-components/Button/Button';
import { BackendCustomRangeModeType } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { useState } from 'react';
import { formatDateRangeByMode } from 'utils/searchV2/searchV2';
import DateModal, { TwoSidedRangeValue } from './DateModal';

interface TimelineRangeProps {
  value?: TwoSidedRangeValue | undefined | null;
  mode?: BackendCustomRangeModeType;
  onChange: (
    mode?: BackendCustomRangeModeType,
    value?: TwoSidedRangeValue
  ) => void;
  label: string;
  dataTestId?: string;
}

const TimelineRange: React.FC<TimelineRangeProps> = ({
  mode,
  value,
  onChange,
  label,
  dataTestId
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pillLabel = formatDateRangeByMode(label, mode, value);

  return (
    <div className="flex gap-g30">
      <Button
        isSelected={isOpen || !isNil(value)}
        label={pillLabel}
        onClick={handleClick}
        emphasis={isOpen || !isNil(value) ? 'high' : 'medium'}
        dataTestId={dataTestId}
      />
      <DateModal
        anchorEl={anchorEl}
        onClose={handleClose}
        open={isOpen}
        mode={mode}
        value={value}
        onChange={onChange}
      />
      {!isNil(value) && (
        <Button
          dataTestId="TimelineCleartBtn"
          type="secondary"
          emphasis="low"
          label="Clear"
          onClick={() => onChange(undefined, undefined)}
        />
      )}
    </div>
  );
};

export default TimelineRange;
