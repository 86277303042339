import { ApolloClient, gql, Reference, StoreObject } from '@apollo/client';
import {
  CreateSavedSearchMutation,
  CreateSavedSearchMutationVariables,
  CreateWatchlistMutation,
  CreateWatchlistMutationVariables,
  RemoveWatchlistMutation,
  RemoveWatchlistMutationVariables,
  SearchType,
  SortInfo,
  UpdateWatchlistMutation,
  UpdateWatchlistMutationVariables
} from '__generated__/graphql';
import { getFirebaseToken } from 'actions/fetchActions';
import axios, { AxiosHeaders, AxiosResponse, CancelToken } from 'axios';
import { companySortableHeaderFields } from 'components/Dashboard/Grids/ColumnDefinitions/CompanyHeaders';
import { PeopleSortableHeaderFields } from 'components/Dashboard/Grids/ColumnDefinitions/PeopleHeaders';
import { LogQueryParams } from 'components/Dashboard/Semantic/types';
import { config } from 'config/config';
import {
  AffinityFieldsResponse,
  AffinityList,
  AffinityListSyncStatus,
  AffinityOptionResponse,
  AffinityPushResponse,
  AffinitySpendByMonth
} from 'interfaces/Affinity';
import { UserOutput } from 'interfaces/Auth';
import { ISavedSearchNotificationSubscriptionResponse } from 'interfaces/DataModel/ApiResource';
import { CompanyCustomTextScope } from 'interfaces/DataModel/Company';
import {
  IUserApiActivityResponse,
  IUserApiRequestsMetricResponse,
  IUserApiUptimeResponse,
  IUserResponse,
  SavedSearchUserOptionsResponse,
  SearchCountApiResponse,
  UserApiKey,
  UserOnboardingPayload,
  UserOnboardingResponse,
  WatchlistPendingAction
} from 'interfaces/DataModel/MidtierResource';
import { ColumnHeaderOrder, EntityActionPayload } from 'interfaces/Grid';
import {
  EntityListType,
  INITIAL_COMPANY_SORT,
  INITIAL_PEOPLE_SEARCH_MODEL,
  INITIAL_PEOPLE_SORT_VALUE,
  INITIAL_SEARCH_MODEL,
  ISearchModel,
  SavedSearchInput
} from 'interfaces/SearchModel/Search';
import { CREATE_SAVED_SEARCH } from 'queries/createSavedSearch';
import { CREATE_WATCHLIST } from 'queries/createWatchlist';
import { REMOVE_WATCHLIST } from 'queries/removeWatchlist';
import { UPDATE_WATCHLIST } from 'queries/updateWatchlist';
import { useAppStore } from '../hooks/useAppStore';
import analytics, { CustomTrackEvent, SearchVersion } from './analytics';
import { makeGraphqlMutationWithApolloClient } from './api';
import './axiosInterceptor';
import {
  API_V2_BULK_UPLOAD,
  API_V2_DATASTORE_USER_ROUTE,
  COMPANY_GRID_EXPORT_MAX_COUNT,
  PEOPLE_GRID_EXPORT_MAX_COUNT,
  PERSONS_ROUTE
} from './constants';
import { logger } from './logger';
import {
  removeArtifactsFromSearchQuery,
  transformSearchModelForApiv2
} from './search';
import { camelize, snakelize } from './utilities';

export interface ISavedSearchInput {
  name: string;
  is_private: boolean;
  type:
    | EntityListType.COMPANY_SAVED_SEARCH
    | EntityListType.PEOPLE_SAVED_SEARCH;
  query: ISearchModel;
  keywords: string;
  page?: number;
  page_size?: number;
  visible_columns?: string[];
  clear_net_new?: boolean;
}

interface AddWatchlistInputCompanies {
  name: string;
  shared_with_team: boolean;
  companies: string[];
}
interface AddWatchlistInputPeople {
  name: string;
  shared_with_team: boolean;
  people: string[];
}

export type IAddWatchlistInput =
  | AddWatchlistInputCompanies
  | AddWatchlistInputPeople;

interface IWatchlistInput {
  name: string;
  shared_with_team: boolean;
}

export const midtierAxios = axios.create({
  baseURL: config.BASE_MIDTIER_API_URL
});

midtierAxios.interceptors.request.use(async (config) => {
  const isAuthenticated = useAppStore.getState().auth?.isAuthenticated;

  if (isAuthenticated) {
    const firebaseToken = await getFirebaseToken();
    const headers = new AxiosHeaders();
    headers.set('authorization', firebaseToken);
    headers.set('x-harmonic-request-source', 'frontend');
    config.headers = headers;
  }
  return config;
});

export const getUser = (): Promise<IUserResponse> => {
  return getFirebaseToken().then((token: string) => {
    return midtierAxios
      .get(`users/firebaseToken/${token}`)
      .then((response: AxiosResponse<IUserResponse>) => {
        return response?.data;
      });
  });
};

export const acceptPilotAgreement = () => {
  return midtierAxios.post('users/acceptPilotContract').then((res) => res.data);
};

export const processOnboarding = async (
  userOnboardingPayload: UserOnboardingPayload
) => {
  return midtierAxios
    .post('users/onboard', userOnboardingPayload)
    .then((res: AxiosResponse<UserOnboardingResponse>) => {
      return res.data;
    });
};

interface CompaniesListForExportInput {
  searchModel: ISearchModel;
  companyCollectionId?: string;
  includeCount?: boolean;
  includeResults?: boolean;
  includeCompanyIdsOnly?: boolean;
  includeCompanySnapshots?: boolean;
  filterOutUnenriched?: boolean;
}

interface StreamCompaniesAsCSVInput extends CompaniesListForExportInput {
  onChunkProcessed: (
    processedContent: string,
    processedRowsCount: number,
    cancel: () => void
  ) => void;
  columns: string[];
}
interface PeopleListForExportInput {
  searchModel: ISearchModel;
  peopleWatchlistId?: string;
  includeCount?: boolean;
  includeResults?: boolean;
  includePersonIdsOnly?: boolean;
  includePersonSnapshots?: boolean;
  filterOutUnenriched?: boolean;
}

interface StreamPeopleAsCSVInput extends PeopleListForExportInput {
  onChunkProcessed: (
    processedContent: string,
    processedRowsCount: number,
    cancel: () => void
  ) => void;
  columns: string[];
}

const _createCompaniesExportRequestBody = (
  searchModel: ISearchModel,
  companyCollectionId?: string,
  includeCount?: boolean,
  includeResults?: boolean,
  includeCompanyIdsOnly?: boolean,
  includeCompanySnapshots?: boolean,
  filterOutUnenriched?: boolean
) => {
  const requestBody: Record<string, unknown> = {};

  const transformedSearchModel: ISearchModel =
    transformSearchModelForApiv2(searchModel);
  const snakeQuery = snakelize(transformedSearchModel);
  requestBody.query = {
    ...snakeQuery,
    pagination: {
      start: 0,
      page_size: COMPANY_GRID_EXPORT_MAX_COUNT
    }
  };
  if (companyCollectionId) {
    requestBody.company_collection_id = companyCollectionId;
  }
  if (includeCount) {
    requestBody.include_count = includeCount;
  }
  requestBody.include_results = includeResults;
  requestBody.include_all_company_ids_only = includeCompanyIdsOnly;
  requestBody.include_company_snapshots = includeCompanySnapshots;
  requestBody.filter_out_unenriched = filterOutUnenriched;

  return requestBody;
};

const _createPeopleExportRequestBody = (
  searchModel: ISearchModel,
  peopleWatchlistId?: string,
  includeCount?: boolean,
  includeResults?: boolean,
  includePersonIdsOnly?: boolean,
  includePersonSnapshots?: boolean,
  filterOutUnenriched?: boolean
) => {
  const requestBody: Record<string, unknown> = {};

  const transformedSearchModel: ISearchModel =
    transformSearchModelForApiv2(searchModel);
  const snakeQuery = snakelize(transformedSearchModel);
  requestBody.query = {
    ...snakeQuery,
    pagination: {
      start: 0,
      page_size: PEOPLE_GRID_EXPORT_MAX_COUNT
    }
  };
  if (peopleWatchlistId) {
    requestBody.people_watchlist_id = peopleWatchlistId;
  }
  if (includeCount) {
    requestBody.include_count = includeCount;
  }
  requestBody.include_results = includeResults;
  requestBody.include_all_person_ids_only = includePersonIdsOnly;
  requestBody.include_person_snapshots = includePersonSnapshots;
  requestBody.filter_out_unenriched = filterOutUnenriched;

  return requestBody;
};

export class ExportCancelledError extends Error {
  constructor() {
    super('Export cancelled');
  }
}

export const streamPeopleAsCSV = async ({
  searchModel,
  peopleWatchlistId,
  columns,
  onChunkProcessed,
  includeCount = true,
  includeResults = true,
  includePersonIdsOnly = false,
  includePersonSnapshots = false,
  filterOutUnenriched = false
}: StreamPeopleAsCSVInput): Promise<void> => {
  const requestBody: Record<string, unknown> = _createPeopleExportRequestBody(
    searchModel,
    peopleWatchlistId,
    includeCount,
    includeResults,
    includePersonIdsOnly,
    includePersonSnapshots,
    filterOutUnenriched
  );
  requestBody.specific_csv_columns = columns;

  const token = await getFirebaseToken();
  // axios does not support streaming responses in the browser
  // using fetch instead
  const response = await fetch(`${config.BASE_MIDTIER_API_URL}exports/people`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      'x-harmonic-request-source': 'frontend'
    },
    body: JSON.stringify(requestBody),
    method: 'POST'
  });

  if (!response.ok || !response.body) {
    throw new Error(
      `Failed to stream people as CSV: ${response.status} ${response.statusText}`
    );
  }

  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
  let cancelled = false;
  const cancel = () => {
    cancelled = true;
  };
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (cancelled) {
      reader.cancel();
      throw new ExportCancelledError();
    }

    if (done) {
      reader.cancel();
      return;
    }

    const newLineCount = (value.match(/(?<!\\)\n/g) || []).length;
    onChunkProcessed(value, newLineCount, cancel);
  }
};

export const streamCompaniesAsCSV = async ({
  searchModel,
  companyCollectionId,
  columns,
  onChunkProcessed,
  includeCount = true,
  includeResults = true,
  includeCompanyIdsOnly = false,
  includeCompanySnapshots = false,
  filterOutUnenriched = false
}: StreamCompaniesAsCSVInput): Promise<void> => {
  const requestBody: Record<string, unknown> =
    _createCompaniesExportRequestBody(
      searchModel,
      companyCollectionId,
      includeCount,
      includeResults,
      includeCompanyIdsOnly,
      includeCompanySnapshots,
      filterOutUnenriched
    );
  requestBody.specific_csv_columns = columns;

  const token = await getFirebaseToken();
  // axios does not support streaming responses in the browser
  // using fetch instead
  const response = await fetch(
    `${config.BASE_MIDTIER_API_URL}exports/companies`,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        'x-harmonic-request-source': 'frontend'
      },
      body: JSON.stringify(requestBody),
      method: 'POST'
    }
  );

  if (!response.ok || !response.body) {
    throw new Error(
      `Failed to stream companies as CSV: ${response.status} ${response.statusText}`
    );
  }

  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
  let cancelled = false;
  const cancel = () => {
    cancelled = true;
  };
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (cancelled) {
      reader.cancel();
      throw new ExportCancelledError();
    }

    if (done) {
      reader.cancel();
      return;
    }

    const newLineCount = (value.match(/(?<!\\)\n/g) || []).length;
    onChunkProcessed(value, newLineCount, cancel);
  }
};

// Sign In Token for Chrome Extension
export const getUserSignInToken = async (): Promise<string> => {
  const token = await getFirebaseToken();
  const response = await midtierAxios.post('users/signInToken', {
    headers: {
      authorization: token
    }
  });

  return response?.data?.token;
};

export const getUserApiRequests = (
  apiKey?: string
): Promise<IUserApiRequestsMetricResponse> => {
  return midtierAxios
    .get(`metrics/api_requests`, {
      headers: {
        apikey: apiKey
      }
    })
    .then((response: AxiosResponse<IUserApiRequestsMetricResponse>) => {
      return response?.data;
    });
};

export const getUserApiKeys = async (): Promise<UserApiKey[]> => {
  const user = await getUser();
  const customerUrn = user.customer;
  return midtierAxios
    .get(`customers/${customerUrn}/apiKeys`)
    .then((response: AxiosResponse<UserApiKey[]>) => {
      return response?.data;
    });
};

export const createNewApiKey = async (
  name: string,
  customerUrn: string
): Promise<UserApiKey> => {
  const bodyFormData = new FormData();
  bodyFormData.append('name', name);
  bodyFormData.append('key_request_source', 'CONSUMER_API');
  return midtierAxios
    .post(`customers/${customerUrn}/apiKeys`, bodyFormData)
    .then((response: AxiosResponse<UserApiKey>) => {
      return response?.data;
    });
};

export const deleteApiKey = async (
  keyId: string,
  customerUrn: string
): Promise<UserApiKey> => {
  const bodyFormData = new FormData();
  bodyFormData.append('api_key_id', keyId);
  return midtierAxios
    .delete(`customers/${customerUrn}/apiKeys`, { data: bodyFormData })
    .then((response: AxiosResponse<UserApiKey>) => {
      return response?.data;
    });
};

export const getUserApiUptime = (
  apiKey?: string
): Promise<IUserApiUptimeResponse> => {
  return midtierAxios
    .get('metrics/uptime', {
      headers: {
        apikey: apiKey
      }
    })
    .then((res: AxiosResponse<IUserApiUptimeResponse>) => {
      return res.data;
    });
};

export const updateSavedSearchUserOptions = (
  savedSearchId: string,
  options: {
    isPinned: boolean;
  }
): Promise<SavedSearchUserOptionsResponse> => {
  return midtierAxios
    .put('savedSearches:userOptions', {
      saved_search_id: savedSearchId,
      is_pinned: options.isPinned
    })
    .then((res: AxiosResponse<SavedSearchUserOptionsResponse>) => {
      return res.data;
    });
};

export const updateUserSettings = (
  {
    pinnedSavedSearchesOrder
  }: {
    pinnedSavedSearchesOrder: string[];
  },
  cancelToken?: CancelToken
): Promise<IUserResponse> => {
  return midtierAxios
    .put(
      'users/settings',
      {
        pinned_saved_searches_order: pinnedSavedSearchesOrder
      },
      {
        cancelToken
      }
    )
    .then((res) => res.data);
};

export const normalizeSearchModel = (
  searchModel: ISearchModel
): ISearchModel => {
  return {
    ...searchModel,
    sort: searchModel.sort?.map((sort) => {
      const normalized = { ...sort };
      if ('sortField' in normalized) {
        normalized.sort_field = normalized.sortField;
        delete normalized.sortField;
      }
      return normalized;
    })
  };
};

export const getPeopleWatchlistPendingActions = async (
  watchlistId: string
): Promise<WatchlistPendingAction[]> => {
  return midtierAxios
    .get(`watchlists/people/${watchlistId}/pending`)
    .then((res) => res.data);
};

export const getCompaniesWatchlistPendingActions = async (
  watchlistId: string
): Promise<WatchlistPendingAction[]> => {
  return midtierAxios
    .get(`watchlists/companies/${watchlistId}/pending`)
    .then((res) => res.data);
};

export const getCompaniesSearchResultCount = async (
  searchModel: ISearchModel,
  cancelToken?: CancelToken
): Promise<SearchCountApiResponse> => {
  const transformedSearchModel = transformSearchModelForApiv2(searchModel);

  return midtierAxios
    .post(
      `search/companies_count`,
      {
        query: normalizeSearchModel(transformedSearchModel),
        type: 'COMPANIES_LIST',
        only_surfaceable: true
      },
      {
        cancelToken
      }
    )
    .then((res: AxiosResponse<SearchCountApiResponse>) => {
      return res.data;
    });
};

export const getPeopleSearchResultCount = async (
  searchModel: ISearchModel,
  cancelToken?: CancelToken
): Promise<SearchCountApiResponse> => {
  const transformedSearchModel: ISearchModel =
    transformSearchModelForApiv2(searchModel);

  return midtierAxios
    .post(
      `search/people_count`,
      {
        query: normalizeSearchModel(transformedSearchModel),
        type: 'PERSONS',
        only_surfaceable: true
      },
      {
        cancelToken
      }
    )
    .then((res: AxiosResponse<SearchCountApiResponse>) => {
      return res.data;
    });
};

export const getRecentApiActivity = (
  apiKey?: string
): Promise<IUserApiActivityResponse> => {
  return midtierAxios
    .get(`metrics/recent_activity`, {
      headers: {
        apikey: apiKey
      }
    })
    .then((response: AxiosResponse<IUserApiActivityResponse>) => {
      return response?.data;
    });
};

export const addWatchlist = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  addWatchlistInput: IAddWatchlistInput
): Promise<{ urn: string }> => {
  const result = await makeGraphqlMutationWithApolloClient<
    CreateWatchlistMutation,
    CreateWatchlistMutationVariables
  >(client, CREATE_WATCHLIST, {
    watchlistInput: camelize(addWatchlistInput)
  });

  if (client) {
    // For reference: write query doesnt work with lists of reference objects.
    // client.cache.writeQuery({
    //   query: getCompanyWatchlists,
    //   data: result.data?.createCompanyWatchlist
    // });
    // so we need to use cache.modify directly
    // finding the root query in the apollo cache with the query key getCompanyWatchlistsForTeam
    // adding the new reference created by the mutation to the list of existing references (watchlist)
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Query',
        id: 'ROOT_QUERY'
      }),
      fields: {
        getCompanyWatchlistsForTeam(existingRefs, options) {
          const newWatchlistRef = options.toReference({
            __typename: 'CompanyWatchlist',
            id: result.data?.createCompanyWatchlist.id
          });
          return [...existingRefs, newWatchlistRef];
        }
      }
    });
  }

  return { urn: result.data?.createCompanyWatchlist.entityUrn };
};

export const updateWatchlist = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  watchlistUrn: string,
  watchlistInput: Partial<IWatchlistInput>
): Promise<UpdateWatchlistMutation['updateCompanyWatchlist'] | undefined> => {
  const result = await makeGraphqlMutationWithApolloClient<
    UpdateWatchlistMutation,
    UpdateWatchlistMutationVariables
  >(client, UPDATE_WATCHLIST, {
    idOrUrn: watchlistUrn,
    watchlistInput: camelize(watchlistInput)
  });
  return result.data?.updateCompanyWatchlist;
};

export const deleteWatchlist = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  watchlistUUID: string
): Promise<RemoveWatchlistMutation['deleteCompanyWatchlist'] | undefined> => {
  const result = await makeGraphqlMutationWithApolloClient<
    RemoveWatchlistMutation,
    RemoveWatchlistMutationVariables
  >(client, REMOVE_WATCHLIST, {
    idOrUrn: watchlistUUID
  });
  // finding the root query in the apollo cache with the query key getCompanyWatchlistsForTeam
  // adding the new reference created by the mutation to the list of existing references (watchlist)
  client.cache.modify({
    id: client.cache.identify({
      __typename: 'Query',
      id: 'ROOT_QUERY'
    }),
    fields: {
      getCompanyWatchlistsForTeam(existingRefs, actions) {
        return existingRefs.filter(
          (watchlistRef: StoreObject | Reference | undefined) =>
            actions.readField('id', watchlistRef) !== watchlistUUID
        );
      }
    }
  });
  return result.data?.deleteCompanyWatchlist;
};

const transformSearchQueryWithValidSortFieldHelper = (
  searchQuery: ISearchModel,
  validSortFields: string[],
  defaultSortValue: string,
  defaultSearchModel: ISearchModel
): ISearchModel => {
  if (searchQuery.sort) {
    searchQuery.sort = searchQuery.sort.map((sort) => {
      const existingSortField = sort.sort_field || sort.sortField;
      return {
        ...sort,
        sortField:
          existingSortField && validSortFields.includes(existingSortField)
            ? existingSortField
            : defaultSortValue
      };
    });
  } else {
    searchQuery = defaultSearchModel;
  }
  return searchQuery;
};

const transformPeopleSearchQueryWithValidSortField = (
  searchQuery: ISearchModel
): ISearchModel => {
  return transformSearchQueryWithValidSortFieldHelper(
    searchQuery,
    PeopleSortableHeaderFields,
    INITIAL_PEOPLE_SORT_VALUE,
    INITIAL_PEOPLE_SEARCH_MODEL
  );
};

const transformCompanySearchQueryWithValidSortField = (
  searchQuery: ISearchModel
): ISearchModel => {
  return transformSearchQueryWithValidSortFieldHelper(
    searchQuery,
    companySortableHeaderFields,
    INITIAL_COMPANY_SORT.sortField,
    INITIAL_SEARCH_MODEL
  );
};

export const transformSearchQueryWithValidSortField = (
  searchQuery: ISearchModel,
  isPeopleSort = false
): ISearchModel => {
  return isPeopleSort
    ? transformPeopleSearchQueryWithValidSortField(searchQuery)
    : transformCompanySearchQueryWithValidSortField(searchQuery);
};

export const createSavedSearch = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  savedSearchInput: ISavedSearchInput,
  version: SearchVersion | undefined = SearchVersion.V1
): Promise<CreateSavedSearchMutation['createSavedSearch'] | undefined> => {
  if (savedSearchInput.type === EntityListType.PEOPLE_SAVED_SEARCH) {
    savedSearchInput.query = transformSearchQueryWithValidSortField(
      savedSearchInput.query,
      true
    );
  } else {
    savedSearchInput.query = transformSearchQueryWithValidSortField(
      savedSearchInput.query
    );
  }

  savedSearchInput.query = removeArtifactsFromSearchQuery(
    savedSearchInput.query
  );

  const result = await makeGraphqlMutationWithApolloClient<
    CreateSavedSearchMutation,
    CreateSavedSearchMutationVariables
  >(
    client,
    CREATE_SAVED_SEARCH,
    {
      savedSearchInput: camelize(savedSearchInput) as SavedSearchInput
    },
    undefined
  );
  analytics.trackCustomEvent({
    event: CustomTrackEvent.SAVED_SEARCH_CREATED,
    properties: {
      type:
        savedSearchInput.type === EntityListType.COMPANY_SAVED_SEARCH
          ? 'company'
          : 'person',
      version: version
    }
  });

  if (client) {
    // finding the root query in the apollo cache with the query key getCompanyWatchlistsForTeam
    // adding the new reference created by the mutation to the list of existing references (watchlist)
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Query',
        id: 'ROOT_QUERY'
      }),
      fields: {
        getSavedSearchesForTeam(existingRefs, options) {
          const newSSRef = options.toReference({
            __typename: 'SavedSearch',
            id: result.data?.createSavedSearch.id
          });
          const newRefList = [...existingRefs, newSSRef];
          return newRefList;
        }
      },
      broadcast: false,
      optimistic: true
    });
  }
  return result.data?.createSavedSearch;
};

interface PeopleUrnResponseData {
  urn?: string;
  error?: string;
}
export const getPeopleUrnsByLinkedinUrl = (
  linkedin_urls: string[]
): Promise<Record<string, PeopleUrnResponseData>> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}${PERSONS_ROUTE}/${API_V2_BULK_UPLOAD}`;
  return midtierAxios
    .post(apiUrl, linkedin_urls)
    .then((response: AxiosResponse) => response.data.result);
};

interface DataStoreResponse {
  key: string;
  columns: ColumnHeaderOrder[];
}

interface SetColumnsBody {
  columns: ColumnHeaderOrder[];
}

export interface ApiContractDetailsResponse {
  api_calls_month?: number;
  api_calls_week?: number;
  api_limit?: number;
  last_updated?: string;
  subscription_period_start?: string;
  subscription_period_end?: string;
  total_api_calls: number;
}
export const getApiContractDetails =
  async (): Promise<ApiContractDetailsResponse> => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}metrics/api_contract_details`;

    return midtierAxios
      .get(apiUrl)
      .then((response: AxiosResponse) => response.data)
      .catch((error) =>
        logger.error('Failed to get API contract details', {
          error
        })
      );
  };

export const setDataStoreUserValue = async (
  key: string,
  body: SetColumnsBody
): Promise<DataStoreResponse> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}${API_V2_DATASTORE_USER_ROUTE}/${key}`;

  const response = await midtierAxios
    .post(apiUrl, body)
    .then((response: AxiosResponse) => response.data)
    .catch((error) =>
      logger.error('Failed to set user value for the datastore', {
        error
      })
    );
  return response;
};

export const authenticate = async (): Promise<UserOutput> => {
  const firebaseToken = await getFirebaseToken();
  return midtierAxios
    .post(
      `users/authenticate`,
      {},
      {
        headers: {
          authorization: firebaseToken
        }
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const getUserApiKey = (): Promise<string> => {
  return authenticate().then((user) => user?.apikey);
};

export const getCustomerAffinityApiKeyIsSet = async (): Promise<boolean> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return Boolean(response?.is_key_set);
};

export const setCustomerAffinityApiKey = async (
  apiKey: string
): Promise<boolean> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity`;

  const response = await midtierAxios
    .put(apiUrl, { affinity_api_key: apiKey })
    .then((response: AxiosResponse) => response.data);

  return Boolean(response?.is_key_set);
};

export const revokeCustomerAffinityApiKey = async (): Promise<boolean> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity`;

  const response = await midtierAxios
    .delete(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return Boolean(response?.is_key_set);
};

export const startAffinityWatchListSync = async (): Promise<boolean> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncWatchlists`;

  const response = await midtierAxios
    .post(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return Boolean(response);
};

export const getAffinityWatchListSync = async (): Promise<
  AffinityListSyncStatus[]
> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncWatchlists`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const setCustomerAffinitySyncMode = async (
  syncMode: string
): Promise<string> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncMode`;

  const response = await midtierAxios
    .put(apiUrl, { sync_mode: syncMode })
    .then((response: AxiosResponse) => response.data);

  return response?.sync_mode;
};

export const getCustomerAffinitySyncMode = async (): Promise<string> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncMode`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return response?.sync_mode;
};

export const getCustomerAffinityFields = async (
  companyId: number
): Promise<AffinityFieldsResponse[]> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/company/${companyId}/fields`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      logger.error('Failed to get affinity fields', {
        error
      });
      return [];
    });

  return response;
};

export const getCustomerAffinitySpendByMonth = async (): Promise<
  AffinitySpendByMonth[]
> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/credits/byMonth`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const getCustomerAffinityLists = async (): Promise<AffinityList[]> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/organizations`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const setCustomerAffinityLists = async (
  lists: AffinityList[]
): Promise<AffinityList[]> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/organizations`;

  const response = await midtierAxios
    .put(apiUrl, lists)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const getAffinityWatchlistStatusOwnerOptions = async (
  watchlist_id: string
): Promise<AffinityOptionResponse> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/urn:harmonic:company_watchlist:${watchlist_id}/field_options`;

  const response = await midtierAxios
    .get(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const pushToAffinity = async (
  watchlist_id: string,
  company_ids: number[],
  new_affinity_owners: string[],
  new_status: string
): Promise<AffinityPushResponse> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/urn:harmonic:company_watchlist:${watchlist_id}/companies`;

  const company_urns = company_ids.map((id) => `urn:harmonic:company:${id}`);
  const payload = {
    company_urns,
    new_affinity_owners,
    ...(new_status !== '' && { new_status: new_status })
  };
  const response = await midtierAxios
    .post(apiUrl, payload)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const removeFromAffinity = async (
  watchlist_id: string,
  company_id: number
): Promise<AffinityPushResponse> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/urn:harmonic:company_watchlist:${watchlist_id}/companies/urn:harmonic:company:${company_id}`;

  const response = await midtierAxios
    .delete(apiUrl)
    .then((response: AxiosResponse) => response.data);

  return response;
};

export const authenticateGoogleIntegration = async (
  code: string
): Promise<{ data: { status: string } }> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}google_auth/authenticate`;
  return midtierAxios.get(`${apiUrl}?code=${code}`);
};

export const authenticateMicrosoftIntegration = async (
  authorizationCode: string
): Promise<{ data: { status: string } }> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}microsoft_auth/authenticate`;
  return midtierAxios.get(`${apiUrl}?code=${authorizationCode}`);
};

export const generateSlackAuthUrl = async (): Promise<string> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/slack/oauth/configUrl`;
  return midtierAxios.get(apiUrl).then((response: AxiosResponse<string>) => {
    return response.data;
  });
};

export const getSavedSearchNotificationSubscriptions = (
  requestParams: Record<string, unknown>
): Promise<ISavedSearchNotificationSubscriptionResponse> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}notifications/subscription`;
  return midtierAxios
    .get(apiUrl, {
      params: requestParams
    })
    .then(
      (
        response: AxiosResponse<ISavedSearchNotificationSubscriptionResponse>
      ) => {
        return response.data;
      }
    );
};

export const upsertSavedSearchNotificationSubscription = async (
  requestBody: Record<string, unknown>
): Promise<ISavedSearchNotificationSubscriptionResponse> => {
  const apiUrl = `${config.BASE_MIDTIER_API_URL}notifications/subscription`;
  return midtierAxios
    .put(apiUrl, requestBody)
    .then(
      (
        response: AxiosResponse<ISavedSearchNotificationSubscriptionResponse>
      ) => {
        return response.data;
      }
    );
};

export const updateUserName = async (
  name: string
): Promise<AxiosResponse<void>> => {
  return midtierAxios
    .put('users/', {
      name
    })
    .then((res) => res)
    .catch((error) => {
      return error.response;
    });
};

export const performEntityActionFromSearchQuery = async (
  entityActionPayload: EntityActionPayload,
  searchQuery: ISearchModel,
  entityLimit: number
) => {
  const res = await midtierAxios.post(
    'entity_actions/search_query',
    snakelize({
      entityActionPayload,
      search_input: {
        query: searchQuery,
        type:
          entityActionPayload.targetEntityType === 'COMPANY'
            ? SearchType.COMPANIES_LIST
            : SearchType.PERSONS
      },
      entity_limit: entityLimit
    })
  );
  return res.data;
};

export const performEntityActionFromWatchlist = async (
  entityActionPayload: EntityActionPayload,
  watchlistUrn: string,
  sortOptions: SortInfo,
  entityLimit: number
) => {
  const res = await midtierAxios.post(
    'entity_actions/watchlist',
    snakelize({ entityActionPayload, watchlistUrn, sortOptions, entityLimit })
  );
  return res.data;
};

export const semanticPOCLogQuery = async (loggingData: LogQueryParams) => {
  const response = await midtierAxios.post(
    'search/companies_by_semantic_logs',
    loggingData
  );

  return response.data;
};

export const updateCompanyCustomText = async (
  client: ApolloClient<unknown>,
  companyId: number,
  customText: string,
  scope: CompanyCustomTextScope,
  cancelToken?: CancelToken
): Promise<void> => {
  const apiKey = useAppStore.getState().auth.apiKey;

  const updatedField =
    scope === CompanyCustomTextScope.USER ? 'userNotes' : 'teamNotes';

  const company = client.cache.readFragment<{
    id: number;
    userNotes?: string;
    teamNotes?: string;
  }>({
    id: client.cache.identify({
      __typename: 'Company',
      id: companyId
    }),
    fragment: gql`
      fragment UpdatedCompanySearch on Company {
        id
        ${updatedField}
      }
    `
  });

  const updateField = (text?: string) => {
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Company',
        id: companyId
      }),
      fields: {
        [updatedField]: () => text
      },
      broadcast: false // so the grid doesn't re-render and jump to the top
    });
  };

  updateField(customText);

  const apiUrl = `${config.BASE_MIDTIER_API_URL}companies/set_custom_text/${companyId}`;

  try {
    await midtierAxios.put(apiUrl, null, {
      params: {
        custom_text: customText,
        scope
      },
      headers: { apiKey },
      cancelToken
    });
  } catch (error) {
    updateField(company?.[updatedField]);
    throw error;
  }
};
