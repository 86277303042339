import { useQuery } from '@apollo/client';
import {
  GetPersonContactInformationQuery,
  GetPersonContactInformationQueryVariables
} from '__generated__/graphql';
import EmailIcon from 'assets/harmonic-icons/email-icon';
import { ButtonSize } from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import React from 'react';
import PersonEmailUnlockModal from './PersonEmailUnlockModal';

const GetPersonEmailButtonContentLoader: React.FC = () => {
  return <div className="w-8 h-8 rounded-full animated-box-pulse" />;
};
interface GetPersonEmailButtonProps {
  personId: number;
  size?: ButtonSize;
}

const GetPersonEmailButton: React.FC<GetPersonEmailButtonProps> = ({
  personId,
  size = 'default'
}) => {
  const { data: personContactInformation } = useQuery<
    GetPersonContactInformationQuery,
    GetPersonContactInformationQueryVariables
  >(getPersonContactInformation, {
    variables: {
      id: personId
    },
    fetchPolicy: 'cache-only'
  });

  const isUnlocked = Boolean(
    personContactInformation?.getPersonById?.contact?.emails?.length ||
      personContactInformation?.getPersonById?.emailEnrichmentStatus
        ?.enrichedAt !== null
  );

  const { show } = useModal();

  const handleOnUnlock = () => {
    show(<PersonEmailUnlockModal personId={personId} />);
  };

  if (!personContactInformation) {
    return <GetPersonEmailButtonContentLoader />;
  }

  return (
    <IconButton
      icon={EmailIcon}
      onClick={handleOnUnlock}
      type="secondary"
      emphasis="high"
      size="compact"
      dataTestId="GetEmailTextIcon"
    />
  );
};

export default GetPersonEmailButton;
