import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CirclePlus: HarmonicIcon = ({ applyCurrentColor = true, className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      stroke="#1A1D23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8284 8.0003H5.17155M7.99998 10.8287V5.17188M14.1666 8C14.1666 11.4058 11.4057 14.1667 7.99998 14.1667C4.59422 14.1667 1.83331 11.4058 1.83331 8C1.83331 4.59425 4.59422 1.83334 7.99998 1.83334C11.4057 1.83334 14.1666 4.59425 14.1666 8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default CirclePlus;
