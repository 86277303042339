import { ColorShorthand } from 'common/utils/design';
import CompanyMultiselectById from 'components/QueryBuilder/Common/CompanyMultiselectById';
import CompanyMultiselectByName from 'components/QueryBuilder/Common/CompanyMultiselectByName';
import IntegratedEmailUserMultiselect from 'components/QueryBuilder/Common/IntegratedEmailUserMultiselect';
import InvestorMultiselectByName from 'components/QueryBuilder/Common/InvestorMultiselectByName';
import ListMultiselect from 'components/QueryBuilder/Common/ListMultiselect';
import PersonMultiSelectById from 'components/QueryBuilder/Common/PersonMultiselectById';
import SchoolMultiselect from 'components/QueryBuilder/Common/SchoolMultiselect';
import UserMultiSelectByName from 'components/QueryBuilder/Common/UserMultiselectByName';
import TextField from 'components/common/lib/TextField';
import DatePicker from 'harmonic-components/DatePicker/DatePicker';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import {
  EntityListType,
  FilterArrayComparator,
  FilterComparator,
  FilterDateComparator,
  FilterDisplayFormatType,
  FilterListComparator,
  FilterNumberComparator,
  FilterStringComparator,
  SearchFieldSpecSelectValue,
  SearchValueToDisplayNameMap,
  TableFilterType
} from 'interfaces/SearchModel/Search';
import { capitalize, compact, isEqual, toNumber } from 'lodash';
import get from 'lodash/get';
import toString from 'lodash/toString';
import React, { ChangeEvent, useEffect } from 'react';
import { AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER } from 'utils/constants';
import { returnNumberFromString } from 'utils/utilities';
import { getFormattedFilterValueBasedOnDisplayType } from '../utils';

const ValueInput = (props: {
  comparator: FilterComparator;
  rangeFromValue: string;
  rangeToValue: string;
  displayFormatType?: FilterDisplayFormatType;
  valueInputRef: React.Ref<HTMLInputElement | HTMLDivElement>;
  field: string;
  filterType: TableFilterType;
  filter_value: string | number | string[] | boolean | null;
  allowedValues: string[] | null;
  selectValues?: SearchFieldSpecSelectValue[];
  onUpdateComparatorRangeFromValue: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onUpdateComparatorRangeToValue: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onUpdateDateRangeValue: (
    startDate: string | null | undefined,
    endDate: string | null | undefined
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateComparatorValue: (event?: any, multiSelectValues?: string[]) => void;
  multiSelectValues: string[];
  onAddMultiSelectValue: (newValue: string) => void;
  onRemoveMultiSelectValue: (value: string) => void;
  disabled?: boolean;
}) => {
  const [filterValue, setFilterValue] = React.useState(props.filter_value);
  const [rangeFromValue, setRangeFromValue] = React.useState(
    props.rangeFromValue
  );
  const [rangeToValue, setRangeToValue] = React.useState(props.rangeToValue);

  useEffect(() => {
    if (!isEqual(props.filter_value, filterValue)) {
      setFilterValue(props.filter_value);
    }
  }, [props.filter_value]);

  const type = Array.isArray(props.filterType)
    ? props.filterType[0]
    : props.filterType;

  const [autocompleteSearchTerm, setAutocompleteSearchTerm] =
    React.useState('');

  const companyIdSearchFields = [
    'person_position_company_id',
    'person_last_company_id',
    'person_experience_company_id'
  ];
  const companyNameSearchFields = [
    'person_position_company_name',
    'person_last_company_name',
    'person_experience_company_name',
    'company_investor_names'
  ];

  if (
    props.comparator == FilterStringComparator.IS_EMPTY ||
    props.comparator == FilterStringComparator.IS_NOT_EMPTY
  ) {
    return <></>;
  }

  if (
    props.field === 'person_name_id' &&
    [
      FilterListComparator.IS_IN_LIST,
      FilterListComparator.IS_NOT_IN_LIST
    ].includes(props.comparator as FilterListComparator)
  ) {
    return (
      <ListMultiselect
        selected={props.multiSelectValues}
        onChange={(newOptions) => {
          props.onUpdateComparatorValue(undefined, newOptions);
        }}
        mode="people_watchlist"
        dataTestid="person-watchlist-multiselect"
      />
    );
  }
  if (
    props.field === 'person_name_id' &&
    [FilterArrayComparator.ANY_OF, FilterArrayComparator.NOT_ANY_OF].includes(
      props.comparator as FilterArrayComparator
    )
  ) {
    return (
      <PersonMultiSelectById
        selected={props.multiSelectValues.map((value) => ({
          id: toNumber(value),
          name: value
        }))}
        onChange={(newOptions) => {
          props.onUpdateComparatorValue(
            undefined,
            compact(newOptions?.map((o) => toString(o.id))) ?? []
          );
        }}
      />
    );
  }
  if (
    [...companyIdSearchFields, ...companyNameSearchFields].includes(
      props.field
    ) &&
    [
      FilterListComparator.IS_IN_LIST,
      FilterListComparator.IS_NOT_IN_LIST
    ].includes(props.comparator as FilterListComparator)
  ) {
    return (
      <div className="min-w-48">
        <ListMultiselect
          selected={props.multiSelectValues}
          onChange={(newOptions) => {
            props.onUpdateComparatorValue(undefined, newOptions);
          }}
          mode="company_watchlist"
          dataTestid="company-watchlist-multiselect"
        />
      </div>
    );
  }

  if (companyIdSearchFields.includes(props.field)) {
    return (
      <CompanyMultiselectById
        selected={props.multiSelectValues.map((value) => ({
          id: toNumber(value),
          name: value
        }))}
        onChange={(newOptions) => {
          props.onUpdateComparatorValue(
            undefined,
            compact(newOptions?.map((o) => toString(o.id))) ?? []
          );
        }}
      />
    );
  }

  if (
    props.field === 'person_education_experience_school' ||
    props.field === 'employee_education_experience_school'
  ) {
    return (
      <SchoolMultiselect
        selected={props.multiSelectValues}
        onChange={(newOptions) => {
          props.onUpdateComparatorValue(undefined, newOptions);
        }}
        entityType={
          props.field === 'person_education_experience_school'
            ? EntityListType.PEOPLE_SAVED_SEARCH
            : EntityListType.COMPANY_SAVED_SEARCH
        }
      />
    );
  }

  if (
    props.field === 'company_customers_connections_last_email_person_email' ||
    props.field === 'company_customers_connections_last_meeting_person_email'
  ) {
    return (
      <IntegratedEmailUserMultiselect
        selected={props.multiSelectValues}
        onChange={(newOptions) => {
          props.onUpdateComparatorValue(undefined, newOptions);
        }}
      />
    );
  }

  if (
    props.field === 'company_investor_names' ||
    props.field === 'person_position_company_investor_names' ||
    props.field === 'person_last_company_investor_names' ||
    props.field === 'person_experience_company_investor_names'
  ) {
    return (
      <div className="w-52 flex-grow">
        <InvestorMultiselectByName
          dataTestId="investor-company-multiselect"
          selected={props.multiSelectValues}
          onChange={(newOptions) => {
            props.onUpdateComparatorValue(undefined, newOptions);
          }}
          // For this field, users can paste in a watchlist ID
          // that will be expanded in the backend.
          freeSolo={props.field === 'company_investor_names'}
        />
      </div>
    );
  }

  if (
    props.field === 'employee_experience_company_name' ||
    props.field === 'person_position_company_name' ||
    props.field === 'person_last_company_name' ||
    props.field === 'person_experience_company_name'
  ) {
    return (
      <div className="w-52 flex-grow">
        <CompanyMultiselectByName
          dataTestId="company-multiselect"
          selected={props.multiSelectValues}
          onChange={(newOptions) => {
            props.onUpdateComparatorValue(undefined, newOptions);
          }}
          // For this field, users can paste in a watchlist ID
          // that will be expanded in the backend.
          freeSolo={props.field === 'employee_experience_company_name'}
        />
      </div>
    );
  }

  if (type === TableFilterType.BOOLEAN) {
    const booleanOptions = [
      {
        label: 'True',
        value: true
      },
      {
        label: 'False',
        value: false
      }
    ];
    return (
      <div className="w-52 flex-grow">
        <Select
          selected={toString(props.filter_value)}
          multiple={false}
          getLabelFromValue={(value) => capitalize(value)}
          dropdownMaxHeight="400px"
        >
          {booleanOptions.map((option) => (
            <ListItem
              key={option.label}
              value={toString(option.value)}
              label={option.label}
              onClick={() => {
                props.onUpdateComparatorValue({
                  target: {
                    value: option.value
                  }
                } as unknown as ChangeEvent<HTMLInputElement>);
              }}
              variant={ListVariant.default}
            />
          ))}
        </Select>
      </div>
    );
  }

  if (
    props.field === 'company_custom_field_person' ||
    props.field === 'person_custom_field_person' ||
    props.field === 'person_entry_added_by' ||
    props.field === 'company_entry_added_by'
  ) {
    return (
      <div className="w-52 flex-grow">
        <UserMultiSelectByName
          selected={props.multiSelectValues}
          onChange={(newOptions) => {
            props.onUpdateComparatorValue(undefined, newOptions);
          }}
          freeSolo={false}
        />
      </div>
    );
  }
  if (
    props.field === 'company_custom_field_single_select' ||
    props.field === 'company_custom_field_multi_select' ||
    props.field === 'person_custom_field_single_select' ||
    props.field === 'person_custom_field_multi_select' ||
    props.field === 'company_custom_field_status' ||
    props.field === 'person_custom_field_status'
  ) {
    const allowedValues = props.selectValues ?? [];
    return (
      <div className="w-52 flex-grow">
        <Select
          multiple
          selected={props.multiSelectValues}
          filterable
          filterTerm={autocompleteSearchTerm}
          onFilterTermChange={setAutocompleteSearchTerm}
          freeSolo={!allowedValues?.length}
          hideChevronDown={!allowedValues?.length}
          hideDropdown={!allowedValues?.length}
          placeholder={
            props.allowedValues?.length == 0
              ? AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER
              : ''
          }
          clampValues={true}
          onAdd={(keyword) => {
            if (!props.multiSelectValues.includes(keyword)) {
              props.onAddMultiSelectValue(keyword);
            }
            setAutocompleteSearchTerm('');
          }}
          onRemove={(keyword) => {
            props.onRemoveMultiSelectValue(keyword);
            setAutocompleteSearchTerm('');
          }}
          getTagColorFromValue={(value) => {
            const color = (allowedValues.find((v) => v.value === value)
              ?.color ?? '') as ColorShorthand;
            return color;
          }}
          getLabelFromValue={(value) => {
            return allowedValues.find((v) => v.value === value)?.label ?? '';
          }}
          dropdownMaxHeight="400px"
        >
          {allowedValues
            .filter((allowedValue) =>
              allowedValue.label
                .toLocaleLowerCase()
                .includes(autocompleteSearchTerm)
            )
            .map((value) => (
              <ListItem
                key={value.value}
                value={value.value}
                label={value.label}
                selected={props.multiSelectValues.includes(value.value)}
                onClick={(e) => {
                  if (!props.multiSelectValues.includes(value.value)) {
                    props.onAddMultiSelectValue(value.value);
                  } else {
                    props.onRemoveMultiSelectValue(value.value);
                  }
                  setAutocompleteSearchTerm('');
                }}
                color={value.color as ColorShorthand}
                variant={ListVariant.tag}
              />
            ))}
        </Select>
      </div>
    );
  }

  if (type === TableFilterType.ARRAY) {
    const allowedValues =
      props.allowedValues
        ?.filter((value) => value)
        .map((value) => ({
          label: get(SearchValueToDisplayNameMap, value, value),
          value: value
        })) || [];

    return (
      <div className="w-52 flex-grow">
        <Select
          multiple
          selected={props.multiSelectValues}
          filterable
          filterTerm={autocompleteSearchTerm}
          onFilterTermChange={setAutocompleteSearchTerm}
          freeSolo={!allowedValues?.length}
          hideChevronDown={!allowedValues?.length}
          hideDropdown={!allowedValues?.length}
          placeholder={
            props.allowedValues?.length == 0
              ? AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER
              : ''
          }
          clampValues
          onAdd={(keyword) => {
            if (!props.multiSelectValues.includes(keyword)) {
              props.onAddMultiSelectValue(keyword);
            }
            setAutocompleteSearchTerm('');
          }}
          onRemove={(keyword) => {
            props.onRemoveMultiSelectValue(keyword);
            setAutocompleteSearchTerm('');
          }}
          getLabelFromValue={(value) => {
            return value
              ? get(SearchValueToDisplayNameMap, value, value)
              : value ?? '';
          }}
          dropdownMaxHeight="300px"
          dataTestId="array-filter"
        >
          {allowedValues
            .filter((allowedValue) =>
              allowedValue.label
                .toLocaleLowerCase()
                .includes(autocompleteSearchTerm)
            )
            .map((value) => (
              <ListItem
                key={value.value}
                value={value.value}
                label={value.label}
                selected={props.multiSelectValues.includes(value.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    props.onAddMultiSelectValue(value.value);
                  } else {
                    props.onRemoveMultiSelectValue(value.value);
                  }
                  setAutocompleteSearchTerm('');
                }}
                variant={ListVariant.checkboxes}
              />
            ))}
        </Select>
      </div>
    );
  }

  if (
    props.comparator === FilterNumberComparator.IN_RANGE ||
    props.comparator === FilterDateComparator.IN_DAYS_AGO_RANGE ||
    props.comparator === FilterDateComparator.NOT_IN_DAYS_AGO_RANGE
  ) {
    const rangeType = type === TableFilterType.DATE ? 'date' : 'string';
    const showDaysAgoLabel =
      props.comparator === FilterDateComparator.IN_DAYS_AGO_RANGE ||
      props.comparator === FilterDateComparator.NOT_IN_DAYS_AGO_RANGE;

    if (rangeType === 'date') {
      const formattedRangeFromValue =
        rangeFromValue !== 'null' ? rangeFromValue : null;
      const formattedRangeToValue =
        rangeToValue !== 'null' ? rangeToValue : null;
      return (
        <DatePicker
          type="range"
          startDate={formattedRangeFromValue}
          endDate={formattedRangeToValue}
          onChange={(startDate, endDate) => {
            props.onUpdateDateRangeValue(startDate, endDate);
            setRangeFromValue(startDate as string);
            setRangeToValue(endDate as string);
          }}
        />
      );
    }

    const formattedRangeFromValue = getFormattedFilterValueBasedOnDisplayType(
      rangeFromValue,
      props.displayFormatType
    );
    const formattedRangeToValue = getFormattedFilterValueBasedOnDisplayType(
      rangeToValue,
      props.displayFormatType
    );

    return (
      <div className="value-range-input-container flex-row flex-grow gap-1.5">
        <div className="w-40">
          <TextField
            dataTestId="SingleFilter-FieldInput-TextField"
            key={`${type}-from`}
            value={formattedRangeFromValue}
            suffix={showDaysAgoLabel ? 'days ago' : ''}
            type={rangeType}
            placeholder="From"
            size="small"
            className="value-input"
            register={props.valueInputRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value;
              if (e.target.type === 'text') {
                value = returnNumberFromString(value);
              }
              setRangeFromValue(value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.onUpdateComparatorRangeFromValue(
                  e as unknown as React.ChangeEvent<HTMLInputElement>
                );
              }
            }}
            onBlur={props.onUpdateComparatorRangeFromValue}
            disabled={props.disabled}
          ></TextField>
        </div>
        <span className="self-center">-</span>
        <div className="w-40">
          <TextField
            disabled={props.disabled}
            dataTestId="SingleFilter-FieldInput-TextField"
            key={`${type}-to`}
            value={formattedRangeToValue}
            type={rangeType}
            placeholder="To"
            suffix={showDaysAgoLabel ? 'days ago' : ''}
            size="small"
            className="value-input"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value;
              if (e.target.type === 'text') {
                value = returnNumberFromString(value);
              }
              setRangeToValue(value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.onUpdateComparatorRangeToValue(
                  e as unknown as ChangeEvent<HTMLInputElement>
                );
              }
            }}
            onBlur={props.onUpdateComparatorRangeToValue}
          ></TextField>
        </div>
      </div>
    );
  }

  if (type === TableFilterType.INTEGER) {
    const formattedFilterValue = getFormattedFilterValueBasedOnDisplayType(
      filterValue,
      props.displayFormatType
    );

    return (
      <div className="w-48 flex-grow">
        <TextField
          disabled={props.disabled}
          dataTestId="SingleFilter-FieldInput-TextField"
          key={`${type}-value`}
          value={formattedFilterValue}
          type={type === TableFilterType.DATE ? 'date' : 'text'}
          size="small"
          suffix={
            props.displayFormatType === FilterDisplayFormatType.PERCENTAGE
              ? '%'
              : ''
          }
          register={props.valueInputRef}
          className="value-input"
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              if (props.field?.includes('_percent_')) {
                let newValue = toString(filterValue || '');
                newValue = newValue.substring(0, newValue.length - 1);
                setFilterValue(newValue);
              }
            }
            if (e.key === 'Enter') {
              props.onUpdateComparatorValue(e);
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = (e.target?.value).replace(/[^-?\d]/g, '');
            setFilterValue(value);
          }}
          onBlur={props.onUpdateComparatorValue}
        />
      </div>
    );
  }

  if (type === TableFilterType.DATE) {
    return (
      <div className="w-48 flex-grow">
        <DatePicker
          type="single"
          selectedDate={props.filter_value as string}
          onChange={(value) => {
            props.onUpdateComparatorValue({
              target: {
                value
              }
            } as unknown as ChangeEvent<HTMLInputElement>);
          }}
        />
      </div>
    );
  }

  return (
    <div className="w-48 flex-grow">
      <TextField
        disabled={props.disabled}
        dataTestId="SingleFilter-FieldInput-TextField"
        key={`${type}-value`}
        defaultValue={(props.filter_value as string) || ''}
        type={type === TableFilterType.DATE ? 'date' : 'text'}
        size="small"
        className="value-input"
        register={props.valueInputRef}
        onBlur={props.onUpdateComparatorValue}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.onUpdateComparatorValue(e);
          }
        }}
      />
    </div>
  );
};

export default ValueInput;
