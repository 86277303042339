const KanbanCardLoader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="100"
      viewBox="0 0 256 100"
      fill="none"
    >
      <g>
        <path
          vectorEffect="non-scaling-stroke"
          mask="url(#shimmer-gradient)"
          d="M0 6.4C0 4.15979 0 3.03968 0.435974 2.18404C0.819467 1.43139 1.43139 0.819468 2.18404 0.435974C3.03968 1.78814e-07 4.15979 0 6.4 0H62.6C64.8402 0 65.9603 1.78814e-07 66.816 0.435974C67.5686 0.819468 68.1805 1.43139 68.564 2.18404C69 3.03968 69 4.15979 69 6.4V13.6C69 15.8402 69 16.9603 68.564 17.816C68.1805 18.5686 67.5686 19.1805 66.816 19.564C65.9603 20 64.8402 20 62.6 20H6.4C4.15979 20 3.03968 20 2.18404 19.564C1.43139 19.1805 0.819467 18.5686 0.435974 17.816C0 16.9603 0 15.8402 0 13.6V6.4Z"
          fill="black"
          fillOpacity="0.02"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 6.4C0 4.15979 0 3.03968 0.435974 2.18404C0.819467 1.43139 1.43139 0.819468 2.18404 0.435974C3.03968 1.78814e-07 4.15979 0 6.4 0H62.6C64.8402 0 65.9603 1.78814e-07 66.816 0.435974C67.5686 0.819468 68.1805 1.43139 68.564 2.18404C69 3.03968 69 4.15979 69 6.4V13.6C69 15.8402 69 16.9603 68.564 17.816C68.1805 18.5686 67.5686 19.1805 66.816 19.564C65.9603 20 64.8402 20 62.6 20H6.4C4.15979 20 3.03968 20 2.18404 19.564C1.43139 19.1805 0.819467 18.5686 0.435974 17.816C0 16.9603 0 15.8402 0 13.6V6.4Z"
          fill="url(#paint0_linear_2397_301367)"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 30.4C0 28.1598 0 27.0397 0.435974 26.184C0.819467 25.4314 1.43139 24.8195 2.18404 24.436C3.03968 24 4.15979 24 6.4 24H249.6C251.84 24 252.96 24 253.816 24.436C254.569 24.8195 255.181 25.4314 255.564 26.184C256 27.0397 256 28.1598 256 30.4V69.6C256 71.8402 256 72.9603 255.564 73.816C255.181 74.5686 254.569 75.1805 253.816 75.564C252.96 76 251.84 76 249.6 76H6.39999C4.15979 76 3.03968 76 2.18404 75.564C1.43139 75.1805 0.819467 74.5686 0.435974 73.816C0 72.9603 0 71.8402 0 69.6V30.4Z"
          fill="black"
          fillOpacity="0.02"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 30.4C0 28.1598 0 27.0397 0.435974 26.184C0.819467 25.4314 1.43139 24.8195 2.18404 24.436C3.03968 24 4.15979 24 6.4 24H249.6C251.84 24 252.96 24 253.816 24.436C254.569 24.8195 255.181 25.4314 255.564 26.184C256 27.0397 256 28.1598 256 30.4V69.6C256 71.8402 256 72.9603 255.564 73.816C255.181 74.5686 254.569 75.1805 253.816 75.564C252.96 76 251.84 76 249.6 76H6.39999C4.15979 76 3.03968 76 2.18404 75.564C1.43139 75.1805 0.819467 74.5686 0.435974 73.816C0 72.9603 0 71.8402 0 69.6V30.4Z"
          fill="url(#paint1_linear_2397_301367)"
        />

        <path
          vectorEffect="non-scaling-stroke"
          d="M0 86.4C0 84.1598 0 83.0397 0.435974 82.184C0.819467 81.4314 1.43139 80.8195 2.18404 80.436C3.03968 80 4.15979 80 6.4 80H167.6C169.84 80 170.96 80 171.816 80.436C172.569 80.8195 173.181 81.4314 173.564 82.184C174 83.0397 174 84.1598 174 86.4V93.6C174 95.8402 174 96.9603 173.564 97.816C173.181 98.5686 172.569 99.1805 171.816 99.564C170.96 100 169.84 100 167.6 100H6.39999C4.15979 100 3.03968 100 2.18404 99.564C1.43139 99.1805 0.819467 98.5686 0.435974 97.816C0 96.9603 0 95.8402 0 93.6V86.4Z"
          fill="black"
          fillOpacity="0.02"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 86.4C0 84.1598 0 83.0397 0.435974 82.184C0.819467 81.4314 1.43139 80.8195 2.18404 80.436C3.03968 80 4.15979 80 6.4 80H167.6C169.84 80 170.96 80 171.816 80.436C172.569 80.8195 173.181 81.4314 173.564 82.184C174 83.0397 174 84.1598 174 86.4V93.6C174 95.8402 174 96.9603 173.564 97.816C173.181 98.5686 172.569 99.1805 171.816 99.564C170.96 100 169.84 100 167.6 100H6.39999C4.15979 100 3.03968 100 2.18404 99.564C1.43139 99.1805 0.819467 98.5686 0.435974 97.816C0 96.9603 0 95.8402 0 93.6V86.4Z"
          fill="url(#paint2_linear_2397_301367)"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 6.4C0 4.15979 0 3.03968 0.435974 2.18404C0.819467 1.43139 1.43139 0.819468 2.18404 0.435974C3.03968 1.78814e-07 4.15979 0 6.4 0H62.6C64.8402 0 65.9603 1.78814e-07 66.816 0.435974C67.5686 0.819468 68.1805 1.43139 68.564 2.18404C69 3.03968 69 4.15979 69 6.4V13.6C69 15.8402 69 16.9603 68.564 17.816C68.1805 18.5686 67.5686 19.1805 66.816 19.564C65.9603 20 64.8402 20 62.6 20H6.4C4.15979 20 3.03968 20 2.18404 19.564C1.43139 19.1805 0.819467 18.5686 0.435974 17.816C0 16.9603 0 15.8402 0 13.6V6.4Z"
          fill="black"
          fillOpacity="0.02"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 30.4C0 28.1598 0 27.0397 0.435974 26.184C0.819467 25.4314 1.43139 24.8195 2.18404 24.436C3.03968 24 4.15979 24 6.4 24H249.6C251.84 24 252.96 24 253.816 24.436C254.569 24.8195 255.181 25.4314 255.564 26.184C256 27.0397 256 28.1598 256 30.4V69.6C256 71.8402 256 72.9603 255.564 73.816C255.181 74.5686 254.569 75.1805 253.816 75.564C252.96 76 251.84 76 249.6 76H6.39999C4.15979 76 3.03968 76 2.18404 75.564C1.43139 75.1805 0.819467 74.5686 0.435974 73.816C0 72.9603 0 71.8402 0 69.6V30.4Z"
          fill="black"
          fillOpacity="0.02"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0 86.4C0 84.1598 0 83.0397 0.435974 82.184C0.819467 81.4314 1.43139 80.8195 2.18404 80.436C3.03968 80 4.15979 80 6.4 80H167.6C169.84 80 170.96 80 171.816 80.436C172.569 80.8195 173.181 81.4314 173.564 82.184C174 83.0397 174 84.1598 174 86.4V93.6C174 95.8402 174 96.9603 173.564 97.816C173.181 98.5686 172.569 99.1805 171.816 99.564C170.96 100 169.84 100 167.6 100H6.39999C4.15979 100 3.03968 100 2.18404 99.564C1.43139 99.1805 0.819467 98.5686 0.435974 97.816C0 96.9603 0 95.8402 0 93.6V86.4Z"
          fill="black"
          fillOpacity="0.02"
        />
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_2397_301367"
          x1="256"
          y1="49.9996"
          x2="0"
          y2="49.9996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="0.56" stopOpacity="0.06" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2397_301367"
          x1="256"
          y1="49.9996"
          x2="0"
          y2="49.9996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="0.56" stopOpacity="0.06" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2397_301367"
          x1="256"
          y1="49.9996"
          x2="0"
          y2="49.9996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="0.56" stopOpacity="0.06" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="shimmer-gradient" gradientTransform="rotate(120)">
          <stop offset="0%" stopColor="black" stopOpacity="0" />
          <stop offset="50%" stopColor="black" stopOpacity="0.08" />
          <stop offset="100%" stopColor="black" stopOpacity="0" />
        </linearGradient>

        <mask id="shimmer-mask" x="0" y="0" width="100%" height="100%">
          <rect width="100%" height="100%" fill="white" />
        </mask>
      </defs>
    </svg>
  );
};

export default KanbanCardLoader;
