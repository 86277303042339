import { StarFilledIcon, StarOutlineIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useFavoriteItem } from 'hooks/useFavoriteItem';
import { EntityListType } from 'interfaces/SearchModel/Search';

interface FavouritesProps {
  isFavourited: boolean;
  id: string;
  type: EntityListType;
}

const Favorites = ({ isFavourited, id, type }: FavouritesProps) => {
  const onUpdateFavoriteItem = useFavoriteItem(type, id, isFavourited);
  return (
    <div
      className={classNames(
        'flex-row',
        isFavourited ? 'flex' : 'group-hover:flex hidden'
      )}
    >
      <IconButton
        icon={isFavourited ? StarFilledIcon : StarOutlineIcon}
        onClick={(e) => {
          e.preventDefault();
          onUpdateFavoriteItem();
        }}
        emphasis="low"
        size="tiny"
        stopPropagation
      />
    </div>
  );
};

export default Favorites;
