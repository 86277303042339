import { FC } from 'react';

const CloseIcon: FC = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M16.192 6.344l-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414z"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  );
};

export default CloseIcon;
