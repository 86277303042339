import classnames from 'classnames';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import React from 'react';

interface ChipSelectOption {
  label: string;
  value?: string;
}
export interface ChipSelectProps {
  selectedValue?: string;
  onValueChange: (newValue?: string) => void;
  options: ChipSelectOption[];
  disabled?: boolean;
}
const ChipSelect: React.FC<ChipSelectProps> = ({
  selectedValue,
  onValueChange,
  options,
  disabled
}) => {
  return (
    <div
      className={classnames('flex gap-2 flex-wrap', {
        'opacity-50': disabled
      })}
    >
      {options.map((option) => (
        <RadioPill
          key={option.value}
          label={option.label}
          selected={selectedValue === option.value}
          onClick={() => onValueChange(option.value)}
        />
      ))}
    </div>
  );
};

export default ChipSelect;
