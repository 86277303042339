import {
  DateListCustomFieldValueFormat,
  ListCustomFieldType,
  NumberListCustomFieldValueFormat
} from '__generated__/graphql';
import {
  DollarIcon,
  HashtagIcon,
  PercentIcon,
  PersonCircleIcon,
  StatusIcon
} from 'assets/harmonic-icons';
import { ColorShorthand } from 'common/utils/design';
import {
  CalendarIcon,
  CheckboxIcon,
  MultiSelectIcon,
  NumberIcon,
  SelectIcon,
  TextIcon,
  URLIcon
} from '../../../../assets/harmonic-icons';
import { HarmonicIcon } from '../../../../assets/harmonic-icons/type';

export interface SelectOptionValue {
  name: string;
  color: ColorShorthand;
  urn?: string;
  default?: boolean;
}
export const EditFieldTypeOptions = [
  {
    label: 'Text',
    value: ListCustomFieldType.TEXT,
    icon: TextIcon
  },
  {
    label: 'Number',
    value: ListCustomFieldType.NUMBER,
    icon: NumberIcon
  },
  {
    label: 'Person',
    value: ListCustomFieldType.PERSON,
    icon: PersonCircleIcon
  },
  {
    label: 'Date',
    value: ListCustomFieldType.DATE,
    icon: CalendarIcon
  },
  {
    label: 'Status',
    value: ListCustomFieldType.STATUS,
    icon: StatusIcon
  },
  {
    label: 'Single select',
    value: ListCustomFieldType.SINGLE_SELECT,
    icon: SelectIcon
  },
  {
    label: 'Multi select',
    value: ListCustomFieldType.MULTI_SELECT,
    icon: MultiSelectIcon
  },
  {
    label: 'URL',
    value: ListCustomFieldType.WEBSITE,
    icon: URLIcon
  },
  {
    label: 'Checkbox',
    value: ListCustomFieldType.CHECKBOX,
    icon: CheckboxIcon
  }
];

export const EditFieldTypeOptionLabel: Record<ListCustomFieldType, string> =
  EditFieldTypeOptions.reduce(
    (acc, option) => ({ ...acc, [option.value]: option.label }),
    {} as Record<ListCustomFieldType, string>
  );

export const EditFieldTypeOptionIcon: Record<
  ListCustomFieldType,
  HarmonicIcon
> = EditFieldTypeOptions.reduce(
  (acc, option) => ({ ...acc, [option.value]: option.icon }),
  {} as Record<ListCustomFieldType, React.FC>
);

export enum PersonType {
  CURRENT_USER = 'current user',
  EMPTY = 'empty'
}

export const PersonOptions = [
  {
    value: PersonType.CURRENT_USER,
    label: 'Current user'
  },
  {
    value: PersonType.EMPTY,
    label: 'Empty'
  }
];

export const PersonOptionLabel: Record<PersonType, string> =
  PersonOptions.reduce(
    (acc, option) => ({ ...acc, [option.value]: option.label }),
    {} as Record<PersonType, string>
  );

export const DateOptions = [
  {
    value: DateListCustomFieldValueFormat.MM_DD_YYYY,
    label: 'MM/DD/YYYY'
  },
  {
    value: DateListCustomFieldValueFormat.DD_MM_YYYY,
    label: 'DD/MM/YYYY'
  },
  {
    value: DateListCustomFieldValueFormat.YYYY_MM_DD,
    label: 'YYYY/MM/DD'
  }
];

export const DateOptionLabel: Record<DateListCustomFieldValueFormat, string> =
  DateOptions.reduce(
    (acc, option) => ({ ...acc, [option.value]: option.label }),
    {} as Record<DateListCustomFieldValueFormat, string>
  );

export const FormatOptions = [
  {
    value: NumberListCustomFieldValueFormat.NUMBER,
    label: 'Number',
    icon: HashtagIcon
  },
  {
    value: NumberListCustomFieldValueFormat.PERCENT,
    label: 'Percent',
    icon: PercentIcon
  },
  {
    value: NumberListCustomFieldValueFormat.US_DOLLAR,
    label: 'US dollar',
    icon: DollarIcon
  }
];

export const FormatOptionLabel: Record<
  NumberListCustomFieldValueFormat,
  string
> = FormatOptions.reduce(
  (acc, option) => ({ ...acc, [option.value]: option.label }),
  {} as Record<NumberListCustomFieldValueFormat, string>
);
