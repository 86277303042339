import classNames from 'classnames';

interface ThumbComponentProps {
  className: string;
  disabled?: boolean;
  selected?: boolean;
}
const ThumbComponent: React.FC<ThumbComponentProps> = (props) => {
  return (
    <div
      {...props}
      aria-disabled={props.disabled}
      aria-selected={props.selected}
      className={classNames(
        props.className,
        'w-[10px] h-[24px] border border-solid border-slider-border-enabled rounded-br20',
        'p-p30 flex gap-g10 bg-surface-default shadow-none hover:border-slider-border-hover focus:border-slider-border-active',
        'aria-disabled:border-slider-border-disabled',
        'aria-selected:border-slider-border-selected-enabled aria-selected:hover:border-slider-border-selected-hover',
        'aria-selected:focus:border-slider-border-selected-active',
        'aria-selected:aria-disabled:border-slider-border-selected-disabled'
      )}
    >
      {[...Array(2)].map((_, index) => (
        <div
          key={index}
          aria-disabled={props.disabled}
          aria-selected={props.selected}
          className={classNames(
            'p-[0.5px] h-[12px] inline-block bg-content-weak rounded-br10',
            'aria-disabled:bg-content-muted',
            'aria-selected:bg-content-default'
          )}
        />
      ))}
    </div>
  );
};

export default ThumbComponent;
