import { SPLITS } from 'utils/constants';
import {
  CalendarIcon,
  CompanyIcon,
  EducationIcon,
  LocationIcon,
  NumberIcon,
  PersonCircleIcon,
  SelectIcon,
  TagIcon,
  TextIcon,
  URLIcon
} from '../assets/harmonic-icons';
import { ColumnFieldType, ColumnsConfig } from './ColumnsConfig';

const PersonColumnsConfig: ColumnsConfig = {
  ID: {
    urn: 'urn:harmonic:person_field:person_id',
    label: 'ID',
    value: 'id',
    fieldType: ColumnFieldType.HARMONIC,
    icon: NumberIcon
  },
  ADDED_TO_LIST_DATE: {
    urn: 'urn:harmonic:person_field:person_added_to_list_date',
    label: 'Date added',
    value: 'entryCreatedAt',
    fieldType: ColumnFieldType.HARMONIC,
    icon: CalendarIcon
  },
  ADDED_TO_LIST_BY: {
    urn: 'urn:harmonic:person_field:person_entry_added_by',
    label: 'Added by',
    value: 'addedBy',
    fieldType: ColumnFieldType.HARMONIC,
    icon: PersonCircleIcon,
    splitFlagToEnable: SPLITS.enableAddedByColumn
  },
  FULL_NAME: {
    urn: 'urn:harmonic:person_field:person_name',
    label: 'Full name',
    value: 'fullName',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TextIcon
  },
  FIRST_NAME: {
    urn: 'urn:harmonic:person_field:person_first_name',
    label: 'First name',
    value: 'firstName',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TextIcon
  },
  LAST_NAME: {
    urn: 'urn:harmonic:person_field:person_last_name',
    label: 'Last name',
    value: 'lastName',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TextIcon
  },
  LISTS: {
    urn: 'urn:harmonic:person_field:person_lists',
    label: 'Lists',
    value: 'watchlists',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TagIcon
  },
  CURRENT_COMPANY: {
    urn: 'urn:harmonic:person_field:person_position_company_id',
    label: 'Current company',
    value: 'experience.currentCompany',
    fieldType: ColumnFieldType.HARMONIC,
    icon: CompanyIcon
  },
  PERSON_HIGHLIGHTS: {
    urn: 'urn:harmonic:person_field:person_highlights_category_list',
    label: 'Person highlights',
    value: 'highlights',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TagIcon
  },
  CURRENT_TITLE: {
    urn: 'urn:harmonic:person_field:person_position_title',
    label: 'Current title',
    value: 'experience.currentTitle',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TagIcon
  },
  CURRENT_DEPARTMENT: {
    urn: 'urn:harmonic:person_field:person_position_department',
    label: 'Current department',
    value: 'experience.currentDepartment',
    fieldType: ColumnFieldType.HARMONIC,
    icon: SelectIcon
  },
  CURRENT_LOCATION: {
    urn: 'urn:harmonic:person_field:person_location',
    label: 'Current location',
    value: 'location.location',
    fieldType: ColumnFieldType.HARMONIC,
    icon: LocationIcon
  },
  LINKEDIN_HEADLINE: {
    urn: 'urn:harmonic:person_field:person_linkedin_headline',
    label: 'LinkedIn headline',
    value: 'linkedinHeadline',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TextIcon
  },
  CURRENT_COMPANY_TAGS: {
    urn: 'urn:harmonic:person_field:person_position_company_tags',
    label: 'Current company tags',
    value: 'experience.currentCompanyTags',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TagIcon
  },
  PREVIOUS_COMPANY: {
    urn: 'urn:harmonic:person_field:person_last_company_id',
    label: 'Previous company',
    value: 'experience.previousCompany',
    fieldType: ColumnFieldType.HARMONIC,
    icon: CompanyIcon
  },
  PREVIOUS_COMPANY_TAGS: {
    urn: 'urn:harmonic:person_field:person_last_company_tags',
    label: 'Previous company tags',
    value: 'experience.previousCompanyTags',
    fieldType: ColumnFieldType.HARMONIC,
    icon: TagIcon
  },
  EDUCATION: {
    urn: 'urn:harmonic:person_field:person_education_experience_school',
    label: 'Education',
    value: 'education',
    fieldType: ColumnFieldType.HARMONIC,
    icon: EducationIcon
  },
  PREVIOUS_COMPANIES: {
    urn: 'urn:harmonic:person_field:person_experience_company_id',
    label: 'Previous companies',
    value: 'experience.previousCompanies',
    fieldType: ColumnFieldType.HARMONIC,
    icon: CompanyIcon
  },
  LINKEDIN: {
    urn: 'urn:harmonic:person_field:person_linkedin_url',
    label: 'LinkedIn',
    value: 'socials.linkedin.url',
    fieldType: ColumnFieldType.HARMONIC,
    icon: URLIcon
  },
  EMAILS: {
    urn: 'urn:harmonic:person_field:person_emails',
    label: 'Emails',
    value: 'contact.emails',
    fieldType: ColumnFieldType.HARMONIC,
    icon: URLIcon
  },
  TEAM_CONNECTIONS: {
    urn: 'urn:harmonic:person_field:person_customers_connections',
    label: 'Team network',
    value: 'userConnections',
    fieldType: ColumnFieldType.SMART,
    icon: PersonCircleIcon
  },
  LAST_MEETING_DATE: {
    urn: 'urn:harmonic:person_field:person_customers_connections_last_meeting_at',
    label: 'Last meeting date',
    value: 'correspondenceSummary.lastMeetingAt',
    fieldType: ColumnFieldType.SMART,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
    icon: CalendarIcon
  },
  LAST_MEETING_WITH: {
    urn: 'urn:harmonic:person_field:person_customers_connections_last_meeting_person_email',
    label: 'Last meeting with',
    value: 'correspondenceSummary.lastMeetingContactPersonEmail',
    fieldType: ColumnFieldType.SMART,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
    icon: PersonCircleIcon
  },
  LAST_EMAIL_AT: {
    urn: 'urn:harmonic:person_field:person_customers_connections_last_email_at',
    label: 'Last email date',
    value: 'correspondenceSummary.lastEmailAt',
    fieldType: ColumnFieldType.SMART,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
    icon: CalendarIcon
  },
  LAST_CONTACTED_BY: {
    urn: 'urn:harmonic:person_field:person_customers_connections_last_email_person_email',
    label: 'Last email with',
    value: 'correspondenceSummary.lastEmailContactPersonEmail',
    fieldType: ColumnFieldType.SMART,
    splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
    icon: PersonCircleIcon
  }
};

export const PersonColumnsValueToFieldTypeMap = Object.fromEntries(
  Object.entries(PersonColumnsConfig).map(([key, value]) => [
    value.value,
    value.fieldType || null
  ])
);

export const PersonColumnsValueToIconMap = Object.fromEntries(
  Object.entries(PersonColumnsConfig).map(([key, value]) => [
    value.value,
    value.icon || null
  ])
);

export const PersonColumnsValueToObjectMap = Object.fromEntries(
  Object.entries(PersonColumnsConfig).map(([key, value]) => [
    value.value,
    PersonColumnsConfig[key]
  ])
);

export const PersonColumnsUrnToValueMap = Object.fromEntries(
  Object.entries(PersonColumnsConfig).map(([key, value]) => [
    value.urn,
    PersonColumnsConfig[key]
  ])
);

export const PersonColumnsValueToUrnMap = Object.fromEntries(
  Object.entries(PersonColumnsConfig).map(([key, value]) => [
    value.value,
    value.urn
  ])
);

export default PersonColumnsConfig;
