import { ICellRendererParams } from 'ag-grid-community';
import { LockedIcon } from 'assets/harmonic-icons';
import GroupPeople from 'assets/harmonic-icons/group-people';
import { Badge } from 'common/components';
import { LoadingCellRenderer } from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import { LOADING_ROW_ID } from 'utils/constants';
import { VisibilityEnum } from '../types';

export const VisibilityCellComponent = (visibility: ICellRendererParams) => {
  if (visibility.data.id == LOADING_ROW_ID) {
    return <LoadingCellRenderer />;
  }

  return visibility.value == VisibilityEnum.private ? (
    <Badge label="Private" color="expressive-12" leadingIcon={LockedIcon} />
  ) : (
    <Badge label="Shared" color="expressive-23" leadingIcon={GroupPeople} />
  );
};
