import { useFieldSpec } from 'hooks/useFieldSpec';
import { EntityListType } from 'interfaces/SearchModel/Search';
import SearchFilterDropdown from './SearchFilterDropdown';
import SearchFilterGroup, {
  SearchFilterGroupBaseProps
} from './SearchFilterGroupBase';

type CompanySearchFilterGroupProps = Omit<
  SearchFilterGroupBaseProps,
  'FilterDropdownComponent' | 'entityType' | 'fieldSpecs'
>;

const CompanySearchFilterGroup: React.FC<CompanySearchFilterGroupProps> = (
  props
) => {
  const { fieldSpec } = useFieldSpec();
  const fieldSpecs = fieldSpec?.filter(
    (fieldSpec) =>
      fieldSpec.allowed_comparators && fieldSpec.allowed_comparators.length > 0
  );
  return (
    <SearchFilterGroup
      {...props}
      fieldSpecs={fieldSpecs}
      entityType={EntityListType.COMPANY_SAVED_SEARCH}
      FilterDropdownComponent={({ onAddNewFilter }) => {
        return (
          <SearchFilterDropdown
            fields={fieldSpecs}
            entityType={EntityListType.COMPANY_SAVED_SEARCH}
            onUpdateComparatorName={(name, urn) =>
              onAddNewFilter(name as string, urn as string)
            }
            selectedField="company_name"
          />
        );
      }}
    />
  );
};

export default CompanySearchFilterGroup;
