import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const AISectorIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5925 2.18018C11.1739 2.06276 10.7327 2 10.2775 2C8.17844 2 6.39162 3.32976 5.71071 5.19199C3.77623 5.63284 2.33301 7.36273 2.33301 9.43056C2.33301 10.3916 2.64546 11.2804 3.17337 12C2.64546 12.7196 2.33301 13.6084 2.33301 14.5694C2.33301 16.2597 3.29745 17.7231 4.70374 18.4422C5.4487 20.516 7.43195 22 9.76356 22C10.412 22 11.0344 21.8849 11.6108 21.674C11.5927 21.6095 11.583 21.5414 11.583 21.4712L11.583 17.0025L11.583 17C11.583 15.6193 10.4637 14.5 9.08301 14.5C8.66879 14.5 8.33301 14.1642 8.33301 13.75C8.33301 13.3358 8.66879 13 9.08301 13C10.029 13 10.8982 13.3284 11.583 13.8773L11.583 6.99999L11.583 6.99799L11.583 2.29999C11.583 2.25921 11.5863 2.21919 11.5925 2.18018Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M13.0553 21.674C13.6316 21.8849 14.254 22 14.9025 22C17.2341 22 19.2173 20.516 19.9623 18.4422C21.3686 17.7231 22.333 16.2597 22.333 14.5694C22.333 13.6084 22.0206 12.7196 21.4927 12C22.0206 11.2804 22.333 10.3916 22.333 9.43056C22.333 7.36273 20.8898 5.63284 18.9553 5.19199C18.2744 3.32976 16.4876 2 14.3886 2C13.9333 2 13.4921 2.06276 13.0735 2.18018C13.0798 2.21919 13.083 2.25921 13.083 2.29999V7.00364C13.085 8.38268 14.2035 9.49999 15.583 9.49999C15.9972 9.49999 16.333 9.83578 16.333 10.25C16.333 10.6642 15.9972 11 15.583 11C14.6371 11 13.7678 10.6716 13.083 10.1227L13.083 21.4712C13.083 21.5414 13.0733 21.6095 13.0553 21.674Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default AISectorIcon;
