import InfoCircle from 'assets/harmonic-icons/info-circle';
import { Tooltip } from 'common';
import {
  SmallSectionHeader,
  SubSectionHeader
} from 'components/QueryBuilder/Common/Typography';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { FilterGroupGeneratorId, SearchData } from 'interfaces/SearchV2';
import SectorKeywords from '../SectorKeywords';
import SemanticInput from './SemanticInput';

interface TextSearchSectionProps {
  andKeywords: string[] | undefined;
  orKeywords: string[] | undefined;
  notKeywords: string[] | undefined;
  semanticInputs:
    | SearchData[FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH]
    | undefined;
  handleUpdateKeywords: (
    orKeywords: string[],
    notKeywords: string[],
    matchKeywords: string[],
    semanticInputs?: SearchData[FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH]
  ) => void;
  disabled: boolean | undefined;
}

const TextSearchSection = ({
  andKeywords = [],
  orKeywords = [],
  notKeywords = [],
  handleUpdateKeywords,
  disabled,
  semanticInputs
}: TextSearchSectionProps) => {
  return (
    <div data-testid="Text-Search-Section">
      <SmallSectionHeader>Text search</SmallSectionHeader>

      <div data-testid="semantic-search-section">
        <SubSectionHeader>
          <div className="flex flex-row items-center gap-g20">
            Semantic{' '}
            <Tooltip body="Tips for using semantic keywords" interactive={true}>
              <a
                href="https://support.harmonic.ai/en/articles/10375135-semantic-keywords"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="info-icon-semantic"
              >
                <IconButton icon={InfoCircle} size="tiny" emphasis="low" />
              </a>
            </Tooltip>
          </div>
        </SubSectionHeader>

        <SemanticInput
          value={semanticInputs}
          onChange={(value) => {
            handleUpdateKeywords(orKeywords, notKeywords, andKeywords, value);
          }}
        />
      </div>

      <div className="mt-p70" data-testid="exact-matching-section">
        <SubSectionHeader>Exact matching</SubSectionHeader>

        <SectorKeywords
          data-testid="exact-keywords"
          andKeywords={andKeywords}
          orKeywords={orKeywords}
          notKeywords={notKeywords}
          handleUpdateKeywords={handleUpdateKeywords}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TextSearchSection;
