import classNames from 'classnames';
import InternalLink from 'components/common/InternalLink';
import NetworkBadgeWithTooltip from 'components/common/Network/NetworkBadgeWithTooltip';
import Avatar from 'harmonic-components/Avatar/Avatar';
interface EmployeeAttributeProps {
  src: string;
  name: string;
  entityUrn: string;
  href?: string;
  disabled?: boolean;
  internalLink?: boolean;
  isInNetwork?: boolean;
}
const EmployeeAttribute: React.FC<EmployeeAttributeProps> = ({
  src,
  href,
  name,
  entityUrn,
  disabled = false,
  internalLink = false,
  isInNetwork = false
}) => {
  const classes = classNames(
    'flex items-start gap-g30 pl-p10 pr-p30 py-p10 rounded-br30',
    'aria-disabled:bg-int-fill-secondary-disabled aria-disabled:cursor-default',
    href && 'cursor-pointer',
    !href && 'cursor-default',
    isInNetwork &&
      'bg-surface-sentiment-informative hover:bg-surface-sentiment-hover-informative',
    !isInNetwork &&
      'bg-int-fill-secondary-enabled hover:bg-int-fill-secondary-hover'
  );

  const mainContent = (
    <div className="flex items-center gap-g20">
      <Avatar
        size="medium"
        kind="face"
        src={src}
        alt="person-profile-picture"
      />
      <p className="text-content-strong typography-label-default-default line-clamp-1">
        {name}
      </p>
      {isInNetwork && (
        <a
          href={href + '?utm_source=harmonic'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <NetworkBadgeWithTooltip
            targetEntityUrn={entityUrn}
            targetName={name}
            variant="attribute"
          />
        </a>
      )}
    </div>
  );

  if (!internalLink)
    return (
      <a
        className={classes}
        aria-disabled={disabled}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {mainContent}
      </a>
    );

  return (
    <InternalLink to={{ pathname: href }}>
      <div className={classes} data-testid="employee-attribute">
        {mainContent}
      </div>
    </InternalLink>
  );
};

export default EmployeeAttribute;
