import { SortContextValues } from '__generated__/graphql';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import useUpsertCompanyWatchlistNamedViews from 'hooks/useUpsertCompanyWatchlistNamedViews';
import { INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { useCallback, useMemo } from 'react';
import useStore from 'stores/zustandStore';
import { getSelectedCompanyNamedViewIndex } from 'utils/namedViews';
import {
  SortHookResponse,
  getModifiedCompanySortValueAfterValidationCheck
} from 'utils/sort';

export const useCompanyWatchlistSort = (): SortHookResponse => {
  const { companyWatchlist } = useFetchSingleWatchlist({
    fetchPolicy: 'cache-only'
  });
  const companyListSelectedNamedView = useStore(
    (state) => state.companyListSelectedNamedView
  );
  const selectedNamedViewIndex = useMemo(() => {
    return getSelectedCompanyNamedViewIndex(
      companyWatchlist,
      companyListSelectedNamedView
    );
  }, [companyListSelectedNamedView, companyWatchlist]);
  const currentNamedViews =
    companyWatchlist?.namedViews?.[selectedNamedViewIndex];
  const { upsertCurrentSelectedNamedView } =
    useUpsertCompanyWatchlistNamedViews();

  const updateSortDataOnBackend = useCallback(
    async (
      sortField: string,
      isDescending: boolean,
      sortContextValues?: SortContextValues
    ) => {
      const updatedSearchQuery = {
        ...INITIAL_SEARCH_MODEL,
        ...(currentNamedViews?.searchQuery ?? {}),
        sort: [
          {
            sortField,
            descending: isDescending,
            sortContextValues
          }
        ]
      };
      upsertCurrentSelectedNamedView({
        variables: {
          searchQuery: updatedSearchQuery
        }
      });
    },
    [upsertCurrentSelectedNamedView, currentNamedViews]
  );

  const changeSort = useCallback(
    ({
      field,
      descending,
      sortContextValues
    }: {
      field: string;
      descending: boolean;
      sortContextValues?: SortContextValues;
    }) => {
      updateSortDataOnBackend(field, descending, sortContextValues);
    },
    [updateSortDataOnBackend]
  );

  const sort = getModifiedCompanySortValueAfterValidationCheck(
    currentNamedViews?.searchQuery?.sort?.[0]?.sortField,
    currentNamedViews?.searchQuery?.sort?.[0]?.descending,
    currentNamedViews?.searchQuery?.sort?.[0]?.sortContextValues,
    companyWatchlist?.customFields
  );

  return {
    changeSort,
    sort
  };
};
