import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export const SectionDivider = () => {
  return <div className="border-solid border-b border-border w-full" />;
};

export const SmallSectionHeader: React.FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <p
      className={classNames('typography-title-small pb-p70 text-content-title')}
    >
      {children}
    </p>
  );
};

export const PeopleSmallSectionHeader: React.FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <p
      className={classNames('typography-title-small mb-g80 text-content-title')}
    >
      {children}
    </p>
  );
};
export const SubSectionHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <p
      className={classNames(
        'typography-title-extraSmall text-content-title pb-p40 mb-p40 border-solid border-b border-border w-full'
      )}
    >
      {children}
    </p>
  );
};
export const PeopleSubSectionHeader: React.FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <p
      className={classNames(
        'typography-title-extraSmall text-content-title pb-p40 mb-g50 border-solid border-b border-border w-full'
      )}
    >
      {children}
    </p>
  );
};
