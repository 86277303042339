import { DashboardLocation } from 'hooks/useDashboardLocation';
import { FilterTabs } from 'interfaces/SearchV2';
import { ENVIRONMENT, FrontendEnvironments } from './constants';

export enum CustomTrackEvent {
  COMPANY_PROFILE_CLICK = 'company_profile_click',
  PERSON_PROFILE_CLICK = 'person_profile_click',
  PERSON_PROFILE_LOAD = 'person_profile_load',
  PERSON_PROFILE_COMPANY_EXPAND = 'person_profile_company_expand',
  COMPANY_PROFILE_LOAD = 'company_profile_load',
  SEARCH_OPENED = 'search_opened',
  SEARCH_INITIATED = 'search_initiated',
  SEARCH_COMPLETED = 'search_completed',
  SAVED_SEARCH_CREATED = 'saved_search_created',
  SAVED_SEARCH_PAGE_LOAD = 'saved_search_page_load',
  COMPANY_ACTION_CLICK = 'company_action_click',
  EXTERNAL_LINK_CLICKED = 'external_link_clicked',
  SEE_SIMILAR_COMPANIES_CLICKED = 'see_similar_companies_clicked',
  SEARCH_SECTION_CLICKED = 'search_section_clicked',
  SIMILAR_COMPANIES_QUALIFICATION = 'similar_companies_qualification',
  SECTOR_PRESET_CLICK = 'sector_preset_click',
  SECTOR_CATEGORY_CLICK = 'sector_category_click',
  REGION_PRESET_CLICK = 'region_preset_click',
  COMPANY_PROFILE_TAB_CHANGE = 'company_profile_tab_change',
  COMPANY_PROFILE_OUTLINK_CLICK = 'company_profile_outlink_click',
  PERSON_PROFILE_OUTLINK_CLICK = 'person_profile_outlink_click',
  INVESTOR_PROFILE_CLICK = 'investor_profile_click',
  PERFORMANCE_METRIC = 'performance_metric',
  OPENED_COMPANY_LIST_LOCAL_SEARCH = 'opened_company_list_local_search',
  OPENED_PERSON_LIST_LOCAL_SEARCH = 'opened_person_list_local_search',
  DISPLAY_SIDEBAR_SHOW_GRID_VIEW = 'display_sidebar_show_grid_view',
  DISPLAY_SIDEBAR_SHOW_BOARD_VIEW = 'display_sidebar_show_board_view',
  BOARD_VIEW_DROP_END = 'board_view_drop_end',
  BOARD_EMPTY_CLICK_STATUS_FIELD = 'board_empty_click_status_field',
  BOARD_EMPTY_CLICK_SELECT_FIELD = 'board_empty_click_select_field',
  BOARD_CHANGE_GROUP_BY_FIELD = 'board_change_group_by_field',
  DISMISS_NET_NEW_ENTITY_CLICKED = 'dismiss_net_new_entity_clicked',
  DISMISS_NET_NEW_FOR_SEARCH_CLICKED = 'dismiss_net_new_for_search_clicked',
  NOTIFICATION_BELL_CLICKED = 'notification_bell_clicked',
  DIGESTS_ENABLED = 'digest_subscription_enabled',
  SLACK_SUBSCRIPTION_CREATED = 'slack_subscription_created',

  // semantic search
  SEMANTIC_SEARCH_QUERY_SUBMIT = 'semantic_search_query_submit',
  SEMANTIC_SEARCH_RATING = 'semantic_search_rating',
  SEMANTIC_SEARCH_COMPANY_CLICK = 'semantic_search_company_click',
  SEMANTIC_SEARCH_ADD_TO_LIST = 'semantic_search_add_to_list',
  GENERATE_RESEARCH_REPORT = 'generate_research_report',
  COMPANY_SEARCH_COMPLETED_WITH_SEMANTIC_KEYWORDS = 'company_search_completed_with_semantic_keywords'
}

enum ComponentLocation {
  GRID_TABLE = 'grid_table',
  GRID_TABLE_COMPANY_NAME_COMPANY_SEARCH = 'grid_table_company_name_company_search',
  GRID_TABLE_COMPANY_NAME_PERSON_SEARCH = 'grid_table_company_name_person_search',
  GRID_TABLE_PERSON_EXPERIENCE = 'grid_table_person_experience',
  GRID_TABLE_HIGHLIGHTS = 'grid_table_highlights',
  GRID_TABLE_INVESTORS = 'grid_table_investors',
  GRID_TABLE_TEAM = 'grid_table_team',

  HEADER_TYPEAHEAD_SEARCH = 'header_typeahead_search',
  STANDALONE_TYPEAHEAD_SEARCH = 'standalone_typeahead_search',

  COMPANY_PROFILE_SIMILAR_COMPANIES = 'company_profile_similar_companies',
  COMPANY_PROFILE_INVESTORS = 'company_profile_investors',

  PERSON_PROFILE_EXPERIENCE = 'person_profile_experience'
}

enum ApplicationLocation {
  SLACK = 'slack'
}

export type EventLocation =
  | DashboardLocation
  | ComponentLocation
  | ApplicationLocation;
export const EventLocations = { ...DashboardLocation, ...ComponentLocation };

export enum SearchVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export enum CompanyActionEventType {
  LIKE = 'LIKE',
  UNLIKE = 'UNLIKE',
  HIDE = 'HIDE',
  UNHIDE = 'UNHIDE',
  VIEW = 'VIEW',
  UNVIEW = 'UNVIEW',
  ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST',
  REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST',
  REMOVE_FROM_CURRENT_WATCHLIST = 'REMOVE_FROM_CURRENT_WATCHLIST'
}

export enum PerformanceMeasureType {
  TIME_TO_DATA_ON_GRID_ON_FIRST_LOAD = 'time-to-data-on-grid-on-first-load'
}

type CompanyClickTrackEventProperties = {
  companyId: number;
  openedFromLocation: EventLocation;
};

type InvestorClickTrackEventProperties = {
  investorId: number;
  openedFromLocation: EventLocation;
};

type SavedSearchPageLoadEventProperties = {
  searchId: number;
  openedFromLocation: EventLocation;
};

type PersonClickTrackEventProperties = {
  personId: number;
  openedFromLocation: EventLocation;
};

type PersonProfileCompanyExpandProperties = {
  companyId: number;
  personId: number;
};

type CompanyLoadTrackEventProperties = CompanyClickTrackEventProperties & {
  duration: number;
};

type PersonLoadTrackEventProperties = PersonClickTrackEventProperties & {
  duration: number;
};

type SearchEventProperties = {
  openedFromLocation: EventLocation;
  version: SearchVersion;
  searchQuery?: string;
  savedSearchId?: number;
  watchlistId?: string;
};

type SavedSearchCreatedEventProperties = {
  version: SearchVersion;
  type: 'company' | 'person';
};

export type SearchCompletedEventProperties = SearchEventProperties & {
  duration: number;
};

type SearchOpenedEventProperties = {
  openedFromLocation: EventLocation;
  version: SearchVersion;
};

type CompanyActionClickEventProperties = {
  action: CompanyActionEventType;
  openedFromLocation?: EventLocation;
  companyIds: string; // comma separated numbers as a string
  watchlistId?: string;
  isBulkAction: boolean;
  fromHotkey: boolean;
};

type ExternalLinkClickedProperties = {
  entityField?: string;
  url: string;
};

type SeeSimilarCompaniesClickEventProperties = {
  companyId: number;
  companyName?: string;
  openedFromLocation?: EventLocation;
};

type CompanyProfileTabChangeProperties = {
  companyId: number;
  tabName: string;
};

type CompanyProfileOutlinkClickProperties = {
  companyId: number;
  link: string;
  linkType: string;
};
type PersonProfileOutlinkClickProperties = {
  personId: number;
  link: string;
  linkType: string;
};

type SearchSectionClickedProperties = {
  section: FilterTabs;
};

type SimilarCompaniesQualificationEventProperties = {
  companyId: number;
  similarCompanyId: number;
  qualification: boolean; // true = good recommendation, false = bad recommendation
};

type SectorPresetClickEventProperties = {
  selected: boolean;
  preset: string;
};

type RegionPresetClickEventProperties = {
  selected: boolean;
  region: string;
};

type SectorCategoryClickEventProperties = {
  checked: boolean;
  category: string;
  categoryValue: string;
};

type PerformanceMetricProperties = {
  name: string;
  durationMs: number;
};

type LocalSearchClickEventProperties = {
  watchlistId: string;
};

type DisplaySidebarProperties = {
  watchlistId: string;
};

type BoardViewProperties = {
  watchlistId: string;
  customFieldUrn: string;
  customFieldValueUrn: string;
  previousCustomFieldValueUrn: string;
};

type DismissNetNewEntityProperties = {
  savedSearchUrn: string;
  type: string;
};

type DismissNetNewForSearchProperties = {
  savedSearchUrn: string;
  currentNetNewCount: number;
};

export type BoardEmptyPropertiesOrigin = 'empty-board' | 'sidebar';

type BoardEmptyProperties = {
  origin: BoardEmptyPropertiesOrigin;
};

type BoardChangeGroupByFieldProperties = {
  from: string;
  to: string;
};

type SavedSearchPageLoadEventParams = {
  event: CustomTrackEvent.SAVED_SEARCH_PAGE_LOAD;
  properties: SavedSearchPageLoadEventProperties;
};

type CompanyClickTrackEventParams = {
  event: CustomTrackEvent.COMPANY_PROFILE_CLICK;
  properties: CompanyClickTrackEventProperties;
};

type PersonClickTrackEventParams = {
  event: CustomTrackEvent.PERSON_PROFILE_CLICK;
  properties: PersonClickTrackEventProperties;
};

type InvestorClickTrackEventParams = {
  event: CustomTrackEvent.INVESTOR_PROFILE_CLICK;
  properties: InvestorClickTrackEventProperties;
};

type CompanyLoadEventParams = {
  event: CustomTrackEvent.COMPANY_PROFILE_LOAD;
  properties: CompanyLoadTrackEventProperties;
};

type PersonLoadEventParams = {
  event: CustomTrackEvent.PERSON_PROFILE_LOAD;
  properties: PersonLoadTrackEventProperties;
};

type PersonProfileCompanyExpandParams = {
  event: CustomTrackEvent.PERSON_PROFILE_COMPANY_EXPAND;
  properties: PersonProfileCompanyExpandProperties;
};
type SearchCompletedEventParams = {
  event: CustomTrackEvent.SEARCH_COMPLETED;
  properties: SearchCompletedEventProperties;
};

type CompanySearchCompletedWithSemanticKeywordsParams = {
  event: CustomTrackEvent.COMPANY_SEARCH_COMPLETED_WITH_SEMANTIC_KEYWORDS;
  properties: SearchCompletedEventProperties;
};

type SearchInitiatedEventParams = {
  event: CustomTrackEvent.SEARCH_INITIATED;
  properties: SearchEventProperties;
};

type SearchOpenedEventParams = {
  event: CustomTrackEvent.SEARCH_OPENED;
  properties: SearchOpenedEventProperties;
};

type SavedSearchCreatedEventParams = {
  event: CustomTrackEvent.SAVED_SEARCH_CREATED;
  properties: SavedSearchCreatedEventProperties;
};

type CompanyActionClickEventParams = {
  event: CustomTrackEvent.COMPANY_ACTION_CLICK;
  properties: CompanyActionClickEventProperties;
};

type CompanyProfileTabChangeParams = {
  event: CustomTrackEvent.COMPANY_PROFILE_TAB_CHANGE;
  properties: CompanyProfileTabChangeProperties;
};

type CompanyProfileOutlinkClickParams = {
  event: CustomTrackEvent.COMPANY_PROFILE_OUTLINK_CLICK;
  properties: CompanyProfileOutlinkClickProperties;
};

type PersonProfileOutlinkClickParams = {
  event: CustomTrackEvent.PERSON_PROFILE_OUTLINK_CLICK;
  properties: PersonProfileOutlinkClickProperties;
};

type ExternalLinkClickEventParams = {
  event: CustomTrackEvent.EXTERNAL_LINK_CLICKED;
  properties: ExternalLinkClickedProperties;
};

type SeeSimilarCompaniesClickEventParams = {
  event: CustomTrackEvent.SEE_SIMILAR_COMPANIES_CLICKED;
  properties: SeeSimilarCompaniesClickEventProperties;
};

type SearchSectionClickedEventParams = {
  event: CustomTrackEvent.SEARCH_SECTION_CLICKED;
  properties: SearchSectionClickedProperties;
};

type SimilarCompaniesQualaificationEventParams = {
  event: CustomTrackEvent.SIMILAR_COMPANIES_QUALIFICATION;
  properties: SimilarCompaniesQualificationEventProperties;
};

type SectorPresetClickEventParams = {
  event: CustomTrackEvent.SECTOR_PRESET_CLICK;
  properties: SectorPresetClickEventProperties;
};
type RegionPresetClickEventParams = {
  event: CustomTrackEvent.REGION_PRESET_CLICK;
  properties: RegionPresetClickEventProperties;
};

type SectorCategoryClickEventParams = {
  event: CustomTrackEvent.SECTOR_CATEGORY_CLICK;
  properties: SectorCategoryClickEventProperties;
};

type PerformanceMetricEventParams = {
  event: CustomTrackEvent.PERFORMANCE_METRIC;
  properties: PerformanceMetricProperties;
};

type CompanyListLocalSearchOpenedEventParams = {
  event: CustomTrackEvent.OPENED_COMPANY_LIST_LOCAL_SEARCH;
  properties: LocalSearchClickEventProperties;
};

type PersonListLocalSearchOpenedEventParams = {
  event: CustomTrackEvent.OPENED_PERSON_LIST_LOCAL_SEARCH;
  properties: LocalSearchClickEventProperties;
};

type DisplaySidebarEventParams = {
  event:
    | CustomTrackEvent.DISPLAY_SIDEBAR_SHOW_GRID_VIEW
    | CustomTrackEvent.DISPLAY_SIDEBAR_SHOW_BOARD_VIEW;
  properties: DisplaySidebarProperties;
};

type BoardViewParams = {
  event: CustomTrackEvent.BOARD_VIEW_DROP_END;
  properties: BoardViewProperties;
};

type BoardEmptyParams = {
  event:
    | CustomTrackEvent.BOARD_EMPTY_CLICK_STATUS_FIELD
    | CustomTrackEvent.BOARD_EMPTY_CLICK_SELECT_FIELD;
  properties: BoardEmptyProperties;
};

type BoardChangeGroupByFieldParams = {
  event: CustomTrackEvent.BOARD_CHANGE_GROUP_BY_FIELD;
  properties: BoardChangeGroupByFieldProperties;
};

type DismissNetNewEntityParams = {
  event: CustomTrackEvent.DISMISS_NET_NEW_ENTITY_CLICKED;
  properties: DismissNetNewEntityProperties;
};

type DismissNetNewForSearchParams = {
  event: CustomTrackEvent.DISMISS_NET_NEW_FOR_SEARCH_CLICKED;
  properties: DismissNetNewForSearchProperties;
};

type NotificationSource = 'dropdown' | 'settings-page';

type NotificationBellClickedProperties = {
  savedSearchUrn: string;
};
type NotificationBellClickedParams = {
  event: CustomTrackEvent.NOTIFICATION_BELL_CLICKED;
  properties: NotificationBellClickedProperties;
};

type DigestsEnabledProperties = {
  savedSearchUrn: string;
  source: NotificationSource;
};

type SlackSubscriptionCreatedProperties = {
  savedSearchUrn: string;
  source: NotificationSource;
};

type DigestsEnabledParams = {
  event: CustomTrackEvent.DIGESTS_ENABLED;
  properties: DigestsEnabledProperties;
};

type SlackSubscriptionCreatedEventParams = {
  event: CustomTrackEvent.SLACK_SUBSCRIPTION_CREATED;
  properties: SlackSubscriptionCreatedProperties;
};

type SemanticSearchQuerySubmitProperties = {
  query: string;
};

type SemanticSearchRatingProperties = {
  query?: string;
  rating: 'good' | 'bad';
  feedback?: string;
};

type SemanticSearchCompanyClickProperties = {
  query?: string;
  companyId: number;
  rank?: number; // Position in results where company was clicked
};

type SemanticSearchAddToListProperties = {
  query?: string;
  companyId: number;
  rank?: number;
};

type SemanticSearchQuerySubmitParams = {
  event: CustomTrackEvent.SEMANTIC_SEARCH_QUERY_SUBMIT;
  properties: SemanticSearchQuerySubmitProperties;
};

type SemanticSearchRatingParams = {
  event: CustomTrackEvent.SEMANTIC_SEARCH_RATING;
  properties: SemanticSearchRatingProperties;
};

type SemanticSearchCompanyClickParams = {
  event: CustomTrackEvent.SEMANTIC_SEARCH_COMPANY_CLICK;
  properties: SemanticSearchCompanyClickProperties;
};

type SemanticSearchAddToListParams = {
  event: CustomTrackEvent.SEMANTIC_SEARCH_ADD_TO_LIST;
  properties: SemanticSearchAddToListProperties;
};

type GenerateResearchReportProperties = {
  domain: string;
  companyId: string;
};

type GenerateResearchReportParams = {
  event: CustomTrackEvent.GENERATE_RESEARCH_REPORT;
  properties: GenerateResearchReportProperties;
};

type TrackEventParams =
  | SavedSearchPageLoadEventParams
  | CompanyClickTrackEventParams
  | CompanyProfileTabChangeParams
  | CompanyProfileOutlinkClickParams
  | PersonProfileOutlinkClickParams
  | PersonClickTrackEventParams
  | CompanyLoadEventParams
  | PersonLoadEventParams
  | SearchCompletedEventParams
  | CompanySearchCompletedWithSemanticKeywordsParams
  | SearchInitiatedEventParams
  | SearchOpenedEventParams
  | SavedSearchCreatedEventParams
  | CompanyActionClickEventParams
  | ExternalLinkClickEventParams
  | SeeSimilarCompaniesClickEventParams
  | SearchSectionClickedEventParams
  | SimilarCompaniesQualaificationEventParams
  | SectorPresetClickEventParams
  | SectorCategoryClickEventParams
  | RegionPresetClickEventParams
  | PersonProfileCompanyExpandParams
  | InvestorClickTrackEventParams
  | PerformanceMetricEventParams
  | CompanyListLocalSearchOpenedEventParams
  | PersonListLocalSearchOpenedEventParams
  | DisplaySidebarEventParams
  | BoardViewParams
  | BoardEmptyParams
  | BoardChangeGroupByFieldParams
  | DismissNetNewEntityParams
  | DismissNetNewForSearchParams
  | NotificationBellClickedParams
  | DigestsEnabledParams
  | SlackSubscriptionCreatedEventParams
  | SemanticSearchQuerySubmitParams
  | SemanticSearchRatingParams
  | SemanticSearchCompanyClickParams
  | SemanticSearchAddToListParams
  | GenerateResearchReportParams;

const loadHeap = (): void => {
  const heapAppId =
    ENVIRONMENT === FrontendEnvironments.Staging ? '1714009127' : '1950460381';
  if (window.heap && window.heap.load) {
    window.heap.load(heapAppId);
  }
};

const initializeAnalytics = async ({
  name,
  email,
  entityUrn
}: {
  name: string;
  email: string;
  entityUrn: string;
}): Promise<void> => {
  if (!window.heap.loaded) await loadHeap();
  window.heap.addUserProperties({ Name: name, Email: email });
  window.heap.identify(entityUrn);
};

const addEventProperties = (properties: HeapPropertiesDictionary) => {
  window.heap.addEventProperties(properties);
};

const trackCustomEvent = (params: TrackEventParams): void => {
  if (!window || !window?.heap || !window?.heap?.loaded) return;
  window.heap.track(params.event, params.properties);
};

export default {
  loadHeap,
  initializeAnalytics,
  addEventProperties,
  trackCustomEvent
};
