export enum CollectionType {
  LISTS = 'Lists',
  SEARCHES = 'Searches'
}

export enum VisibilityEnum {
  any = 'any',
  private = 'private',
  shared = 'shared'
}

export enum CreatorEnum {
  anyone = 'anyone',
  me = 'me'
}

export enum CurrentTabEnum {
  all = 'all',
  companies = 'companies',
  people = 'people'
}

export interface IDisplaySettings {
  showCreator: boolean;
  showVisibility: boolean;
  // showLastViewDate: boolean;
  showLastUpdatedDate: boolean;
  showCreationDate: boolean;
}

interface ISort {
  field: string;
  descending: boolean;
}

export interface IGridFilterSettings {
  visibility: VisibilityEnum;
  creator: CreatorEnum;
}

export interface IGridFilterState {
  currentTab: CurrentTabEnum;
  searchInput: string;
  gridFilterSettings: {
    visibility: VisibilityEnum;
    creator: CreatorEnum;
  };
  displaySettings: {
    showCreator: boolean;
    showVisibility: boolean;
    // showLastViewDate: boolean;
    showLastUpdatedDate: boolean;
    showCreationDate: boolean;
  };
  sort: {
    field: string;
    descending: boolean;
  };
}

export interface IGridFilterActions {
  setSearchInput: (input: string) => void;
  setGridFilterSettings: (settings: IGridFilterSettings) => void;
  setDisplaySettings: (settings: IDisplaySettings) => void;
  setCurrentTab: (tab: CurrentTabEnum) => void;
  resetSearchInput: () => void;
  setSort: (sort: ISort) => void;
}

// ------------------- Net New -------------------
interface Search {
  id: string;
  name: string;
  entityUrn: string;
}

interface CommonRowProps {
  entityUrn: string; // entityUrn of the Company/Person
  id: number;
  name: string;
  picUrl: string;
  searches: Search[];
  netNewIds: number[];
}

export interface CompanyRow extends CommonRowProps {
  totalRaised: number;
  headcount: number;
  headcountChangePercent: number;
  headcountBeforeValue: number;
}

interface Company {
  urn?: string | null;
  name?: string | null;
  logoUrl?: string | null;
}

export interface PersonRow extends CommonRowProps {
  companies?: Company[];
  roles?: (string | undefined | null)[];
}
