import { Popover } from '@material-ui/core';
import { CrossIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import { BackendCustomRangeModeType } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_CUSTOM_RANGE,
  formatCustomRange,
  getInternalModeFromBackendMode
} from 'utils/searchV2/searchV2';
import RangeInlineDatePicker from './RangeInlineDatePicker';
import RollingRange from './RollingRange';
import SideActions from './SideActions';
import SingleInlineDatePicker from './SingleInlineDatepicker';

//Backend stores only two modes: custom and custom_rolling
//Our frontend has 4 modes: before, after, range, rolling_range
//We need to map the backend modes to the frontend modes
//custom -> before, after, range
//custom_rolling -> rolling_range
export enum InternalModeType {
  BEFORE = 'before',
  AFTER = 'after',
  RANGE = 'range',
  ROLLING_RANGE = 'rolling_range',
  IN = 'in'
}

const dayModeOptions = [
  {
    label: 'is on or before',
    value: InternalModeType.BEFORE
  },
  {
    label: 'is on or after',
    value: InternalModeType.AFTER
  },
  {
    label: 'is between',
    value: InternalModeType.RANGE
  },
  {
    label: 'is in rolling range',
    value: InternalModeType.ROLLING_RANGE
  }
];

const yearModeOptions = [
  {
    label: 'is between',
    value: InternalModeType.RANGE
  },
  {
    label: 'is in',
    value: InternalModeType.IN
  },
  {
    label: 'is in or before',
    value: InternalModeType.BEFORE
  },
  {
    label: 'is in or after',
    value: InternalModeType.AFTER
  },
  {
    label: 'is in rolling range',
    value: InternalModeType.ROLLING_RANGE
  }
];

export enum DateTypeEnum {
  DAY = 'day',
  YEAR = 'year'
}

export type OneSidedRangeValue = string | null;
export type TwoSidedRangeValue = [string | null, string | null];
interface CustomRangeProps {
  label?: string;
  value?: TwoSidedRangeValue | undefined | null;
  mode?: BackendCustomRangeModeType;
  onChange: (
    mode?: BackendCustomRangeModeType,
    value?: TwoSidedRangeValue
  ) => void;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLButtonElement | null;
  dateType?: DateTypeEnum;
}

const DateModal: React.FC<CustomRangeProps> = ({
  label,
  mode,
  value,
  onChange,
  open,
  onClose,
  anchorEl,
  dateType = DateTypeEnum.DAY
}) => {
  const [stateMode, setStateMode] = useState<InternalModeType>(
    InternalModeType.BEFORE
  );
  const [beforeOrAfterDate, setBeforeOrAfterDate] =
    useState<OneSidedRangeValue>(null);
  const [rangeDate, setRangeDate] = useState<TwoSidedRangeValue>([null, null]);
  const [rollingRangeValue, setRollingRangeValue] =
    useState<TwoSidedRangeValue>(
      formatCustomRange(DEFAULT_CUSTOM_RANGE) as [string, string]
    );

  if (dateType !== DateTypeEnum.DAY && dateType !== DateTypeEnum.YEAR) {
    throw new Error('Invalid date type');
  }
  const modeOptions =
    dateType === DateTypeEnum.YEAR ? yearModeOptions : dayModeOptions;

  useEffect(() => {
    if (!mode) return;
    const internalMode = getInternalModeFromBackendMode(mode, value);
    setStateMode(internalMode);
    if (internalMode === InternalModeType.BEFORE) {
      setBeforeOrAfterDate(value?.[1] as string);
    }
    if (internalMode === InternalModeType.AFTER) {
      setBeforeOrAfterDate(value?.[0] as string);
    }

    if (
      internalMode === InternalModeType.RANGE ||
      internalMode === InternalModeType.IN
    ) {
      setRangeDate(value as TwoSidedRangeValue);
    }
    if (internalMode === InternalModeType.ROLLING_RANGE) {
      setRollingRangeValue(value as TwoSidedRangeValue);
    }
  }, [mode, value]);

  const onSideActionChange = (
    value: OneSidedRangeValue | TwoSidedRangeValue
  ) => {
    if (
      stateMode === InternalModeType.BEFORE ||
      stateMode === InternalModeType.AFTER
    ) {
      setBeforeOrAfterDate(value as string);
    } else if (
      stateMode === InternalModeType.RANGE ||
      stateMode === InternalModeType.IN
    ) {
      setRangeDate(value as TwoSidedRangeValue);
    }
  };

  const onApplyFilter = () => {
    if (stateMode === InternalModeType.BEFORE) {
      onChange(BackendCustomRangeModeType.CUSTOM, [null, beforeOrAfterDate]);
    }
    if (stateMode === InternalModeType.AFTER) {
      onChange(BackendCustomRangeModeType.CUSTOM, [beforeOrAfterDate, null]);
    }
    if (
      stateMode === InternalModeType.RANGE ||
      stateMode === InternalModeType.IN
    ) {
      onChange(BackendCustomRangeModeType.CUSTOM, rangeDate);
    }
    if (stateMode === InternalModeType.ROLLING_RANGE) {
      onChange(BackendCustomRangeModeType.CUSTOM_ROLLING, rollingRangeValue);
    }
    onClose();
  };

  const onClearFilter = () => {
    onChange(undefined, undefined);
    onClose();
  };

  const isButtonDisabled = useMemo(() => {
    if (
      stateMode === InternalModeType.BEFORE ||
      stateMode === InternalModeType.AFTER
    ) {
      return isNil(beforeOrAfterDate);
    }
    if (stateMode === InternalModeType.RANGE) {
      return !rangeDate?.[0] && !rangeDate?.[1];
    }
    return false;
  }, [stateMode, beforeOrAfterDate, rangeDate]);

  const modeSelectComponent = (
    <Select
      selected={stateMode}
      multiple={false}
      getLabelFromValue={(value) => {
        return (
          modeOptions.find((option) => option.value === value)?.label ?? ''
        );
      }}
      dataTestId="CustomRange-Mode-Select"
    >
      {modeOptions.map((option) => (
        <ListItem
          variant={ListVariant.default}
          key={option.value}
          value={option.value}
          label={option.label}
          onClick={() => setStateMode(option.value)}
        />
      ))}
    </Select>
  );
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      classes={{
        paper:
          'border-border border-solid shadow-static-elevation-floating mt-p30'
      }}
      disableAutoFocus
      disableEnforceFocus
    >
      <div
        className="w-[560px]"
        data-testid={`date-modal-${dateType}`}
        data-visual-test="transparent"
      >
        <div className="p-p50 flex justify-between gap-g50 border-b border-solid border-border">
          <p className="typography-label">{label || 'Custom range'}</p>
          <IconButton
            size="tiny"
            type="secondary"
            emphasis="low"
            onClick={onClose}
            icon={CrossIcon}
          />
        </div>
        {stateMode === InternalModeType.ROLLING_RANGE && (
          <div className="p-p50">
            <div className="w-1/2">{modeSelectComponent}</div>
            <div className="h-[300px]">
              <RollingRange
                value={rollingRangeValue}
                onChange={(newVal) => setRollingRangeValue(newVal)}
                dateType={dateType}
              />
            </div>
            <div className="pt-p40 border-t border-solid border-border flex">
              <Button
                label="Apply filter"
                fullWidth
                type="secondary"
                emphasis="high"
                isDisabled={isButtonDisabled}
                dataTestId="CustomRange-Apply-Btn"
                onClick={onApplyFilter}
              />
            </div>
          </div>
        )}
        {stateMode !== InternalModeType.ROLLING_RANGE && (
          <div className="grid grid-cols-2 gap-g50 p-p50 min-h-[250px]">
            <div className="flex flex-col gap-g40 justify-between">
              <div className="flex flex-col gap-g40">
                {modeSelectComponent}
                <SideActions
                  mode={stateMode}
                  onChange={onSideActionChange}
                  dateType={dateType}
                />
              </div>
            </div>
            <div>
              {(stateMode === InternalModeType.BEFORE ||
                stateMode === InternalModeType.AFTER) && (
                <SingleInlineDatePicker
                  selectedDate={beforeOrAfterDate}
                  onChange={(date) => {
                    setBeforeOrAfterDate(date);
                  }}
                  dateType={dateType}
                  internalModeType={stateMode}
                />
              )}
              {stateMode === InternalModeType.IN && (
                <SingleInlineDatePicker
                  selectedDate={rangeDate[0]}
                  onChange={(date) => {
                    if (date) {
                      // In 2022 means 2022-01-01 to 2022-12-31
                      // date is the first day of the year
                      setRangeDate([date, `${date.split('-')[0]}-12-31`]);
                    }
                  }}
                  dateType={dateType}
                />
              )}
              {stateMode === InternalModeType.RANGE && (
                <RangeInlineDatePicker
                  value={rangeDate}
                  onChange={(date) => setRangeDate(date)}
                  dateType={dateType}
                />
              )}
              <div className="pt-p40 border-t border-solid border-border flex gap-g20">
                <Button
                  label="Clear"
                  fullWidth
                  type="secondary"
                  emphasis="high"
                  dataTestId="CustomRange-Clear-Btn"
                  onClick={onClearFilter}
                />
                <Button
                  label="Apply"
                  fullWidth
                  type="primary"
                  emphasis="high"
                  isDisabled={isButtonDisabled}
                  dataTestId="CustomRange-Apply-Btn"
                  onClick={onApplyFilter}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default DateModal;
