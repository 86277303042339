import { ApolloError, useMutation } from '@apollo/client';
import {
  DeletePersonWatchlistMutation,
  DeletePersonWatchlistMutationVariables
} from '__generated__/graphql';
import { DELETE_PERSON_WATCHLIST } from 'queries/deletePersonWatchlist';

interface UseDeletePersonWatchlistResult {
  deletePersonWatchlist: (watchlist: string) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
}

const useDeletePersonWatchlist = (): UseDeletePersonWatchlistResult => {
  const [deletePersonWatchlistMutation, { loading, error }] = useMutation<
    DeletePersonWatchlistMutation,
    DeletePersonWatchlistMutationVariables
  >(DELETE_PERSON_WATCHLIST);

  const deletePersonWatchlist = async (watchlist: string) => {
    const payload: DeletePersonWatchlistMutationVariables = {
      idOrUrn: watchlist
    };

    await deletePersonWatchlistMutation({
      variables: payload,
      optimisticResponse: {
        deletePeopleWatchlist: {
          __typename: 'DeletePeopleWatchlistPayload',
          entityUrn: {
            urn: `urn:harmonic:people_watchlist:${watchlist}`
          }
        }
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: 'PeopleWatchlist',
            id: watchlist
          })
        });
      }
    });
  };

  return {
    deletePersonWatchlist,
    loading,
    error
  };
};

export default useDeletePersonWatchlist;
