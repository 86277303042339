import { gql } from '@apollo/client';
import { FullCompanySearch } from './companySearchFragment';

export const getCompaniesByIds = gql`
  query GetCompaniesByIds(
    $ids: [Int!]!
    $extended: Boolean!
    $skipExtended: Boolean = false
  ) {
    getCompaniesByIds(ids: $ids, extended: $extended) {
      ...CompanySearch
    }
  }
  ${FullCompanySearch}
`;
