import { useApolloClient } from '@apollo/client';
import { updateSavedSearchUserOptions } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';

const useToggleFavoriteSavedSearch = () => {
  const client = useApolloClient();

  const toggleFavoriteMode = async (
    savedSearchId: string,
    isPinned: boolean
  ) => {
    try {
      await updateSavedSearchUserOptions(savedSearchId as string, {
        isPinned: !isPinned
      });
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'SavedSearch',
          id: savedSearchId
        }),
        fields: {
          userOptions: (_, __) => {
            return {
              __typename: 'SavedSearchUserOptions',
              isPinned: !isPinned,
              lastViewedAt: null
            };
          }
        },
        broadcast: true
      });
    } catch (err) {
      displayToast({
        primaryText: `Unable to ${isPinned ? 'unpin' : 'pin'} the saved search`,
        mode: 'error'
      });
    }
  };

  return {
    toggleFavoriteMode
  };
};

export default useToggleFavoriteSavedSearch;
