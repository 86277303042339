import FilterIcon from 'assets/harmonic-icons/filter';
import classNames from 'classnames';
import { EntityListType } from 'interfaces/SearchModel/Search';
import * as React from 'react';
import {
  ISearchFilterContext,
  SearchFilterContext
} from './SearchFilterGroupBase';
import SingleFilter from './SingleFilter/SingleFilter';
import { FilterConditionWrapper } from './styles';
import { SearchFilterType, getGroupTitle } from './utils';
interface IFilterQuery {
  recursiveLevel: number;
  disabled?: boolean;
  entityType: SearchFilterType;
  QuickFiltersComponent?: React.ReactNode;
}

const FilterQuery: React.FC<IFilterQuery> = ({
  recursiveLevel,
  disabled,
  entityType,
  QuickFiltersComponent
}) => {
  const searchFilterContext: ISearchFilterContext =
    React.useContext(SearchFilterContext);
  const searchGroup = searchFilterContext.rootSearchFilterGroup;
  const searchFilters = searchGroup.filters ?? [];

  const groupName = searchGroup.name;

  const descriptionText =
    entityType === EntityListType.COMPANY_SAVED_SEARCH ||
    entityType === EntityListType.PEOPLE_SAVED_SEARCH
      ? 'Add filters to refine your search results'
      : 'Add filters to refine your results';

  return (
    <FilterConditionWrapper>
      {searchFilters?.length === 0 && searchGroup?.filter_groups?.length === 0 && (
        <div
          className={classNames(
            'rounded-md w-full bg-surface-nested border-border flex text-center flex-col',
            {
              'py-p100 border border-solid': recursiveLevel === 0,
              'py-p50': recursiveLevel > 0
            }
          )}
        >
          <div className="typography-title-extraSmall mx-auto">
            <FilterIcon className="inline-block mr-p30 text-content-default" />
            No filters
          </div>
          <div className="typography-label text-content-weak mt-p50">
            {descriptionText}
          </div>
          {QuickFiltersComponent}
        </div>
      )}
      {/* a mapping through an object containing groups of filters */}

      <div>
        {groupName && (
          <div className="title typography-label">
            {getGroupTitle(groupName)}
          </div>
        )}
        <div>
          <div className="container">
            {searchFilters.map((filter, filterIndex) => {
              return (
                <SingleFilter
                  disabled={disabled}
                  recursiveLevel={recursiveLevel}
                  showWhereClause={filterIndex === 0}
                  showJoinToggle={filterIndex === 1}
                  {...filter}
                  index={filterIndex}
                  entityType={entityType}
                  key={filter.key || `${filter.field}_${filterIndex}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </FilterConditionWrapper>
  );
};

export default FilterQuery;
