import { FC } from 'react';

const CompanyBuildingIcon: FC = () => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M13.067 1.333H6.4c-.735 0-1.333.598-1.333 1.334v4H3.733C2.998 6.667 2.4 7.265 2.4 8v6c0 .368.298.667.667.667h10.666A.666.666 0 0014.4 14V2.667c0-.736-.598-1.334-1.333-1.334zM3.733 8h4v5.333h-4V8zm9.334 5.333h-4V8c0-.735-.598-1.333-1.334-1.333H6.4v-4h6.667v10.666z"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        fill="currentColor"
        d="M7.733 4h1.334v1.333H7.733V4zM10.4 4h1.333v1.333H10.4V4zm0 2.687h1.333V8H10.4V6.687zm0 2.646h1.333v1.334H10.4V9.333zm-5.333.001H6.4v1.333H5.067V9.334z"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  );
};

export default CompanyBuildingIcon;
