import React from 'react';
import { IconProps } from './type';

const CompanyListIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="List: Companies">
        <g id="shape">
          <path
            vectorEffect="non-scaling-stroke"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V6C2 5.63333 2.13056 5.31944 2.39167 5.05833C2.65278 4.79722 2.96667 4.66667 3.33333 4.66667H4.66667V3.33333C4.66667 2.96667 4.79722 2.65278 5.05833 2.39167C5.31944 2.13056 5.63333 2 6 2H10C10.3667 2 10.6806 2.13056 10.9417 2.39167C11.2028 2.65278 11.3333 2.96667 11.3333 3.33333L11.3333 7.49351C11.3333 7.82488 11.0647 8.09351 10.7333 8.09351H8.77972C7.74142 8.09351 6.89972 8.93521 6.89972 9.97351V13.4C6.89972 13.7314 6.63109 14 6.29972 14H3.33333ZM3.33333 12.6667H4.66667V11.3333H3.33333V12.6667ZM3.33333 10H4.66667V8.66667H3.33333V10ZM3.33333 7.33333H4.66667V6H3.33333V7.33333ZM6 7.33333H7.33333V6H6V7.33333ZM6 4.66667H7.33333V3.33333H6V4.66667ZM8.66667 7.33333H10V6H8.66667V7.33333ZM8.66667 4.66667H10V3.33333H8.66667V4.66667Z"
            fill="#7C8598"
          />
          <path
            vectorEffect="non-scaling-stroke"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.76705 9.39355C8.45371 9.39355 8.19971 9.66167 8.19971 9.99241C8.19971 10.3232 8.45371 10.5913 8.76705 10.5913H14.8187C15.132 10.5913 15.386 10.3232 15.386 9.99241C15.386 9.66167 15.132 9.39355 14.8187 9.39355H8.76705ZM8.19971 12.3879C8.19971 12.0571 8.45371 11.789 8.76705 11.789H14.8187C15.132 11.789 15.386 12.0571 15.386 12.3879C15.386 12.7186 15.132 12.9867 14.8187 12.9867H8.76705C8.45371 12.9867 8.19971 12.7186 8.19971 12.3879ZM8.76705 14.1844C8.45371 14.1844 8.19971 14.4526 8.19971 14.7833C8.19971 15.114 8.45371 15.3822 8.76705 15.3822H11.8187C12.132 15.3822 12.386 15.114 12.386 14.7833C12.386 14.4526 12.132 14.1844 11.8187 14.1844H8.76705Z"
            fill="#7C8598"
          />
        </g>
      </g>
    </svg>
  );
};

export default CompanyListIcon;
