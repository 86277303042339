import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  colorPrimary: {
    backgroundColor: '#F7F7F7'
  },
  barColorPrimary: {
    backgroundColor: '#EDEDED'
  }
})(LinearProgress);
