import { HarmonicIcon } from '../type';

const RoundIconIPO: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M11.3332 13.3327C11.1443 13.3327 10.9859 13.2688 10.8582 13.141C10.7304 13.0132 10.6665 12.8549 10.6665 12.666V9.33268C10.6665 9.14379 10.7304 8.98546 10.8582 8.85768C10.9859 8.7299 11.1443 8.66602 11.3332 8.66602H12.6665C12.8554 8.66602 13.0137 8.7299 13.1415 8.85768C13.2693 8.98546 13.3332 9.14379 13.3332 9.33268V12.666C13.3332 12.8549 13.2693 13.0132 13.1415 13.141C13.0137 13.2688 12.8554 13.3327 12.6665 13.3327H11.3332ZM7.33317 13.3327C7.14428 13.3327 6.98595 13.2688 6.85817 13.141C6.73039 13.0132 6.6665 12.8549 6.6665 12.666V3.33268C6.6665 3.14379 6.73039 2.98546 6.85817 2.85768C6.98595 2.7299 7.14428 2.66602 7.33317 2.66602H8.6665C8.85539 2.66602 9.01373 2.7299 9.1415 2.85768C9.26928 2.98546 9.33317 3.14379 9.33317 3.33268V12.666C9.33317 12.8549 9.26928 13.0132 9.1415 13.141C9.01373 13.2688 8.85539 13.3327 8.6665 13.3327H7.33317ZM3.33317 13.3327C3.14428 13.3327 2.98595 13.2688 2.85817 13.141C2.73039 13.0132 2.6665 12.8549 2.6665 12.666V6.66602C2.6665 6.47713 2.73039 6.31879 2.85817 6.19102C2.98595 6.06324 3.14428 5.99935 3.33317 5.99935H4.6665C4.85539 5.99935 5.01373 6.06324 5.1415 6.19102C5.26928 6.31879 5.33317 6.47713 5.33317 6.66602V12.666C5.33317 12.8549 5.26928 13.0132 5.1415 13.141C5.01373 13.2688 4.85539 13.3327 4.6665 13.3327H3.33317Z"
      fill="#3AA691"
    />
  </svg>
);

export default RoundIconIPO;
