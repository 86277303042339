import { Permission } from 'hooks/usePermissions';
import { UserSettings } from 'interfaces/DataModel/MidtierResource';
import { Location } from 'react-router-dom';
import { HideAllSearchesType } from 'utils/utilities';
import { create } from 'zustand';
import { useShallowStoreGeneric } from './util';

interface LoggingFields {
  didLogGridReady?: boolean;
  didLogGridFirstDataRendered?: boolean;
  didLogExtendedGridFirstDataRendered?: boolean;
  didLogGridDataLoadingStarted?: boolean;
  didLogGridDataLoadingCompleted?: boolean;
  didLogGridExtendedDataLoadingStarted?: boolean;
  didLogGridExtendedDataLoadingCompleted?: boolean;
  didLogBoardFirstColumnRendered?: boolean;
  didLogBoardFirstCardRendered?: boolean;
}

interface IZustandStoreState {
  hiddenSearches: HideAllSearchesType;
  isCustomizeColumnModalOpen: boolean;
  isSearchQueryBuilderOpen: boolean;
  isMobileDevice: boolean;
  logging?: LoggingFields;
  didChangeRoute: boolean;
  pageTitle: string;
  //When we navigate from grid to profiles, we want to show the tile of saved search/watchlist from where we are navigating
  previousPageTitle: string;
  disableHotkeys: boolean;
  userUrn: string;
  customerUrn: string;
  userSettings?: Partial<UserSettings>;
  isUpgradeToProModalOpen: boolean;
  upgradeModalMode?: Permission;
  // If this is set, we will trigger a useEffect in ListFilter component to create a filter for that field.
  isAffinityFieldsDrawerOpen: boolean;
  affinityFieldsDrawerCompanyId: number;
  editStoreData<Key extends keyof IZustandStoreState>(
    key: Key,
    payload: IZustandStoreState[Key]
  ): void;
  displayUpgradeModal(permissionMode?: Permission): void;
  displayAffinityFieldsDrawer(companyId: number): void;
  setLogging(logging: LoggingFields): void;
  resetLogging(): void;
  settingsCalledFromLocation?: Location;
  netNewFilterSearchId?: string;
  companyListSelectedNamedView: Record<string, string>;
  personListSelectedNamedView: Record<string, string>;
}

const useStore = create<IZustandStoreState>((set) => ({
  hiddenSearches: [],
  isCustomizeColumnModalOpen: false,
  isSearchQueryBuilderOpen: false,
  isMobileDevice: !!navigator.userAgent.match(/iPhone|iPad|iPod|Android/i),
  logging: {},
  didChangeRoute: false,
  disableHotkeys: false,
  pageTitle: '',
  previousPageTitle: '',
  isUpgradeToProModalOpen: false,
  userUrn: '',
  customerUrn: '',
  userSettings: {},
  isAffinityFieldsDrawerOpen: false,
  affinityFieldsDrawerCompanyId: -1,
  netNewTab: undefined,
  companyListSelectedNamedView: {},
  personListSelectedNamedView: {},
  editStoreData: (key, payload) =>
    set((state) => ({
      ...state,
      [key]: payload
    })),
  displayUpgradeModal: (permission: Permission) =>
    set((state) => ({
      ...state,
      isUpgradeToProModalOpen: true,
      upgradeModalMode: permission
    })),
  displayAffinityFieldsDrawer: (companyId: number) => {
    set((state) => ({
      ...state,
      isAffinityFieldsDrawerOpen: true,
      affinityFieldsDrawerCompanyId: companyId
    }));
  },
  setLogging: (logging: LoggingFields) => {
    set((state) => ({
      ...state,
      logging: {
        ...state.logging,
        ...logging
      }
    }));
  },
  resetLogging: () => {
    set((state) => ({
      ...state,
      logging: undefined
    }));
  }
}));

// Only re-render the component when the selected keys change
export const useShallowStore = (keys: (keyof IZustandStoreState)[]) =>
  useShallowStoreGeneric(useStore, keys);

export default useStore;
