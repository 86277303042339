import { ICellRendererParams } from 'ag-grid-community';
import { useEffect } from 'react';
import { usePerformanceLogging } from '../../../../hooks/usePerformanceLogging';
import { ITableHeader } from '../../../../interfaces/Grid';
import { LOADING_ROW_ID } from '../../../../utils/constants';
import { defaultColDef } from '../../../../utils/grid';
import {
  CellRenderers,
  LoadingCellRenderer,
  PersonExperienceDataCellRendererProps,
  SelectEditCellRendererProps
} from './CellRenderers';
import { useIsExtendedLoading } from './hooks/useIsExtendedLoading';

export type CommonCellRendererProps = ICellRendererParams &
  PersonExperienceDataCellRendererProps &
  SelectEditCellRendererProps;

export const CommonCellRenderer = (
  props: CommonCellRendererProps & {
    header: ITableHeader;
    headers: { [key: string]: boolean };
  }
) => {
  const { logGridFirstDataRendered, logGridFirstExtendedDataRendered } =
    usePerformanceLogging();
  const isExtendedLoading = useIsExtendedLoading(props);

  const { column, headers, header, data, node } = props;
  const cellRendererName = header.cellRenderer || defaultColDef.cellRenderer;
  const rowIndex = node?.rowIndex ?? 0;

  const field = column?.getColDef().field;

  const isExtended = field && !headers[field];

  useEffect(() => {
    if (rowIndex === 0 && data) {
      if (!isExtended) {
        logGridFirstDataRendered();
      } else {
        logGridFirstExtendedDataRendered();
      }
    }
  }, [
    rowIndex,
    data,
    isExtended,
    logGridFirstDataRendered,
    logGridFirstExtendedDataRendered
  ]);

  if (
    (isExtended && isExtendedLoading) ||
    (!isExtended && !data) ||
    data?.id === LOADING_ROW_ID
  ) {
    return <LoadingCellRenderer />;
  }

  const Component =
    CellRenderers[cellRendererName as keyof typeof CellRenderers];

  if (!Component) return null;

  return <Component {...props} />;
};
