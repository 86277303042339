import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { forwardRef, SVGProps } from 'react';
import {
  generateDraftTypeClasses,
  generateFocusClasses,
  generateInvertedTypeClasses,
  generateNegativeTypeClasses,
  generateNetNewTypeClasses,
  generatePrimaryTypeClasses,
  generateSecondaryTypeClasses
} from './utils';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'negative'
  | 'inverted'
  | 'inline'
  | 'net-new'
  | 'draft';
export type ButtonEmphasis = 'high' | 'medium' | 'low';
export type ButtonSize = 'default' | 'compact' | 'tiny';

//To reuse on IconButton
export interface CommonButtonProps {
  type?: ButtonType;
  emphasis?: ButtonEmphasis;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ref?: React.Ref<HTMLButtonElement>;
  loading?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  fullWidth?: boolean;
}
export interface ButtonProps extends CommonButtonProps {
  size?: ButtonSize;
  label?: string;
  leadingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  trailingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  ref?: React.Ref<HTMLButtonElement>;
  trailingBadgeCount?: number;
}

const generateSizeClasses = (size: ButtonSize) => {
  if (size === 'tiny') return 'px-p20 py-p10';
  if (size === 'compact') return 'px-p40 py-p20';
  return 'px-p50 py-p40';
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'secondary',
      emphasis = 'high',
      size = 'default',
      isSelected = false,
      isDisabled = false,
      label,
      leadingIcon,
      trailingIcon,
      onClick,
      onMouseDown,
      autoFocus,
      loading,
      dataTestId,
      fullWidth,
      trailingBadgeCount
    },
    ref
  ) => {
    let mainClasses = '';
    if (type === 'primary')
      mainClasses = generatePrimaryTypeClasses(isSelected, isDisabled);
    else if (type === 'negative')
      mainClasses = generateNegativeTypeClasses(isSelected, isDisabled);
    else if (type === 'secondary')
      mainClasses = generateSecondaryTypeClasses(
        emphasis,
        isSelected,
        isDisabled
      );
    else if (type === 'inverted')
      mainClasses = generateInvertedTypeClasses(
        emphasis,
        isSelected,
        isDisabled
      );
    else if (type === 'net-new')
      mainClasses = generateNetNewTypeClasses(isSelected, isDisabled);
    else if (type === 'draft')
      mainClasses = generateDraftTypeClasses(isSelected, isDisabled);

    const sizeClasses = generateSizeClasses(size);
    const focusClasses = generateFocusClasses(type);

    const LeadingIcon = leadingIcon;
    const TrailingIcon = trailingIcon;
    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          mainClasses,
          sizeClasses,
          focusClasses,
          //border radius and font sizes are same in all variations. Change it if needed
          'rounded-br30',
          'typography-label',
          'flex items-center justify-center whitespace-nowrap',
          'relative',
          'gap-g20',
          fullWidth && 'w-full',
          'data-visual-test-no-radius' // visual tests will ignore radius to avoid flakiness
        )}
        disabled={isDisabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        data-testid={dataTestId}
        autoFocus={autoFocus}
      >
        {LeadingIcon && (
          <span className="p-p10 items-center flex">
            <LeadingIcon className="w-4 h-4" />
          </span>
        )}
        {/* When a loader is shown, label disappears and that will cause changes in width.
          To prevent that, we are making text transparent such that width is preseved.
          And loader is shown via absolute position overlaying on top of it
        */}
        {label && (
          <span className={classNames('px-p10', loading && 'text-transparent')}>
            {label}
          </span>
        )}
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center">
            <CircularProgress
              classes={{
                root: 'text-inherit'
              }}
              size={16}
            />
          </div>
        )}
        {TrailingIcon && (
          <span>
            <TrailingIcon className="w-4 h-4" />
          </span>
        )}
        {!!trailingBadgeCount && (
          <div className="flex bg-surface-sentiment-highlight-informative rounded-br20 h-4 w-4 typography-label-small-default justify-center items-center text-content-inverted-strong">
            {trailingBadgeCount}
          </div>
        )}
      </button>
    );
  }
);

export default Button;
