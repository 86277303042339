import React from 'react';
import { IconProps } from './type';

const PeopleListIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="List: People">
        <g id="shape">
          <path
            vectorEffect="non-scaling-stroke"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.76705 9.39343C8.45371 9.39343 8.19971 9.66155 8.19971 9.99229C8.19971 10.323 8.45371 10.5912 8.76705 10.5912H14.8187C15.132 10.5912 15.386 10.323 15.386 9.99229C15.386 9.66155 15.132 9.39343 14.8187 9.39343H8.76705ZM8.19971 12.3877C8.19971 12.057 8.45371 11.7889 8.76705 11.7889H14.8187C15.132 11.7889 15.386 12.057 15.386 12.3877C15.386 12.7185 15.132 12.9866 14.8187 12.9866H8.76705C8.45371 12.9866 8.19971 12.7185 8.19971 12.3877ZM8.76705 14.1843C8.45371 14.1843 8.19971 14.4524 8.19971 14.7832C8.19971 15.1139 8.45371 15.382 8.76705 15.382H11.8187C12.132 15.382 12.386 15.1139 12.386 14.7832C12.386 14.4524 12.132 14.1843 11.8187 14.1843H8.76705Z"
            fill="#7C8598"
          />
          <path
            vectorEffect="non-scaling-stroke"
            d="M5.45 7.2165C5.97222 7.73873 6.6 7.99984 7.33333 7.99984C8.06667 7.99984 8.69444 7.73873 9.21667 7.2165C9.73889 6.69428 10 6.0665 10 5.33317C10 4.59984 9.73889 3.97206 9.21667 3.44984C8.69444 2.92761 8.06667 2.6665 7.33333 2.6665C6.6 2.6665 5.97222 2.92761 5.45 3.44984C4.92778 3.97206 4.66667 4.59984 4.66667 5.33317C4.66667 6.0665 4.92778 6.69428 5.45 7.2165Z"
            fill="#7C8598"
          />
          <path
            vectorEffect="non-scaling-stroke"
            d="M2 11.4665V11.9998C2 12.3665 2.13056 12.6804 2.39167 12.9415C2.65278 13.2026 2.96667 13.3332 3.33333 13.3332H6.29972C6.63109 13.3332 6.89972 13.0645 6.89972 12.7332V9.97338C6.89972 9.71412 6.9522 9.46711 7.04712 9.2424C7.14909 9.00101 6.99585 8.66888 6.73435 8.68581C6.20592 8.72002 5.68336 8.7997 5.16667 8.92484C4.45556 9.09706 3.75556 9.35539 3.06667 9.69984C2.74444 9.8665 2.48611 10.1082 2.29167 10.4248C2.09722 10.7415 2 11.0887 2 11.4665Z"
            fill="#7C8598"
          />
        </g>
      </g>
    </svg>
  );
};

export default PeopleListIcon;
