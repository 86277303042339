import { gql } from '@apollo/client';

export const UpsertUseListConfig = gql`
  mutation UpsertUserListConfig($input: UserListConfigUpsertInput!) {
    upsertUserListConfig(input: $input) {
      isPinned
      id
    }
  }
`;
