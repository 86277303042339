import { PersonUpdateEvent, SearchType } from '__generated__/graphql';
import { ICellRendererParams } from 'ag-grid-community';
import { Badge, Tooltip } from 'common/components';
import { UserInNetwork } from 'common/types/network';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import Avatar from 'harmonic-components/Avatar/Avatar';
import useDashboardLocation, {
  DashboardLocation
} from 'hooks/useDashboardLocation';
import { compact, get, uniqBy } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useShallowTableStore } from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import analytics, { CustomTrackEvent, EventLocations } from 'utils/analytics';
import { CheckboxSubRenderer } from './Checkbox';
import UnviewedNewDot from './UnviewedNewDot';

export const PersonNameWithCheckboxCellRenderer: React.FC<
  ICellRendererParams
> = (props: ICellRendererParams) => {
  const pageTitle = useStore((state) => state.pageTitle);
  const editStoreData = useStore((state) => state.editStoreData);
  const personId = get(props, 'data.person.id');
  const personUpdates: PersonUpdateEvent[] = compact(
    get(props, 'data.person.updates') || []
  );
  const personName = get(props, 'data.person.fullName');
  const personUserConnections = (props.node.data.person?.userConnections ??
    []) as UserInNetwork[];
  const usersInNetwork = uniqBy(personUserConnections, 'user.email');

  const { urn, location } = useDashboardLocation();
  const {
    addSelectedRowIds,
    selectedRowIds,
    selectAllStateEnabled,
    removeSelectedRowIds
  } = useShallowTableStore([
    'addSelectedRowIds',
    'selectedRowIds',
    'selectAllStateEnabled',
    'removeSelectedRowIds'
  ]);
  const isCheckboxChecked =
    selectedRowIds.includes(personId) || selectAllStateEnabled;
  const isSearchPage = location == DashboardLocation.PEOPLE_SEARCH;
  const isExplorePage = isSearchPage && (urn === '' || !urn);

  // net new stuff
  const { hasNetNew, onClearNetNewByIds } = useNetNewCounts();
  const countExists = hasNetNew(urn);
  const netNewId: number | undefined = get(props.data, 'netNewId');
  const isNetNew: boolean = useMemo(() => {
    return !!(countExists && netNewId);
  }, [countExists, netNewId]);

  const showPersonUpdateChip =
    location === DashboardLocation.PERSON_LIST && personUpdates.length > 0;
  const jobUpdatedChip = (
    <Badge color="informative" label="Profile recently updated" size="large" />
  );
  const tooltipText = (
    <>
      {personUpdates.map((update, index) => (
        <>
          <span key={index} className="typography-label-small-default">
            {update.helperText ?? ''}
          </span>
          <br />
        </>
      ))}
    </>
  );

  const onCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.node.setSelected(event.target.checked);
    if (event.target.checked) {
      addSelectedRowIds([personId]);
    } else {
      removeSelectedRowIds([personId]);
    }
  };

  const logoUrl = get(props, 'data.person.profile_picture_url');
  const logoUrlGql = get(props, 'data.person.profilePictureUrl');
  const profilePictureUrl = logoUrl || logoUrlGql;

  return (
    <div>
      <div className="flex justify-start items-start gap-g50 w-full">
        <CheckboxSubRenderer
          isChecked={isCheckboxChecked}
          onCheckboxClick={onCheckboxClick}
        />
        <div className="flex flex-col gap-g30 w-full">
          <div className="flex flex-row justify-between w-full items-center">
            {personId ? (
              <Link
                onClick={() => {
                  editStoreData(
                    'previousPageTitle',
                    isExplorePage ? 'Explore' : pageTitle
                  );
                  if (isNetNew && urn && netNewId)
                    onClearNetNewByIds({
                      ids: [netNewId],
                      savedSearchUrn: urn,
                      type: SearchType.PERSONS
                    });
                  analytics.trackCustomEvent({
                    event: CustomTrackEvent.PERSON_PROFILE_CLICK,
                    properties: {
                      personId,
                      openedFromLocation: EventLocations.GRID_TABLE
                    }
                  });
                }}
                to={`/dashboard/person/${personId}`}
              >
                <div className="flex items-start">
                  <Avatar
                    kind="face"
                    size="medium"
                    src={profilePictureUrl}
                    alt="person-profile-picture"
                  />
                  <span className="typography-label text-content-strong line-clamp-1 pl-p30">
                    {personName}
                  </span>
                </div>
              </Link>
            ) : (
              <span className="typography-label">{personName}</span>
            )}
            <UnviewedNewDot netNewId={isNetNew ? netNewId : undefined} />
          </div>
          <div>
            {showPersonUpdateChip && (
              <Tooltip body={tooltipText} alignment="right">
                <div className="inline-block">{jobUpdatedChip}</div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
