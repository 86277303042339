import { ColorShorthand, stringToExpressiveNumber } from 'common/utils/design';
import React, { useMemo } from 'react';
import Tag from '../../../../../harmonic-components/Tag/Tag';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';

export const Tags: React.FC<{
  tags: string[];
}> = ({ tags }) => {
  const memoizedTags = useMemo(() => {
    if (!tags || tags.length === 0) return null;

    return tags.map((tag, index) => (
      <div key={`${tag}-${index}`}>
        <Tag
          label={tag}
          color={
            `expressive-${stringToExpressiveNumber(tag)}` as ColorShorthand
          }
          whiteSpace="normal"
        />
      </div>
    ));
  }, [tags]);

  return (
    <div className="inline-block">
      <TruncatedList height={56}>{memoizedTags}</TruncatedList>
    </div>
  );
};
