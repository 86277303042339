import { HarmonicIcon } from '../type';

const RoundIconPE: HarmonicIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M4.66683 4.00065V2.66732C4.66683 2.30065 4.79739 1.98676 5.0585 1.72565C5.31961 1.46454 5.6335 1.33398 6.00016 1.33398H10.0002C10.3668 1.33398 10.6807 1.46454 10.9418 1.72565C11.2029 1.98676 11.3335 2.30065 11.3335 2.66732V4.00065H13.3335C13.7002 4.00065 14.0141 4.13121 14.2752 4.39232C14.5363 4.65343 14.6668 4.96732 14.6668 5.33398V12.6673C14.6668 13.034 14.5363 13.3479 14.2752 13.609C14.0141 13.8701 13.7002 14.0007 13.3335 14.0007H2.66683C2.30016 14.0007 1.98627 13.8701 1.72516 13.609C1.46405 13.3479 1.3335 13.034 1.3335 12.6673V5.33398C1.3335 4.96732 1.46405 4.65343 1.72516 4.39232C1.98627 4.13121 2.30016 4.00065 2.66683 4.00065H4.66683ZM6.00016 4.00065H10.0002V2.66732H6.00016V4.00065ZM2.66683 11.334H13.3335V9.33398H2.66683V11.334Z"
      fill="#3AA657"
    />
  </svg>
);

export default RoundIconPE;
