import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const OceaniaIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.93949 12.0937L1 8.46233L3.72179 6.92002L4.13054 5.61785L4.77755 5.65263L5.76402 4.00686L6.07025 4.93243H7.02349L7.7035 3.32211L9.57479 3.2541L9.06484 3.80084L9.30265 4.93243L11.9246 6.23504L11.72 5.51462L12.1613 5.03544L12.1954 3.32211L12.9099 2.5L13.0127 3.45879L13.6929 3.63026L13.8975 4.52148L14.5434 4.76074L14.5777 6.37195H15.5651L15.7345 7.50243L16.4497 8.05028V9.86729L15.4964 11.4267L15.2243 13.2248L13.4549 14.3897L13.7945 13.568L12.5018 14.0467L11.6516 13.362L11.5156 12.7459L10.5283 13.362L10.6981 12.6076L9.77938 13.0199L10.017 11.8542L9.13326 11.0667L6.17211 11.9922L5.83133 12.8141L4.2665 12.6424L3.58671 13.362L2.39566 12.951L2.93949 12.0937ZM14.3594 16.0857L13.543 15.0924L14.1889 15.0241L15.2438 14.3897V15.0924L14.3594 16.0857ZM13.7109 20.335L13.9824 21.5H15.0879L16.6705 19.7359L18.2019 19.3418L18.5935 18.0899L17.3517 17.7997L15.9052 19.393L13.7109 20.335ZM19.0703 17.2173L19.1898 16.0857L20.6186 15.6065L19.3259 13.6019L20.1597 13.0534L21.4181 15.2386L22.2862 15.0414L23.0005 16.0857L21.5719 16.2917L20.6349 18.4342L19.3946 18.8617L20.2103 17.4909L19.0703 17.2173Z"
        fill=""
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default OceaniaIcon;
