import { Portal } from '@material-ui/core';
import { ReactComponent as InfoIcon } from 'assets/bx-info-triangle.svg';
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { HotkeyBinding } from 'config/hotkeys';
import Button from 'harmonic-components/Button/Button';
import { useCopySavedSearch } from 'hooks/useCopySavedSearch';
import useDashboardLocation, {
  DashboardLocation
} from 'hooks/useDashboardLocation';
import { cloneDeep, debounce, uniqueId } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HOTKEYS_FILTERS_SCOPE } from 'utils/constants';
import { truncateValue } from 'utils/utilities';
import useFetchSingleSavedSearch from '../../hooks/useFetchSingleSavedSearch';
import useHotkeys from '../../hooks/useHotkeys';
import { useUpsertEntityListModal } from '../../hooks/useUpsertEntityListModal';
import {
  EntityListModalType,
  EntityListType,
  INITIAL_COMPANY_SEARCH_SORT_QUERY,
  INITIAL_PEOPLE_SEARCH_SORT_QUERY,
  INITIAL_SEARCH_MODEL,
  ISearchModel,
  SearchFilterGroupQuery
} from '../../interfaces/SearchModel/Search';
import {
  ActiveFilterCountByTabResponse,
  CompanyFilterTabs,
  FilterTabs,
  PeopleFilterTabs
} from '../../interfaces/SearchV2';
import useStore from '../../stores/zustandStore';
import { logger } from '../../utils/logger';
import {
  getCompaniesSearchResultCount,
  getPeopleSearchResultCount
} from '../../utils/midtierApi';
import {
  PeopleSearchExperienceGeneratorKey,
  generateCompanyInListFilterGroup,
  generatePeopleInListFilterGroup,
  getActiveFilterCountByTab,
  resetGeneratorsFromATab
} from '../../utils/searchV2/searchV2';
import CustomSection from './Common/CustomSection';
import FundingSection from './CompanySearch/FundingSection';
import CompanyGeneralSection from './CompanySearch/GeneralSection/GeneralSection';
import ProductSection from './CompanySearch/ProductSection/ProductSection';
import TeamSection from './CompanySearch/TeamSection';
import { FilterTabSelector, getFilterTabOptions } from './FilterTabSelector';
import CurrentExperienceSection from './PeopleSearch/CurrentExperienceSection';
import EducationSection from './PeopleSearch/EducationSection';
import PeopleGeneralSection from './PeopleSearch/GeneralSection';
import PastExperienceSection from './PeopleSearch/PastExperienceSection';

export const DEFAULT_COMPANY_SEARCH_QUERY: SearchFilterGroupQuery = {
  filter_group: {
    join_operator: 'and',
    filter_groups: [],
    filters: []
  },
  controlled_filter_group: {
    filter_group_generators: [],
    join_operator: 'and',
    filter_groups: [],
    filters: []
  },
  sort: INITIAL_COMPANY_SEARCH_SORT_QUERY
};

export const DEFAULT_PEOPLE_SEARCH_QUERY: SearchFilterGroupQuery = {
  filter_group: {
    join_operator: 'and',
    filter_groups: [],
    filters: []
  },
  controlled_filter_group: {
    filter_group_generators: [],
    join_operator: 'and',
    filter_groups: [],
    filters: []
  },
  sort: INITIAL_PEOPLE_SEARCH_SORT_QUERY
};

interface SeeResultsButtonProps {
  onClick: () => void;
  resultCount?: number;
  loading: boolean;
}

const SeeResultsButton: React.FC<SeeResultsButtonProps> = ({
  onClick,
  resultCount,
  loading
}) => {
  useHotkeys({
    keys: HotkeyBinding.FILTER_SEE_RESULTS,
    callback: onClick,
    options: {
      scopes: [HOTKEYS_FILTERS_SCOPE],
      description: 'See results'
    }
  });
  return (
    <Button
      dataTestId="QueryBuilder-Results-Btn"
      type="primary"
      onClick={onClick}
      label={`See ${
        resultCount !== undefined ? truncateValue(resultCount) : 0
      } results`}
      loading={loading}
    ></Button>
  );
};

interface QueryBuilderProps {
  entityId?: number;
  selectedFilterTab?: FilterTabs;
  setSelectedFilterTab: (newFilterTab?: FilterTabs) => void;
  tabsToShow: FilterTabs[];
  entityType: EntityListType;
  searchQuery: SearchFilterGroupQuery;
  setSearchQuery: (query: SearchFilterGroupQuery) => void;
  submitFilter: (query: SearchFilterGroupQuery) => void;
  onResetSearch: () => void;
  currentExperienceExpandedFields: PeopleSearchExperienceGeneratorKey[];
  setCurrentExperienceExpandedFields: (
    fields: PeopleSearchExperienceGeneratorKey[]
  ) => void;
  pastExperienceExpandedFields: PeopleSearchExperienceGeneratorKey[];
  setPastExperienceExpandedFields: (
    fields: PeopleSearchExperienceGeneratorKey[]
  ) => void;
  closeModal: (reset?: boolean) => void;
  activeFilterCountByTab?: ActiveFilterCountByTabResponse;
  setActiveFilterCountByTab: (
    activeFilterCountByTab: ActiveFilterCountByTabResponse
  ) => void;
}

export const QueryBuilderPopover: React.FC<QueryBuilderProps> = ({
  entityId,
  selectedFilterTab,
  setSelectedFilterTab,
  tabsToShow,
  entityType,
  searchQuery,
  setSearchQuery,
  submitFilter,
  onResetSearch,
  closeModal,
  currentExperienceExpandedFields,
  setCurrentExperienceExpandedFields,
  pastExperienceExpandedFields,
  setPastExperienceExpandedFields,
  activeFilterCountByTab,
  setActiveFilterCountByTab
}) => {
  // Custom section filter group is uncontrolled component under the hood.
  // Restting the search query won't actually reset the custom section UI. Value can only be set initially. After that, component acts on its own
  // Filter group uses some recursive state update logic so it's tricky to make it controlled.
  // This key is used to force a re-render of the custom section to reset the state.
  const [customSectionKey, setCustomSectionKey] = useState(uniqueId());
  const { currentPageSavedSearch } = useFetchSingleSavedSearch();
  const { urn, location: dashboardLocation } = useDashboardLocation();
  const { makeCopy } = useCopySavedSearch();
  const [resultCount, setResultCount] = useState<undefined | number>(undefined);
  const [resultsLoading, setResultsLoading] = useState(false);
  const { showUpsertEntityListModal, closeUpsertEntityListModal } =
    useUpsertEntityListModal();

  const [resultCountSearchCancelToken, setResultCountSearchCancelToken] =
    useState<null | CancelTokenSource>(null);

  const isSearchPage =
    dashboardLocation == DashboardLocation.COMPANY_SEARCH ||
    dashboardLocation == DashboardLocation.PEOPLE_SEARCH;
  const isExplorePage = isSearchPage && !urn;
  const isCustomLikeFilterTab =
    selectedFilterTab === CompanyFilterTabs.CUSTOM ||
    selectedFilterTab === PeopleFilterTabs.CUSTOM ||
    selectedFilterTab === PeopleFilterTabs.FILTER;

  const defaultSearchQuery =
    entityType === EntityListType.COMPANY_SAVED_SEARCH
      ? DEFAULT_COMPANY_SEARCH_QUERY
      : DEFAULT_PEOPLE_SEARCH_QUERY;

  const userUrn = useStore((state) => state.userUrn);

  const cancelToken = axios.CancelToken;
  // Only for companies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchActiveQueryResultCount = useCallback(
    debounce(async (searchModel: ISearchModel, entityId?: number) => {
      const source = cancelToken.source();
      if (resultCountSearchCancelToken) resultCountSearchCancelToken.cancel();
      const modifiedSearchModel = cloneDeep(searchModel);
      if (entityType === EntityListType.COMPANY_WATCHLIST) {
        const companyInWatchlistFilterGroup = generateCompanyInListFilterGroup(
          entityId?.toString() as string
        );

        modifiedSearchModel.controlled_filter_group =
          companyInWatchlistFilterGroup;
      }
      if (entityType === EntityListType.PEOPLE_WATCHLIST) {
        const peopleInWatchlistFilterGroup = generatePeopleInListFilterGroup(
          entityId?.toString() as string
        );
        modifiedSearchModel.controlled_filter_group =
          peopleInWatchlistFilterGroup;
      }
      try {
        setResultCountSearchCancelToken(source);
        setResultsLoading(true);
        const searchCountQueryPromise =
          entityType === EntityListType.COMPANY_SAVED_SEARCH ||
          entityType === EntityListType.COMPANY_WATCHLIST
            ? getCompaniesSearchResultCount(modifiedSearchModel, source.token)
            : getPeopleSearchResultCount(modifiedSearchModel, source.token);
        const countResponse = await searchCountQueryPromise;

        setResultCount(countResponse.count);
        setResultsLoading(false);
      } catch (err) {
        setResultsLoading(false);
        logger.error('Companies search result count error', {
          error: err
        });
      }
    }, 500),
    []
  );

  useEffect(() => {
    const newActiveFilterCount = getActiveFilterCountByTab(searchQuery);
    setActiveFilterCountByTab(newActiveFilterCount);
    const newSearchModel = {
      ...INITIAL_SEARCH_MODEL,
      ...searchQuery
    };
    debouncedFetchActiveQueryResultCount(newSearchModel, entityId);
    // Only fetch result count when the search query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;

  const onSubmitFilter = () => {
    submitFilter(searchQuery);

    closeModal(false);
  };

  const FilterTabOptions = getFilterTabOptions(
    entityType === EntityListType.COMPANY_WATCHLIST ||
      entityType === EntityListType.PEOPLE_WATCHLIST
  );
  const onClearCategory = () => {
    const newQuery = resetGeneratorsFromATab(
      searchQuery,
      selectedFilterTab as FilterTabs
    );
    setSearchQuery(newQuery);

    if (
      selectedFilterTab === CompanyFilterTabs.CUSTOM ||
      selectedFilterTab === PeopleFilterTabs.CUSTOM ||
      selectedFilterTab === PeopleFilterTabs.FILTER
    ) {
      setCustomSectionKey(uniqueId());
    }
  };
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const selectedFilterTabLabel = useMemo(() => {
    return FilterTabOptions.find(({ value }) => value === selectedFilterTab)
      ?.label;
  }, [selectedFilterTab, FilterTabOptions]);

  const widthClass = 'w-px-850';
  const minWidthClass = 'min-w-px-850';

  const disabled = !isSearchOwner && !isExplorePage;
  const duplicateSearch = async (name: string) => {
    await makeCopy(urn, name);
    closeUpsertEntityListModal();
    closeModal();
  };

  const openDuplicateEntityListModal = () => {
    showUpsertEntityListModal({
      entityType: EntityListType.COMPANY_SAVED_SEARCH,
      modalType: EntityListModalType.DuplicateList,
      onSubmit: duplicateSearch,
      entityName: currentPageSavedSearch?.name
    });
  };

  return (
    <>
      <Portal>
        {/* Backdrop component which acts as a way to detect onOutsideClick */}
        <div
          onClick={handleBackdropClick}
          className="fixed inset-0 z-100 bg-black opacity-20"
        />
      </Portal>
      <div
        className={classNames('pt-3 pl-3 rounded-br50', 'bg-white')}
        data-testid="QueryBuilder"
      >
        {/* Navigational tab selector */}
        <FilterTabSelector
          selectedFilterTab={selectedFilterTab}
          setSelectedFilterTab={setSelectedFilterTab}
          onResetSearch={onResetSearch}
          activeFilterCountByTab={activeFilterCountByTab}
          tabsToShow={tabsToShow}
          FilterTabOptions={FilterTabOptions}
          showPopover
        />

        {/* Popover tabs container */}
        {selectedFilterTab !== undefined && (
          <div
            className={classNames(
              !isCustomLikeFilterTab && widthClass,
              //Custom section can expand
              isCustomLikeFilterTab && minWidthClass
            )}
            data-testid="QueryBuilder-Popover"
          >
            {disabled && (
              <div className="inline-flex px-3 py-1 mt-3 mb-1.5 typography-label rounded-md bg-yellow-lightest text-content-weak">
                <InfoIcon className="mr-1.5" />
                Shared searches are not editable. Duplicate the search first to
                make edits.
              </div>
            )}

            <div
              className={classNames(
                'py-4 max-h-px-750 h-vh-70 pl-3 pr-6 overflow-auto'
              )}
            >
              {selectedFilterTab === CompanyFilterTabs.GENERAL && (
                <CompanyGeneralSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === CompanyFilterTabs.FUNDING && (
                <FundingSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === CompanyFilterTabs.TEAM && (
                <TeamSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === CompanyFilterTabs.PRODUCT && (
                <ProductSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === PeopleFilterTabs.GENERAL && (
                <PeopleGeneralSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === PeopleFilterTabs.CURRENT_EXP && (
                <CurrentExperienceSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  expandedFields={currentExperienceExpandedFields}
                  onUpdateExpandedFields={setCurrentExperienceExpandedFields}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === PeopleFilterTabs.PAST_EXP && (
                <PastExperienceSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  expandedFields={pastExperienceExpandedFields}
                  onUpdateExpandedFields={setPastExperienceExpandedFields}
                  disabled={disabled}
                />
              )}
              {selectedFilterTab === PeopleFilterTabs.EDUCATION && (
                <EducationSection
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
              {isCustomLikeFilterTab && (
                <CustomSection
                  key={customSectionKey}
                  entityType={entityType}
                  searchQuery={searchQuery}
                  onUpdateSearchQuery={setSearchQuery}
                  disabled={disabled}
                />
              )}
            </div>
            <div className="border-t border-solid border-border p-4 -ml-3 flex justify-between">
              <div className="flex gap-4">
                <Button
                  onClick={onClearCategory}
                  emphasis="low"
                  isDisabled={disabled}
                  label={`Clear ${selectedFilterTabLabel}`}
                ></Button>
              </div>
              {disabled && (
                <button
                  className="bg-int-fill-primary-enabled typography-label text-int-fill-primary-onEnabled py-1.5 min-w-28 px-2 rounded-md"
                  onClick={openDuplicateEntityListModal}
                >
                  Duplicate search
                </button>
              )}

              {!disabled && (
                <span data-visual-test="removed">
                  <SeeResultsButton
                    resultCount={resultCount}
                    onClick={onSubmitFilter}
                    loading={resultsLoading}
                  />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
