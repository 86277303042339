import { BackendCustomRangeModeType } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { TwoSidedRangeValue } from './CustomRange/DateModal';
import TimelineRange from './CustomRange/TimelineRange';

export enum DateWithSelectMode {
  BEFORE = 'before',
  AFTER = 'after',
  EXACT = 'exact',
  BETWEEN = 'between',
  ROLLING_RANGE = 'rollingRange'
}

interface DateWithSelectProps {
  mode?: DateWithSelectMode;
  value: string | TwoSidedRangeValue | null | undefined;
  onChange: (
    mode?: DateWithSelectMode,
    value?: string | [string, string] | null
  ) => void;
  label: string;
  dataTestId?: string;
}

const DateWithSelect: React.FC<DateWithSelectProps> = ({
  mode = DateWithSelectMode.BEFORE,
  value,
  onChange,
  label,
  dataTestId
}) => {
  const handleOnChange = (
    mode?: BackendCustomRangeModeType,
    value?: TwoSidedRangeValue
  ) => {
    if (isNil(mode) || isNil(value)) {
      onChange(undefined, undefined);
      return;
    }
    if (mode === BackendCustomRangeModeType.CUSTOM) {
      if (value[0] === null && value[1]) {
        onChange(DateWithSelectMode.BEFORE, value[1]);
        return;
      }
      if (value[0] && value[1] === null) {
        onChange(DateWithSelectMode.AFTER, value[0]);
        return;
      }
      if (value[0] && value[1]) {
        onChange(DateWithSelectMode.BETWEEN, value as [string, string]);
        return;
      }
    } else if (mode === BackendCustomRangeModeType.CUSTOM_ROLLING) {
      onChange(DateWithSelectMode.ROLLING_RANGE, value as [string, string]);
      return;
    }
  };

  const newValue: TwoSidedRangeValue | null = useMemo(() => {
    if (!value) {
      return null;
    }
    if (mode === DateWithSelectMode.BEFORE) {
      return [null, value as string | null];
    }
    if (mode === DateWithSelectMode.AFTER) {
      return [value as string | null, null];
    }
    return value as TwoSidedRangeValue;
  }, [mode, value]);

  return (
    <div className="flex flex-col gap-g20">
      <div className="flex gap-g30">
        <TimelineRange
          mode={
            mode === DateWithSelectMode.ROLLING_RANGE
              ? BackendCustomRangeModeType.CUSTOM_ROLLING
              : BackendCustomRangeModeType.CUSTOM
          }
          value={newValue}
          onChange={handleOnChange}
          label={label}
          dataTestId={dataTestId}
        />
      </div>
    </div>
  );
};

export default DateWithSelect;
