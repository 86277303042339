import InfoCircle from 'assets/harmonic-icons/info-circle';
import { Tooltip } from 'common';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { SearchFilterGroupQuery } from 'interfaces/SearchModel/Search';
import {
  FilterGroupGeneratorId,
  PeopleSearchGeneralHighlightsGenerator
} from 'interfaces/SearchV2';
import React from 'react';
import { HighlightCategory } from 'utils/constants';
import {
  getFilterGroupGeneratorsMap,
  getUpdatedQuery
} from 'utils/searchV2/searchV2';
import Checkboxes from '../Common/Checkboxes';
import { SubSectionHeader } from '../Common/Typography';

interface GeneralHighlightsSubSectionProps {
  searchQuery: SearchFilterGroupQuery;
  onUpdateSearchQuery: (newQuery: SearchFilterGroupQuery) => void;
  disabled?: boolean;
}

const peopleHighlightOptions = [
  {
    label: 'Prior exit',
    value: HighlightCategory.PRIOR_EXIT
  },
  {
    label: 'Free agent',
    value: HighlightCategory.FREE_AGENT
  },
  {
    label: 'Major tech company experience',
    value: HighlightCategory.MAJOR_TECH_COMPANY_EXPERIENCE
  },
  {
    label: 'Top university',
    value: HighlightCategory.TOP_UNIVERSITY
  },
  {
    label: 'HBCU Alum',
    value: HighlightCategory.HBCU_ALUM
  },
  {
    label: 'Top company alum',
    value: HighlightCategory.TOP_COMPANY_ALUM
  },
  {
    label: 'Prior VC-backed founder',
    value: HighlightCategory.PRIOR_VC_BACKED_FOUNDER
  },
  {
    label: 'Prior VC-backed executive',
    value: HighlightCategory.PRIOR_VC_BACKED_EXECUTIVE
  },
  {
    label: 'Deep technical background',
    value: HighlightCategory.DEEP_TECHNICAL_BACKGROUND
  },
  {
    label: 'Major research institution experience',
    value: HighlightCategory.MAJOR_RESEARCH_INSTITUTION_EXPERIENCE
  },
  {
    label: 'Previously raised $5M+',
    value: HighlightCategory.FIVE_M_CLUB
  },
  {
    label: 'Elite industry experience',
    value: HighlightCategory.ELITE_INDUSTRY_EXPERIENCE
  },
  {
    label: 'Previously raised $10M+',
    value: HighlightCategory.TEN_M_CLUB
  },
  {
    label: 'Seasoned operator',
    value: HighlightCategory.SEASONED_OPERATOR
  },
  {
    label: 'Previously raised $50M+',
    value: HighlightCategory.FIFTY_M_PLUS_CLUB
  },
  {
    label: 'Seasoned founder',
    value: HighlightCategory.SEASONED_FOUNDER
  },
  {
    label: 'YC backed founder',
    value: HighlightCategory.YC_BACKED_FOUNDER
  },
  {
    label: 'Top AI experience',
    value: HighlightCategory.TOP_AI_EXPERIENCE
  },
  {
    label: 'Top Web3 experience',
    value: HighlightCategory.TOP_WEB3_EXPERIENCE
  }
];

const GeneralHighlightsSubSection: React.FC<
  GeneralHighlightsSubSectionProps
> = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
  const [selectAllHighlightsChecked, setSelectAllHighlightsChecked] =
    React.useState<boolean>(false);

  const controlledSearchState = getFilterGroupGeneratorsMap(
    searchQuery?.controlled_filter_group
  );

  const currentHighlightsState =
    controlledSearchState?.[
      FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS
    ];

  const updateValues = (newValues?: PeopleSearchGeneralHighlightsGenerator) => {
    onUpdateSearchQuery(
      getUpdatedQuery(
        searchQuery,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS,
        newValues !== undefined
          ? {
              ...newValues
            }
          : undefined
      )
    );
  };

  const onToggleSelectAllHighlights = () => {
    if (selectAllHighlightsChecked) {
      updateValues(undefined);
    } else {
      updateValues({ highlights: peopleHighlightOptions.map((o) => o.value) });
    }
    setSelectAllHighlightsChecked(!selectAllHighlightsChecked);
  };

  const peopleHighlights = currentHighlightsState?.highlights ?? [];
  return (
    <div data-testid="Highlights-Section" className="w-full">
      <SubSectionHeader>
        <div className="flex justify-between">
          <div className="flex flex-row items-center">
            Highlights
            <div className="ml-p20">
              <Tooltip
                body="Learn more about our highlights"
                interactive={true}
              >
                <a
                  href="https://support.harmonic.ai/en/articles/5881310-highlights"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    icon={InfoCircle}
                    size="tiny"
                    emphasis="low"
                    onClick={() =>
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      {}
                    }
                  />
                </a>
              </Tooltip>
            </div>
          </div>
          <Checkbox
            onChange={onToggleSelectAllHighlights}
            checked={selectAllHighlightsChecked}
            variant={selectAllHighlightsChecked ? 'default' : 'indeterminate'}
            label="Select all highlights"
            labelAlignment="left"
            id="select-all-founder"
            dataTestId="Founder-Highlights-Select-All"
          />
        </div>
      </SubSectionHeader>

      <div className="flex flex-col gap-g90">
        <Checkboxes
          dataTestId="Team-Founder-Highlights-Checkboxes"
          options={peopleHighlightOptions}
          selectedValues={peopleHighlights}
          onValueChange={(newValues) =>
            updateValues(
              !newValues
                ? undefined
                : { highlights: newValues as HighlightCategory[] }
            )
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default GeneralHighlightsSubSection;
