import { useQuery } from '@apollo/client';
import {
  GetCompaniesWithTypeaheadFragment,
  TypeaheadCompaniesQuery,
  TypeaheadCompaniesQueryVariables
} from '__generated__/graphql';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { TYPEAHEAD_COMPANIES_QUERY } from 'queries/typeahead';
import { useState } from 'react';
import {
  SelectMode,
  getTagColorBySelectMode,
  getTagIconBySelectMode
} from './utils';

interface CompanyMultiselectProps {
  onChange: (newOptions?: string[]) => void;
  selected: string[];
  placeholder?: string;
  disabled?: boolean;
  initialFocus?: boolean;
  freeSolo?: boolean;
  mode?: SelectMode;
  dataTestId?: string;
}

const CompanyMultiselect: React.FC<CompanyMultiselectProps> = ({
  onChange,
  selected,
  placeholder,
  initialFocus = false,
  freeSolo,
  mode = null,
  dataTestId
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: companiesData, loading } = useQuery<
    TypeaheadCompaniesQuery,
    TypeaheadCompaniesQueryVariables
  >(TYPEAHEAD_COMPANIES_QUERY, { variables: { query: searchTerm } });

  const onClickListItem = (company: GetCompaniesWithTypeaheadFragment) => {
    const selectedOption = selected.find((s) => s === company.name);
    if (selectedOption) {
      onChange(selected.filter((s) => s !== company.name));
    } else {
      onChange([...selected, company.name ?? '']);
    }

    setSearchTerm('');
  };

  return (
    <Select
      multiple
      filterable
      freeSolo={freeSolo}
      onAdd={(value) => {
        onChange([...selected, value]);
        setSearchTerm('');
      }}
      filterTerm={searchTerm}
      onFilterTermChange={setSearchTerm}
      selected={selected}
      placeholder={placeholder}
      onRemove={(key) => onChange(selected.filter((s) => s !== key))}
      initialFocus={initialFocus}
      hideDropdown={
        !loading && companiesData?.getCompaniesWithTypeahead?.length === 0
      }
      getTagColorFromValue={() => getTagColorBySelectMode(mode)}
      getTagIconFromValue={() => getTagIconBySelectMode(mode)}
      clampValues
      dropdownMaxHeight="400px"
      dataTestId={dataTestId}
    >
      {loading && (
        <div className="flex w-full items-center justify-center">
          <HarmonicLoader showText={false} />
        </div>
      )}
      {!loading &&
        companiesData?.getCompaniesWithTypeahead?.map((company) => {
          if (!company) return null;
          return (
            <SelectListItem
              key={company.id}
              value={company.name ?? ''}
              label={company.name ?? ''}
              logo="logo"
              src={company.logoUrl ?? ''}
              onClick={() => onClickListItem(company)}
              variant={ListVariant.entity}
            />
          );
        })}
    </Select>
  );
};

export default CompanyMultiselect;
