import React from 'react';
import { IconProps } from './type';

const CompanySavedSearchIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Search: Companies">
        <g id="shape">
          <path
            vectorEffect="non-scaling-stroke"
            d="M13.9413 14.8262L12.3612 13.246C12.14 13.3725 11.9029 13.4778 11.6501 13.5621C11.3973 13.6464 11.1287 13.6885 10.8442 13.6885C10.0542 13.6885 9.38262 13.412 8.82957 12.8589C8.27652 12.3059 8 11.6343 8 10.8442C8 10.0542 8.27652 9.38262 8.82957 8.82957C9.38262 8.27652 10.0542 8 10.8442 8C11.6343 8 12.3059 8.27652 12.8589 8.82957C13.412 9.38262 13.6885 10.0542 13.6885 10.8442C13.6885 11.1287 13.6464 11.3973 13.5621 11.6501C13.4778 11.9029 13.3725 12.14 13.246 12.3612L14.8262 13.9413C14.9421 14.0572 15 14.2047 15 14.3837C15 14.5628 14.9421 14.7103 14.8262 14.8262C14.7103 14.9421 14.5628 15 14.3837 15C14.2047 15 14.0572 14.9421 13.9413 14.8262ZM10.8442 12.4244C11.2867 12.4244 11.6606 12.2716 11.9661 11.9661C12.2716 11.6606 12.4244 11.2867 12.4244 10.8442C12.4244 10.4018 12.2716 10.0278 11.9661 9.72235C11.6606 9.41686 11.2867 9.26411 10.8442 9.26411C10.4018 9.26411 10.0278 9.41686 9.72235 9.72235C9.41685 10.0278 9.26411 10.4018 9.26411 10.8442C9.26411 11.2867 9.41685 11.6606 9.72235 11.9661C10.0278 12.2716 10.4018 12.4244 10.8442 12.4244Z"
            fill="#7C8598"
          />
          <path
            vectorEffect="non-scaling-stroke"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.39167 13.6083C2.65278 13.8694 2.96667 14 3.33333 14H6.87972C7.30471 14 7.5348 13.4353 7.30586 13.0773C6.88472 12.4187 6.66998 11.6646 6.66998 10.8443C6.66998 9.70551 7.0838 8.69447 7.8891 7.88916C8.62535 7.15291 9.53357 6.74389 10.5542 6.67916C10.9699 6.65279 11.3333 6.33252 11.3333 5.91599V3.33333C11.3333 2.96667 11.2028 2.65278 10.9417 2.39167C10.6806 2.13056 10.3667 2 10 2H6C5.63333 2 5.31944 2.13056 5.05833 2.39167C4.79722 2.65278 4.66667 2.96667 4.66667 3.33333V4.66667H3.33333C2.96667 4.66667 2.65278 4.79722 2.39167 5.05833C2.13056 5.31944 2 5.63333 2 6V12.6667C2 13.0333 2.13056 13.3472 2.39167 13.6083ZM4.66667 12.6667H3.33333V11.3333H4.66667V12.6667ZM4.66667 10H3.33333V8.66667H4.66667V10ZM4.66667 7.33333H3.33333V6H4.66667V7.33333ZM7.33333 7.33333H6V6H7.33333V7.33333ZM7.33333 4.66667H6V3.33333H7.33333V4.66667ZM10 4.66667H8.66667V3.33333H10V4.66667Z"
            fill="#7C8598"
          />
        </g>
      </g>
    </svg>
  );
};

export default CompanySavedSearchIcon;
