import { useQuery } from '@apollo/client';
import {
  EmployeeGroupType,
  GetCompaniesAffinityListsByIdQuery,
  GetCompaniesAffinityListsByIdQueryVariables,
  GetCompaniesAllEntityActionsQuery,
  GetCompaniesAllEntityActionsQueryVariables,
  GetCompanyContactsV2Query,
  GetCompanyContactsV2QueryVariables,
  GetCompanyEmployeesQuery,
  GetCompanyEmployeesQueryVariables,
  GetCompanyFinancingV2Query,
  GetCompanyFinancingV2QueryVariables,
  GetCompanyFoundersQuery,
  GetCompanyFoundersQueryVariables,
  GetCompanyMetricsQuery,
  GetCompanyMetricsQueryVariables,
  GetCompanyProfileHeaderQuery,
  GetCompanyProfileHeaderQueryVariables,
  GetSimilarCompaniesV2Query,
  GetSimilarCompaniesV2QueryVariables,
  GetUsersInNetworkForCompanyQuery,
  GetUsersInNetworkForCompanyQueryVariables
} from '__generated__/graphql';
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import stealthLogo from 'assets/company-profile/stealth-logo.svg';
import { default as classnames } from 'classnames';
import CompanyConnections from 'components/Dashboard/Company/CompanyConnections/CompanyConnections';
import { getCompanyContacts } from 'components/Dashboard/Company/CompanyContact/CompanyContactEmailsPhone';
import CompanyFinancing from 'components/Dashboard/Company/CompanyFinancing/CompanyFinancing';
import CompanyHeader, {
  CompanyNavbarOptions,
  nonStealthNavbarOptions,
  stealthNavbarOptions
} from 'components/Dashboard/Company/CompanyHeader/CompanyHeader';
import CompanyLineage from 'components/Dashboard/Company/CompanyLineage/CompanyLineage';
import CompanyOverview from 'components/Dashboard/Company/CompanyOverview/CompanyOverview';
import CompanySocials from 'components/Dashboard/Company/CompanySocials/CompanySocials';
import {
  FETCH_FOUNDERS_CEO_COUNT,
  getCompanyFounders
} from 'components/Dashboard/Company/CompanyTeam/CompanyFoundersCeo';
import CompanyTeam from 'components/Dashboard/Company/CompanyTeam/CompanyTeam';
import { StickyNavBar } from 'components/Dashboard/Company/StickyNavBar';
import { GET_AFFINITY_LISTS_QUERY } from 'components/Dashboard/Grids/CompanyAffinityFieldsDrawer';
import useFlags from 'hooks/useFlags';
import { IDashboardParams } from 'interfaces/Dashboard';
import { InitialScrollToSection } from 'interfaces/DataModel/Company';
import { isNil } from 'lodash';
import { getCompaniesAllEntityActions } from 'queries/getCompaniesAllEntityActions';
import { getCompanyEmployees } from 'queries/getCompanyEmployees';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import getUsersInNetworkForCompany from 'queries/getUsersInNetworkForCompany';
import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import useStore from 'stores/zustandStore';
import analytics, { CustomTrackEvent, EventLocation } from 'utils/analytics';
import {
  COMPANY_ROUTE,
  COMPANY_TAB_INITIAL_SCROLL_TO_PARAM_KEY,
  DASHBOARD_ROUTE,
  DEFAULT_DASHBOARD_ROUTE,
  EMPLOYEE_LIST_PAGE_SIZE,
  PageTitle,
  SPLITS,
  TAB_QUERY_PARAM_KEY
} from 'utils/constants';
import { useDocumentTitle } from 'utils/hooks';
import { isSafeHostedAsset } from 'utils/utilities';
import SimilarCompanies, {
  getSimilarCompanies
} from './SimilarCompanies/SimilarCompanies';

const companyTabQueryKey = TAB_QUERY_PARAM_KEY;
const companyTabInitialScrollToKey = COMPANY_TAB_INITIAL_SCROLL_TO_PARAM_KEY;

export const MAX_WIDTH_CLASS = 'px-p70';

export type OnTabChangeType = (
  newTabOption: CompanyNavbarOptions,
  initialScrollTo?: InitialScrollToSection
) => void;

const Company = () => {
  const { enabled: enableCrmIntegration } = useFlags(
    SPLITS.enableCrmIntegration
  );

  const { enabled: enableCompanyLineageTab } = useFlags(
    SPLITS.showLineageOnCompanyPage
  );

  const params = useParams<IDashboardParams>();
  const isMobileDevice = useStore((state) => state.isMobileDevice);
  const companyId = parseInt(params.id ?? '');
  const [isStickyNavbar, setIsStickyNavbar] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyTabQueryParam = searchParams
    .get(companyTabQueryKey)
    ?.toUpperCase();

  const initialSelectedTab =
    companyTabQueryParam &&
    CompanyNavbarOptions[
      companyTabQueryParam.toUpperCase() as CompanyNavbarOptions
    ];

  const [selectedNavTab, setSelectedNavTab] = useState<CompanyNavbarOptions>(
    initialSelectedTab ? initialSelectedTab : CompanyNavbarOptions.OVERVIEW
  );
  const HEADER_HEIGHT_PX = 330;
  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  // This is the only query that will block the page from rendering
  const {
    loading: headerContentLoading,
    data: companyProfileData,
    error: companyProfileDataError
  } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    }
  });

  const companyName = companyProfileData?.getCompanyById?.name;
  const fetchedCompanyId = companyProfileData?.getCompanyById?.id;
  const isStealthCompany = companyName?.startsWith('Stealth Company');

  let filteredNavbarOptions = isStealthCompany
    ? stealthNavbarOptions
    : nonStealthNavbarOptions;

  if (!enableCompanyLineageTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.LINEAGE
    );
  }

  const logo = companyProfileData?.getCompanyById?.logoUrl;
  const companyLogoUrl = isStealthCompany
    ? stealthLogo
    : isSafeHostedAsset(logo ?? '')
    ? logo
    : defaultCompanyLogo;

  useDocumentTitle(
    companyName
      ? `${companyName} | ${PageTitle.COMPANY_PAGE_TITLE}`
      : PageTitle.COMPANY_PAGE_TITLE
  );

  useEffect(() => {
    if (!searchParams.get(companyTabQueryKey)) {
      setSearchParams(
        {
          [companyTabQueryKey]: selectedNavTab
        },
        { replace: true }
      );
    }
  }, [selectedNavTab]);

  useEffect(() => {
    const stealthNavValues = stealthNavbarOptions.map((st) => st.value);
    if (isStealthCompany && !stealthNavValues.includes(selectedNavTab)) {
      const newTabValue = CompanyNavbarOptions.OVERVIEW;
      setSelectedNavTab(newTabValue);
      setSearchParams({ [companyTabQueryKey]: newTabValue }, { replace: true });
    }
  }, [isStealthCompany, selectedNavTab]);

  useEffect(() => {
    // If the page was loaded from another harmonic application and
    // we have some utm content, use it to track a custom event for
    // the profile click.
    const utmSource = searchParams.get('utm_source');
    const utmContent = searchParams.get('utm_content');
    if (utmSource === 'harmonic' && utmContent) {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_PROFILE_CLICK,
        properties: {
          openedFromLocation: utmContent as EventLocation,
          companyId: companyId
        }
      });

      // Remove the utm params so we do not attribute the click when
      // a user shares the link via copy/paste.
      searchParams.delete('utm_source');
      searchParams.delete('utm_content');
      setSearchParams(searchParams, { replace: true });
    }
  }, [companyId]);

  const stickNavbar = () => {
    if (isMobileDevice) return;
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      setIsStickyNavbar(windowHeight > HEADER_HEIGHT_PX);
    }
  };

  const onTabChange: OnTabChangeType = (newTabOption, initialScrollTo) => {
    const newSearchParams: Record<string, string> = {
      [companyTabQueryKey]: newTabOption
    };
    if (initialScrollTo) {
      newSearchParams[companyTabInitialScrollToKey] = initialScrollTo;
    }
    analytics.trackCustomEvent({
      event: CustomTrackEvent.COMPANY_PROFILE_TAB_CHANGE,
      properties: {
        companyId: companyId,
        tabName: newTabOption
      }
    });
    setSearchParams(newSearchParams, { replace: true });
    setSelectedNavTab(newTabOption);
    window.scrollTo(0, 0);
  };

  // Prefetch company financing
  useQuery<GetCompanyFinancingV2Query, GetCompanyFinancingV2QueryVariables>(
    getCompanyFinancing,
    {
      variables: {
        id: companyId
      }
    }
  );

  // Prefetch company actions
  useQuery<
    GetCompaniesAllEntityActionsQuery,
    GetCompaniesAllEntityActionsQueryVariables
  >(getCompaniesAllEntityActions, {
    variables: { ids: [companyId], extended: false }
  });

  // Prefetch company affinity lists
  useQuery<
    GetCompaniesAffinityListsByIdQuery,
    GetCompaniesAffinityListsByIdQueryVariables
  >(GET_AFFINITY_LISTS_QUERY, {
    variables: {
      id: companyId
    },
    skip: !enableCrmIntegration
  });

  // Prefetch employees
  useQuery<GetCompanyEmployeesQuery, GetCompanyEmployeesQueryVariables>(
    getCompanyEmployees,
    {
      variables: {
        companyUrn: `urn:harmonic:company:${companyId}`,
        page: 0,
        size: EMPLOYEE_LIST_PAGE_SIZE,
        employeeGroupType: EmployeeGroupType.NON_LEADERSHIP,
        userConnectionStatus: null
      }
    }
  );

  // Prefetch executives
  useQuery<GetCompanyEmployeesQuery, GetCompanyEmployeesQueryVariables>(
    getCompanyEmployees,
    {
      variables: {
        companyUrn: `urn:harmonic:company:${companyId}`,
        page: 0,
        size: EMPLOYEE_LIST_PAGE_SIZE,
        employeeGroupType: EmployeeGroupType.EXECUTIVES,
        userConnectionStatus: null
      }
    }
  );

  // Prefetch metrics
  useQuery<GetCompanyMetricsQuery, GetCompanyMetricsQueryVariables>(
    getCompanyMetrics,
    {
      variables: {
        id: companyId
      }
    }
  );

  // Prefetch similar companies
  useQuery<GetSimilarCompaniesV2Query, GetSimilarCompaniesV2QueryVariables>(
    getSimilarCompanies,
    {
      variables: {
        urn: `urn:harmonic:company:${companyId}`,
        size: 10
      }
    }
  );

  // Prefetch founders and CEO
  useQuery<GetCompanyFoundersQuery, GetCompanyFoundersQueryVariables>(
    getCompanyFounders,
    {
      variables: {
        companyUrn: `urn:harmonic:company:${companyId}`,
        page: 0,
        size: FETCH_FOUNDERS_CEO_COUNT,
        employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
      }
    }
  );

  // Prefetch contacts
  useQuery<GetCompanyContactsV2Query, GetCompanyContactsV2QueryVariables>(
    getCompanyContacts,
    {
      variables: { id: companyId }
    }
  );

  // Prefetch user connections
  useQuery<
    GetUsersInNetworkForCompanyQuery,
    GetUsersInNetworkForCompanyQueryVariables
  >(getUsersInNetworkForCompany, {
    variables: { id: companyId }
  });

  // Prefetch connections page
  // TODO: Reinstate prefetch once we move to more performant query. See: #gql-socket-hangup
  // useQuery<
  //   GetCompanyUserConnectionsWithOverviewAndEventsQuery,
  //   GetCompanyUserConnectionsWithOverviewAndEventsQueryVariables
  // >(getCompanyUserConnectionsWithOverviewAndEvents, {
  //   variables: { id: companyId },
  //   skip: !enableCompanyEngagementTab
  // });

  if (
    !companyId ||
    companyId < 1 ||
    (companyProfileDataError && isNil(companyProfileData))
  )
    return <Navigate to={DEFAULT_DASHBOARD_ROUTE} />;

  // We block the page until the header can be rendered
  if (headerContentLoading) {
    return (
      <div
        data-testid="CompanyProfile-Loader"
        className="w-full h-full flex justify-center items-center"
      >
        <PuffLoader />
      </div>
    );
  }

  // If the company returned by API differs (i.e. in the case of a merge), redirect to the actual company page
  if (!isNil(fetchedCompanyId) && fetchedCompanyId !== companyId) {
    return (
      <Navigate to={DASHBOARD_ROUTE + COMPANY_ROUTE + '/' + fetchedCompanyId} />
    );
  }

  return (
    <div
      data-testid="CompanyProfile"
      className={classnames(
        'bg-white min-h-screen w-full max-w-[100vw] relative'
      )}
    >
      {/* Sticky navbar */}
      {isStickyNavbar && (
        <div className="hidden lg:block sticky z-100 top-0 bg-white">
          {/* 53px because that's the height of the top nav bar */}
          <StickyNavBar
            companyLogoUrl={companyLogoUrl}
            companyId={companyId}
            companyName={companyName}
            selectedTab={selectedNavTab}
            filteredNavbarOptions={filteredNavbarOptions}
            onTabChange={onTabChange}
          />
        </div>
      )}
      <CompanyHeader
        companyId={companyId}
        isStealthCompany={isStealthCompany}
        isSticky={isStickyNavbar}
        selectedTab={selectedNavTab}
        onTabChange={onTabChange}
        filteredNavbarOptions={filteredNavbarOptions}
      />
      <div className="pb-32 max-w-[2000px] pt-p50 px-[24px] lg:pt-p100 lg:px-[96px]">
        {selectedNavTab === CompanyNavbarOptions.OVERVIEW && (
          <CompanyOverview
            isStealthCompany={isStealthCompany}
            onTabChange={onTabChange}
            companyId={companyId}
          />
        )}
        {selectedNavTab === CompanyNavbarOptions.TEAM && (
          <CompanyTeam companyId={companyId} />
        )}
        {selectedNavTab === CompanyNavbarOptions.FINANCING && (
          <CompanyFinancing companyId={companyId} />
        )}
        {selectedNavTab === CompanyNavbarOptions.TRACTION_METRICS && (
          <CompanySocials companyId={companyId} />
        )}
        {selectedNavTab === CompanyNavbarOptions.SIMILAR_COMPANIES && (
          <SimilarCompanies companyId={companyId} />
        )}
        {selectedNavTab === CompanyNavbarOptions.CONNECTIONS && (
          <CompanyConnections companyId={companyId} />
        )}
        {selectedNavTab === CompanyNavbarOptions.LINEAGE && (
          <CompanyLineage companyId={companyId} />
        )}
      </div>
    </div>
  );
};

export default Company;
