import { BusinessIcon, TeamIcon } from 'assets/harmonic-icons';
import { Badge, Tooltip } from 'common/components';
import fuzzysort from 'fuzzysort';
import ListGroup from 'harmonic-components/ListGroup/ListGroup';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import Tabs, { IndividualTabProbs } from 'harmonic-components/Tabs/Tabs';
import useFlags from 'hooks/useFlags';
import ThreeDotIcon from 'icons/ThreeDotIcon';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import {
  EntityListType,
  FieldCategory,
  FieldCategoryLabel,
  FieldEntityType,
  ISearchFieldSpec
} from 'interfaces/SearchModel/Search';
import { capitalize, groupBy } from 'lodash';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { EntityFilterCategoryMap } from './EntityFilterCategoryTabs';
import PopularFilters from './PopularFilters';
import { SearchFilterType, getFieldsByCategory, getGroupTitle } from './utils';

interface SearchFilterDropdownProps {
  fields: ISearchFieldSpec[];
  onUpdateComparatorName: (newValue: string | null, urn: string | null) => void;
  selectedField: string; // Unique name of field(underscore form)
  entityType: SearchFilterType;
}

interface FilterFieldProps {
  fieldSpec: ISearchFieldSpec;
  onUpdateComparatorName: (newValue: string | null, urn: string | null) => void;
}

const FilterField: React.FC<FilterFieldProps> = ({
  fieldSpec,
  onUpdateComparatorName
}) => {
  const icon =
    fieldSpec?.entity_type === FieldEntityType.PERSON ? TeamIcon : BusinessIcon;

  let listItem = (
    <ListItem
      key={fieldSpec.urn}
      testId={`SearchFilterDropdown-ListItem-${fieldSpec?.urn}`}
      variant={ListVariant.default}
      value={fieldSpec.urn}
      onClick={() =>
        onUpdateComparatorName(fieldSpec.unique_name, fieldSpec.urn)
      }
      label={fieldSpec.display_name}
      primaryIcon={icon}
      secondaryIcon={
        fieldSpec.beta ? (
          <Badge
            label="Beta"
            size="small"
            color="neutral"
            intensity="highlight"
            borderRadius="square"
          />
        ) : undefined
      }
      showSecondaryIcon={() => true}
    />
  );

  if (fieldSpec.description) {
    listItem = (
      <Tooltip
        key={fieldSpec.unique_name}
        alignment="right"
        title={fieldSpec.description}
      >
        {listItem}
      </Tooltip>
    );
  }

  return listItem;
};

interface FilterFieldGroupProps {
  title: string;
  fields: ISearchFieldSpec[];
  onUpdateComparatorName: (newValue: string | null, urn: string | null) => void;
  defaultOpen?: boolean;
}

const FilterFieldGroup: React.FC<FilterFieldGroupProps> = ({
  title,
  fields,
  onUpdateComparatorName,
  defaultOpen = true
}) => {
  return (
    <ListGroup title={title} defaultOpen={defaultOpen}>
      {fields.map((field) => (
        <FilterField
          key={field.unique_name}
          fieldSpec={field}
          onUpdateComparatorName={onUpdateComparatorName}
        />
      ))}
    </ListGroup>
  );
};

const SearchFilterDropdown: React.FC<SearchFilterDropdownProps> = ({
  fields,
  selectedField,
  onUpdateComparatorName,
  entityType
}) => {
  let selectableFields = fields.filter(
    (field) =>
      field.display_name && !field.deprecated && field.entity_type !== undefined
  );

  const { enabled: correspondenceFieldsEnabled } = useFlags(
    SPLITS.showCorrespondencesInSearch
  );
  const { enabled: enableAddedByColumn } = useFlags(SPLITS.enableAddedByColumn);
  const { enabled: enableCustomColumns } = useFlags(SPLITS.enableCustomColumns);
  if (!correspondenceFieldsEnabled) {
    selectableFields = selectableFields.filter(
      (field) => !field.unique_name.includes('customers_connections_last_')
    );
  }
  if (!enableAddedByColumn) {
    selectableFields = selectableFields.filter(
      (field) => !field.unique_name.includes('entry_added_by')
    );
  }

  const resourceType: ApiResourceType =
    entityType === EntityListType.PEOPLE_SAVED_SEARCH ||
    entityType === EntityListType.PEOPLE_WATCHLIST
      ? ApiResourceType.PeopleList
      : ApiResourceType.CompaniesList;
  let defaultCategory =
    resourceType === ApiResourceType.PeopleList
      ? FieldCategory.PERSON
      : FieldCategory.COMPANY;
  const selectedFieldDetail =
    selectableFields?.filter((field) => field?.unique_name === selectedField) ??
    [];
  if (selectedFieldDetail.length === 1) {
    defaultCategory = selectedFieldDetail[0]?.category;
  }

  const [category, setCategory] = React.useState(defaultCategory);
  const [searchTerm, setSearchTerm] = React.useState('');
  const hasSearchTerm = React.useMemo(() => !!searchTerm.trim(), [searchTerm]);

  const getFieldSearchResults = () => {
    if (!selectableFields) {
      return [];
    }

    const searchTermFields: ISearchFieldSpec[] = selectableFields.filter(
      (field): field is ISearchFieldSpec =>
        !!fuzzysort.single(searchTerm, field.display_name)?.target
    );
    return searchTermFields;
  };

  const fieldsByCategory = getFieldsByCategory(selectableFields, resourceType);
  const categoryFields = fieldsByCategory[category] ?? [];
  const fieldsByGrouping = groupBy(
    selectableFields.filter(
      (field) => field.grouping && field.category === category
    ),
    (field) => field.grouping
  );
  const fieldsSearchResult = hasSearchTerm ? getFieldSearchResults() : [];

  let filterCategoryMap = EntityFilterCategoryMap[entityType] || [];
  if (
    enableCustomColumns &&
    (entityType === EntityListType.PEOPLE_WATCHLIST ||
      entityType === EntityListType.COMPANY_WATCHLIST)
  ) {
    filterCategoryMap = [
      ...filterCategoryMap,
      {
        category: FieldCategory.CUSTOM,
        icon: <ThreeDotIcon />
      }
    ];
  }

  const tabs: IndividualTabProbs[] = filterCategoryMap.map((filterTab) => ({
    label:
      filterTab.category === FieldCategory.PERSON &&
      resourceType === ApiResourceType.PeopleList
        ? 'Person'
        : FieldCategoryLabel[filterTab.category],
    value: filterTab.category,
    variation: 'label'
  }));
  return (
    <div
      data-testid="SearchFilterDropdown"
      className="flex flex-col flex-1 max-w-[512px] min-h-[530px] max-h-[530px]"
    >
      <div className="mx-p50 mt-p50 mb-p20">
        <SearchInput
          value={searchTerm}
          onChange={(newValue) => setSearchTerm(newValue)}
          placeholder="Search for a filter"
          fullWidth
        />
      </div>

      <Tabs
        tabs={tabs}
        onTabChange={(category: string) => {
          setSearchTerm('');
          setCategory(category as FieldCategory);
        }}
        selectedTabValue={category}
      />
      <div className="flex flex-col min-w-0 overflow-y-auto flex-1 min-h-0 p-p20">
        {!hasSearchTerm && (
          <ListGroup title="Popular filters">
            <div className="flex flex-row flex-wrap min-w-0">
              <PopularFilters
                fields={selectableFields}
                onUpdateComparatorName={onUpdateComparatorName}
              />
            </div>
          </ListGroup>
        )}
        {hasSearchTerm && (
          <FilterFieldGroup
            fields={fieldsSearchResult}
            onUpdateComparatorName={onUpdateComparatorName}
            title={'Search results'}
          />
        )}

        {!hasSearchTerm && categoryFields.length > 0 && (
          <FilterFieldGroup
            fields={categoryFields}
            onUpdateComparatorName={onUpdateComparatorName}
            title={FieldCategoryLabel[category]}
          />
        )}

        {!hasSearchTerm &&
          Object.entries(fieldsByGrouping).map(([grouping, groupingFields]) => {
            const title = capitalize(getGroupTitle(grouping));
            return (
              <FilterFieldGroup
                key={grouping}
                fields={groupingFields}
                onUpdateComparatorName={onUpdateComparatorName}
                title={title}
                defaultOpen={false}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SearchFilterDropdown;
