import { useQuery } from '@apollo/client';
import {
  GetCompanyProfileHeaderQuery,
  GetCompanyProfileHeaderQueryVariables
} from '__generated__/graphql';
import classNames from 'classnames';
import { Badge } from 'common/components';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { useState } from 'react';
import { truncateString } from 'utils/utilities';

interface CompanyAboutProps {
  companyId: number;
}

const DESCRIPTION_MAX_LENGTH = 300;

const CompanyAbout: React.FC<CompanyAboutProps> = ({ companyId }) => {
  const [showAllDescription, setShowAllDescription] = useState(false);
  const { data } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only'
  });

  const companyData = data?.getCompanyById;
  const description = companyData?.description ?? '';
  const companyTags =
    companyData?.tagsV2?.map((tag) => tag?.displayValue) ?? [];
  const isDescriptionOverLimit = !!(
    description.length > DESCRIPTION_MAX_LENGTH
  );

  if (!description && !companyTags?.length) return null;

  return (
    <div>
      <p
        className={classNames(
          'text-content-default typography-paragraph whitespace-pre-line'
        )}
      >
        {showAllDescription
          ? description
          : truncateString(description, DESCRIPTION_MAX_LENGTH)}
        {isDescriptionOverLimit && (
          <button
            className={classNames(
              'ml-2 typography-paragraph-default-link',
              'text-content-strong'
            )}
            data-testid="CompanyAbout-Expand-Description"
            onClick={() => setShowAllDescription(!showAllDescription)}
          >
            {showAllDescription ? 'see less' : 'see more'}
          </button>
        )}
      </p>

      <div className="mt-3 text-sm flex items-center gap-g40 flex-wrap">
        {companyTags?.map((tag, index) => (
          <Badge
            key={tag}
            label={tag as string}
            color="neutral"
            size="large"
            intensity="subtle"
          />
        ))}
      </div>
    </div>
  );
};

export default CompanyAbout;
