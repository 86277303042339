import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React from 'react';

const EdtechIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66602 13.1926L11.6403 16.2526C12.2843 16.5825 13.0477 16.5825 13.6917 16.2526L19.666 13.1926V16.4056C19.666 17.0467 19.3155 17.6365 18.7523 17.9429L13.5023 20.7989C12.9809 21.0825 12.3512 21.0825 11.8297 20.7989L6.57975 17.9429C6.01658 17.6365 5.66602 17.0467 5.66602 16.4056V13.1926Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12.3241 14.9175C12.5388 15.0275 12.7932 15.0275 13.0079 14.9175L22.166 10.2268V15.25C22.166 15.6642 22.5018 16 22.916 16C23.3302 16 23.666 15.6642 23.666 15.25V9C23.666 8.71854 23.5084 8.46078 23.2579 8.33247L13.0079 3.08247C12.7932 2.97251 12.5388 2.97251 12.3241 3.08247L2.07411 8.33247C1.8236 8.46078 1.66602 8.71854 1.66602 9C1.66602 9.28146 1.8236 9.53922 2.07411 9.66753L12.3241 14.9175Z"
        fill="current"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default EdtechIcon;
