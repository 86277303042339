import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import {
  FilterArrayComparator,
  FilterComparator,
  FilterDisplayFormatType,
  FilterListComparator,
  FilterNumberComparator,
  FilterStringComparator
} from 'interfaces/SearchModel/Search';
import get from 'lodash/get';
import {
  ComparatorToLabelMap,
  YearComparatorToLabelMap
} from 'utils/constants';

const ComparatorInput = (props: {
  field: string;
  comparator: FilterComparator;
  displayFormatType?: FilterDisplayFormatType;
  allowedComparators: FilterComparator[];
  onUpdateComparatorType: (value: string) => void;
  disabled?: boolean;
}) => {
  const allowsAnyOf =
    props.allowedComparators.includes(FilterArrayComparator.ANY_OF) ||
    props.allowedComparators.includes(FilterArrayComparator.ANY_OF_ARRAY);

  const options: { label: string; value: string }[] = [];
  let allowedComparators = allowsAnyOf
    ? props.allowedComparators.filter(
        (comparator) => comparator !== FilterStringComparator.EXACT_MATCH
      )
    : props.allowedComparators;

  // Some bug with list filter. Fixing them. Revert when fixed
  if (props.field === 'person_last_company_name') {
    allowedComparators = props.allowedComparators.filter(
      (comparator) => comparator !== FilterListComparator.IS_IN_LIST
    );
  }

  if (props.displayFormatType === FilterDisplayFormatType.YEAR) {
    allowedComparators.forEach((comparator) => {
      const comparatorDisplayName = get(
        YearComparatorToLabelMap,
        comparator as FilterNumberComparator
      );
      options.push({
        label: comparatorDisplayName,
        value: comparator
      });
    });
  } else {
    allowedComparators.forEach((comparator) => {
      const comparatorDisplayName = get(ComparatorToLabelMap, comparator);
      options.push({
        label: comparatorDisplayName,
        value: comparator
      });
    });
  }

  return (
    <Select
      multiple={false}
      selected={props.comparator}
      getLabelFromValue={() =>
        options.find((option) => option.value === props.comparator)?.label ??
        props.comparator
      }
      dataTestId="Comparator-Select-Input"
      dropdownWidth={160}
    >
      {options.map((option) => (
        <ListItem
          key={option.value}
          variant={ListVariant.default}
          label={option.label}
          value={option.value}
          onClick={() => props.onUpdateComparatorType(option.value)}
        />
      ))}
    </Select>
  );
};

export default ComparatorInput;
