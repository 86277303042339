import Button, { ButtonProps } from 'harmonic-components/Button/Button';
import { useState } from 'react';
import { useOutsideClick } from 'utils/hooks';

interface ButtonWrapper extends Omit<ButtonProps, 'onClick'> {
  skipToChildren: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const ButtonWrapper: React.FC<ButtonWrapper> = ({
  skipToChildren,
  children,
  onClick,
  ...props
}) => {
  const [showChildren, setShowChildren] = useState(false);
  const outsideClickRef = useOutsideClick(() => setShowChildren(false));

  const childrenWrapper = <div ref={outsideClickRef}>{children}</div>;
  if (skipToChildren || showChildren) return childrenWrapper;
  return (
    <Button
      onClick={() => {
        onClick?.();
        setShowChildren(!showChildren);
      }}
      {...props}
    />
  );
};

export default ButtonWrapper;
