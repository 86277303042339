import { useQuery } from '@apollo/client';
import { GetPeopleWatchlistDigestConfigQuery } from '__generated__/graphql';
import { GET_PEOPLE_WATCHLIST_DIGEST_CONFIG } from 'queries/getPeopleWatchlistDigestConfig';
import { useMemo } from 'react';

const usePeopleWatchlistEnabledDigests = () => {
  const { data: getPeopleWatchlistDigestConfig } =
    useQuery<GetPeopleWatchlistDigestConfigQuery>(
      GET_PEOPLE_WATCHLIST_DIGEST_CONFIG
    );

  const enabledPeopleWatchlistDigests = useMemo(() => {
    const digests = new Set<string>();
    const config =
      getPeopleWatchlistDigestConfig?.getPeopleWatchlistDigestConfig;

    if (!config || !config.peopleWatchlists) return digests;

    config.peopleWatchlists.forEach((wl) => {
      digests.add(wl);
    });

    return digests;
  }, [getPeopleWatchlistDigestConfig]);

  const hasEnabledPeopleWatchlistDigest = (watchlistUrn?: string): boolean => {
    if (!watchlistUrn) return false;
    return enabledPeopleWatchlistDigests.has(watchlistUrn);
  };

  return { hasEnabledPeopleWatchlistDigest };
};

export default usePeopleWatchlistEnabledDigests;
