import { useQuery } from '@apollo/client';
import {
  GetPersonExperienceAndHighlightsForConnectionsCardQuery,
  GetPersonExperienceAndHighlightsForConnectionsCardQueryVariables,
  ParticipantResponseStatus,
  UserConnection,
  UserConnectionFragment
} from '__generated__/graphql';
import { ReactComponent as AnonymousPersonLogo } from 'assets/anonymous-person-logo.svg';
import defaultPersonLogo from 'assets/default-person-logo.svg';
import { LinkedInIcon } from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import { ReactComponent as PathInIcon } from 'common/assets/network/path-in.svg';
import { formatConnectionStrings } from 'common/components/Network/util';
import { UserConnectionInNetwork } from 'common/types/network';
import AddPeopleToWatchlist from 'components/common/AddPeopleToWatchlist';
import ContactButton from 'components/common/ContactData/ContactButton';
import GetPersonEmailButton from 'components/common/ContactData/GetEmailButton';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import InternalLink from 'components/common/InternalLink';
import PersonUserConnections from 'components/common/PersonUserConnections';
import { dayjsExt } from 'config/dayjs';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useAppState } from 'hooks/useAppState';
import useFlags from 'hooks/useFlags';
import { compact, first, isNil, trim } from 'lodash';
import { getPersonExperienceAndHighlightsForConnectionsCard } from 'queries/getCompanyUserConnections';
import { SPLITS } from 'utils/constants';
import {
  PersonWithConnections,
  getDisplayNameFromParticipantList,
  getParticipantResponseStatusMap
} from 'utils/userConnections';
import { isSafeHostedAsset } from 'utils/utilities';
import CalendarEventCard from './CalendarEventCard';
import EmailMessageCard from './EmailMessageCard';
import { EventParticipant } from './SingleParticipant';

interface PersonConnectionCardIndividualConnectionDetailProps {
  latestEmail?: UserConnectionFragment['latestEmail'] | null;
  latestCalendarEvent?: UserConnectionFragment['latestCalendarEvent'] | null;
  emailCombinedParticipants: EventParticipant[];
  calendarEventParticipants: EventParticipant[];
}

const ConnectionCorrespondenceDetail = ({
  latestEmail,
  latestCalendarEvent,
  emailCombinedParticipants,
  calendarEventParticipants
}: PersonConnectionCardIndividualConnectionDetailProps) => {
  const calendarParticipantResponseStatusMap = getParticipantResponseStatusMap(
    (latestCalendarEvent?.participantResponseStatuses as ParticipantResponseStatus[]) ??
      []
  );

  return (
    <div className="flex flex-col gap-g40">
      {latestEmail && (
        <EmailMessageCard
          sender={latestEmail?.sender?.emailAddress.name ?? ''}
          participants={emailCombinedParticipants}
          name={latestEmail?.title || ''}
          startTime={latestEmail?.timestamp}
        />
      )}
      {latestCalendarEvent && (
        <div className="">
          <CalendarEventCard
            participants={calendarEventParticipants}
            name={latestCalendarEvent?.name || ''}
            startTime={latestCalendarEvent?.startTime}
            participantsResponseStatusMap={calendarParticipantResponseStatusMap}
          />
        </div>
      )}
    </div>
  );
};

export const UserConnectionDetail = ({
  userConnection
}: {
  userConnection: UserConnection;
}) => {
  const currentUserEmail = useAppState<string>((state) => {
    return state.auth.user?.email ?? '';
  });
  const connectionString = first(
    formatConnectionStrings(
      [userConnection as UserConnectionInNetwork],
      currentUserEmail,
      userConnection.targetPerson?.fullName ?? ''
    )?.detailedConnections
  );
  const emailCombinedParticipants =
    userConnection.latestEmail?.receivers?.map((participant) => ({
      name: participant?.emailAddress.name ?? '',
      emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];
  const calendarEventParticipants =
    userConnection.latestCalendarEvent?.participants?.map((participant) => ({
      name: participant?.emailAddress.name ?? '',
      emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];
  return (
    <div className="ml-p100">
      <div className="flex items-start gap-2">
        <div className="w-4 h-4 pt-p10">
          <PathInIcon data-testid="path-in-icon" />
        </div>
        <p className="typography-label-strong text-content-default">
          {connectionString}
        </p>
      </div>
      {(userConnection.latestEmail || userConnection.latestCalendarEvent) && (
        <div className="w-full mt-g40 ml-p80 pr-p80">
          <ConnectionCorrespondenceDetail
            latestEmail={userConnection.latestEmail}
            latestCalendarEvent={userConnection.latestCalendarEvent}
            emailCombinedParticipants={emailCombinedParticipants}
            calendarEventParticipants={calendarEventParticipants}
          />
        </div>
      )}
    </div>
  );
};

interface PersonConnectionCardProps {
  personWithConnections: PersonWithConnections;
  currentCompanyId: number;
}

const PersonConnectionCard: React.FC<PersonConnectionCardProps> = ({
  personWithConnections,
  currentCompanyId
}) => {
  const person = personWithConnections.person;

  const { data: personExperienceAndHighlightsData } = useQuery<
    GetPersonExperienceAndHighlightsForConnectionsCardQuery,
    GetPersonExperienceAndHighlightsForConnectionsCardQueryVariables
  >(getPersonExperienceAndHighlightsForConnectionsCard, {
    variables: {
      id: person?.id ?? -1
    },
    skip: !person?.id
  });

  const { enabled: useNetworkMappingNewTooltips } = useFlags(
    SPLITS.networkMappingNewTooltips
  );

  const personExperienceAndHighlights =
    personExperienceAndHighlightsData?.getPersonById;
  const personEmail = personWithConnections.personEmail;
  const userConnections = personWithConnections.userConnections;
  const userConnectionsWithFullMetadata =
    personWithConnections.userConnectionsWithFullMetadata;
  const current = personExperienceAndHighlights?.experience?.find((exp) => {
    return exp?.company.id === currentCompanyId && exp?.isCurrentPosition;
  });
  const relevantExperience = personExperienceAndHighlights?.experience?.find(
    (exp) => {
      return exp?.company.id === currentCompanyId;
    }
  );
  const relevantTitle = current?.title ?? relevantExperience?.title;
  const relevantStartDate =
    current?.startDate || relevantExperience?.startDate
      ? dayjsExt(current?.startDate ?? relevantExperience?.startDate).format(
          'MMM YYYY'
        )
      : undefined;
  const relevantEndDate = current
    ? 'Current'
    : relevantExperience?.endDate
    ? dayjsExt(relevantExperience?.endDate).format('MMM YYYY')
    : undefined;

  const latestCalendarEvent = personWithConnections?.latestCalendarEvent;
  const latestEmail = personWithConnections?.latestEmail;

  const calendarEventParticipants =
    latestCalendarEvent?.participants?.map((participant) => ({
      name: participant?.emailAddress.name ?? '',
      emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];

  const latestEmailReceivers =
    latestEmail?.receivers?.map((participant) => ({
      name: participant?.emailAddress.name ?? '',
      emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];

  const emailCombinedParticipants = latestEmailReceivers;
  emailCombinedParticipants.unshift({
    name: latestEmail?.sender?.emailAddress.name ?? '',
    emailAddress: latestEmail?.sender?.emailAddress.emailAddress ?? ''
  });

  const personLogo = isSafeHostedAsset(person?.profilePictureUrl ?? '')
    ? person?.profilePictureUrl
    : defaultPersonLogo;

  const personHighlights =
    compact(
      personExperienceAndHighlights?.highlights?.map(
        (highlight) => highlight?.category
      )
    ) ?? [];

  let targetConnectionName =
    person?.fullName ??
    getDisplayNameFromParticipantList(
      personEmail ?? '',
      emailCombinedParticipants.concat(calendarEventParticipants)
    );
  targetConnectionName = trim(targetConnectionName, '"');

  return (
    <div
      data-testid="UserConnectionsList-PersonWithConnectionsCard"
      className={classNames(
        'flex flex-col',
        'p-p10 rounded-br40 bg-surface-background'
      )}
    >
      {!useNetworkMappingNewTooltips && (
        <div className="px-p30 py-p20">
          <PersonUserConnections
            target={{ name: targetConnectionName }}
            userConnections={userConnections}
            emphasizedStyle={true}
            showIcon={false}
          />
        </div>
      )}
      <div
        className={classNames(
          'flex flex-col gap-2',
          'p-p50',
          'rounded-br40 bg-surface-default border border-border border-solid',
          'h-full'
        )}
      >
        <div className="flex-1">
          <div className="flex flex-col gap-g50">
            {person ? (
              <div className="flex flex-col gap-g10">
                <div className="flex justify-between">
                  <div className="flex gap-g40">
                    <AvatarWithHighlightBadge
                      href={`/dashboard/person/${person.id}`}
                      src={personLogo as string}
                      highlights={personHighlights}
                      personId={person.id}
                      size="x-large"
                    />

                    <div>
                      <div className="flex flex-row items-center gap-g20">
                        <InternalLink
                          to={{ pathname: `/dashboard/person/${person.id}` }}
                        >
                          <p className="text-content-strong typography-label-default-strong line-clamp-1">
                            {targetConnectionName}
                          </p>
                        </InternalLink>

                        {person.socials?.linkedin?.url && (
                          <IconButton
                            icon={(props: IconProps) => (
                              <LinkedInIcon
                                {...props}
                                applyCurrentColor={false}
                              />
                            )}
                            type="secondary"
                            emphasis="low"
                            size="tiny"
                            onClick={() => {
                              person.socials?.linkedin?.url &&
                                window.open(
                                  person.socials?.linkedin?.url,
                                  '_blank'
                                );
                            }}
                          />
                        )}
                      </div>
                      <div className="line-clamp-1 text-content-weak typography-label-default-default">
                        {!current && 'was'} {relevantTitle}
                      </div>
                      <div>
                        <p className="line-clamp-1 text-content-weak typography-label-default-default">
                          {relevantStartDate && relevantStartDate} -{' '}
                          {relevantEndDate && relevantEndDate}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-g20 max-h-[28px]">
                    <AddPeopleToWatchlist type="icon" personIds={[person.id]} />
                    {personEmail ? (
                      <ContactButton
                        name={targetConnectionName}
                        emailAddress={personEmail}
                      />
                    ) : (
                      <GetPersonEmailButton
                        personId={person.id}
                        size="compact"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-g10">
                <div className="flex gap-g40">
                  <AnonymousPersonLogo />

                  <div className="flex justify-between w-full">
                    <div>
                      <p className="text-content-strong typography-label-default-strong line-clamp-1">
                        {targetConnectionName}
                      </p>
                      <p className="typography-label-default-default text-content-weak">
                        Imported from emails
                      </p>
                    </div>
                    <div className="flex gap-g20 max-h-[28px]">
                      {personEmail && (
                        <ContactButton
                          name={targetConnectionName}
                          emailAddress={personEmail}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userConnectionsWithFullMetadata
              .sort(
                (a, b) =>
                  (isNil(b.latestCalendarEvent) ? 0 : 1) -
                  (isNil(a.latestCalendarEvent) ? 0 : 1) +
                  (isNil(b.latestEmail) ? 0 : 1) -
                  (isNil(a.latestEmail) ? 0 : 1)
              )
              .map((userConnection) => (
                <UserConnectionDetail
                  key={
                    userConnection.userUrn + userConnection.connectionTargetUrn
                  }
                  userConnection={userConnection}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonConnectionCard;
