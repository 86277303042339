import { getIdFromUrn } from 'common/utils/urn';
import dayjs from 'dayjs';
import { max } from 'lodash';
import { PersonWithConnections } from 'utils/userConnections';

const INTEGER_STRING_PATTERN = /^-?\d+$/;

export const shouldLinkCompanyId = (
  companyId: number | string | undefined | null
): boolean => {
  let id: number | null = null;
  if (typeof companyId === 'string') {
    const idFromUrn = getIdFromUrn(companyId) ?? companyId;
    const match = INTEGER_STRING_PATTERN.exec(idFromUrn.trim());
    if (match) {
      id = Number(match[0]);
    }
  } else if (typeof companyId === 'number') {
    id = companyId;
  }
  return id !== null && id >= 1;
};

// Sort by whether person works there first and then date connected in desc order
export const sortByConnectionRecencyAndCurrentlyWorkingAtCompany = (
  a: PersonWithConnections,
  b: PersonWithConnections,
  companyId: number
) => {
  const personAWorksHere = a.person?.experience?.find((exp) => {
    return exp?.isCurrentPosition && exp.company.id === companyId;
  });

  const personBWorksHere = b.person?.experience?.find((exp) => {
    return exp?.isCurrentPosition && exp.company.id === companyId;
  });

  const aDate =
    max([
      dayjs(a?.latestCalendarEvent?.startTime ?? 0).unix(),
      dayjs(a?.latestEmail?.timestamp ?? 0).unix()
    ]) ?? 0;
  const bDate =
    max([
      dayjs(b?.latestCalendarEvent?.startTime ?? 0).unix(),
      dayjs(b?.latestEmail?.timestamp ?? 0).unix()
    ]) ?? 0;

  if (!a.person?.experience && !b.person?.experience) {
    // guards against projection changes if experience is not part of the request
    return bDate - aDate;
  } else if (!personAWorksHere && !personBWorksHere) {
    const personAEndDate = a.person?.experience?.find((exp) => {
      return exp?.company.id === companyId;
    })?.endDate;

    const personBEndDate = b.person?.experience?.find((exp) => {
      return exp?.company.id === companyId;
    })?.endDate;

    const personAEndDateUnix = dayjs(personAEndDate ?? 0).unix();
    const personBEndDateUnix = dayjs(personBEndDate ?? 0).unix();

    return personBEndDateUnix - personAEndDateUnix;
  } else if (personAWorksHere && !personBWorksHere) {
    return -1;
  } else if (!personAWorksHere && personBWorksHere) {
    return 1;
  }
  return bDate - aDate;
};
