import { gql, useQuery } from '@apollo/client';
import {
  GetCompaniesAllEntityActionsQuery,
  GetCompaniesAllEntityActionsQueryVariables,
  GetSimilarCompaniesV2Query,
  GetSimilarCompaniesV2QueryVariables,
  SimilarCompaniesFragment
} from '__generated__/graphql';
import { Badge } from 'common/components';
import { compact, uniq } from 'lodash';
import { getCompaniesAllEntityActions } from 'queries/getCompaniesAllEntityActions';
import React from 'react';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import SimilarCompaniesContentLoader from './SimilarCompaniesContentLoader';
import SimilarCompany from './SimilarCompany';

export const getSimilarCompanies = gql`
  fragment SimilarCompanies on Company {
    id
    logoUrl
    headcount
    tractionMetrics {
      headcount {
        ago90d {
          percentChange
          value
          change
        }
      }
    }
    name
    description
    funding {
      fundingTotal
      fundingStage
    }
    foundingDate {
      date
    }
    fundingAttributeNullStatus
    tagsV2 {
      displayValue
      type
      entityUrn
    }
    relevanceScore
  }
  query GetSimilarCompaniesV2($urn: String!, $size: Int!) {
    getSimilarCompanies(urn: $urn, size: $size) {
      edges {
        node {
          ...SimilarCompanies
        }
      }
      totalCount
    }
  }
`;

interface SimilarCompaniesProps {
  companyId: number;
}

const SimilarCompanies: React.FC<SimilarCompaniesProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetSimilarCompaniesV2Query,
    GetSimilarCompaniesV2QueryVariables
  >(getSimilarCompanies, {
    variables: {
      urn: `urn:harmonic:company:${companyId}`,
      size: 10
    },
    fetchPolicy: 'cache-only'
  });

  const handleOnQualificationClick = (
    similarCompanyId: number,
    isPositiveQualification: boolean
  ) => {
    analytics.trackCustomEvent({
      event: CustomTrackEvent.SIMILAR_COMPANIES_QUALIFICATION,
      properties: {
        companyId,
        similarCompanyId,
        qualification: isPositiveQualification
      }
    });
  };

  const similarCompanies = uniq(compact(data?.getSimilarCompanies?.edges)).map(
    (edge) => edge.node as SimilarCompaniesFragment
  );
  const similarCompaniesIds = similarCompanies.map((company) => company.id);

  // Prefetch company actions
  useQuery<
    GetCompaniesAllEntityActionsQuery,
    GetCompaniesAllEntityActionsQueryVariables
  >(getCompaniesAllEntityActions, {
    variables: { ids: similarCompaniesIds, extended: false },
    skip: !similarCompaniesIds.length
  });

  return (
    <div>
      <div className="pb-p70 flex items-center gap-g40 border-b border-solid border-border">
        <p className="text-content-title typography-title-medium">
          Similar companies
        </p>
        <Badge
          label={similarCompanies?.length?.toString() ?? ''}
          color="neutral"
        />
      </div>

      <div className="py-6 w-full">
        {!data ? (
          <SimilarCompaniesContentLoader />
        ) : (
          <div data-testid="SimilarCompanies" className="flex flex-col gap-y-4">
            {similarCompanies.map((similarCompany) => (
              <SimilarCompany
                key={similarCompany.id}
                similarCompany={similarCompany}
                onQualificationClick={handleOnQualificationClick}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SimilarCompanies;
