import { WarningIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import { useModal } from 'hooks/useModal';
import { EntityListType } from 'interfaces/SearchModel/Search';

interface DeleteEntityListConfirmationModalProps {
  entityName: string;
  entityType: EntityListType;
  onClose: () => void;
  onDeleteEntityList: () => Promise<void>;
}

const DeleteEntityConfirmationModal: React.FC<
  DeleteEntityListConfirmationModalProps
> = ({ entityName, onClose, onDeleteEntityList, entityType }) => {
  const title =
    entityType === EntityListType.COMPANY_SAVED_SEARCH
      ? `Delete ${entityName} search`
      : entityType === EntityListType.PEOPLE_SAVED_SEARCH
      ? `Delete ${entityName} search`
      : entityType === EntityListType.COMPANY_WATCHLIST
      ? `Delete ${entityName} list`
      : entityType === EntityListType.PEOPLE_WATCHLIST
      ? `Delete ${entityName} list`
      : '';

  const typeText =
    entityType === EntityListType.COMPANY_WATCHLIST ||
    entityType === EntityListType.PEOPLE_WATCHLIST
      ? 'list'
      : 'search';
  const onDeleteButtonClicked = async () => {
    onClose();
    await onDeleteEntityList();
  };

  return (
    <>
      <ModalTitle title={title} titleIcon={WarningIcon} sentiment="negative" />
      <p
        className="typography-paragraph text-content-default px-p50 pt-p50 pb-p80"
        id="alert-dialog-description"
      >
        Are you sure you want to delete this {typeText}? This <br /> action
        cannot be undone.
      </p>
      <ModalFooter>
        <button />
        <Button
          data-testid="DeleteEntityListModal-CancelButton"
          onClick={onClose}
          type="secondary"
          emphasis="medium"
          label="Cancel"
          autoFocus
        />
        <Button
          data-testid="DeleteEntityListModal-DeleteButton"
          onClick={onDeleteButtonClicked}
          type="negative"
          emphasis="high"
          label="Delete"
          autoFocus
        />
      </ModalFooter>
    </>
  );
};

export function useDeleteEntityListConfirmationModal() {
  const { show, isOpen, close } = useModal();

  type ModalOptions = Omit<DeleteEntityListConfirmationModalProps, 'onClose'>;
  const showDeleteEntityListConfirmationModal = (props: ModalOptions) => {
    show(<DeleteEntityConfirmationModal {...props} onClose={close} />, {
      showCloseBtn: false
    });
  };

  return {
    showDeleteEntityListConfirmationModal,
    isDeleteEntityListConfirmationModalOpen: isOpen
  };
}
