import Select from 'harmonic-components/Select/Select';
import { isNil, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import {
  SelectMode,
  getTagColorBySelectMode,
  getTagIconBySelectMode
} from './utils';

interface KeywordsMultiselectProps {
  selected: string[];
  disabled?: boolean;
  dataTestId?: string;
  onChange: (newOptions?: string[]) => void;
  initialFocus?: boolean;
  mode?: SelectMode;
}

const KeywordsMultiselect: React.FC<KeywordsMultiselectProps> = ({
  selected,
  onChange,
  initialFocus = false,
  mode = null,
  dataTestId
}) => {
  const [userInput, setUserInput] = useState('');
  const handleOnSubmit = (inputValue: string) => {
    if (inputValue === '') return;

    let inputValuesList = inputValue.split(',');
    // Trim values if there are more than one to avoid list separated by comma and spaces
    // In the case that there is only one value we allow spaces so we can match words
    if (inputValuesList.length > 1) {
      inputValuesList = inputValuesList.map((value) => value.trim());
    }

    const filteredInputValuesList = uniq([...selected, ...inputValuesList]);

    onChange(filteredInputValuesList);
  };

  const handleOnDelete = (value: string) => {
    const updatedValues = selected.filter(
      (selectedValue) => selectedValue !== value
    );
    onChange(updatedValues);
  };

  const label = useMemo(() => {
    if (isNil(mode)) return;
    if (mode === 'include') return 'Contains any of';
    if (mode === 'select') return 'Contains all of';
    if (mode === 'exclude') return 'Contains none of';
  }, [mode]);

  return (
    <div className="flex flex-col gap-g20">
      {label && (
        <label className="text-content-weak typography-label">{label}</label>
      )}
      <Select
        dataTestId={dataTestId}
        multiple
        selected={selected}
        onRemove={(keyword) => handleOnDelete(keyword)}
        filterable
        filterTerm={userInput}
        onFilterTermChange={(value) => setUserInput(value)}
        freeSolo
        placeholder="Add any keywords"
        initialFocus={initialFocus}
        getTagColorFromValue={() => getTagColorBySelectMode(mode)}
        getTagIconFromValue={() => getTagIconBySelectMode(mode)}
        onAdd={(value) => {
          handleOnSubmit(value);
          setUserInput('');
        }}
        hideDropdown={true}
        hideChevronDown={true}
      />
    </div>
  );
};

export default KeywordsMultiselect;
