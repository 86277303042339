import { useQuery } from '@apollo/client';
import {
  GetLocationsWithTypeaheadQuery,
  GetLocationsWithTypeaheadQueryVariables
} from '__generated__/graphql';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import RadioCard from 'harmonic-components/RadioCard/RadioCard';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { FilterGroupGeneratorId, SearchData } from 'interfaces/SearchV2';
import { debounce } from 'lodash';
import { getLocationTypeahead } from 'queries/typeahead';
import { useCallback, useEffect, useState } from 'react';
import { chipDisplayOrder } from './RegionPresets';
import { useLocationState } from './hooks/useLocationState';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
import { parseSelectedLocations } from './utils/utils';

export type LocationStateType =
  | SearchData[FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION]
  | SearchData[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION];

interface AddLocationProps {
  disabled?: boolean;
  onValueChange: (newLocationState?: LocationStateType) => void;
  locationState: LocationStateType;
}

const AddLocation = ({ onValueChange, locationState }: AddLocationProps) => {
  const {
    handleRegionClick,
    handleOnRemoveClick,
    handleLocationChange,
    handleExcludeLocationChange,
    locationFilterTerm,
    setLocationFilterTerm
  } = useLocationState({ locationState, onValueChange });
  const [debouncedTerm, setDebouncedTerm] = useState(locationFilterTerm);
  const debouncedSetTerm = debounce(setDebouncedTerm, 300);

  useEffect(() => {
    debouncedSetTerm(locationFilterTerm);
  }, [locationFilterTerm, debouncedSetTerm]);

  const { loading, data } = useQuery<
    GetLocationsWithTypeaheadQuery,
    GetLocationsWithTypeaheadQueryVariables
  >(getLocationTypeahead, {
    variables: { query: debouncedTerm },
    skip: locationFilterTerm.length === 0
  });

  const options = data?.getLocationsWithTypeahead || [];

  const included = parseSelectedLocations(locationState).includedLocationsArray;
  const excluded = parseSelectedLocations(locationState).excludedLocationsArray;
  const selected = [...included, ...excluded];

  const getTagColorFromValue = useCallback(
    (value: string) =>
      getTagColorBySelectMode(
        included.includes(value)
          ? 'include'
          : excluded.includes(value)
          ? 'exclude'
          : 'select'
      ),
    [included, excluded]
  );

  const getTagIconFromValue = useCallback(
    (value: string) =>
      getTagIconBySelectMode(
        included.includes(value)
          ? 'include'
          : excluded.includes(value)
          ? 'exclude'
          : 'select'
      ),
    [included, excluded]
  );

  return (
    <div className="my-p40">
      <Select
        filterTerm={locationFilterTerm}
        onFilterTermChange={setLocationFilterTerm}
        multiple
        filterable
        placeholder="Search City, State or Country"
        selected={selected}
        onRemove={handleOnRemoveClick}
        fullWidth
        getTagColorFromValue={(value) => getTagColorFromValue(value ?? '')}
        getTagIconFromValue={(value) => getTagIconFromValue(value ?? '')}
        dataTestId="location-include-exclude-multiselect"
      >
        {!loading && locationFilterTerm.length < 1 && (
          <div className="p-p40 w-full" data-testid="region-select">
            <div className="mb-p40">Select from presets:</div>
            <div className="grid grid-cols-3 place-content-around gap-2 w-full">
              {chipDisplayOrder.map((chipMeta) => (
                <RadioCard
                  key={chipMeta.title}
                  label={chipMeta.title}
                  icon={chipMeta.icon}
                  selected={!!locationState?.region?.includes(chipMeta.region)}
                  onClick={() => {
                    handleRegionClick(chipMeta.region);
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {loading && locationFilterTerm.length > 0 ? (
          <div className="flex w-full items-center justify-center">
            <HarmonicLoader showText={false} />
          </div>
        ) : (
          options.map((location) => {
            if (!location) {
              return null;
            }

            const selected = [
              ...(locationState?.locations ?? []),
              ...(locationState?.excluded_locations ?? [])
            ].some((value) => value.entity_urn === location.entityUrn); // entity_urn is used here as using text will cause clashes with legacy filter

            return (
              <SelectListItem
                variant={ListVariant.checkboxes}
                selected={selected}
                onChange={() =>
                  handleLocationChange({
                    selected,
                    location
                  })
                }
                key={location.entityUrn}
                label={location.text}
                value={location.text}
                onExclude={() =>
                  handleExcludeLocationChange({
                    selected,
                    location
                  })
                }
                excludeMode={excluded.includes(location.text)}
              />
            );
          })
        )}
      </Select>
    </div>
  );
};

export default AddLocation;
