import { Badge } from 'common/components';
import { ColorShorthand } from 'common/utils/design';
import { ICapTableEntry } from 'interfaces/DataModel/ApiResource';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { UNKNOWN_VALUE } from 'utils/constants';
import { getFundingStageBadgeColor } from 'utils/funding';

interface CapTableProps {
  companyId: number;
  capTableData?: ICapTableEntry[];
}

const CapTableCell: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <div className="border-solid border-b border-border p-p40 typography-label-small-default text-content-default">
      {children || UNKNOWN_VALUE}
    </div>
  );
};

const CapTableHeader: React.FC = () => {
  return (
    <div className="grid grid-cols-7">
      <CapTableHeaderCell>Stock</CapTableHeaderCell>
      <CapTableHeaderCell># of shares</CapTableHeaderCell>
      <CapTableHeaderCell>Value</CapTableHeaderCell>
      <CapTableHeaderCell>Issue price</CapTableHeaderCell>
      <CapTableHeaderCell>Liquidation</CapTableHeaderCell>
      <CapTableHeaderCell>Pref. multiple</CapTableHeaderCell>
      <CapTableHeaderCell>Conversion</CapTableHeaderCell>
    </div>
  );
};

const CapTableHeaderCell: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <div className="border-solid border-b py-p50 px-p40 border-border typography-label-small-default text-content-strong">
      {children}
    </div>
  );
};

const getRoundDisplay = (round: string, roundNumber: number | null): string => {
  if (roundNumber) {
    return getFundingTypeDisplayName(round) + '-' + roundNumber;
  } else {
    return getFundingTypeDisplayName(round);
  }
};

const getRoundColor = (round: string): ColorShorthand => {
  return getFundingStageBadgeColor(getFundingTypeDisplayName(round));
};

const CapTable: React.FC<CapTableProps> = ({ companyId, capTableData }) => {
  return (
    <div className="border-t border-r border-l border-border border-solid rounded-br30 overflow-x-scroll">
      <div className="min-w-[600px]">
        <CapTableHeader />
        {capTableData?.map((capTableRow, index) => (
          <div className="grid grid-cols-7" key={'grid-row-' + index}>
            <CapTableCell>
              <Badge
                label={getRoundDisplay(
                  capTableRow.round,
                  capTableRow.roundNumber
                )}
                color={getRoundColor(capTableRow.round)}
                size="small"
                intensity="subtle"
                borderRadius="square"
              />
            </CapTableCell>
            <CapTableCell>
              {capTableRow.shares && capTableRow.shares.toLocaleString()}
            </CapTableCell>
            <CapTableCell>
              {capTableRow.value && '$' + capTableRow.value}
            </CapTableCell>
            <CapTableCell>
              {capTableRow.issuePrice && '$' + capTableRow.issuePrice}
            </CapTableCell>
            <CapTableCell>
              {capTableRow.liquidation && '$' + capTableRow.liquidation}
            </CapTableCell>
            <CapTableCell>
              {capTableRow.pref_multiple && capTableRow.pref_multiple + 'x'}
            </CapTableCell>
            <CapTableCell>
              {capTableRow.conversion && '$' + capTableRow.conversion}
            </CapTableCell>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CapTable;
