import {
  SearchCompaniesBySemanticQueryVariables,
  SemanticField,
  SemanticQueryParserQuery
} from '__generated__/graphql';
import { ColorShorthand } from 'common';

export type SearchSettings = Omit<
  SearchCompaniesBySemanticQueryVariables,
  'parsedQuery'
>;

export interface LogQueryParams {
  userEmail: string;
  userID: number;
  query: string;
  settings: string;
  requestId?: string;

  // rating params
  rating?: 'good' | 'bad' | 'add-to-list';
  resultIds?: string;
  context?: string;
}

export enum SemanticOptionsEnum {
  PRECISE = 'Precise',
  STANDARD = 'Standard',
  BROAD = 'Broad',
  CUSTOM = 'Custom' // For internal users only - via the settings gear
}

export const SEMANTIC_OPTIONS = [
  {
    label: SemanticOptionsEnum.PRECISE,
    value: 0.65
  },
  {
    label: SemanticOptionsEnum.STANDARD,
    value: 0.55
  },
  {
    label: SemanticOptionsEnum.BROAD,
    value: 0.45
  }
];

// For POC page (/semantic)
export const DEFAULT_SEMANTIC_POC_SETTINGS: SearchSettings = {
  first: 25,
  similarity: SEMANTIC_OPTIONS[1].value,
  k: 1000,
  sortByEngagement: true,
  fieldsToMatch: [
    SemanticField.WEBSITE_TEXT_EMBEDDING,
    SemanticField.EXTERNAL_DESCRIPTION_EMBEDDING
  ],
  query: '',
  after: null,
  expandSemanticQuery: true
};

// For company page
export const DEFAULT_SEMANTIC_INPUTS = {
  query: '',
  k: 1000,
  similarity: SEMANTIC_OPTIONS[1].value
};

// Facets stuff
export interface FacetTagProps {
  label: string;
  color: ColorShorthand;
  leadingIcon: string;
  extraValues?: string[];
  tooltip?: string;
  formatter?: (value: string, operator?: string) => string;
  operator?: string;
}

export interface ParsingQueryTagProps {
  loading: boolean;
  parsedQuery: SemanticQueryParserQuery | undefined;
  expandSemanticQuery: boolean;
  setExpandSemanticQuery: (expandSemanticQuery: boolean) => void;
}

export type FacetInput = {
  fieldName: string;
  operator: string;
  filterValue: string | string[];
  parsedText: string;
};
