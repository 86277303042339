import Slider from '@material-ui/core/Slider';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getClosestNumberIndexInAnArray } from 'utils/utilities';
import ThumbComponent from './ThumbComponent';

interface RangeSliderProps {
  value: NumberRange;
  onValueChange: (newValue: NumberRange) => void;
  isActive?: boolean;
  // Custom value for each step of slider.
  // Eg: Say we have an array [10,20,500,1000].
  // When user drags one step to the right from 20, the value changes to 500. (Rather than 30)
  // To support that, slider will have internal state from 1 to stepRanges.length.
  // Internal state gets converted to the value from above array and vice versa
  stepRanges: number[];
  disabled?: boolean;
}

// Converts slider internal smaller value to the big values from the stepRanges array that we passed
const convertSliderValueToExternalValue = (
  stepIndex: number,
  stepRanges: number[]
): number => {
  return stepRanges[stepIndex];
};

// Converts big numbers to internal state smaller value(usually from 1 to 100 at max).
// Simply it stores the index to map to stepRanges value.
const convertExternalValueToSliderValue = (
  value: NumberRange,
  stepRanges?: number[]
): NumberRange => {
  if (!stepRanges) return value;
  const minimumElementIndex = getClosestNumberIndexInAnArray(
    value[0],
    stepRanges
  );
  const maximumElementIndex = getClosestNumberIndexInAnArray(
    value[1],
    stepRanges
  );
  return [minimumElementIndex, maximumElementIndex];
};

const RangeSlider: React.FC<RangeSliderProps> = ({
  value,
  onValueChange,
  stepRanges,
  isActive,
  disabled
}) => {
  const [currentRangeValue, setCurrentRangeValue] = useState<NumberRange>(
    convertExternalValueToSliderValue(value, stepRanges)
  );

  useEffect(() => {
    setCurrentRangeValue(convertExternalValueToSliderValue(value, stepRanges));
  }, [value, stepRanges]);

  const onSliderValueChange = (newValue: NumberRange) => {
    if (!stepRanges) return onValueChange(newValue);
    const formattedMinValue = convertSliderValueToExternalValue(
      newValue[0],
      stepRanges
    );
    const formattedMaxValue = convertSliderValueToExternalValue(
      newValue[1],
      stepRanges
    );

    const formattedValue = [
      newValue[0] === currentRangeValue[0] ? value[0] : formattedMinValue,
      newValue[1] === currentRangeValue[1] ? value[1] : formattedMaxValue
    ];
    onValueChange(formattedValue as NumberRange);
  };

  return (
    <Slider
      value={currentRangeValue}
      onChange={(_, newValue) => onSliderValueChange(newValue as NumberRange)}
      classes={{
        root: classNames(
          'w-full shadow-none',
          {
            'opacity-50': disabled
          },
          {
            'text-content-default': isActive,
            'text-border': !isActive
          }
        ),
        rail: 'text-border h-[2px]',
        thumb: '-mt-2'
      }}
      max={stepRanges.length - 1}
      min={0}
      step={1}
      disabled={disabled}
      ThumbComponent={(props) => (
        //eslint-disable-next-line
        //@ts-ignore
        <ThumbComponent {...props} disabled={disabled} selected={isActive} />
      )}
      aria-labelledby="range-slider"
    />
  );
};

export default RangeSlider;
