// helper function for now as we migrate things over to the new structure
// we need an array for the select component as that is what it expects

import { ILocation } from 'interfaces/SearchV2';
import { LocationStateType } from '../AddLocation';
import { chipDisplayOrder } from '../RegionPresets';

interface ISimpleLocation {
  text: string;
  type: 'city' | 'state' | 'country' | 'region';
}

interface IComplexLocation extends ILocation {
  text: string;
  type: 'locations' | 'excluded_locations';
}

type ILocationObject = ISimpleLocation | IComplexLocation;

// we need the object in order to understand what type of location it is and handle removals
interface IParseSelectedLocations {
  locationObject: ILocationObject[];
  includedLocationsArray: string[];
  excludedLocationsArray: string[];
}

export const parseSelectedLocations = (
  locationState: LocationStateType
): IParseSelectedLocations => {
  if (!locationState) {
    return {
      locationObject: [],
      includedLocationsArray: [],
      excludedLocationsArray: []
    };
  }
  const locationObject: ILocationObject[] = [];
  const locationArray: string[] = [];
  const excludedLocationArray: string[] = [];

  const keys: ISimpleLocation['type'][] = [
    'city',
    'state',
    'country',
    'region'
  ];

  for (const key of keys) {
    if (locationState[key as keyof typeof locationState]) {
      locationState[key as keyof typeof locationState]?.forEach((value) => {
        if (key === 'region') {
          value =
            chipDisplayOrder.find((chip) => chip.region === value)?.title ??
            value;
        }

        locationObject.push({
          text: value as string,
          type: key
        });
        locationArray.push(value as string);
      });
    }
  }

  locationState.locations?.forEach((location) => {
    locationObject.push({ ...location, type: 'locations' } as IComplexLocation);
    locationArray.push(location.text);
  });

  locationState.excluded_locations?.forEach((location) => {
    excludedLocationArray.push(location.text);
    locationObject.push({
      ...location,
      type: 'excluded_locations'
    } as IComplexLocation);
  });

  return {
    locationObject,
    includedLocationsArray: locationArray,
    excludedLocationsArray: excludedLocationArray
  };
};
